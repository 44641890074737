import "./stylesheet/Post.css";
import "../../../../../../../src/App.css";
import PostHeader from "./views/PostHeader";
import PostSection from "./views/PostSection";
import PostFooter from "./views/PostFooter";
import { useState } from "react";
import { useSelector } from "react-redux";
import { STRINGS, getItemBorderColor } from "../../../../../../utils/base";
import { useRef } from "react";
import { useEffect } from "react";
import { readCounterFeed } from "../../../store/slice";
import { useDispatch } from "react-redux";
import { readNotificationAction } from "../../../../notifiation/store/action";
import { NotificationFeatureTypeEnum } from "../../../../notifiation/enums";
import PostBody from "./views/postBody";
import { CanSharePostOnFeed, IsPostShareable } from "../../../utils/constants";

const Post = ({
  post = { attachments: [] },
  viewAllComments,
  reactionModule,
  referenceType,
  referenceId,
  isDetail = false,
  modulePrivacyId,
}) => {
  const [modelState, setmodelState] = useState(false);
  const {
    userSlice: { user },
  } = useSelector((state) => state);
  const userPermissions = user.permissions;

  const openModel = (value) => {
    setmodelState(value);
  };

  const dispatch = useDispatch();
  console.log("mmmmmmm", modulePrivacyId);

  const {
    id,
    creator,
    isPinned = false,
    tags,
    createDate,
    privacyId,
    attachments,
    comments,
    commentCount,
    reactionCount,
    myReaction,
    isImportant = false,
    mentions = [],
    notificationCount,
    notificationTypes,
    commentNotificationCount,
    commentNotificationTypes,
    parentId = STRINGS.DEFAULTS.guid,
    parent = null,
    type,
  } = post;

  const postRef = useRef(null);

  useEffect(() => {
    // Create an Intersection Observer for the post
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]; // There's only one entry for a single observed element
      if (entry.isIntersecting) {
        const postid = entry.target.dataset.postid;
        const notificationcount = entry.target.dataset.notificationcount;
        if (notificationcount - commentNotificationCount > 0) {
          setTimeout(() => {
            const count =
              notificationCount -
              (notificationCount - commentNotificationCount);
            dispatch(readCounterFeed({ postid, count }));
            const payload = {
              filter: {
                types: notificationTypes,
                featureType: NotificationFeatureTypeEnum.Feed,
                featureId: postid,
              },
            };

            dispatch(readNotificationAction(payload));
          }, 1000);
        }
      }
    });

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [post]);

  return (
    <div
      className={`${getItemBorderColor(
        notificationCount,
        false,
        isImportant,
        isPinned,
        mentions,
        user.id
      )} post`}
      key={id}
      data-postId={id}
      data-notificationCount={notificationCount}
      ref={postRef}
    >
      <PostHeader
        id={id}
        privacyId={privacyId}
        creator={creator}
        isPinned={isPinned}
        tags={tags}
        createDate={createDate}
      />
      <PostSection
        post={post}
        attachments={attachments}
        isOpen={modelState}
        onOpen={openModel}
        id={id}
        isDetail={isDetail}
        isShared={true}
      />
      <PostFooter
        key={post.id}
        isDetail={isDetail}
        id={id}
        comments={comments}
        reactionCount={reactionCount}
        commentCount={commentCount}
        isOpen={openModel}
        viewAllComments={viewAllComments}
        attachments={attachments}
        reactionModule={reactionModule}
        referenceType={referenceType}
        referenceId={referenceId}
        postRefType = {post.referenceType}
        postRefId = {post.referenceId}
        isDetailViewOpen={modelState}
        myReaction={myReaction}
        commentNotificationCount={commentNotificationCount}
        commentNotificationTypes={commentNotificationTypes}
        content={post.title}
        parentId={parentId}
        shareOnFeed = {CanSharePostOnFeed(type)}
        isSharable = {IsPostShareable(privacyId)}
        modulePrivacyId = {modulePrivacyId}
        post={
          <PostBody
            post={parent ? parent : post}
            isDetail={isDetail}
            isShared={true}
          />
        }
      />
    </div>
  );
};

export default Post;
