import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import {
  getCompanyAction,
  getRewardsAction,
  getAllLoanAction,
  getAllSignupAction,
  ResendSignupEmailAction,
  GetSignupById,
  getCompanyByIdAction,
  dashboardViewData,
  GetAllProductLoanSettings,
  SaveProductLoanSettings,
  GetAllAttachmentUploadSizeSettingAction,
  SaveAttachmentUploadSizeSetting,
  getBusinessDetailAction,
} from "./action";

const initialState = {
  ProductLoanSettings: [],
  AttachmentUploadSizeSetting: [],
  signup: [],
  dashBordData: {},
  teams: [],
  companies: [],
  company: {
    basicInfo: {},
  },
  team: {
    rewardsdetails: [],
    loandetails: [],
  },
  loader: false,
  success: false,
  resendSuccess: false,
  signupDetail: null,
  businessDetailData:{},
};

const companySlice = createSlice({
  name: "comapnies",
  initialState,
  reducers: {
    updateCompanyStatusReducer: (state, { payload }) => {
      const { businessId, newStatus } = payload
      const indexofBusiness = state.companies.findIndex(item => item.id === businessId)
      if (indexofBusiness !== -1) {
        state.companies[indexofBusiness].isDisable = newStatus
      }
    },
    updateProductLoanSetting: (state, { payload }) => {
      const { id, updatedData } = payload;
      const index = state.ProductLoanSettings.findIndex((setting => setting.id === id));
      if (index !== -1) {
        state.ProductLoanSettings[index] = { ...state.ProductLoanSettings[index], ...updatedData };
      }
    },
    updateAttachmentUploadSizeSetting: (state, { payload }) => {
      const { businessId, updatedData } = payload;
      const index = state.AttachmentUploadSizeSetting.findIndex(setting => setting.businessId === businessId);
      if (index !== -1) {
        state.AttachmentUploadSizeSetting[index] = { ...state.AttachmentUploadSizeSetting[index], ...updatedData };
      }
    },

    handleEnableDisableGlobalLimit: (state, { payload }) => {
      const { businessId } = payload;

      console.log(businessId, "ZOHAIB")

      try {
        const index = state.AttachmentUploadSizeSetting.findIndex(item => item.businessId === businessId);

        if (index !== -1) {
          state.AttachmentUploadSizeSetting[index].hasGlobalFileLimit = !state.AttachmentUploadSizeSetting[index].hasGlobalFileLimit;
        }
      } catch (e) {
        console.log(e, "error");
      }
    }


  },

  extraReducers: (builder) => {
    builder
      .addCase(getCompanyAction.fulfilled, (state, { payload }) => {
        state.companies = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(dashboardViewData.fulfilled, (state, { payload }) => {
        state.dashBordData = payload;
        state.loader = false;
      })
      .addCase(GetAllProductLoanSettings.fulfilled, (state, { payload }) => {
        state.ProductLoanSettings = payload.data.data;
      })
      .addCase(GetAllAttachmentUploadSizeSettingAction.fulfilled, (state, { payload }) => {
        state.AttachmentUploadSizeSetting = payload.data.data;
      })
      .addCase(SaveProductLoanSettings.fulfilled, (state, { payload }) => {
        let index = state.ProductLoanSettings.findIndex(({ id }) => id === payload?.data?.data?.id)
        state.ProductLoanSettings[index] = payload?.data?.data
      })
      .addCase(SaveAttachmentUploadSizeSetting.fulfilled, (state, { payload }) => {
        console.log("Payload on SaveAttachmentUploadSizeSetting:", payload);
        const updatedItem = payload.data.data;
        const index = state.AttachmentUploadSizeSetting.findIndex(item => item.businessId === updatedItem.businessId);
        if (index !== -1) {
          state.AttachmentUploadSizeSetting[index] = updatedItem;
        } else {
          state.AttachmentUploadSizeSetting.push(updatedItem);
        }
      })
      .addCase(getCompanyByIdAction.fulfilled, (state, { payload }) => {
        state.company.basicInfo = payload;
      })
      .addCase(getRewardsAction.fulfilled, (state, { payload }) => {
        state.team.rewardsdetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllLoanAction.fulfilled, (state, { payload }) => {
        state.team.loandetails = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllSignupAction.fulfilled, (state, { payload }) => {
        state.signup = payload;
      })
      .addCase(getBusinessDetailAction.fulfilled, (state, { payload }) => {
        state.businessDetailData = payload;
      })
      .addCase(ResendSignupEmailAction.fulfilled, (state, { payload }) => {
        state.resendSuccess = true;
      })
      .addCase(GetSignupById.fulfilled, (state, { payload }) => {
        state.signupDetail = payload.data.data;
      })
      .addCase(ResendSignupEmailAction.rejected, (state, { payload }) => {
        state.resendSuccess = false;
      })
      .addMatcher(isPending(ResendSignupEmailAction), (state) => {
        state.resendSuccess = false;
      })
      .addMatcher(isPending(getCompanyAction), (state) => {
        state.loader = true;
        state.success = false;
      })
      .addMatcher(isPending(getRewardsAction), (state) => {
        state.loader = true;
        state.success = false;
      })
      .addMatcher(isPending(getAllLoanAction), (state) => {
        state.loader = true;
        state.success = false;
      })
      .addMatcher(
        isRejected(...[getCompanyAction, getRewardsAction, getAllLoanAction]),
        (state) => {
          state.loader = false;
          state.success = false;
        }
      );
  },
});
export const {
  updateCompanyStatusReducer,
  updateProductLoanSetting,
  handleEnableDisableGlobalLimit,
  updateAttachmentUploadSizeSetting,

} = companySlice.actions

export default companySlice.reducer;
