import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AuctionOfferStatusEnum, AuctionStatusEnum, auctionFilterTypeEnum } from "../../../main/features/auction/utils/enums";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { CareerInterviewStatusEnum } from "../../../main/features/careers/utils/CareerInterviewStatusEnum";
import { CareerFilterSortEnum } from "../../../main/features/careers/utils/careerFilterSortEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerLevelEnum } from "../../../main/features/careers/utils/careerLevelTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { JobShiftTypeEnums } from "../../../main/features/careers/utils/jobShiftTypeEnum";
import { JobTypeEnums } from "../../../main/features/careers/utils/jobTypeEnum";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { PostReferenceType, PostType, ReactionType } from "../../../main/features/feed/utils/constants";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { LeaveFilterSortEnum } from "../../../main/features/leave/utils/leaveFilterSortEnum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { LoanFilterSortEnum } from "../../../main/features/loan/utils/loanFilterSortEnum";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { PromotionFilterSortEnum } from "../../../main/features/promotion/utils/PromotionFilterSortEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { ResignationFilterSortEnum } from "../../../main/features/resignation/utils/ResignationFilterSortEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ResignationPurposeEnum } from "../../../main/features/resignation/utils/resignationPurposeEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { TravelFilterSortEnum } from "../../../main/features/travel/utils/travleFilterSortEnum";
import { AccountTypeEnum } from "../../../main/features/trialBalance/utils/AccountTypeEnum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums";

const italian = {
    sharedLabels: {
        SelectLanguage: "Select Language",
        English: "English ",
        Turkish: "Turkish",
        Hindi: "Hindi",
        Urdu: "اردو",
        Arabic: "عربي",
        czech: "Czech",
        russian: "Russian",
        french: "French",
        spanish: "Spanish",
        italian: "Italian",
        theme: "Tema",
        awardTo: "Assegna a",
        inprogress: "In corso",
        filter: "Filtra",
        approvals: "Approvazioni",
        approvers: "Approvatori",
        members: "Membri",
        Approvers: "Approvatori",
        resignations: "Dimissioni",
        Description: "Descrizione",
        Descriptionisrequired: "La descrizione è obbligatoria!",
        WriteDescriptionhere: "Scrivi qui la descrizione...",
        enterDescription: "Inserisci descrizione",
        pleseEnterDescription: "Si prega di inserire una descrizione",
        pleaseEnterGrade: "Si prega di inserire il grado",
        manager: "Manager",
        requiredMessageManager: "Seleziona il Manager",
        SelectManager: "Seleziona Manager",
        create: "Crea",
        humanresource: "HR",
        finance: "Finanza",
        it: "IT",
        admin: "Admin",
        selectMember: "Seleziona Membro",
        othersapprovals: "Altre Approvazioni",
        exitinterview: "Intervista d'uscita",
        selectAperson: "Seleziona una persona",
        SelectHumanResource: "Seleziona HR",
        selectIt: "Seleziona IT",
        selectFinance: "Seleziona Finanza",
        selectAdmin: "Seleziona Admin",
        selectOthersApprovals: "Seleziona Altre Approvazioni",
        inProcess: "In corso",
        reason: "Motivo",
        refrence: "Riferimento",
        Rewards: "Ricompense",
        name: "Nome",
        add: "Aggiungi",
        action: "Azione",
        userType: "Tipo Utente",
        SelectUserType: "Seleziona Tipo Utente",
        requiredMessageUserType: "Si prega di selezionare il Tipo Utente",
        RewardTo: "Ricompensa Per",
        WarningTo: "Avviso Per",
        Approved: "Approvato",
        Declined: "Declinato",
        Resend: "Reinvia",
        NotRequired: "Non Richiesto",
        Cancelled: "Annullato",
        InActive: "Inattivo",
        ListView: "Vista Elenco",
        TableView: "Vista Tabella",
        MyReward: "La Mia Ricompensa",
        ForApprovals: "Per Approvazioni",
        ForApproval: "Per Approvazione",
        RewardToMe: "Ricompensa Per Me",
        Search: "Cerca",
        Disable: "Disabilita",
        Attachments: "Allegati",
        Update: "Aggiorna",
        update: "aggiorna",
        AddEmployee: "Aggiungi Dipendente",
        complain: "Lamentela",
        Submit: "Invia",
        Delete: "Elimina",
        Edit: "Modifica",
        NotDesignated: "Non Designato",
        Profile: "Profilo",
        Settings: "Impostazioni",
        Logout: "Esci",
        Table: "Tabella",
        List: "Lista",
        Grid: "Griglia",
        GridView: "Vista Griglia",
        startyear: "Anno di Inizio",
        endyear: "Anno di Fine",
        startMonth: "Mese di Inizio",
        endMonth: "Mese di Fine",
        EnterStartDate: "Inserisci Data d'Inizio",
        SelectStartDate: "Seleziona Data d'Inizio",
        startDate: "Data d'Inizio",
        endDate: "Data di Fine",
        businessLogo: "Logo Aziendale",
        title: "Titolo",
        enterTitle: "Inserisci Titolo",
        percentage: "Percentuale",
        PleaseEnterPercentage: "Si prega di inserire la Percentuale",
        previousCharge: "Carica Precedente",
        min: "Min",
        max: "Max",
        country: "Paese",
        requiredCountry: "Il Paese è obbligatorio",
        SelectCountry: "Seleziona Paese",
        latitude: "Latitudine",
        longitude: "Longitudine",
        address: "Indirizzo",
        EnterAddress: "Inserisci Indirizzo",
        requiredMessageAddress: "Si prega di inserire l'indirizzo",
        branch: "Filiale",
        question: "Domande",
        allotLeaves: "Giorni di Congedo Assegnati",
        SubjectRequired: "Oggetto Richiesto",
        WriteSubject: "Scrivi Oggetto",
        category: "Categoria",
        amount: "Importo",
        documents: "Documenti",
        enterName: "Inserisci Nome",
        nameRequired: "Il Nome è Richiesto",
        accessTo: "Accesso A",
        selectAccessTo: "Seleziona Accesso A",
        collaborators: "Collaboratori",
        selectCollaborators: "Seleziona Collaboratori",
        readers: "Lettori",
        selectReaders: "Seleziona Lettori",
        createBoard: "Crea Board",
        createPad: "Crea Pad",
        createGrid: "Crea Griglia",
        createSlide: "Crea Slide",
        createMindmap: "Crea Mappa Mentale",
        drive: "Drive",
        myDrive: "Il Mio Drive",
        allDocuments: "Tutti i Documenti",
        subject: "Oggetto",
        Subjectisrequired: "L'Oggetto è obbligatorio",
        pleaseEnterSubject: "Si prega di inserire l'Oggetto",
        EnterSubject: "Inserisci Oggetto",
        enterAmount: "Inserisci Importo",
        pleaseEnterAmount: "Si prega di inserire l'Importo",
        upload: "Carica",
        value: "Valore",
        addCategory: "Aggiungi Categoria",
        saveCategory: "Salva categoria",
        clear: "Cancella",
        loans: "Prestiti",
        disburse: "Erogare",
        referenceNo: "Numero di Riferimento",
        status: "Stato",
        createDate: "Data di Creazione",
        creator: "Creatore",
        netSalary: "Stipendio Netto",
        type: "Tipo",
        PleaseSelectType: "Si prega di Selezionare il Tipo",
        CreatedByMe: "Creato Da Me",
        Members: "Membri",
        pleaseEnterCategory: "Si prega di Inserire la Categoria",
        Memberisrequired: "Il Membro è Richiesto",
        EnterCategoryName: "Inserisci Nome Categoria",
        Clear: "Cancella",
        ReferenceNumber: "Numero di Riferimento",
        Grade: "Grado",
        Status: "Stato",
        CreateDate: "Data di Creazione",
        Creator: "Creatore",
        SNo: "N. ",
        Employee: "Dipendente",
        SelectApprovers: "Seleziona Approvatori",
        Approverisrequired: "L'approvatore è richiesto",
        Yes: "Sì",
        No: "No",
        totalAmount: "Importo Totale",
        Total: "Totale",
        Date: "Data",
        Pleaseselectdate: "Seleziona una data",
        SelectDate: "Seleziona Data",
        requiredMessageDate: "Seleziona una Data",
        Priority: "Priorità",
        Pleaseselectpriority: "Seleziona la priorità",
        Pleaseselecttype: "Seleziona il tipo",
        SelectProject: "Seleziona Progetto",
        SelectGroup: "Seleziona Gruppo",
        Project: "Progetto",
        Group: "Gruppo",
        Progress: "Progresso",
        SuretoRate: "Sicuro di voler valutare?",
        Invalidfiletype: "Tipo di file non valido",
        Pleaseinputyoursubject: "Inserisci il tuo oggetto",
        Pleaseinputyourdescription: "Inserisci la tua descrizione",
        Return: "Ritorna",
        EnterReason: "Inserisci Motivo",
        TravelReason: "Motivo del Viaggio",
        NoDesignation: "Nessuna Designazione",
        Hi: "Ciao",
        Quotation: "Preventivo",
        Amountisrequired: "L'importo è richiesto",
        WriteDescriptionHere: "Scrivi la tua descrizione qui...",
        Types: "Tipi",
        Header: "Intestazione",
        MyTeam: "Il Mio Team",
        Email: "Email",
        EnterEmail: "Inserisci Email",
        EmailIsRequired: "L'email è richiesta",
        PhoneNo: "Numero di Telefono",
        Designation: "Designazione",
        ReferenceType: "Tipo di Riferimento",
        referenceId: "ID di Riferimento",
        Save: "Salva",
        save: "salva",
        Color: "Colore",
        Yesterday: "Ieri",
        Overdue: "Scaduto",
        Today: "Oggi",
        Complete: "Completa",
        DueDate: "Data di Scadenza",
        SelectDueDate: "Seleziona Data di Scadenza",
        Cover: "Copertina",
        Cancel: "Annulla",
        cancel: "Annulla",
        and: "e",
        other: "altro",
        Others: "Altri",
        Option: "Opzione",
        Public: "Pubblico",
        Private: "Privato",
        click: "clic",
        With: "Con",
        with: "con",
        Comments: "Commenti",
        Commentsdot: "Commenti...",
        Comment: "Commento",
        Seeless: "Vedi meno",
        Seemore: "Vedi di più",
        Share: "Condividi",
        ShareNow: "Condividi Ora",
        SelectEmployee: "Seleziona Dipendente",
        SelectCategory: "Seleziona Categoria",
        PleaseSelectCategory: "Seleziona una Categoria",
        privacy: "Privacy",
        tags: "Tags",
        updateDate: "Data di Aggiornamento",
        otherApprovals: "Altre Approvazioni",
        resignationDate: "Data di Dimissioni",
        purpose: "Scopo",
        warnings: "Avvisi",
        Courses: "Corsi",
        selectType: "Seleziona tipo",
        daysCount: "Conteggio Giorni",
        reportingTo: "Riferito a",
        selectReason: "Seleziona Motivo",
        pleaseSelectReason: "Seleziona un Motivo",
        days: "Giorni",
        day: 'giorno',
        Day: 'Giorno',
        Dates: "Date",
        selectMembers: "Seleziona Membri",
        selectGrade: "Seleziona Grado",
        newGrade: "Nuovo Grado",
        currentGrade: "Grado Attuale",
        PleaseSelectMember: "Seleziona un Membro",
        enterCategory: "Inserisci Categoria",
        deadline: "Scadenza",
        Department: "Dipartimento",
        designationmessage: "Seleziona una Designazione",
        selectDesignation: "Seleziona Designazione",
        jobdescription: "Descrizione del Lavoro",
        salaryRange: "Fascia Salariale",
        interviewers: "Intervistatori",
        hiringBuddy: "Compagno di Assunzione",
        enterCity: "Inserisci Città",
        City: "Città",
        selectCity: "Seleziona Città",
        requiredCity: "Città è richiesta",
        skills: "Competenze",
        experience: "Esperienza (Anni)",
        Experience: "Esperienza",
        jobType: "Tipo di Lavoro",
        education: "Educazione",
        interview: "Intervistatore",
        Rating: "Valutazione",
        poweredbyWorkw: "Powered by Workwise Ltd - Copyrights 2016-",
        copyLink: "Copia Link",
        EffectiveDate: "Data Efficace",
        experienceRequired: "Esperienza Richiesta",
        user: "Utenti",
        postinterview: "Post Interviste",
        Copied: "Copiato",
        unreadNotifyItem: "Elemento di Notifica Non Letto",
        createBy: "Creato Da",
        Create: "Crea",
        Open: "Apri",
        Closed: "Chiuso",
        Nodescription: "Nessuna descrizione",
        writeDescription: "Scrivi la tua descrizione qui...",
        Employees: "Dipendenti",
        BasicInfo: "Informazioni di Base",
        FatherName: "Nome del Padre",
        EnterFatherName: "Inserisci il Nome del Padre",
        PersonalEmail: "Email Personale",
        EnterPersonalEmail: "Inserisci l'Email Personale",
        personalEmailIsRequired: "L'email personale è richiesta",
        PhoneNumber: "Numero di Telefono",
        EnterPhoneNumber: "Inserisci il Numero di Telefono",
        NationalIdentity: "Identità Nazionale",
        EnterNationalIdentityNumber: "Inserisci il Numero di Identità Nazionale",
        Grades: "Gradi",
        SelectGrade: "Seleziona il Grado",
        requiredMessageGrade: "Seleziona il Grado",
        EnterProbationPeriod: "Inserisci il Periodo di Prova",
        NoticePeriod: "Periodo di Preavviso (Giorni)",
        EnterNoticePeriod: "Inserisci il Periodo di Preavviso",
        requiredMessageNoticePeriod: "Inserisci il Periodo di Preavviso",
        requiredMessageNoticePeriodRestriction: "Il periodo di preavviso deve essere compreso tra 1 e 60",
        DateOfBirth: "Data di Nascita",
        Gender: "Genere",
        requiredMessageGender: "Seleziona il Genere",
        SelectGender: "Seleziona il Genere",
        MartialStatus: "Stato Civile",
        SelectMartialStatus: "Seleziona lo Stato Civile",
        requiredDepartment: "Il Dipartimento è richiesto",
        SelectDepartment: "Seleziona il Dipartimento",
        Subsidiary: "Sussidiaria",
        SelectSubsidiary: "Seleziona la Sussidiaria",
        SubsidiaryOffice: "Ufficio Sussidiaria",
        SelectSubsidiaryOffice: "Seleziona l'Ufficio Sussidiaria",
        PayrollGroup: "Gruppo Payroll",
        SelectPayrollGroup: "Seleziona il Gruppo Payroll",
        DefaultApproval: "Approvazione Predefinita",
        SelectDefaultApproval: "Seleziona l'Approvazione Predefinita",
        SelectAll: "Seleziona Tutto",
        NonMandatoryApprovals: "Approvazioni Non Obbligatorie",
        SelectNonMandatoryApprovals: "Seleziona le Approvazioni Non Obbligatorie",
        FirstName: "Nome",
        EnterFirstName: "Inserisci il Nome",
        requiredMessageName: "Inserisci il Nome",
        requiredMessageFName: "Inserisci il Nome",
        requiredMessageLName: "Inserisci il Cognome",
        LastName: "Cognome",
        EnterLastName: "Inserisci il Cognome",
        DateOfJoining: "Data di Assunzione",
        requiredDateOfBirth: "La Data di Nascita è richiesta",
        requiredMessageJoiningDate: "Seleziona la Data di Assunzione",
        JobShift: "Turno di Lavoro",
        requiredMessageJobShift: "Seleziona il Turno di Lavoro",
        SelectJobShift: "Seleziona il Turno di Lavoro",
        AccessRole: "Ruolo di Accesso",
        requiredMessageAccessRole: "Seleziona almeno un ruolo di accesso",
        SelectAccessRole: "Seleziona il Ruolo di Accesso",
        StartEndDate: "Data di Inizio/Fine",
        ErrorInValidation: "Errore nella convalida",
        EducationInfo: "Informazioni sull'Istruzione",
        NonMandatory: "Non Obbligatorio",
        Present: "Presente",
        FailedToFetch: "Impossibile recuperare",
        Position: "Posizione",
        EnterPosition: "Inserisci la Posizione",
        requiredMessagePosition: "Inserisci la Posizione",
        Organization: "Organizzazione",
        EnterOrganization: "Inserisci l'Organizzazione",
        requiredMessageOrganziation: "Inserisci l'Organizzazione",
        Number: "Numero",
        EnterNumber: "Inserisci il Numero",
        requiredMessageNumber: "Inserisci il Numero",
        Relation: "Relazione",
        Id: "ID",
        SelectRelation: "Seleziona la Relazione",
        requiredMessageRelation: "La Relazione è richiesta",
        AccountName: "Nome dell'Account",
        BranchCode: "Codice della Filiale",
        EnterBranchCode: "Inserisci il Codice della Filiale",
        requiredMessageBranchCode: "Il Codice della Filiale è richiesto",
        BankName: "Nome della Banca",
        requiredMessageBankName: "Il Nome della Banca è richiesto",
        EnterBankName: "Inserisci il Nome della Banca",
        AccountNumber: "Numero di Conto",
        EnterAccountNumber: "Inserisci il Numero di Conto",
        requiredAccountNumber: "Il Numero di Conto è richiesto",
        IBAN: "IBAN",
        EnterIBAN: "Inserisci l'IBAN",
        requiredIBAN: "L'IBAN è richiesto",
        SortCode: "Codice di Ordinamento",
        IsDefault: "Predefinito",
        requiredSortCode: "Il Codice di Ordinamento è richiesto",
        EnterSortCode: "Inserisci il Codice di Ordinamento",
        BankInfo: "Informazioni Bancarie",
        AccountTitle: "Titolo dell'Account",
        EnterAccountTitle: "Inserisci il Titolo dell'Account",
        requiredMessageAccount: "Il Titolo dell'Account è richiesto",
        validAccountTitle: "Inserisci un Titolo dell'Account valido",
        AttachmentType: "Tipo di Allegato",
        requiredAttchmentType: "Il Tipo di Allegato è richiesto",
        Attachment: "Allegato",
        requiredAttachment: "L'Allegato è richiesto",
        AddAttachment: "Aggiungi Allegato",
        requiredMessageAssignMember: "Seleziona un Membro",
        Remove: "Rimuovi",
        ContactNo: "Numero di Contatto",
        EnterContactNo: "Inserisci il Numero di Contatto",
        requiredContactNo: "Il Numero di Contatto è richiesto",
        Contact: "Contatto",
        pleasEnterDescription: "Inserisci una descrizione",
        Information: "Informazioni",
        Features: "Caratteristiche",
        AddMembers: "Aggiungi Membri",
        Summary: "Sommario",
        AcessRole: "Ruolo di Accesso",
        defaultApprovers: "Approvatori Predefiniti",
        NonMandatoryApprovers: "Approvatori Non Obbligatori",
        Pleasefillallrequiredfields: "Completa tutti i campi obbligatori",
        Suretodelete: "Sei sicuro di voler eliminare?",
        Code: "Codice",
        createdDate: "Data di Creazione",
        Searchmembers: "Cerca membri",
        AddDate: "Aggiungi Data",
        duesoon: "scadenza imminente",
        Payroll: "Paghe",
        ReferenceNo: "Numero di Riferimento",
        Associate: "Associato",
        InternalMembers: "Membri Interni",
        ExternalMembers: "Membri Esterni",
        TotalExpense: "Spesa Totale",
        TotalExpenseAmount: "Importo Totale della Spesa",
        TotalPost: "Post Totali",
        TotalMeeting: "Meeting Totali",
        Expense: "Spesa",
        TotalBudget: "Budget Totale",
        Balance: "Bilancio",
        successfully: "completato con successo",
        enterValidEmail: 'Inserisci un indirizzo email valido',
        ViewSummary: "Visualizza Riepilogo",
        AddAdmin: "Aggiungi Admin",
        AddExternals: "Aggiungi Esterni",
        DefaultApprovers: "Approvatori Predefiniti",
        Billings: "Fatturazioni",
        spent: "Speso",
        SelectUser: "Seleziona Utente",
        Image: "Immagine",
        Contacts: "Contatti",
        noContacts: "Nessun Contatto",
        AddContact: "Aggiungi Contatto",
        Roles: "Ruoli",
        Tag: "Tag",
        AccessRoles: "Ruoli di Accesso",
        member: "membro",
        uploadCover: "Carica Copertina",
        Pleaseselect: "Seleziona",
        composeEmail: "Componi Email",
        send: "Invia",
        ContactDetail: "Dettaglio Contatto",
        Detail: "Dettaglio",
        UploadImage: "Carica Immagine",
        WriteName: "Scrivi Nome",
        business: "Business",
        website: "Sito Web",
        Unknown: "Sconosciuto",
        Interested: "Interessato",
        NotInterested: "Non Interessato",
        meetings: "Incontri",
        noMeetings: "Nessun Incontro",
        Communications: "Comunicazioni",
        Details: "Dettagli",
        LastUpdate: "Ultimo Aggiornamento",
        nameRequiredMessage: "Nome richiesto!",
        addressRequiredMessage: "Indirizzo richiesto!",
        Intrested: "Interessato",
        StartDateTime: "Data e Ora di Inizio",
        DateTimeisrequired: "Data e Ora richieste",
        Selectdatetime: "Seleziona data e ora",
        Board: "Board",
        Notes: "Note",
        Miletap: "Miletap",
        EMail: "E-mail",
        Attendance: "Presenza",
        Absent: "Assente",
        NoData: "Nessun Dato",
        Prority: "Priorità",
        Heigh: "Alta",
        Medium: "Media",
        Low: "Bassa",
        error: "errore",
        Select: "Seleziona",
        Interview: "Colloquio",
        Meeting: "Incontro",
        Appointment: "Appuntamento",
        Pleaseinputyouremail: "Inserisci la tua email",
        EnterEmailAddress: "Inserisci l'indirizzo email",
        AddExternalMember: "Aggiungi Membro Esterno",
        Pleaseenterexternalmembers: "Inserisci i membri esterni",
        externals: "esterni",
        Decline: "Rifiuta",
        Approve: "Approva",
        NotAvailable: "Non Disponibile",
        AddApprovals: "Aggiungi Approvazioni",
        Everyone: "Tutti",
        SelectDownloaders: "Seleziona Downloader",
        SelectCollabrators: "Seleziona Collaboratori",
        pleaseSelectAssignMemeber: "Seleziona un Membro da Assegnare!",
        Availed: "Utilizzato",
        Allotted: "Assegnato",
        Degree: "Titolo di Studio",
        Time: "Tempo",
        Mood: "Umore",
        CheckOut: "Check Out",
        CheckIn: "Check In",
        Late: "In Ritardo",
        State: "Stato",
        PleaseSelectYesNo: "Seleziona Sì/No",
        PleaseselectGrade: "Seleziona il Grado",
        PleaseenterAmount: "Inserisci l'Importo",
        Salary: "Stipendio",
        Task: "Compito",
        Nameisrequired: "Il Nome è richiesto",
        PhoneNumberisrequired: "Il Numero di Telefono è richiesto",
        Dateisrequired: "La Data è richiesta",
        Month: "Mese",
        Year: "Anno",
        FinalApprovers: "Approvatori Finali",
        link: "Link",
        forFinalApproval: "Per l'Approvazione Finale",
        validateBonus: "Impossibile processare il bonus: nessuna informazione sullo stipendio disponibile per il dipendente",
        validateLoan: "Impossibile processare il prestito: nessuna informazione sullo stipendio disponibile per il dipendente",
        User: "Utente",
        Action: "Azione",
        Created: "Creato",
        Toast: "Toast",
        selectyourcountry: "Seleziona il tuo paese",
        PleaseEnterPhoneNumber: "Inserisci il Numero di Telefono",
        Pleaseselectyourcity: "Seleziona la tua città",
        SelectCurrency: "Seleziona Valuta",
        Pleaseselectcurrency: "Seleziona la valuta",
        totalGroups: "Gruppi Totali",
        totalProjects: "Progetti Totali",
        totalMemory: "Memoria Totale",
        memory: "Memoria",
        EnterMemory: "Inserisci Memoria",
        availedMemory: "Memoria Utilizzata (GB)",
        used: "Usato",
        usedMemory: "Memoria Utilizzata (GB)",
        From: "Da",
        To: "A",
        WelcomeSendEmail: "Benvenuto Invio Email",
        SendEmail: "Invia Email",
        writeYourRemarksHere: "Scrivi qui i tuoi commenti...",
        NoRemarkFound: "Nessun commento trovato",
        ToastEnable: "Abilita Toast",
        EmailEnable: "Abilita Email",
        FeatureName: "Nome Funzione",
        LoginMessage: "Per favore, inserisci le credenziali prima",
        netAmount: "Importo Netto",
        SelectedDate: "Data Selezionata",
        SelectedUser: "Utente Selezionato",
        Default: "Predefinito",
        mylog: "Il Mio Log",
        SelectStatus: "Seleziona Stato",
        Selectuser: "Seleziona un utente",
        PolicyType: "Tipo di Polizza",
        Percent: "Percentuale",
        uploadProfilePicture: "Carica Foto Profilo",
        inputUsername: "Inserisci nome utente",
        userName: "Nome utente",
        Dummy: "Dummy",
        Lesson: "Lezione",
        Duration: "Durata",
        Text: "Testo",
        Enterlink: "Inserisci link",
        Dashboard: "Dashboard",
        searchSchedule: "Cerca programma...",
        AddOption: "Aggiungi Opzione",
        ChangeImage: "Cambia Immagine",
        DeleteImage: "Elimina Immagine",
        Question: "Domanda",
        Next: "Avanti",
        Prev: "Precedente",
        Wrong: "Sbagliato",
        Correct: "Corretto",
        YourResult: "Il Tuo Risultato",
        Createdby: "Creato da",
        Lastupdated: "Ultimo aggiornamento",
        Assignedto: "Assegnato a",
        Addlesson: "Aggiungi lezione",
        Addtopic: "Aggiungi argomento",
        minutes: "minuti",
        Modules: "Moduli",
        Valid: "Valido",
        Expired: "Scaduto",
        AssignedMembers: "Membri assegnati",
        Start: "Inizio",
        Previous: "Precedente",
        Read: "Leggi",
        DeleteBook: "Elimina Libro",
        Assign: "Assegna",
        Explanation: "Spiegazione",
        Hey: "Ciao",
        requiredMessageEmail: "Inserisci l'indirizzo email",
        BusinessName: "Nome dell'azienda",
        PleaseEnterBusinessName: "Inserisci il nome dell'azienda",
        BusinessAddress: "Indirizzo dell'azienda",
        PleaseEnterBusinessAddress: "Inserisci l'indirizzo dell'azienda",
        Offers: "Offerte",
        PleaseEnterOffer: "Inserisci l'offerta",
        CreateOffer: "Crea Offerta",
        AppliedDate: "Data di applicazione",
        ShortList: "Lista breve",
        Finalised: "Finalizzato",
        Member: "Membro",
        SelectMember: 'Seleziona membro',
        Approver: "Approvatori",
        CategoryName: "Nome della categoria",
        serialNo: "Numero di serie",
        Allocated: "Allocato",
        Available: "Disponibile",
        lost: "Perso",
        damage: "Danneggiato",
        itemType: "Tipo di articolo",
        Service: "Servizio",
        consumable: "Consumabile",
        NonConsumable: "Non consumabile",
        Quantity: "Quantità",
        accountType: "Tipo di account",
        PleaseEnterQuantity: "Inserisci una quantità",
        QuantityMustBePositive: "La quantità deve essere positiva",
        MembersIsRequired: "Membri richiesti",
        CreateRequest: "Crea Richiesta",
        IBANNumber: "Numero IBAN",
        About: "Informazioni",
        AboutMe: "Su di me",
        TimePeriod: "Periodo di tempo",
        Company: "Azienda",
        CityTown: "Città/Comune",
        BankDetails: "Dettagli bancari",
        Work: "Lavoro",
        addWorkPlace: "Aggiungi luogo di lavoro",
        AddEducation: "Aggiungi istruzione",
        basicSalary: "Stipendio base",
        feed: 'Feed',
        TakeaNote: "Fai una nota",
        awards: 'Premi',
        Appraisal: "Valutazione",
        salary: 'Stipendio',
        activityLog: "Registro attività",
        device: 'Dispositivo',
        leave: 'Congedo',
        Ip: "IP",
        DeviceId: "ID dispositivo",
        AppVersion: "Versione dell'app",
        Selectfeature: "Seleziona la funzionalità",
        EnterYourFeedBackDescription: "Inserisci la descrizione del tuo feedback",
        HideAll: "Nascondi tutto",
        ShowAll: "Mostra tutto",
        PrintBy: "Stampa per",
        AccCode: "Codice Acc.",
        SelectAccountType: "Seleziona tipo di conto",
        Files: "File",
        Space: "Spazio",
        DisburseVoucher: "Distribuire/Buono",
        TopUsersbyMostFiles: "Utenti principali per numero di file",
        TopUsersbyStorageSize: "Utenti principali per dimensione dello spazio",
        Apply: "Applica",
        NumberOfTopUsers: "Numero di utenti principali",
        TopUsers: "Utenti principali",
        SearchUser: "Cerca utente",
        ByStorageSize: "Per dimensione dello spazio",
        ByFileSize: "Per dimensione del file",
        companyDashboard: "Dashboard aziendale",
        signup: "Iscrizione non confermata",
        billing: "Fatturazione",
        PendingBills: "Fatture in sospeso",
        MonthYear: "Mese - Anno",
        SendBill: "Invia fattura",
        BillingUsers: "Utenti di fatturazione",
        Item: "Articolo",
        Rate: "Tariffa",
        PaidAmount: "Importo pagato",
        PaymentDate: "Data di pagamento",
        DarkDays: "Giorni bui",
        ProcessingFee: "Spese di elaborazione",
        Currency: "Valuta",
        IsEnable: "È abilitato",
        Actions: "Azioni",
        TakeNote: "Prendi una nota",
        PleaseSelectUsers: "Seleziona utenti",
        StickyNotes: "Note adesive",
        Copy: "Copia",
        MarkAllRead: "Segna tutto come letto",
        SeeAll: "Vedi tutto",
        Refresh: "Ricarica",
        close: "Chiudi",
        Calling: "Chiamata",
        LoadMore: "Carica altro",
        Quizez: "Quiz",
        content: "Contenuto",

    },
    /*** navMenuLabel only used in LeftSideBar ***/
    navMenuLabel: {
        Schedules: "Orari",
        workWiseCompanies: "Aziende Workwise",
        promotions: "Promozioni",
        customApprovals: "Approvazioni Personalizzate",
        feed: "Feed Notizie",
        messenger: "Messaggero",
        chartOfAccount: "Piano dei Conti",
        createVoucher: "Crea Voucher",
        voucherList: "Elenco Voucher",
        Quotation: "Preventivi",
        QuotationClient: "Cliente Preventivo",
        transaction: "Transazioni",
        report: "Report",
        mailBox: "Posta in Arrivo",
        groups: "Gruppi",
        projects: "Progetti",
        tasks: "Compiti",
        discussionBoard: "Bacheca delle Discussioni",
        pages: "Pagine",
        workBoard: "Bacheca di Lavoro",
        leadManager: "Gestione dei Lead",
        schedules: "Orari",
        expense: "Spese",
        travel: "Viaggi",
        docsArchives: "Documenti/Archivi",
        eLearning: "E-Learning",
        inventory: "Inventario",
        myTeam: "Il Mio Team",
        orgChart: "Organigramma",
        employee: "Dipendenti",
        administration: "Amministrazione",
        appraisals: "Valutazioni",
        departments: "Dipartimenti",
        leaves: "Congedi",
        basicInfo: "Informazioni di Base",
        holidays: "Giorni Festivi",
        careers: "Carriere",
        companiesSection: "Aziende Workwise",
        companies: "Aziende",
        menu: "Menu",
        hr: "HR",
        finance: "Finanza",
        inventorySection: "Inventario",
        privacy: "Privacy",
        TC: "Termini e Condizioni",
        more: "Altro",
        download: "Download",
        feedBack: "Feedback",
        warnings: "Avvisi",
        complains: "Reclami",
        bonus: "Bonus",
        resignations: "Dimissioni",
        requisition: "Richieste",
        assets: "Assegnazione di Beni",
        assetsList: "Elenco Beni",
        createAssets: "Crea Beni",
        requestListItems: "Richiesta di Articoli",
        rewards: "Ricompense",
        businessPolicy: "Politiche Aziendali",
        companiesPolicy: "Politiche Aziendali",
        form: "Moduli",
        ledgerReport: "Rapporti di Contabilità",
        trialBalance: "Bilancio di Verifica",
        salary: "Stipendio",
        payroll: "Paghe",
        jobBoard: "Bacheca Lavoro",
        teams: "Il Mio Team",
        LD: "Formazione e Sviluppo",
        marketPlace: "Mercato",
        loan: "Prestiti",
        Courses: "Corsi",
        selectMembers: "Seleziona Membri",
        auction: "Asta",
        Nodescription: "Nessuna descrizione",
        contract: "Contratto"

    },
    /*** AppHeader only used in Component headerTab ***/
    appHeader: {
        newsFeed: {
            posts: "Post",
            photos: "Foto",
            videos: "Video",
            polls: "Sondaggi",
            docs: "Documenti",
            tagged: "Taggato",
        },
        mailBox: {},
        messenger: {},
        groups: {
            groups: "Gruppi",
        },
        projects: {
            projects: "Progetti",
            dashboard: "Dashboard",
        },
        Task: {
            Tasks: "Compiti",
            myTask: "I Miei Compiti",
            assignedByMe: "Assegnati Da Me",
            createdByMe: "Creati Da Me",
            teamTask: "Compiti di Squadra",
            dashboard: "Dashboard",
        },
        leadManager: {
            leadCategory: "Categoria Lead",
        },
        schedules: {
            calendar: "Calendario",
            schedule: "Pianifica",
            scheduleInterviews: "Pianifica Colloqui",
        },
        expense: {
            expenses: "Spese",
            forApprovals: "In Attesa di Approvazione",
            myExpenses: "Le Mie Spese",
            forExecution: "In Attesa di Esecuzione",
            forFinance: "In Attesa di Finanza",
            reports: "Report",
            dashboard: "Dashboard",
        },
        travel: {
            travels: "Viaggi",
            forApprovals: "In Attesa di Approvazione",
            agentProcess: "Processo dell'Agente",
            travelDetail: "Dettaglio Viaggio",
        },
        docsArchives: {
            documents: "Documenti",
            myDrive: "Il Mio Drive",
            myDocuments: "I Miei Documenti",
            forApprovals: "In Attesa di Approvazione",
        },
        eLearning: {
            dashboard: "Dashboard",
        },
        inventory: {
            approvals: "Approvazioni",
            inventory: "Inventario",
            category: "Categoria",
        },
        customApproval: {
            CreateCustomApproval: "Crea Approvazione Personalizzata",
            CustomApproval: "Approvazione Personalizzata",
            ForApproval: "In Attesa di Approvazione",
            MyCustomApprovals: "Le Mie Approvazioni Personalizzate",
            AllCustomApproval: "Tutte le Approvazioni Personalizzate",
        },
        payroll: {
            payroll: "Paghe",
            payrollApproval: "Approvazione Paghe",
            salaryApproval: "Approvazione Salario",
        },
        administration: {
            administration: "Amministrazione",
        },
        appraisals: {
            appraisals: "Valutazioni",
        },
        reward_category: {
            reward_category: "Categoria Ricompensa",
        },
        departments: {
            departments: "Dipartimenti",
        },
        leaves: {
            myLeaves: "I Miei Congedi",
            forApprovals: "In Attesa di Approvazione",
        },
        holidays: {
            holidays: "Vacanze",
        },
        warnings: {
            warnings: "Avvisi",
        },
        resignations: {
            resignations: "Dimissioni",
            approvers: "Approvazioni",
        },
        complains: {
            complains: "Lamentele",
            approvers: "Approvazioni",
            createComplain: "Crea Lamentela",
        },
        rewards: {
            rewards: "Ricompense",
            AllRewards: "Tutti i Bonus",
            CreatedByMe: "Creati Da Me",
            ForApprovals: "In Attesa di Approvazione",
            MyRewards: "Le Mie Ricompense",
        },
        promotions: {
            Promotions: "Promozioni",
        },
        salary: {
            Salary: "Stipendio",
            CreateSalary: "Crea Stipendio",
        },
        pages: {
            Pages: "Pagine",
        },
    },
    /*** Main Menu ***/
    globalSearch: {
        All: "Tutto",
        feed: "Feed",
        Lead_Manager: "Gestore dei lead",
        Travel: "Viaggio",
        Document: "Documento",
        Project: "Progetto",
        Workboard: "Lavagna di lavoro",
        Group: "Gruppo",
        Expense: "Spesa",
        Task: "Attività",
        Employee: "Dipendente",
        e_learning_course: "Corso e-learning",
        e_learning_videos: "Video e-learning",
        e_learning_article: "Articolo e-learning",
        e_learning_quiz: "Quiz e-learning",
        e_learning_book: "Libro e-learning",
        e_learning_tedTalks: "TED Talks e-learning",
        rewards: "Premi",
    },

    auction: {
        auctionType: "Tipo Asta",
        personal: "Personale",
        official: "Ufficiale",
        offer: "Offerta",
        startingBid: "Offerta Iniziale",
        buyNowAmount: "Importo Acquisto Immediato",
        immediateBuy: "Acquisto Immediato",
        minIncreaseBid: "Aumento Minimo dell'Offerta",
        Auction: "Asta",
        CreateAuction: "Crea Asta",
        actionType: "Tipo Azione",
        external: "Esterno",
        auctionDetail: "Dettagli Asta",
        currentBid: "Offerta Attuale",

        FormLabel: {
            startingBid: "Offerta Iniziale",
            member: "Membro",
            approvers: "Approvatori",
            minIncreaseBid: "Aumento Minimo dell'Offerta",
            buyNowAmount: "Importo Acquisto Immediato",
            offer: "Offerta",
            immediateBuy: "Acquisto Immediato",
        },
        FormPlaceholder: {
            name: "Inserisci Nome Asta",
            startingBid: "Inserisci Offerta Iniziale",
            minIncreaseBid: "Inserisci Aumento Minimo dell'Offerta",
            selectMember: "Seleziona Membro",
            selectApprovers: "Seleziona Approvatori",
            buyNowAmount: "Inserisci Importo Acquisto Immediato",
            offer: "Inserisci Offerta",

        },
        FormRequiredMessage: {
            name: "Inserisci Nome Asta",
            startingBid: "Inserisci Offerta Iniziale",
            minIncreaseBid: "Inserisci Aumento Minimo dell'Offerta",
            buyNowAmount: "Inserisci Importo Acquisto Immediato",
            offer: "Inserisci Offerta",
            endDate: "Inserisci Data di Scadenza",

        },
        auctionFilterTypeEnumLabel: {
            [auctionFilterTypeEnum.All]: "Tutte le Aste",
            [auctionFilterTypeEnum.CreatedByMe]: "Le Mie Aste",
            [auctionFilterTypeEnum.ForApproval]: "In Attesa di Approvazione",
        },
        statusEnum: {
            [AuctionStatusEnum.InProcess]: "In Processo",
            [AuctionStatusEnum.Approved]: "Approvata",
            [AuctionStatusEnum.Declined]: "Declinata",
            [AuctionStatusEnum.Resend]: "Rinviata",
            [AuctionStatusEnum.InActive]: "Inattiva",
            [AuctionStatusEnum.NotRequired]: "Non Richiesta",
            [AuctionStatusEnum.Cancelled]: "Annullata",
            [AuctionStatusEnum.Hold]: "In Sospeso",
            [AuctionStatusEnum.Sold]: "Venduta",

        },
        auctionOfferStatus: {
            [AuctionOfferStatusEnum.BidInProcess]: "Offerta in Corso",
            [AuctionOfferStatusEnum.BidSold]: "Offerta Venduta",
            [AuctionOfferStatusEnum.OutBid]: "Offerta Superata",

        },
        noDataMessage: "Crea Asta",
    },
    newsFeed: {
        WriteForMe: "Scrivi per me",
        AddDocuments: "Aggiungi Documenti",
        AddPolls: "Aggiungi Sondaggi",
        WhatsOnYourMind: "Cosa ti passa per la mente",
        Direction: "ltr",
        Generate: "Genera",
        Change: "Cambia",
        Whatwouldyouliketotalkabout: "Di cosa vorresti parlare?",
        Post: "Pubblica",
        Poll: "Sondaggio",
        Documents: "Documenti",
        PhotoVideo: "Foto/Video",
        Addpolloption: "Aggiungi opzione di voto",
        Removepoll: "Rimuovi sondaggio",
        WriteName: "Scrivi Nome",
        AddImageVideo: "Aggiungi Immagine/Video",
        CheckIn: "Check In",
        ShareonFeed: "Condividi sulla Bacheca",
        ShareinChat: "Condividi in Chat",
        NoPosts: "Nessun Post...",
        Nomoreposts: "Non ci sono più post...",
        noDataMessage: "Crea un post ora!",
        Saysomethingaboutthis: "Dì qualcosa su questo...",
        specificTimeline: " cronologia di",
        SharedestinationNames: {
        [SharePostDestinations.FEED]: "Feed",
        [SharePostDestinations.MEMBERS_PROFILE]: "Profilo del membro",
        [SharePostDestinations.GROUP]: "Gruppo",
        [SharePostDestinations.PROJECT]: "Progetto",
        [SharePostDestinations.TIMELINE]: "Timeline",
        },
        SharePostSuggestionPlaceholder: {
        [SuggestionTypeEnum.Employee]: "Seleziona membro",
        [SharePostDestinations.GROUP]: "Seleziona gruppo",
        [SharePostDestinations.PROJECT]: "Seleziona progetto",
        },
        CreateFeedNow: "Crea un feed ora!",

        QuickOptions: {
            CreateSlide: "Crea Slide",
            CreateBoard: "Crea Bacheca",
            CreateGrid: "Crea Griglia",
            CreatePad: "Crea Pad",
            CreateTask: "Crea Compito",
            CreateExpense: "Crea Spesa",
            AddUser: "Aggiungi Utente",
        },
        reactionDescription: {
            [ReactionType.Like]: "Mi piace",
            [ReactionType.Love]: "Amore",
            [ReactionType.NoReaction]: "Mi piace",
            [ReactionType.Support]: "Apprezzo",
            [ReactionType.Celebrate]: "Celebro",
            [ReactionType.Curious]: "Curioso",
        },
        PostTypePlaceHolder: {
            [PostType.DEFAULT]: "Cosa ti passa per la mente",
            [PostType.POLL]: "Chiedi qualcosa...",
        },
        Titlecantbeempty: "Il titolo non può essere vuoto",
        Attachmentscantbeempty: "Gli allegati non possono essere vuoti",
        Optioncantbeempty: "L'opzione non può essere vuota",
        SuccessfullySharedonFeed: "Condiviso con successo sulla Bacheca",
        SuccessfullySharedonMembersProfile: "Condiviso con successo sul profilo del membro",
        SuccessfullyPostSharedMessage:{
        [PostReferenceType.MAIN_FEED]: "Condiviso con successo sul Feed principale",
        [PostReferenceType.GROUP]: "Condiviso con successo nel Gruppo",
        [PostReferenceType.PROJECTS]: "Condiviso con successo sul Progetto",
        [PostReferenceType.TIMELINE]: "Condiviso con successo sulla Timeline",
        },
    },
    mail: {
        composer: "Componi",
        menuItem: {
            inbox: "Posta in arrivo",
            starred: "In evidenza",
            snoozed: "Posticipate",
            sent: "Inviata",
            spam: "Posta indesiderata",
            archive: "Archivio",
            trash: "Cestino",
            draft: "Bozze",
            allMail: "Tutta la posta",
            createNewLabel: "Crea nuova etichetta",
        },
        close: "Chiudi",
        send: "Invia",
        newMessage: "Nuovo Messaggio",
        newEmail: "Crea Nuova Email",
        ComposeEmail: "Componi E-mail",
    },
    messenger: {
        Messenger: "Messaggero",
        Typing: "Digitando...",
        TypeaMessage: "Digita un Messaggio...",
        Recording: "Registrazione...",
        nameYourGroup: "Nome del tuo Gruppo",
        Create: "Crea",
        GroupNameRequired: "Nome del Gruppo Richiesto",
        searchMemberToAdd: "Cerca Membro da Aggiungere",
        contacts: "Contatti",
        selected: "Selezionato",
        whatwouldyoutalkingabout: "Di cosa vuoi parlare?",
        SayHi: "Saluta i tuoi Contatti",
    },
    marketPlace: {
        jobBoardName: "Bacheca Lavoro",
        requsitionName: "Richiesta",
    },
    discussionBoard: {
        DiscussionBoard: "Bacheca Discussioni",
        Topic: "Argomento",
        pleaseEnterTopicName: "Inserisci il nome dell'argomento",
        enterTopicName: "Inserisci il nome dell'argomento",
        addDiscussionBoard: "Aggiungi Bacheca Discussioni",
        errormessage: "Si è verificato un errore durante il recupero della bacheca discussioni.",
        noDataMessage: "– Crea una discussione adesso!",
    },
    pages: {
        messageName: "Inserisci il nome",
        tags: "Tag",
        messageTags: "Inserisci i tag",
        enterTag: "Inserisci i tag",
        createPage: "Crea Pagina",
        noDataMessage: "– Crea una pagina adesso!"
    },
    group: {
        group: "Gruppi",
        createGroup: "Crea Gruppo",
        Updategroup: "Aggiorna gruppo",
        enterGroupName: "Inserisci il nome del gruppo.",
        Code: "Codice",
        exampleText: "Esempio: G (per un nome di gruppo)",
        GroupTag: "Tag di Gruppo",
        AddAdmin: "Aggiungi Amministratore",
        TotalBudget: "Budget Totale",
        Spent: "Speso",
        Balance: "Bilancio",
        EnterGroupTag: "Inserisci il tag del gruppo",
        SaveGroupTag: "Salva Tag di Gruppo",
        AddGroupTag: "Aggiungi Tag di Gruppo",
        EditGroup: "Modifica Gruppo",
        CreateGroupNow: "Crea un gruppo ora!"
    },
    project: {
        WIP: "WIP",
        privateProject: "Private Project",
        Projects: "Projects",
        UpdateProject: "Update Project",
        CreateProject: "Create Project",
        noDataMessage: "– Create Project now!",
        ProjectTag: "ProjectTag",
        ProjectRole: "Project Role",
        exampleProjectName: "E.g. P (for a project name)",
        projectDate: "Project Date",
        EnterProjectTag: "Enter Project Tag",
        SaveProjectTag: " Save ProjectTag",
        AddProjectTag: "Add ProjectTag",
        editProject: "Edit Project",
        PersonalNotes: "Personal Notes",
        EnterProjectRole: "Enter Project Role",
        SaveCustomTag: "Save Custom Tag",
        AddProjectRole: "Add Project Role",
        TakeaNote: "Take a Note",
        PublicProject: "Progetto pubblico",
    },
    tasks: {
        Tasks: "Compiti",
        Predecessor: "Predecessore",
        AssignTo: "Assegna A",
        TaskDate: "Data Compito",
        CreateTask: "Crea Compito",
        TaskAssignTo: "Assegna Compito A",
        Rating: "Valutazione",
        TaskDetail: "Dettaglio Compito",
        ProgressofAssignedmembers: "Progresso dei Membri Assegnati",
        SubTasks: "Sotto-Compiti",
        SubGoals: "Sotto-Obiettivi",
        Subtask: "Sotto-compito",
        CreateSubtask: "Crea Sotto-compito",
        Addsubtask: "Aggiungi sotto-compito",
        AddGoal: "Aggiungi Obiettivo",
        ParentTask: "Compito Principale",
        DueToday: "Scadenza Oggi",
        DueTomorrow: "Scadenza Domani",
        Overdue: "In Ritardo",
        Duein: "Scadenza tra",
        days: "giorni",
        TaskCreatedSuccessfully: "Attività creata con successo",
        noDataMessage: "– Crea un Compito ora!",

        selfTaskEnum: {
            [SelfTaskEnum.SelfTask]: "Compito Personale",
            [SelfTaskEnum.AssignTask]: "Assegna A",
        },
        Priority: "Priorità",
        taskFilterTypeEnum: {
            [TaskFilterTypeEnum.CreatedByMe]: "Assegnato Da Me",
            [TaskFilterTypeEnum.MyTasks]: "I Miei Compiti",
            [TaskFilterTypeEnum.MyTeamTasks]: "Compiti del Mio Team",
        },
        statusEnum: {
            [TaskStatusEnum.NotStarted]: "Non Iniziato",
            [TaskStatusEnum.InProcess]: "In Corso",
            [TaskStatusEnum.Completed]: "Completato",
            [TaskStatusEnum.RatingAssign]: "Assegnazione Valutazione",
            [TaskStatusEnum.Cancel]: "Annullato",
            [TaskStatusEnum.Hold]: "In Sospeso",
        },
        taskReferenceTypeEnum: {
            [TaskReferenceTypeEnum.General]: "Generale",
            [TaskReferenceTypeEnum.Project]: "Progetto",
            [TaskReferenceTypeEnum.Group]: "Gruppo",
            [TaskReferenceTypeEnum.Lead]: "Lead",
        },
        taskPriorityEnum: {
            [TaskPriorityEnum.Default]: "Predefinita",
            [TaskPriorityEnum.Low]: "Bassa",
            [TaskPriorityEnum.Medium]: "Media",
            [TaskPriorityEnum.High]: "Alta",
        },
        SortEnum: {
            [TaskFilterSortEnum.CreateDateDesc]: "Data di Creazione - Decrescente",
            [TaskFilterSortEnum.CreateDateAsc]: "Data di Creazione - Crescente",
            [TaskFilterSortEnum.StartDateDesc]: "Data di Inizio - Decrescente",
            [TaskFilterSortEnum.StartDateAsc]: "Data di Inizio - Crescente",
            [TaskFilterSortEnum.UpdateDateDesc]: "Data di Aggiornamento - Decrescente",
            [TaskFilterSortEnum.UpdateDateAsc]: "Data di Aggiornamento - Crescente"
        }

    },
    contracts: {
        Contract: "Contratto",
        createContract: "Crea Contratto",
        contractDate: "Data del Contratto",
        contractPurpose: "Scopo del Contratto",
        selectContractPurpose: "Seleziona lo Scopo del Contratto",
        purposeofContractisRequired: "Lo Scopo del Contratto è Obbligatorio",
        category: "Categoria",
        purpose: "Scopo",
        signatureMember: "Membri Firmatari",
        contractDetail: "Dettaglio Contratto",
        selectCategory: "Seleziona Categoria",
        cateogryofContractisRequired: "Categoria è Obbligatoria",
        externalMember: "Membri Firmatari Esterni",
        selectinternalMembers: "Seleziona Membri Firmatari Interni",
        internalMembers: "Membri Firmatari Interni",
        selectExternalMember: "Seleziona Membri Firmatari Esterni",
        contractType: "Tipo di Contratto",
        contractTypeRequired: "Tipo di Contratto è Obbligatorio",
        internal: "Interno",
        external: "Esterno",
        noDataMessage: "Crea un contratto ora!",
        contractsFilterTypeLables: {
            [ContractFilterTypeEnum.Contracts]: "Tutti i Contratti Commerciali",
            [ContractFilterTypeEnum.BusinessContract]: "I miei Contratti"
        },
        statusEnum: {
            [ContractStatusEnum.InProcess]: "In Corso",
            [ContractStatusEnum.Completed]: "Completato",
            [ContractStatusEnum.Cancelled]: "Annullato"
        },
        contractReferenceTypeEnum: {
            [ContractReferenceTypeEnum.General]: "Generale",
            [ContractReferenceTypeEnum.Project]: "Progetto",
            [ContractReferenceTypeEnum.Group]: "Gruppo",
            [ContractReferenceTypeEnum.Lead]: "Lead"
        }
    },
    workBoard: {
        WorkBoardDashBoard: "Dashboard della bacheca di lavoro",
        pleaseEnterYourBoardName: "Inserisci il nome della tua bacheca",
        pleaseEnterYourBoardDescription: "Inserisci la descrizione della tua bacheca",
        EnterMembers: "Inserisci membri",
        UpdateBoard: "Aggiorna bacheca",
        CreateBoard: "Crea bacheca",
        privacy: "Privacy",
        AddSection: "Aggiungi sezione",
        AddTodo: "Aggiungi attività",
        Save: "Salva",
        EnterSectionTitle: "Inserisci il titolo della sezione",
        InSection: "Nella sezione",
        Label: "Etichetta",
        Activity: "Attività",
        Labels: "Etichette",
        UploadCoverPhoto: "Carica foto di copertina",
        Section: "Sezione",
        Board: "Bacheca",
        Todos: "Attività",
        CompleteDate: "Data di completamento",
        DetailedDesc: "Aggiungi una descrizione dettagliata...",
        EnterTodo: "Inserisci il testo per questa carta...",
        CreateWorkBoardNow: "- Crea la bacheca ora!",
        workBoardExample: "Esempio: WB (per una bacheca chiamata Bacheca di lavoro)",
    },
    leadManager: {
        LeadManager: "Gestione dei lead",
        AssignedLeads: "Lead assegnati",
        AllLeads: "Tutti i lead",
        Groups: "Gruppi",
        email: "Email",
        Lead: "Lead",
        Leads: "Leads",
        Pleaseinputyourboardname: "Inserisci il nome della tua bacheca!",
        exampleLeadManagerMessage: "Esempio: LM (per un gruppo chiamato Gestione dei lead)",
        inputBoardDescription: "Inserisci la descrizione della tua bacheca!",
        UpdateLeadManager: "Aggiorna gestione dei lead",
        CreateLeadManager: "Crea gestione dei lead",
        CreateLeadGroup: "Crea gruppo di lead",
        noDataMessage: "– Crea gestione dei lead ora!",
        LastUpdated: "Ultimo aggiornamento",
        LeadValue: "Valore del lead",
        individual: "Individuale",
        leadEmailAddress: "Indirizzo email del lead",
        leadPhoneNumber: "Numero di telefono del lead",
        leadAddress: "Indirizzo del lead",
        leadUrl: "URL del lead",
        ConvertProject: "Converti in progetto",
        goToProject: "Vai al progetto",
        SelectAssignMembers: "Seleziona membri assegnati",
        LeadGroup: "Gruppo di lead",
        leadName: "Nome del lead",
        leadMembers: "Membri del lead",
        leadWebiste: "Sito web del lead",
        LeadValuenegative: "Il valore del lead non può essere negativo!",
        AddLead: "Aggiungi lead",
        SelectCommunicationTo: "Seleziona destinatario della comunicazione",
        CommunicationTypeisrequired: "Il tipo di comunicazione è obbligatorio!",
        PleaseselectCommunicationType: "Seleziona il tipo di comunicazione",
        CommunicationType: "Tipo di comunicazione",
        AddCommunication: "Aggiungi comunicazione",
        CommunicationTo: "Comunicazione a",
        NoCalls: "Nessuna chiamata",
        LeadsValue: "Valore dei lead",
        metricTypeEnum: {
            [LeadManagerMetricTypeEnum.allGroup]: "Totale gruppi",
            [LeadManagerMetricTypeEnum.allAssign]: "Lead assegnati",
            [LeadManagerMetricTypeEnum.leadToProject]: "Convertiti in progetto",
            [LeadManagerMetricTypeEnum.leadSection]: "Sezione con più lead",
        },
    },
    schedule: {
        StartMeeting: "Inizia riunione",
        MeetinginProgress: "Riunione in corso",
        EndMeeting: "Termina riunione",
        MeetingEnded: "Riunione terminata",
        NewTokenAdded: "Nuovo token aggiunto",
        Copied: "Copiato",
        SuccessfullyBlocked: "Bloccato con successo",
        SuccessfullyUnBlocked: "Sbloccato con successo",
        UpdateCalendarLink: "Aggiorna link calendario",
        AddCalendarLink: "Aggiungi link calendario",
        GetCalendarLink: "Ottieni link calendario",
        CopyLink: "Copia link",
        Block: "Blocca",
        UnBlock: "Sblocca",
        CreateSchedule: "Crea programma",
        Draft: "Bozza",
        Finalize: "Finalizza",
        Complete: "Completa",
        Preview: "Anteprima",
        MinutesOftheMeeting: "Verbale della riunione",
        MeetingId: "ID riunione",
        Venue: "Luogo",
        Workwisecall: "Chiamata Workwise",
        ConferenceCall: "Chiamata conferenza",
        Attendees: "Partecipanti",
        Absentees: "Assenti",
        PointsofDiscussion: "Argomenti di discussione",
        AddPoint: "Aggiungi punto",
        Print: "Stampa",
        SaveToPDF: "Salva in PDF",
        MEETINGMINUTES: "VERBALE DELLA RIUNIONE",
        Yes: "Sì",
        No: "No",
        WorkwiseCall: "Chiamata Workwise",
        MeetingObjectives: "Obiettivi della riunione",
        Points: "Punti",
        RefNo: "Rif No",
        AttendanceatMeeting: "Presenza alla riunione",
        Loadingschedules: "Caricamento programmi...",
        Nodata: "Nessun dato...",
        Projects: "Progetti",
        Groups: "Gruppi",
        titleMessage: "Sei sicuro? Cambiare tipo rimuoverà i membri assegnati.",
        pleaseSelectAssignMemeber: "Seleziona un membro assegnato!",
        SelectAssignto: "Seleziona assegnato a",
        GetLink: "Ottieni link",
        Anyone: "Chiunque",
        anyoneText: "con il link può creare un appuntamento.",
        TodayEvents: "Eventi di oggi",
        UpcomingEvents: "Prossimi eventi",
        InterviewRequests: "Richieste di colloquio",
        sendviachat: "Invia via chat",
        OnVideoConference: "In videoconferenza:",
        OnWorkwiseCall: "In chiamata Workwise",
        Enterlocation: "Inserisci luogo",
        Duration: "Durata",
        inminutes: "in minuti",
        Durationisrequired: "La durata è obbligatoria",
        Rooms: "Stanze",
        SelectRoom: "Seleziona stanza",
        CheckRoomAvailability: "Controlla disponibilità stanza",
        Available: "Disponibile",
        NotAvailable: "Non disponibile",
        TravelTime: "Tempo di viaggio",
        PreparationTime: "Tempo di preparazione",
        SendviaEmail: "Invia via email",
        schedulerCalender: "Calendario scheduler",
        Next: "Successivo",
        Prev: "Precedente",
        titlemyCustomButton: "titolo myCustomButton",
        prevnexttoday: "precedente successivo oggi",
        timeGridDaytimeGridWeekdayGridMonth: "timeGridDay timeGridWeek dayGridMonth",
        timeGridDay: "timeGridDay",
        top: "in alto",
        block: "blocca",
        minutes: "minuti",
        AllDay: "Tutto il giorno",
        timeGridWeek: "timeGridWeek",
        slotDuration: "00:15:00",
        numeric: "numerico",
        dayGridMonth: "dayGridMonth",
        dayGridWeek: "dayGridWeek",
        timeGrid: "timeGrid",
        Day: "Giorno",
        Week: "Settimana",
        Month: "Mese",
        NoEventsonthisday: "Nessun evento in questo giorno.",
        CreateInterviewSchedule: "Crea programma colloqui",
        Interviewwith: "Colloquio con",
        MySchedules: "I miei programmi",
        TeamSchedules: "Programmi del team",
        Past: "Passato",
        Today: "Oggi",
        Upcoming: "Prossimo",
        ScheduleDetail: "Dettaglio programma",
        JoinWorkwiseCall: "Unisciti alla chiamata Workwise",
        AddWorkwiseCall: "Aggiungi chiamata Workwise",
        Host: "(Host)",
        MakeHost: "Rendi host",
        Attending: "Partecipanti",
        UpdateSchedule: "Aggiorna programma",
        Calendar: "Calendario",
        Schedule: "Programma",
        ShareCalendarLink: "Condividi link calendario",
        pointer: "puntatore",
        default: "predefinito",
        blue: "blu",
        red: "rosso",
        purple: "viola",
        Self: "Se stesso",
        AssignTo: "Assegna a",
        assign: "assegna",
        min: "min",
        Minutes: "15 minuti",
        MeetingTranscript: "Trascrizione della riunione",
    },
    expenses: {
        CreateExpense: "Crea Spesa",
        IsReimbursable: "È Rimborsabile",
        WriteHeaderHere: "Scrivi Qui l'Intestazione...",
        ExpenseDetail: "Dettaglio Spesa",
        ExpenseDate: "Data Spesa",
        Executors: "Esecutori",
        Financers: "Finanziatori",
        ExecutorRequired: "Esecutore richiesto",
        selectExecutor: "Seleziona l'Esecutore della Spesa",
        noDataMessage: "Potenzia il tuo percorso finanziario – Crea Spesa adesso!",
        expenseFilterTypeEnum: {
            [ExpenseFilterTypeEnum.MyExpense]: "Mie Spese",
            [ExpenseFilterTypeEnum.ForApproval]: "In Attesa di Approvazione",
            [ExpenseFilterTypeEnum.ForExecutions]: "In Esecuzione",
            [ExpenseFilterTypeEnum.ForFinance]: "In Finanza",
        },
        expenseType: {
            [ExpenseReferenceTypeEnum.General]: "Generale",
            [ExpenseReferenceTypeEnum.Group]: "Gruppo",
            [ExpenseReferenceTypeEnum.Project]: "Progetto",
            [ExpenseReferenceTypeEnum.Travel]: "Viaggio",
            [ExpenseReferenceTypeEnum.Assets]: "Beni",
        },
        statusEnum: {
            [ExpenseApprovalStatusEnum.InProcess]: "In Processo",
            [ExpenseApprovalStatusEnum.Approved]: "Approvato",
            [ExpenseApprovalStatusEnum.Declined]: "Rifiutato",
            [ExpenseApprovalStatusEnum.Cancelled]: "Annullato",
            [ExpenseApprovalStatusEnum.Completed]: "Completato",
            [ExpenseApprovalStatusEnum.Hold]: "In Attesa",
            [ExpenseApprovalStatusEnum.Disbursed]: "Erogato",
            [ExpenseApprovalStatusEnum.WaitingForDisburse]: "In Attesa di Erogazione",
        },
        SortEnum: {
            [ExpenseFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [ExpenseFilterSortEnum.CreateDateAsc]: "Data Creazione",
            [ExpenseFilterSortEnum.ExpenseDateDesc]: "Data Spesa - Decrescente",
            [ExpenseFilterSortEnum.ExpenseDate]: "Data Spesa",
            [ExpenseFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
            [ExpenseFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [ExpenseFilterSortEnum.Amount]: "Importo",
            [ExpenseFilterSortEnum.AmountDesc]: "Importo - Decrescente",
        },
        Category: {
            [ExpenseCategoryEnum.Transport]: "Trasporto",
            [ExpenseCategoryEnum.Health]: "Salute",
            [ExpenseCategoryEnum.Food]: "Cibo",
            [ExpenseCategoryEnum.Shopping]: "Spesa",
            [ExpenseCategoryEnum.Entertainment]: "Intrattenimento",
            [ExpenseCategoryEnum.Travel]: "Viaggio",
            [ExpenseCategoryEnum.Bill]: "Fattura",
            [ExpenseCategoryEnum.Office]: "Ufficio",
            [ExpenseCategoryEnum.Fuel]: "Carburante",
            [ExpenseCategoryEnum.Asset]: "Asset",
            [ExpenseCategoryEnum.Other]: "Altro",
        }
    },
    travel: {
        TravelDetail: "Dettaglio Viaggio",
        CreateTravel: "Crea Viaggio",
        PleaseAddTravelDetail: "Si prega di aggiungere i dettagli del viaggio",
        TravelToandFrom: "Viaggio Da e Per",
        ReturnDateIsRequired: "Data di ritorno obbligatoria",
        PleaseSelectTravelType: "Si prega di selezionare il tipo di viaggio",
        HotelTADACar: "Hotel, TADA & Auto",
        HotelRequired: "Hotel Richiesto",
        RentaCar: "Noleggio Auto",
        PickUpRequired: "Pick-Up Richiesto",
        DropOffRequired: "Drop-Off Richiesto",
        TADAApplicable: "TADA Applicabile",
        TravelBy: "Viaggio Da",
        SpecialRequest: "Richiesta Speciale",
        SearchDestinationCity: "Cerca Città di Destinazione",
        SearchDepartureCity: "Cerca Città di Partenza",
        PleaseSelectDepartureCity: "Si prega di selezionare la città di partenza.",
        PleaseSelectDestinationCity: "Si prega di selezionare la città di destinazione.",
        DepartureTimeIsRequired: "L'orario di partenza è obbligatorio",
        RetunTimeIsRequired: "L'orario di ritorno è obbligatorio",
        AttachmentType: "Tipo di Allegato",
        WriteSpecialRequestDetail: "Scrivi dettagli richiesta speciale",
        Agents: "Agenti",
        Departure: "Partenza",
        Arrival: "Arrivo",
        DepartureDate: "Data di Partenza",
        DepartureTime: "Ora di Partenza",
        DepartureCity: "Città di Partenza",
        ShiftType: "Tipo di Turno",
        DepartureShift: "Turno di Partenza",
        ArrivalCity: "Città di Arrivo",
        CreateTravelnow: "Crea Viaggio adesso",
        UpdateTravel: "Aggiorna Viaggio",
        TravelAttachmentTypeOptions: {
            [TravelAttachmentTypeEnum.Passport]: "Passaporto",
            [TravelAttachmentTypeEnum.Ticket]: "Biglietto",
            [TravelAttachmentTypeEnum.Insurance]: "Assicurazione",
            [TravelAttachmentTypeEnum.CarRental]: "Noleggio Auto",
            [TravelAttachmentTypeEnum.Other]: "Altro",
        },
        Shift: {
            [ShiftEnum.Morning]: "Mattina",
            [ShiftEnum.Afternoon]: "Pomeriggio",
            [ShiftEnum.Evening]: "Sera",
            [ShiftEnum.Night]: "Notte",
        },
        travelBy: {
            plane: "In Aereo",
            bus: "In Autobus",
            ship: "In Nave",
            train: "In Treno",
            car: "In Auto",
        },
        TravelOptions: {
            [TravelOptionsEnums.ByPlane]: "In Aereo",
            [TravelOptionsEnums.ByShip]: "In Nave",
            [TravelOptionsEnums.ByBus]: "In Autobus",
            [TravelOptionsEnums.ByTrain]: "In Treno",
            [TravelOptionsEnums.ByCar]: "In Auto",
        },
        travelFilterTypeEnum: {
            [TravelFilterTypeEnum.Travels]: "Viaggi",
            [TravelFilterTypeEnum.MyTravel]: "Miei Viaggi",
            [TravelFilterTypeEnum.ForApproval]: "In Attesa di Approvazione",
            [TravelFilterTypeEnum.AgentProcess]: "Elaborazione da parte dell'Agente",
        },
        statusEnum: {
            [TravelStatusEnum.InProcess]: "In Processo",
            [TravelStatusEnum.Approved]: "Approvato",
            [TravelStatusEnum.Declined]: "Rifiutato",
            [TravelStatusEnum.Cancelled]: "Annullato",
            [TravelStatusEnum.ApprovedByApprovers]: "Approvato dagli Approvatori",
            [TravelStatusEnum.ApprovedByAgents]: "Approvato dagli Agenti",
            [TravelStatusEnum.DeclineByApproves]: "Rifiutato dagli Approvatori",
            [TravelStatusEnum.DeclineByAgents]: "Rifiutato dagli Agenti",
            [TravelStatusEnum.Hold]: "In Attesa",
        },
        SortEnum: {
            [TravelFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [TravelFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento",
            [TravelFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [TravelFilterSortEnum.ReferenceNoAsc]: "Numero di Riferimento",
            [TravelFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [TravelFilterSortEnum.CreateDateAsc]: "Data Creazione",
            [TravelFilterSortEnum.TravelEndDateDesc]: "Data Fine Viaggio",
            [TravelFilterSortEnum.TravelStartDate]: "Data Inizio Viaggio",
        }
    },
    eLearning: {
        Course: "Corso",
        eBook: "eBook",
        Quiz: "Quiz",
        TedTalk: "TedTalk",
        Article: "Articolo",
        Video: "Video",
        BasicCourseDetail: "Dettaglio di base del corso",
        SelectSubject: "Seleziona Soggetto",
        SelectCourse: "Seleziona Corso",
        PleaseSelectOption: "Seleziona Opzione",
        Selectcoursetype: "Seleziona tipo di corso",
        CourseName: "Nome del corso",
        Entercoursename: "Inserisci nome del corso",
        Level: "Livello",
        PleaseSelectLevel: "Seleziona Livello",
        Selectlevel: "Seleziona livello",
        CourseAdminMembers: "Membri dell'amministrazione del corso",
        CourseAdminisrequired: "L'amministrazione del corso è richiesta",
        AssignMembers: "Assegna membri",
        UpdateEBook: "Aggiorna eBook",
        CreateEBook: "Crea eBook",
        AuthorName: "Nome dell'autore",
        EnterInformation: "Inserisci Informazioni",
        UploadBook: "Carica Libro (PDF)",
        CreateQuiz: "Crea Quiz",
        QuizName: "Nome del Quiz",
        CreateTedTalk: "Crea TedTalk",
        ViaLink: "Tramite Link",
        ViaVideoUpload: "Tramite Caricamento Video",
        PleaseInsertLink: "Inserisci Link",
        UploadVideo: "Carica Video",
        CreateArticle: "Crea Articolo",
        InsertLink: "Inserisci Link",
        UploadPDF: "Carica Foto o PDF",
        CreateVideo: "Crea Video",
        LessonDetail: "Dettaglio della Lezione",
        Eitherselect: "Seleziona un quiz o rimuovilo.",
        Selectquiz: "Seleziona quiz",
        AddNewQuiz: "Aggiungi Nuovo Quiz",
        Atleastonetopic: "È richiesto almeno un argomento di questa lezione.",
        LessonNameRequired: "Il nome della lezione è obbligatorio.",
        LessonName: "Nome della lezione",
        DeleteLesson: "Elimina Lezione",
        LessonDescriptionRequired: "La descrizione della lezione è obbligatoria.",
        TopicNameIsRequired: "Il nome dell'argomento è obbligatorio.",
        TopicName: "Nome dell'argomento",
        DeleteTopic: "Elimina Argomento",
        TopicDescription: "La descrizione dell'argomento è obbligatoria.",
        eitherEnterText: "Inserisci testo o rimuovilo.",
        EitherenterA: "Inserisci un",
        OrRemoveIt: "o rimuovilo.",
        CreateArticlesNow: "Crea Articoli adesso!",
        CreateCoursesNow: "Crea Corsi adesso!",
        CreateEbooksNow: "Crea eBook adesso!",
        CreateElearningNow: "Crea E-learning adesso!",
        CreateQuizzesNow: "Crea Quiz adesso!",
        CreateTeamDashboardNow: "Crea Dashboard del Team adesso!",
        CreateTedTalksNow: "Crea TedTalks adesso!",
        CreateVideosNow: "Crea Video adesso!",
        OptionEmpty: "L'opzione non può essere vuota",
        CorrectOptionRequired: "Opzione corretta richiesta",
        QuestionEmpty: "La domanda non può essere vuota",
        UntitledQuestion: "Domanda senza titolo",
        AddQuestion: "Aggiungi Domanda",
        DeleteQuestion: "Elimina Domanda",
        Quiznamerequired: "Nome del quiz richiesto!",
        QuizexpirationDate: "Data di scadenza del quiz",
        QuizExpirationDateIsRequired: "La data di scadenza del quiz è obbligatoria",
        QuizDescriptionRequired: "Descrizione del quiz richiesta!",
        StartQuiz: "Inizia Quiz",
        GoToResults: "Vai ai risultati",
        Gettoknown: "Scopri l'esperienza utente",
        Missingdata: "Dati mancanti.",
        UntitledLesson: "Lezione senza titolo",
        RemoveText: "Rimuovi Testo",
        AddText: "Aggiungi Testo",
        RemoveImage: "Rimuovi Immagine",
        AddImage: "Aggiungi Immagine",
        RemoveVideo: "Rimuovi Video",
        AddVideo: "Aggiungi Video",
        RemovePDF: "Rimuovi PDF",
        AddPDF: "Aggiungi PDF",
        RemoveQuiz: "Rimuovi Quiz",
        AddQuiz: "Aggiungi Quiz",
        RemoveLink: "Rimuovi Link",
        AddLink: "Aggiungi Link",
        RemoveYouTubeLink: "Rimuovi Link YouTube",
        AddYouTubeLink: "Aggiungi Link YouTube",
        RemoveExternalCourse: "Rimuovi Corso Esterno",
        AddExternalCourse: "Aggiungi Corso Esterno",
        UntitledTopic: "Argomento Senza Titolo",
        DashboardChart: "Grafico della Dashboard",
        LatestTrendInBusiness: "Ultima Tendenza nel Business",
        InformationWillComeHere: "Le informazioni verranno qui",
        CourseContent: "Contenuto del Corso",
        DummyContent: "Contenuto Fittizio",
        EndCourse: "Fine Corso",
        Chapters: "Capitoli •",
        Lectures: "Lezioni •",
        LessonsInThisProgram: "Lezioni In Questo Programma",
        BookInformation: "Informazioni sul Libro",
        TedTalksDaily: "TedTalks Quotidiani",
        TopicTitle: "Titolo dell'Argomento",
        VisitThis: "Visita Questo",
        LearnPractical: "Impara abilità pratiche attraverso corsi e ottieni tirocini retribuiti.",
        Courses: "Corsi",
        Book: "Libro",
        Tedtalk: "Tedtalk",
        Articles: "Articoli",
        NoPageExist: "Nessuna pagina esistente",
        Books: "Libri",
        TedTalks: "Ted Talks",
        Videos: "Video",
        GoTo: "Vai a",
        CourseLevelIdLabel: {
            [CourseLevelIdEnum.Advance]: "Avanzato",
            [CourseLevelIdEnum.Intermediate]: "Intermedio",
            [CourseLevelIdEnum.Beginner]: "Principiante"
        }
    },
    attendence: {
        Attendance: "Presenza",
        TimeSpent: "Tempo Trascorso",
        GraceTime: "Tempo di Grazia",
        GoodDay: "Buona Giornata",
        Howfeeling: "Come ti senti oggi?",
        Note: "Nota",
        Noattendanceforapproval: "Nessuna presenza da approvare.",
        Iwaslate: "Ero in ritardo a causa del traffico.",
        Showtimespent: "Mostra il tempo trascorso",
        ClockIn: "Fai il Clock-In per iniziare il registro delle presenze.",
        selectStatus: "Si prega di selezionare uno stato!",
        ByTime: "Per Tempo",
        ByStatus: "Per Stato",
        DeductionPolicy: "Politica di Deduzione",
        UpdateDeductionPolicy: "Aggiorna Politica di Deduzione",
        AddDeductionPolicy: "Aggiungi Politica di Deduzione",
        DaysRange: "Intervallo di Giorni",
        PerDay: "Per Giorno",
        SaveDeductionPolicy: "Salva Politica di Deduzione",
        AbsentPolicy: "Politica di Assenza",
        LatePolicy: "Politica di Ritardo",
        IsPercentage: "È Percentuale",
        attendanceFilterTypeEnum: {
            [AttendanceFilterTypeEnum.checkInForApproval]: "Check-In da approvare",
            [AttendanceFilterTypeEnum.dayWiseAttendance]: "Presenza Giornaliera",
            [AttendanceFilterTypeEnum.userAttendance]: "Presenza Utente",
            [AttendanceFilterTypeEnum.Summary]: "Riepilogo",
        },
        updateErrorMessage: {
            [AttendanceStateEnum.Off]: "Azione di aggiornamento non disponibile per lo stato Off",
            [AttendanceStateEnum.Holiday]: "Azione di aggiornamento non disponibile per le vacanze",
            [AttendanceStateEnum.Leave]: "Azione di aggiornamento non disponibile per lo stato di permesso",
        },
        statusEnum: {
            [AttendanceStatusEnum.InProcess]: "In Corso",
            [AttendanceStatusEnum.Approved]: "Approvato",
            [AttendanceStatusEnum.Declined]: "Rifiutato",
        },
        moodLabels: {
            [AttendanceMoodEnum.VeryUnsatisfied]: "Molto Insoddisfatto",
            [AttendanceMoodEnum.Unsatisfied]: "Insoddisfatto",
            [AttendanceMoodEnum.Neutral]: "Neutro",
            [AttendanceMoodEnum.Satisfied]: "Soddisfatto",
            [AttendanceMoodEnum.VerySatisfied]: "Molto Soddisfatto",
        },
        attendanceState: {
            [AttendanceStateEnum.Unknown]: "",
            [AttendanceStateEnum.Present]: "Presente",
            [AttendanceStateEnum.Absent]: "Assente",
            [AttendanceStateEnum.Leave]: "Permesso",
            [AttendanceStateEnum.Late]: "In Ritardo",
            [AttendanceStateEnum.WorkFromHome]: "Lavoro da Casa",
            [AttendanceStateEnum.Off]: "Off",
            [AttendanceStateEnum.Holiday]: "Vacanza",
        },
        attendanceType: {
            [ATTENDANCE_ENUM.CHECK_IN.IN]: "Check-In",
            [ATTENDANCE_ENUM.CHECK_IN.OUT]: "Check-Out",
        },
        attendanceMetricLabel: {
            [AttendanceMetricTypeEnum.overallTimeSpent]: "Tempo Complessivo Trascorso",
            [AttendanceMetricTypeEnum.Present]: "Presente",
            [AttendanceMetricTypeEnum.Absent]: "Assente",
            [AttendanceMetricTypeEnum.Leave]: "Permesso",
            [AttendanceMetricTypeEnum.Late]: "In Ritardo",
        },
    },
    customApproval: {
        createCustomApproval: "Crea Approvazione Personalizzata",
        customApproval: "Approvazione Personalizzata",
        noDataMessage: "– Crea approvazioni personalizzate ora!",
        customApprovalFilterTypeEnum: {
            [CustomApprovalFilterTypeEnum.All]: "Tutte le approvazioni personalizzate",
            [CustomApprovalFilterTypeEnum.CreatedByMe]: "Mie approvazioni personalizzate",
            [CustomApprovalFilterTypeEnum.ForApproval]: "Per approvazione",
        },
        statusEnum: {
            [CustomApprovalStatusEum.InProcess]: "In Corso",
            [CustomApprovalStatusEum.Approved]: "Approvato",
            [CustomApprovalStatusEum.Declined]: "Rifiutato",
            [CustomApprovalStatusEum.Resend]: "Rimanda",
            [CustomApprovalStatusEum.InActive]: "Inattivo",
            [CustomApprovalStatusEum.NotRequired]: "Non Richiesto",
            [CustomApprovalStatusEum.Cancelled]: "Annullato",
            [CustomApprovalStatusEum.Hold]: "In Attesa",
        },
        SortEnum: {
            [CustomApprovalFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [CustomApprovalFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento",
            [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [CustomApprovalFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
            [CustomApprovalFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [CustomApprovalFilterSortEnum.CreateDateAsc]: "Data Creazione"
        }
    },
    reward: {
        CreateReward: "Crea Ricompensa",
        Rewards: "Ricompense",
        RewardTo: "Ricompensa a",
        RewardCategory: "Categoria della Ricompensa",
        AddRewardCategory: "Aggiungi Categoria della Ricompensa",
        EnterRewardCategory: "Inserisci Categoria della Ricompensa",
        RewardName: "Nome della Ricompensa",
        EnterRewardName: "Inserisci Nome della Ricompensa",
        PleaseEnterRewardName: "Per favore inserisci il nome della ricompensa",
        ReasonForReward: "Motivo della Ricompensa",
        EnterReasonForReward: "Inserisci Motivo della Ricompensa",
        PleaseEnterReasonForReward: "Per favore inserisci il motivo della ricompensa",
        noDataMessage: "– Crea una ricompensa ora!",
        rewardFilterTypeEnum: {
            [RewardFilterTypeEnum.All]: "Tutte le Ricompense",
            [RewardFilterTypeEnum.CreatedByMe]: "Create da Me",
            [RewardFilterTypeEnum.ForApproval]: "Per Approvazione",
            [RewardFilterTypeEnum.RewardForMe]: "Le Mie Ricompense",
        },
        statusEnum: {
            [RewardStatusEnum.InProcess]: "In Corso",
            [RewardStatusEnum.Approved]: "Approvato",
            [RewardStatusEnum.Declined]: "Rifiutato",
            [RewardStatusEnum.Cancelled]: "Annullato",
            [RewardStatusEnum.Hold]: "In Attesa",
        },
        SortEnum: {
            [RewardFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [RewardFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento - Ascendente",
            [RewardFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [RewardFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
            [RewardFilterSortEnum.StatusDesc]: "Stato - Decrescente",
            [RewardFilterSortEnum.Status]: "Stato",
            [RewardFilterSortEnum.CategoryDesc]: "Categoria - Decrescente",
            [RewardFilterSortEnum.Category]: "Categoria",
            [RewardFilterSortEnum.NameDesc]: "Nome - Decrescente",
            [RewardFilterSortEnum.Name]: "Nome",
            [RewardFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [RewardFilterSortEnum.CreateDateAsc]: "Data Creazione - Ascendente"
        }
    },
    document: {
        mySigned: "Firmato da me",
        forMySignature: "Per la mia firma",
        draftSignature: "Bozza",
        inSigning: "In fase di firma",
        signaturesCompleted: "Firme completate",
        DocsArchive: "Documenti & Archivi",
        createDocument: "Crea Documento",
        CreateSlides: "Crea Diapositive",
        CreateBoard: "Crea Board",
        CreatePad: "Crea Pad",
        CreateGrid: "Crea Griglia",
        newFolder: "Nuova Cartella",
        createFolder: "Crea Cartella",
        uploadDocuments: "Carica Documenti",
        addBoard: "Aggiungi Board",
        addPad: "Aggiungi Pad",
        addGrid: "Aggiungi Griglia",
        addSlide: "Aggiungi Diapositiva",
        addMindmap: "Aggiungi Mappa Mentale",
        CompleteDocument: "Documento Completo",
        noDataMessage: "- Crea un documento ora!",
        AllDocuments: "Tutti i Documenti",
        MyDrive: "Il Mio Drive",
        MyDocuments: "I Miei Documenti da Approvare",
        ForApprovals: "Per Approvazioni",
        OnlyFiles: "Solo File",
        fileName: "Nome del File",
        shortDescription: "Breve Descrizione (max 100 caratteri)",
        AllFiles: "Tutti i File",
        Grids: "Griglie",
        Pads: "Pad",
        Slides: "Diapositive",
        Boards: "Board",
        Mindmaps: "Mappe Mentali",
        updateFolder: "Aggiorna Cartella",
        updateGrid: "Aggiorna Griglia",
        updatePad: "Aggiorna Pad",
        updateSlide: "Aggiorna Diapositiva",
        updateBoard: "Aggiorna Board",
        updateMindMap: "Aggiorna Mappa Mentale",
        updateDocuments: "Aggiorna Documento",
        status: {
            InProcess: "In Corso",
            Approved: "Approvato",
            Declined: "Rifiutato",
            Resend: "Rimanda",
            NotRequired: "Non Richiesto",
            Cancelled: "Annullato",
            Draft: "Bozza"
        },
        documentReferenceType: {
            [DocumentReferenceTypeEnum.General]: "Generale",
            [DocumentReferenceTypeEnum.Group]: "Gruppo",
            [DocumentReferenceTypeEnum.Project]: "Progetto",
        },
    },
    warning: {
        WarningDetail: "Dettagli di Avvertimento",
        warningTo: "Avvertimento a",
        createWarning: "Crea Avvertimento",
        enterwarningCategory: "Inserisci categoria di avvertimento",
        noDataMessage: "– Crea un avvertimento ora!",
        filter: "Filtro",
        warningFilterTypeEnum: {
            [WarningFilterTypeEnum.Warning]: "Avvertimenti",
            [WarningFilterTypeEnum.CreatedByMe]: "Creato da Me",
            [WarningFilterTypeEnum.ForApproval]: "Per Approvazioni",
            [WarningFilterTypeEnum.WarningToMe]: "Avvertimenti a Me",
        },
        statusEnum: {
            [WarningStatusEnum.InProcess]: "In Corso",
            [WarningStatusEnum.Approved]: "Approvato",
            [WarningStatusEnum.Declined]: "Rifiutato",
            [WarningStatusEnum.Cancelled]: "Annullato",
            [WarningStatusEnum.Hold]: "In Attesa",
        },
        SortEnum: {
            [WarningFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [WarningFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento - Ascendente",
            [WarningFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [WarningFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
            [WarningFilterSortEnum.StatusDesc]: "Stato - Decrescente",
            [WarningFilterSortEnum.Status]: "Stato",
            [WarningFilterSortEnum.CategoryDesc]: "Categoria - Decrescente",
            [WarningFilterSortEnum.Category]: "Categoria",
            [WarningFilterSortEnum.NameDesc]: "Nome - Decrescente",
            [WarningFilterSortEnum.Name]: "Nome",
            [WarningFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [WarningFilterSortEnum.CreateDateAsc]: "Data Creazione - Ascendente"
        }
    },
    jobBoard: {
        jobBoard: "Bacheca Lavori",
        noDataMessage: "– Crea Carriere ora!",
    },
    businessPolicy: {
        BusinessPolicy: "Politica Aziendale",
        policies: "Politiche",
        noPolicy: "Nessuna Politica Trovata...",
    },
    forms: {
        CreateForm: "Crea Modulo",
        Forms: "Moduli",
        Attempt: "Tentativo",
        CopyLink: "Copia Link",
        Title: "Titolo",
        EnterTitle: "Inserisci Titolo",
        PleaseEnterTitle: "Per favore inserisci il titolo",
        PleaseFillTheQuestionField: "Per favore compila il campo della domanda",
        EnterQuestion: "Inserisci Domanda",
        SelectAnswerType: "Seleziona Tipo di Risposta",
        Text: "Testo",
        Number: "Numero",
        Polls: "Sondaggi",
        Poll: "Sondaggio",
        AddQuestion: "Aggiungi Domanda",
        AddOption: "Aggiungi Opzione",
        Details: "Dettagli",
        PoweredBy: "Offerto da",
        Submit: "Invia",
        EditForm: "Modifica Modulo",
        EnterEmail: "Inserisci Email",
        Question: "Domanda...",
        YourAnswer: "La Tua Risposta",
        noDataMessage: "– Crea un modulo ora!",
        formQuestionTypeEnum: {
            [FormQuestionTypeEnum.Number]: "Numero",
            [FormQuestionTypeEnum.Text]: "Testo",
            [FormQuestionTypeEnum.Poll]: "Sondaggio",
        },
        formFilterTypeEnum: {
            [FormFilterTypeEnum.All]: "Tutti i Moduli",
            [FormFilterTypeEnum.CreatedByMe]: "Creato da Me",
            [FormFilterTypeEnum.ForApproval]: "Per Approvazione",
            [FormFilterTypeEnum.FormForMe]: "Modulo per Me"
        },
        statusEnum: {
            [FormStatusEnum.InProcess]: "In Corso",
            [FormStatusEnum.Approved]: "Approvato",
            [FormStatusEnum.Declined]: "Rifiutato",
            [FormStatusEnum.Resend]: "Rimanda",
            [FormStatusEnum.Inactive]: "Inattivo",
            [FormStatusEnum.NotRequired]: "Non Richiesto",
            [FormStatusEnum.Cancelled]: "Annullato",
            [FormStatusEnum.Hold]: "In Attesa",
        },
        SortEnum: {
            [FormFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [FormFilterSortEnum.CreateDateAsc]: "Data Creazione - Ascendente",
            [FormFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [FormFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento - Ascendente",
            [FormFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [FormFilterSortEnum.ReferenceNo]: "Numero di Riferimento"
        }
    },
    careers: {
        Careers: "Carriere",
        CareerDetail: "Dettagli Carriera",
        createCareer: "Crea Carriera",
        MinSalary: "Salario Minimo",
        MaxSalary: "Salario Massimo",
        All: "Tutti",
        Applicants: "Candidati",
        ShortListed: "Selezionati",
        Rejected: "Rifiutati",
        Completed: "Completato",
        Finalist: "Finalista",
        Maybe: "Forse",
        OfferSent: "Offerta Inviata",
        WaitingForInterview: "In Attesa di Colloquio",
        OfferAccepted: "Offerta Accettata",
        OfferDeclined: "Offerta Rifiutata",
        OfferResend: "Offerta Rimandata",
        noDataMessage: "– Crea Carriera ora!",
        writeHeaderHere: "Scrivi intestazione",
        pickCurrentDate: "Scegli data attuale",
        enterAmount: "Inserisci importo",
        selectApprovers: "Seleziona approvatori",
        selectFinancers: "Seleziona finanziatori",
        selectExecutors: "Seleziona esecutori",
        selectDesignation: "Seleziona designazione",
        writeDescription: "Scrivi descrizione",
        enterMinSalary: "Inserisci salario minimo",
        enterMaxSalary: "Inserisci salario massimo",
        selectDepartment: "Seleziona dipartimento",
        selectSupervisor: "Seleziona supervisore",
        selectInterviewers: "Seleziona intervistatori",
        selectPostInterviewers: "Seleziona post-intervistatori",
        description: "Scrivi la tua descrizione qui...",
        addSkills: "Aggiungi competenza",
        selectJobType: "Seleziona tipo di lavoro",
        selectJobshift: "Seleziona turno di lavoro",
        selectEducation: "Seleziona livello di istruzione",
        selectCareerLevel: "Seleziona livello di carriera",
        selectEndDate: "Seleziona data di fine",
        enterreviewcriteria: "Inserisci criteri di valutazione",
        reviewCriteria: "Criteri di Valutazione",
        enterDepartment: "Per favore inserisci dipartimento",
        supervisor: "Supervisore",
        supervisorrequired: "Supervisore richiesto",
        postInterviewers: "Post-Intervistatori",
        jobShift: "Turno di Lavoro",
        enterJobShift: "Per favore inserisci turno di lavoro",
        enterJobType: "Per favore inserisci tipo di lavoro",
        careerLevel: "Livello di Carriera",
        selectBuddy: "Seleziona compagno di assunzione",
        selectInerview: "Seleziona intervistatori",
        requiredInterview: "Intervistatore richiesto",
        enterCareerLevel: "Per favore inserisci livello di carriera",
        createJob: "Crea Lavoro",
        applyJob: "Candidati per il Lavoro",
        firstName: "Nome",
        enterFirstName: "Inserisci Nome",
        requiredMessageFName: "Nome richiesto",
        lastName: "Cognome",
        enterLastName: "Inserisci Cognome",
        requiredMessageLName: "Cognome richiesto",
        email: "Email",
        enterEmail: "Inserisci Email",
        EmailIsRequired: "Email richiesta",
        phoneNumber: "Numero di Telefono",
        enterPhoneNumber: "Inserisci Numero di Telefono",
        currentSalary: "Salario Attuale",
        expectedSalary: "Salario Atteso",
        radioMessageApplicant: "Candidato",
        radioMessageShortlisted: "Selezionato",
        radioMessageCompleted: "Completato",
        coverNote: "Nota di Copertura",
        noticePeriod: "Periodo di Preavviso",
        requiredMessageFName: "Per favore inserisci nome",
        requiredMessageLName: "Per favore inserisci cognome",
        requiredMessageEmail: "Per favore inserisci email",
        requiredMessagePhoneNumbeer: "Per favore inserisci numero di telefono",
        requiredMessageCurrentSalary: "Per favore inserisci salario attuale",
        requiredMessageExpextedsalary: "Per favore inserisci salario atteso",
        requiredMessageExperience: "Per favore inserisci esperienza",
        requiredMessageNoticePeriod: "Per favore inserisci periodo di preavviso",
        appliedDate: "Data di Candidatura",
        interviewSchedule: "Programma Colloquio",
        applyNow: "Candidati Ora",
        jobPost: "Lavoro Pubblicato",
        jobexpire: "Scadenza Lavoro",
        skillrequired: "Competenze Richieste",
        joblocation: "Luogo di Lavoro",
        jobType: "Tipo di Lavoro",
        jobDetails: "Dettagli Lavoro",
        EducationLevel: "Livello di Istruzione",
        MinimumSalary: "Salario Minimo",
        MaximumSalary: "Salario Massimo",
        YearOfExperience: "Anni di Esperienza",
        errormessage: "Impossibile Cambiare Stato",
        MakeOffer: "Fai un'Offerta",
        updatestatus: "Sei sicuro di voler aggiornare lo stato?",
        Pleaseenterminsalary: "Si prega di inserire lo stipendio minimo",
        Pleaseentermaxsalary: "Si prega di inserire lo stipendio massimo",
        NoRequirements: " Nessun requisito",
        careerInterviewStatuEnum: {
            [CareerInterviewStatusEnum.NotScheduled]: "Non Programmato",
            [CareerInterviewStatusEnum.Ended]: "Terminato",
            [CareerInterviewStatusEnum.Scheduled]: "Programmato",
            [CareerInterviewStatusEnum.Started]: "Iniziato",
            [CareerInterviewStatusEnum.WaitingForReSchedule]: "In Attesa di Riprogrammazione"
        },
        statusEnum: {
            [CareerStatusEnum.InProcess]: "In Corso",
            [CareerStatusEnum.Approved]: "Approvato",
            [CareerStatusEnum.Declined]: "Rifiutato",
            [CareerStatusEnum.Cancelled]: "Annullato",
            [CareerStatusEnum.Available]: "Disponibile",
            [CareerStatusEnum.Expired]: "Scaduto",
            [CareerStatusEnum.Hold]: "In Attesa",
        },
        careerFilterTypeEnum: {
            [CareerFilterTypeEnum.All]: "Tutte le Carriere",
            [CareerFilterTypeEnum.MyCareers]: "Le Mie Carriere",
            [CareerFilterTypeEnum.ForApproval]: "Per Approvazione",
        },
        SortEnum: {
            [CareerFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [CareerFilterSortEnum.CreateDateAsc]: "Data Creazione - Ascendente",
            [CareerFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [CareerFilterSortEnum.ReferenceNoAsc]: "Numero di Riferimento - Ascendente",
            [CareerFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [CareerFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento - Ascendente",
            [CareerFilterSortEnum.MinSalary]: "Salario Minimo",
            [CareerFilterSortEnum.MinSalaryDesc]: "Salario Minimo - Decrescente",
            [CareerFilterSortEnum.MaxSalary]: "Salario Massimo",
            [CareerFilterSortEnum.MaxSalaryDesc]: "Salario Massimo - Decrescente",
        },
        CareerLevelEnum: {
            [CareerLevelEnum.Entry]: "Entry",
            [CareerLevelEnum.MidLevel]: "Mid Level",
            [CareerLevelEnum.SeniorLevel]: "Senior Level",
            [CareerLevelEnum.ExecutiveLevel]: "Executive Level",
            [CareerLevelEnum.CSuite]: "C-Suite",
            [CareerLevelEnum.PresidentCeo]: "Presidente CEO",
        },
        JobTypeEnums: {
            [JobTypeEnums.FullTime]: "Tempo Pieno",
            [JobTypeEnums.PartTime]: "Part Time",
            [JobTypeEnums.Contractual]: "Contrattuale",
            [JobTypeEnums.Consultant]: "Consulente",
            [JobTypeEnums.Agent]: "Agente",
            [JobTypeEnums.ProjectBased]: "Basato su Progetto",
        },
        JobShiftTypeEnums: {
            [JobShiftTypeEnums.Day]: "Giorno",
            [JobShiftTypeEnums.Morning]: "Mattina",
            [JobShiftTypeEnums.AfterNoon]: "Pomeriggio",
            [JobShiftTypeEnums.Night]: "Notte",
            [JobShiftTypeEnums.Other]: "Altro",
        }
    },
    /*** HR Menu ***/
    myTeam: {
        MyTeams: "Le Mie Squadre",
        EmployeeNo: "Numero Dipendente",
        noDataMessage: "Crea Squadre ora!",
        teamDetails: "Dettagli Squadra",
        DeductionPerMonth: "Deduzione Mensile",
        teamActivities: {
            attendance: "Presenze",
            checkIn: "Registrazione Ingresso",
            leaves: "Permessi",
            rewards: "Ricompense",
            appraisals: "Valutazioni",
            warnings: "Avvertimenti",
            complains: "Reclami",
            courses: "Corsi",
            education: "Istruzione",
            experience: "Esperienza",
            loan: "Prestito",
            activityLog: "Registro Attività",
            checkInCheckOut: "Registrazione Ingresso/Uscita",
            storageStatistics: " Statistiche di archiviazione"
        },
    },
    administration: {
        basicInfo: "Informazioni di base",
        Subsidiary_Office: "Ufficio Secondario",
        Fiscal_Year: "Anno Fiscale",
        Tax_Slabs: "Aliquote Fiscali",
        Tax_Slabs_Group: "Gruppo Aliquote Fiscali",
        E_Learning_Category: "Categoria di E-Learning",
        Company_Policy: "Politiche Aziendali",
        Default_Hiring_Criteria: "Criteri di Assunzione Predefiniti",
        subsidiary: "Sussidiaria",
        AddSubsidiary: "Aggiungi Sussidiaria",
        Business_Logo: "Logo Aziendale",
        Complain_Category: "Categoria di Reclamo",
        Custom_Tag: "Tag Personalizzato",
        Payroll_Group: "Gruppo Paghe",
        Business_Policy: "Politica Aziendale",
        Companies_Policy: "Politiche Aziendali",
        Billing: "Fatturazione",
        Rebate_Category: "Categoria di Sconto",
        Assets_Category: "Categoria di Beni",
        AccessRole: "Ruolo di Accesso",
        Grade: "Grado",
        Default_Account_Header: "Intestazione Account Predefinita",
        Business_Paging: "Numero Aziendale",
        Discussion_Board_Category: "Categoria della Bacheca di Discussione",
        Room: "Stanza",
        Business_Association: "Associazione Aziendale",
        Designation: "Designazione",
        Company_Goal: "Obiettivo Aziendale",
        Individual_Goal: "Obiettivo Individuale",
        Office_Timings: "Orari di Ufficio",
        Leave_Types: "Tipi di Permesso",
        User_Types: "Tipi di Utente",
        Expense_Headers: "Intestazioni Spese",
        Salary_Headers: "Intestazioni Stipendio",
        Request_For_Right: "Richiesta di Diritti",
        Custom_Approval_Category: "Categoria di Approvazione Personalizzata",
        GRADE_ALLOWANCES: "Indennità di Grado",
        Paging_Category: "Categoria di Paginazione",
        Page_Category: "Categoria di Pagina",
        Add_Paging_Category: "Aggiungi Categoria di Paginazione",
        Project_Category: "Categoria di Progetto",
        Email_Configuration: "Configurazione Email",
        Allowances: "Indennità",
        Default_Approvals: "Approvazioni Predefinite",
        NonMandatory_Approvals: "Approvazioni Non Obbligatorie",
        WORKGATING: "Criteri di Gestione WIP",
        Job_Description: "Descrizione del Lavoro",
        Rewards: "Ricompense",
        Reward_Category: "Categoria di Ricompensa",
        Add_Reward_Category: "Aggiungi Categoria di Ricompensa",
        Warning_Category: "Categoria di Avviso",
        Add_Warning_Category: "Aggiungi Categoria di Avviso",
        Holidays: "Vacanze",
        paymentCard: "Carta di Pagamento",
        addPaymentCard: "Aggiungi Carta di Pagamento",
        Company_Info: "Informazioni Aziendali",
        Business_Approval: "Approvazione Aziendale",
        ATTENDANCE_SETTINGS: `Impostazioni di Presenza`,
        COMPANY_STATISTICS: "Statistiche aziendali",
        Form: {
            Name: "Nome",
            Entername: "Inserisci nome",
            PleaseEnterName: "Per favore, inserisci il nome",
            Description: "Descrizione",
            enterDescription: "Per favore, inserisci la descrizione",
            Create: "Crea",
            Add: "Aggiungi",
            AddBusinessNumber: "Aggiungi Numero Aziendale",
            AddAllowance: "Aggiungi Indennità",
            AddAssetsCategory: "Aggiungi Categoria Beni",
            UpdateAssetsCategory: "Aggiorna Categoria Beni",
            AddBusinessAssociation: "Aggiungi Associazione Aziendale",
            AddCustomApprovalCategory: "Aggiungi Categoria di Approvazione Personalizzata",
            AddCustomTag: "Aggiungi Tag Personalizzato",
            AddDesignation: "Aggiungi Designazione",
            AddComplain: "Aggiungi Reclamo",
            Save: "Salva",
            Clear: "Cancella",
            CategoryName: "Nome Categoria",
            Entercategoryname: "Inserisci nome categoria",
            SaveCategory: "Salva Categoria",
            Date: "Data",
            startDate: "Data d'inizio",
            endDate: "Data di fine",
            Upload: "Carica",
            Percent: "Percentuale",
            Amount: "Importo",
            EnterAmount: "Inserisci Importo",
            addCategory: "Aggiungi Categoria",
            SelectMember: "Seleziona Membro",
            Email: "Email",
            EnterEmail: "Inserisci Email",
            Types: "Tipi",
            SelectStatus: "Seleziona Stato",
            Submit: "Invia",
            Nomoreapprovals: "Nessuna ulteriore approvazione",
        },
        Table: {
            Name: "Nome",
            Members: "Membri",
            Description: "Descrizione",
            Value: "Valore",
            Allot_Leave: "Assegna Permesso",
            Type: "Tipo",
            Unit: "Unità",
            Taxable: "Tassabile",
            Provider: "Fornitore",
            IncomingServerAddress: "Indirizzo Server in Arrivo",
            OutgoingServerAddress: "Indirizzo Server in Uscita",
            IncomingPort: "Porta in Arrivo",
            OutgoingPort: "Porta in Uscita",
            Action: "Azione",
            startDate: "Data d'inizio",
            endDate: "Data di fine",
            Actions: "Azioni",
            Account: "Account",
            approvers: "Approvatori",
            Status: "Stato",
            Allocated: "Allocato",
            AddMember: "Aggiungi Membro",
            CreateDate: "Data di Creazione",
        },
        accessRole: {
            Button: {
                AddAccessRole: "Aggiungi Ruolo di Accesso",
                UpdateAccessRole: "Aggiorna Ruolo di Accesso",
            },
            Drawer: {
                CreateAccessRole: "Crea Ruolo di Accesso",
                UpdateAccessRole: "Aggiorna Ruolo di Accesso",
                UserType: "Tipo di Utente",
                Description: "Descrizione",
                placeholders: {
                    EnterName: "Inserisci Nome del Ruolo di Accesso",
                    UserType: "Seleziona Tipo di Utente",
                    Description: "Inserisci Descrizione",
                },
                formLabel: {
                    AccessRoleName: "Nome del Ruolo di Accesso",
                },
                placeholders: {
                    EnterName: "Inserisci ruolo di accesso",
                    SelectUserType: "Seleziona tipo di utente",
                    EnterDescription: "Inserisci descrizione",
                },
            },
        },
        grade: {
            AddGrade: "Aggiungi Grado",
            AddGradeAllowances: "Aggiungi Indennità di Grado",
            EnterGrade: "Inserisci grado",
            EnterDescription: "Inserisci descrizione",
            Grade: "Grado",
            Description: "Descrizione",
            Name: "Nome",
            clear: "Cancella",
            saveGrade: "Salva Grado",
        },
        fiscalyear: {
            Add: "Aggiungi",
            AddFiscalYear: "Aggiungi Anno Fiscale",
            EnterName: "Inserisci nome",
            EnterDescription: "Inserisci descrizione",
            Fiscalyear: "Anno Fiscale",
            description: "Descrizione",
            name: "Nome",
            clear: "Cancella",
            save: "Salva",
            startyear: "Anno di Inizio",
            endyear: "Anno di Fine",
            startMonth: "Mese di Inizio",
            endMonth: "Mese di Fine",
            startDate: "Data di Inizio",
            endDate: "Data di Fine",
        },
        businessPolicy: {
            companypolicy: "Politiche Aziendali",
            enterName: "Inserisci nome",
            name: "Nome",
            type: "Seleziona tipo",
            typee: "Tipo",
            description: "Descrizione",
            EnterDescription: "Inserisci Descrizione",
            create: "Crea",
            save: "Salva",
            createPolicy: "Crea Politica",
            updatePolicy: "Aggiorna Politica",
        },
        taxSlab: {
            TaxSlabs: "Aliquote Fiscali",
            UpdateTaxSlab: "Aggiorna Aliquota Fiscale",
            CreateTaxSlab: "Crea Aliquota Fiscale",
            NoTaxSlabGroup: "Nessun Gruppo di Aliquote Fiscali...",
            enterCountry: "Seleziona Paese",
            enterName: "Inserisci Nome",
            enterDescription: "Inserisci Descrizione",
            description: "Descrizione",
            name: "Nome",
            TaxSlab: "Aliquota Fiscale",
            title: "Titolo",
            enterTitle: "Inserisci Titolo",
            percentage: "Percentuale",
            previousCharge: "Carica Precedente",
            min: "Min",
            max: "Max",
            enterMin: "Inserisci Min",
            enterMax: "Inserisci Max",
            enterPercent: "Inserisci Percentuale",
            save: "Salva",
            clear: "Cancella",
            Add: "Aggiungi",
            TaxSlabgroup: "Gruppo di Aliquote Fiscali",
            country: "Paese",
            minimumCharge: "Carica Minima",
        },
        payrollGroup: {
            PayrollGroup: "Gruppo Paghe",
            AddPayrollGroup: "Aggiungi Gruppo Paghe",
            save: "Salva Gruppo",
            clear: "Cancella",
            Add: "Aggiungi Gruppo",
            name: "Nome",
            enterName: "Inserisci nome",
        },
        subsidiaryOffice: {
            enterName: "Inserisci nome",
            enterAddress: "Inserisci indirizzo",
            name: "Nome",
            subsidiary_Office: "Ufficio Secondario",
            latitude: "Latitudine",
            longitude: "Longitudine",
            address: "Indirizzo",
            branch: "Filiale",
            Subsidiary: "Sussidiaria",
            select: "Seleziona Sussidiaria",
            save: "Salva",
            clear: "Cancella",
            AddSubsidiaryOffice: "Aggiungi Ufficio Secondario",
            Add: "Aggiungi",
            title: "Titolo",
            enterTitle: "Inserisci titolo",
        },
        defaultHiringCriteria: {
            default: "Criteri di Assunzione Predefiniti",
            question: "Domande",
            enterQuestions: "Inserisci Domanda",
            save: "Salva Domanda",
            clear: "Cancella",
            Add: "Aggiungi Domanda",
        },
        workGatingCriteria: {
            work: "Criteri di Gestione WIP",
            question: "Domande",
            enterQuestions: "Inserisci domanda",
            save: "Salva Domanda",
            clear: "Cancella",
            Add: "Aggiungi Domanda",
            addWork: "Aggiungi Criterio di Gestione WIP"
        },
        designation: {
            desig: "Designazione",
            enterdesig: "Inserisci designazione",
            enterName: "Inserisci Nome",
            enterDescription: "Inserisci descrizione",
            description: "Descrizione",
            name: "Nome",
            save: "Salva Designazione",
            clear: "Cancella",
            Add: "Aggiungi Designazione",
        },
        appraisal: {
            Appraisal: "Valutazione",
            enterAppraisal: "Inserisci valutazione",
            enterDescription: "Inserisci descrizione",
            description: "Descrizione",
            save: "Salva Valutazione",
            clear: "Cancella",
            Add: "Aggiungi Valutazione",
            CompanyGoal: "Obiettivo Aziendale",
            IndividualGoal: "Obiettivo Individuale",
            DepartmentsGoal: "Obiettivo dei Dipartimenti",
            Goal: "Obiettivo",
            saveGoal: "Salva Obiettivo",
            AddCompanyGoal: "Aggiungi Obiettivo Aziendale",
            AddDepartmentGoal: "Aggiungi Obiettivo del Dipartimento",
            AddIndividualGoal: "Aggiungi Obiettivo Individuale",
            enterCompanyGoal: "Inserisci Obiettivo Aziendale",
            enterDepartmentGoal: "Inserisci Obiettivo del Dipartimento",
            enterIndividualGoal: "Inserisci Obiettivo Individuale",
        },
        office: {
            Office_Timing: "Orari Ufficio",
            group: "Nome Gruppo",
            enterGroup: "Inserisci nome",
            enterDescription: "Inserisci descrizione",
            description: "Descrizione",
            save: "Salva Orari Ufficio",
            clear: "Cancella",
            Add: "Aggiungi Orari Ufficio",
            days: "Giorni",
            work: "È Lavorativo",
            checkIn: "Entrata",
            checkOut: "Uscita",
            time: "Tempo di Grazia",
            min: "Min",
            create: "Crea Gruppo",
            new: "Nuovo Gruppo Orari",
            UpdateOfficeTiming: "Aggiorna Orari Ufficio",
            CreateOfficeTiming: "Crea Orari Ufficio",
            Monday: "Lunedì",
            Tuesday: "Martedì",
            Wednesday: "Mercoledì",
            Thursday: "Giovedì",
            Friday: "Venerdì",
            Saturday: "Sabato",
            Sunday: "Domenica",
        },
        leave: {
            Leave: "Tipo di Permesso",
            leaveType: "Tipo di Permesso",
            enterLeave: "Inserisci tipo di permesso",
            save: "Salva Tipo di Permesso",
            clear: "Cancella",
            Add: "Aggiungi Tipo di Permesso",
            enterDescription: "Inserisci descrizione",
            description: "Descrizione",
            allotLeaves: "Permesso Assegnato",
            balanceLeaves: "Tipi di Permesso (permessi residui)",
            selectType: "Seleziona tipo",
            leaveFor: "Permesso Per",
            leaveDates: "Date del Permesso",
            createleave: "Crea Permesso",
        },
        expense: {
            Expense: "Intestazione Spese",
            enterExpense: "Inserisci intestazione spese",
            enterDescription: "Inserisci descrizione",
            description: "Descrizione",
            save: "Salva",
            clear: "Cancella",
            Add: "Aggiungi Intestazione Spese",
        },
        elearningCategory: {
            elearningCategory: "Categoria di Formazione Online",
            description: "Descrizione",
            enterelearningCategory: "Inserisci categoria",
            enterDescription: "Inserisci descrizione",
            save: "Salva",
            clear: "Cancella",
            Add: "Aggiungi Categoria di Formazione Online",
        },
        salaryHeader: {
            SalaryHeader: "Intestazione Stipendio",
            AddSalaryHeader: "Aggiungi Intestazione Stipendio",
            enterSalaryHeader: "Inserisci intestazione stipendio",
            desc: "Descrizione",
            enterDesc: "inserisci descrizione",
            saveHeader: "Salva Intestazione",
            addHeader: "Aggiungi Intestazione",
            name: "Nome",
            clear: "cancella",
        },
        room: {
            Room: "Stanza",
            Name: "Nome",
            Entername: "Inserisci nome",
            Description: "Descrizione",
            Enterdescription: "Inserisci descrizione",
            Location: "Posizione",
            Enterlocation: "Inserisci posizione",
            TotalSeats: "Posti totali",
            Enterseats: "Inserisci posti",
            SaveRoom: "Salva Stanza",
            AddRoom: "Aggiungi Stanza",
            clear: "cancella"
        },
        RebateCategory: {
            rebateCategory: "Categoria Rimborso",
            addRebateCategory: "Aggiungi Categoria Rimborso",
            maxPercentage: "Percentuale Massima",
            entermaxPercentage: "Inserisci percentuale massima",
            maxAmount: "Importo Massimo",
            enterMaxAmount: "Inserisci importo massimo",
            type: "Tipo",
            basic: "Base",
            tax: "Tassa",
            fullAmount: "Importo Intero",
            rebateType: "Tipo di Rimborso",
        },
        projectCategory: {
            ProjectCategory: "Categoria Progetto",
            AddProjectCategory: "Aggiungi Categoria Progetto",
        },
        pageCategory: {
            Pagecategory: "Categoria Pagina",
            AddPageCategory: "Aggiungi Categoria Pagina",
        },
        NonMandatoryApprovals: {
            NonMandatoryApproval: "Approvazioni Non Obbligatorie",
        },
        jobdescription: {
            jobDesc: "Descrizione Lavoro",
            designation: "Designazione",
            selectDesign: "Seleziona Designazione",
            saveJobDesc: "Salva",
            addJobdesc: "Aggiungi Descrizione Lavoro",
        },
        Holiday: {
            Holidays: "Vacanze",
            AddHolidays: "Aggiungi Vacanze",
            AddHoliDay: "Aggiungi Giorno Festivo"
        },
        GradeAllowance: {
            gradeAllowance: "Indennità di Grado",
            Grades: "Gradi",
            AllowanceType: "Tipo di Indennità",
            SelectType: "Seleziona Tipo",
            AddAllowance: "Aggiungi Indennità",
            UpdateAllowance: "Aggiorna Indennità",
            Allowance: "Indennità",
            IsTaxable: "È Tassabile",
        },
        EmailConfiguration: {
            emailConfi: "Configurazione Email",
            enterEmailConfi: "Inserisci configurazione email",
            incomingPort: "Porta in Arrivo",
            enterIncomingPort: "Inserisci porta in arrivo",
            incomingServerAddress: "Indirizzo Server in Arrivo",
            enterIncomingServerAddress: "Inserisci indirizzo server in arrivo",
            outgoingPort: "Porta in Uscita",
            enterOutgoingPort: "Inserisci porta in uscita",
            outgoingServerAddress: "Indirizzo Server in Uscita",
            enterOutgoingServerAddress: "Inserisci indirizzo server in uscita",
            provider: "Provider",
            enterProvider: "Inserisci provider",
            saveEmailConfi: "Salva Configurazione Email",
            addEmailConfi: "Aggiungi Configurazione Email",
        },
        DiscussionBoardCategory: {
            discussionBoardCategory: "Categoria Bacheca Discussioni",
            SaveDiscussionBoardCategory: "Salva Categoria Bacheca Discussioni",
            AddDiscussionBoardCategory: "Aggiungi Categoria Bacheca Discussioni",
            Save: "Salva",
            Clear: "Cancella",
            Update: "Aggiorna",
        },
        DefaultApprovers: {
            defaultApprovers: "Approvatori Predefiniti",
        },
        DefaultAccountHeader: {
            defaultAccountHeader: "Intestazione Conto Predefinita",
            SaveAccountHeader: "Salva Intestazione Conto",
            SelectAccountTypes: "Seleziona Tipi di Conto",
        },
        CustomTag: {
            customTag: "Tag Personalizzato",
            Entercustomtag: "Inserisci tag personalizzato",
            SaveCustomTag: "Salva Tag Personalizzato",
            AddCustomTag: "Aggiungi Tag Personalizzato",
        },
        customApprovalCategory: {
            customApprovalcategory: "Categoria Approvazione Personalizzata",
            enterCustomApprovalcategory: "Inserisci categoria approvazione personalizzata",
        },
        BusinessNumber: {
            Manual: "Manuale",
            TotalNumber: "Numero Totale",
            Country: "Paese",
            SelectCountry: "Seleziona Paese",
            Number: "Numero",
            Random: "Casuale",
            SelectNumber: "Seleziona Numero",
            Cost: "Costo",
            Request: "Richiesta",
        },
        BusinessAssociation: {
            businessAssociation: "Associazione Aziendale",
            SaveBusinessAssociation: "Salva Associazione Aziendale",
            AddBusinessAssociation: "Aggiungi Associazione Aziendale",
            AssociationType: "Tipo di Associazione",
        },
        AssetsCategory: {
            assetsCategory: "Categoria Beni",
            categoryName: "Nome Categoria",
            enterCategoryName: "Inserisci nome categoria",
            desc: "Descrizione",
            enterDesc: "Inserisci descrizione",
            accountType: "Tipo di Conto",
            parentAccount: "Conto Principale",
            selectParent: "Seleziona Principale",
            selectType: "Seleziona Tipo",
            add: "Aggiungi",
            save: "Salva",
            clear: "Cancella",
            name: "Nome",
            assets: "Bene",
            liability: "Passivo",
            capital: "Capitale",
            revenue: "Ricavo",
            expense: "Spesa",
            costOfGoodsale: "Costo del Prodotto Venduto",
        },
        Allowance: {
            allowance: "Indennità",
            enterAllownace: "Inserisci indennità",
            percent: "Percentuale",
            desc: "Inserisci descrizione",
            grade: "Grado",
            selectGrade: "Seleziona Grado",
            amount: "Importo",
            benefit: "Beneficio",
            deduction: "Deduzione",
            texable: "Tassabile",
            nonTexable: "Non Tassabile",
            director: "Direttore",
            ceo: "CEO",
            addAllowance: "Aggiungi Indennità",
            saveAllowance: "Salva Indennità",
            clear: "Cancella",
            name: "Nome",
            allowanceType: "Tipo di Indennità",
            allowanceUnit: "Unità di Indennità",
            istexable: "È Tassabile",
            yes: "Sì",
            no: "No",
        },
        CompanyInfo: {
            businessLogo: "Logo Aziendale",
            basicinfo: "Informazioni di Base",
            ShortLogo: "Logo Breve",
            Logo: "Logo",
            FullLogo: "Logo Completo",
            companyName: "Nome Azienda",
            PleaseEnterCompanyName: "Inserisci il Nome dell'Azienda",
            enterCompanyName: "Inserisci Nome Azienda",
            companyEmail: "Inserisci Email Aziendale",
            PleaseEnterCompanyEmail: "Inserisci l'Email dell'Azienda",
            SelectOwnerName: "Seleziona Nome Proprietario",
            totalEmployees: "Totale Dipendenti",
            totalEmployeesInCall: "Totale Dipendenti in Chiamata",
            SaveShortLogo: "Salva Logo Breve",
            LongLogo: "Logo Lungo",
            SaveLongLogo: "Salva Logo Lungo",
            SaveFullLogo: "Salva Logo Completo",
        },
        billing: {
            Invoice: "Fattura",
            InvoiceNo: "Numero Fattura",
            CustomerName: "Nome Cliente",
            PayInvoice: "Paga Fattura",
            Payment: "Pagamento",
            BillNo: "Numero Fattura",
            CardLast: "Ultimi 4 Cifre della Carta",
            BusinessName: "Nome Azienda",
            CardHolder: "Titolare della Carta",
            Viewinvoicedetails: "Visualizza dettagli fattura e pagamento",
            Viewinvoicedetail: "Visualizza dettaglio fattura",
            Qty: "Q.tà",
            BillType: "Tipo Fattura"
        },
        PaymentCard: {
            paymentCard: "Carta di Pagamento",
            CardHolderName: "Nome del Titolare della Carta",
            AddCard: "Aggiungi Carta",
            PayInvoice: "Paga Fattura",
            CardHolderNameRequired: "Il Nome del Titolare della Carta è Obbligatorio.",
            PayCard: "Paga Carta",
            payanotherway: "O paga in un altro modo",
            credentialstocontinue: "Inserisci le tue credenziali per continuare.",
            Expiry: "Scadenza",
            Brand: "Marca",
            Country: "Paese",
            LastFourDigits: "Ultimi Quattro Cifre",
        }
    },
    billing: {
        BillType: "Tipo Fattura",
        billingTypeEnum: {
            [BillingTypeEnum.NewUser]: "Nuovo Utente",
            [BillingTypeEnum.MonthlySubscription]: "Abbonamento Mensile",
            [BillingTypeEnum.NewBusinessNumber]: "Nuovo Numero Aziendale",
        }
    },
    businessNumber: {
        statusEnum: {
            [BuisnessNumberStatusEnum.Waiting]: "In Attesa",
            [BuisnessNumberStatusEnum.Declined]: "Declinato",
            [BuisnessNumberStatusEnum.NumberCancelled]: "Numero Annullato",
            [BuisnessNumberStatusEnum.Purchased]: "Acquistato",
            [BuisnessNumberStatusEnum.RequestCancelled]: "Richiesta Annullata",
            [BuisnessNumberStatusEnum.Rejected]: "Respinto",
        },
    },
    appraisal: {
        Appraisals: "Valutazioni",
        createAppraisals: "Crea Valutazioni",
        noDataMessage: "– Crea Valutazioni ora!",
        Approvers: "Approvatori",
        Questions: "Domande",
        Tasks: "Compiti",
        Bonus: "Bonus",
        Increment: "Incremento",
        previousApraisals: "Valutazioni Precedenti",
        warning: "Avviso",
        rewards: "Premi",
        courses: "Corsi",
        Promotion: "Promozione",
        PleaseEnterBonusPercentage: "Inserisci la Percentuale di Bonus",
        validateIncrement: "Impossibile processare l'incremento: nessuna informazione sullo stipendio disponibile per l'impiegato",
        PleaseEnterBonusAmount: "Inserisci l'Importo del Bonus",
        PleaseEnterIncrementAmount: "Inserisci l'Importo dell'Incremento",
        RateAtleastOneItem: "Valuta almeno un elemento",
        Noemployeeselected: "Nessun dipendente selezionato",
        submitAppraisal: "Invia Valutazioni",
        Appraisedof: "Valutato di",
        AppraisalApprovers: "Approvatori della Valutazione",
        AppraisalDetail: "Dettaglio Valutazione",
        appraisalFilterTypeEnum: {
            [AppraisalFilterEnum.All]: "Tutte le Valutazioni",
            [AppraisalFilterEnum.CreatedByMe]: "Create da Me",
            [AppraisalFilterEnum.ForApproval]: "In Attesa di Approvazione",
            [AppraisalFilterEnum.TeamAppraisal]: "Valutazione del Team",
            [AppraisalFilterEnum.MyApprovedAppraisal]: "Mie Valutazioni Approvate",
        },
        statusEnum: {
            [AppraisalStatusEnum.InProcess]: "In Corso",
            [AppraisalStatusEnum.Approved]: "Approvato",
            [AppraisalStatusEnum.Declined]: "Declinato",
            [AppraisalStatusEnum.Cancelled]: "Annullato",
            [AppraisalStatusEnum.Hold]: "In Attesa",
        },
        SortEnum: {
            [AppraisalFilterSortEnum.CreateDateDesc]: "Data di Creazione - Decrescente",
            [AppraisalFilterSortEnum.CreateDateAsc]: "Data di Creazione",
            [AppraisalFilterSortEnum.UpdateDateDesc]: "Data di Aggiornamento - Decrescente",
            [AppraisalFilterSortEnum.UpdateDateAsc]: "Data di Aggiornamento",
            [AppraisalFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [AppraisalFilterSortEnum.ReferenceNo]: "Numero di Riferimento",

        }
    },
    loan: {
        loan: "Prestito",
        createLoan: "Crea Prestito",
        loanTenure: "Durata del Prestito (in mesi)",
        enterLoanTenure: "Inserisci la durata del prestito (in mesi)",
        deduction: "Detrazione/Mesi",
        selectLoanPurpose: "Seleziona lo scopo del prestito",
        noDataMessage: "– Crea prestiti ora!",
        SortBy: "Ordina per",
        SelectSortBy: "Seleziona Ordina per",
        InterestRate: "Tasso di interesse",
        loanPurpose: {
            loanPurpose: "Scopo del Prestito",
            vehicle: "Veicolo",
            personal: "Personale",
            wedding: "Matrimonio",
            medical: "Medico",
            education: "Educazione",
            house: "Casa",
            other: "Altro",
        },
        loanPurposeEnumList: {
            [LoanPurposeEnum.Personal]: "Personale",
            [LoanPurposeEnum.Wedding]: "Matrimonio",
            [LoanPurposeEnum.Medical]: "Medico",
            [LoanPurposeEnum.Education]: "Educazione",
            [LoanPurposeEnum.House]: "Casa",
            [LoanPurposeEnum.Vehicle]: "Veicolo",
            [LoanPurposeEnum.Other]: "Altro",
        },
        loanFilterTypeEnum: {
            [LoanFilterTypeEnum.All]: "Tutti i Prestiti",
            [LoanFilterTypeEnum.ForApproval]: "In Attesa di Approvazione",
            [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "I Miei Prestiti",
            [LoanFilterTypeEnum.LoanForMe]: "Prestito Per Me",
        },
        statusEnum: {
            [LoanStatusEnum.InProcess]: "In Elaborazione",
            [LoanStatusEnum.Approved]: "Approvato",
            [LoanStatusEnum.Declined]: "Declinato",
            [LoanStatusEnum.Cancelled]: "Annullato",
            [LoanStatusEnum.Disbursed]: "Erogato",
            [LoanStatusEnum.PartialCleared]: "Parzialmente Liquidato",
            [LoanStatusEnum.Completed]: "Completato",
            [LoanStatusEnum.Hold]: "In Attesa",
            [LoanStatusEnum.WaitingForDisburse]: "In Attesa di Erogazione",

        },
        loanTypeEnum: {
            [LoanTypeEnum.Company]: "Prestito Aziendale",
            [LoanTypeEnum.WorkWise]: "Prestito Workwise",
            [LoanTypeEnum.Both]: "Entrambi",
        },
        loanFilterSortEnum: {
            [LoanFilterSortEnum.CreateDateDesc]: "Data di Creazione - Decrescente",
            [LoanFilterSortEnum.CreateDateAsc]: "Data di Creazione",
            [LoanFilterSortEnum.UpdateDateDesc]: "Data di Aggiornamento - Decrescente",
            [LoanFilterSortEnum.UpdateDateAsc]: "Data di Aggiornamento",
            [LoanFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
        },
    },
    quotations: {
        Quotation: "Preventivo",
        CreateQuotation: "Crea Preventivo",
        quotationDate: "Data",
        ClientName: "Nome Cliente",
        ClientEmail: "Email Cliente",
        PhoneNumber: "Numero di Telefono",
        TotalAmount: "Importo Totale",
        QuotationType: "Tipo",
        Rate: "Tariffa",
        Quantity: "Quantità",
        Attachments: "Allegati",
        serviceItem: "Servizio/Articolo",
        price: "Prezzo",
        tax: "Tassa (%)",
        taxAmount: "Importo Tassa",
        sno: "N.Ord.",
        EnterClientName: "Inserisci Nome Cliente",
        clientsName: "Nome Cliente",
        EnterClientEmail: "Inserisci Email Cliente",
        addressLine1: "Indirizzo linea 1",
        addressLine2: "Indirizzo linea 2",
        EnterAddress1: "Inserisci Indirizzo 1:",
        EnterAddress2: "Inserisci Indirizzo 2:",
        Product: "Prodotto",
        Service: "Servizio",
        netAmount: "Importo Netto",
        CODE: "CODICE",
        DATE: "DATA",
        TOTAL: "TOTALE",
        To: "A",
        SubTotal: "Subtotale",
        noDataMessage: "– Crea Preventivo adesso!",

        quotationFilterTypeEnum: {
            [QuotationFilterEnum.All]: "Preventivi",
            [QuotationFilterEnum.CreatedByMe]: "Creati da Me",
            [QuotationFilterEnum.ForApproval]: "In Approvazione",
        },
        statusEnum: {
            [QuotationStatusEnum.InProcess]: "In Elaborazione",
            [QuotationStatusEnum.Approved]: "Approvato",
            [QuotationStatusEnum.Declined]: "Declinato",
            [QuotationStatusEnum.Cancelled]: "Annullato",
            [QuotationStatusEnum.Hold]: "In Attesa",
            [QuotationStatusEnum.ClientApproved]: "Approvato dal cliente",
            [QuotationStatusEnum.ClientRejected]: "Rifiutato dal cliente",
        },
        SortEnum: {
            [QuotationFilterSortEnum.CreateDateDesc]: "Data di Creazione - Decrescente",
            [QuotationFilterSortEnum.CreateDateAsc]: "Data di Creazione - Crescente",
            [QuotationFilterSortEnum.UpdateDateDesc]: "Data di Aggiornamento - Decrescente",
            [QuotationFilterSortEnum.UpdateDateAsc]: "Data di Aggiornamento - Crescente",
            [QuotationFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
            [QuotationFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente"
        }
    },
    payroll: {
        Payroll: "Paghe",
        createPayroll: "Crea Paghe",
        noDataMessage: "– Crea Paghe adesso!",
        basicSalary: "Salario Base",
        loan: "Prestito",
        allowance: "Indennità",
        ExpenseReimbursement: "Rimborso Spese",
        deduction: "Deduzione",
        tax: "Imposta",
        rebate: "Rimborso",
        bonus: "Bonus",
        selectMonthYear: "Seleziona Mese e Anno",
        disburseDate: "Data di Erogazione",
        Voucher: "Voucher",
        DownloadtoExcel: "Scarica in Excel",
        PayrollDetail: "Dettaglio Paghe",
        TransactionMode: "Modalità di Transazione",
        IBANNumber: "Numero IBAN",

        statusEnum: {
            [PayrollStatusEnum.InProcess]: "In Elaborazione",
            [PayrollStatusEnum.Approved]: "Approvato",
            [PayrollStatusEnum.Declined]: "Declinato",
            [PayrollStatusEnum.Resend]: "Rinviato",
            [PayrollStatusEnum.Inactive]: "Inattivo",
            [PayrollStatusEnum.NotRequired]: "Non Richiesto",
            [PayrollStatusEnum.Cancelled]: "Annullato",
            [PayrollStatusEnum.Hold]: "In Attesa",
            [PayrollStatusEnum.Disbursed]: "Erogato",
            [PayrollStatusEnum.Completed]: "Completato",
        },
        payrollFilterTypeEnum: {
            [PayrollFilterTypeEnum.All]: "Paghe",
            [PayrollFilterTypeEnum.CreatedByMe]: "Creati da Me",
            [PayrollFilterTypeEnum.ForApproval]: "In Approvazione",
        },
    },
    voucher: {
        createVoucher: "Crea Voucher",
        SelectVoucherType: "Seleziona Tipo Voucher",
        BasicSalary: "Salario Base",
        LoanPayment: "Pagamento Prestito",
        LoanDispersable: "Importo Prestito Erogabile",
        Allowance: "Indennità",
        ExpenseReimbursement: "Rimborso Spese",
        Deduction: "Deduzione",
        VoucherDetail: "Dettaglio Voucher",
        SavePrint: "Salva e Stampa",
        Bonus: "Bonus",
        Rebate: "Rimborso",
        Tax: "Imposta",
        ChequeNo: "Numero Assegno",
        Account: "Conto",
        Narration: "Narrativa",
        Debit: "Debito",
        Credit: "Credito",
        Dr: "Debito",
        Cr: "Credito",
        differences: "Differenze",
        drCr: "Dr/Cr",
        LinkVoucher: "Collega Voucher",
        PostVoucher: "Pubblica Voucher",
        voucherDate: "Data Voucher",
        VoucherType: "Tipo Voucher",

        voucherTypeList: {
            [voucherTypeEnum.GeneralVoucher]: "Voucher Generale",
            [voucherTypeEnum.PaymentVoucher]: "Voucher Pagamento",
            [voucherTypeEnum.ReceiptVoucher]: "Voucher Ricevuta",
        },
        accountTypeList: {
            [accountTypeEnum.Asset]: "Attivo",
            [accountTypeEnum.Capital]: "Capitale",
            [accountTypeEnum.Expense]: "Spesa",
            [accountTypeEnum.Liability]: "Passivo",
            [accountTypeEnum.Revenue]: "Ricavo",
        }
    },
    trialBalance: {
        accountTypeList: {
            [AccountTypeEnum.Asset]: "Attivo",
            [AccountTypeEnum.Capital]: "Capitale",
            [AccountTypeEnum.Expense]: "Spesa",
            [AccountTypeEnum.Liability]: "Passivo",
            [AccountTypeEnum.Revenue]: "Ricavo",
        }
    },
    businessApproval: {
        statusTypeList: {
            [ApprovalStatusTypeEnum.InProcess]: "In Elaborazione",
            [ApprovalStatusTypeEnum.Approved]: "Approvato",
            [ApprovalStatusTypeEnum.Declined]: "Declinato",
            [ApprovalStatusTypeEnum.Cancelled]: "Annullato",
            [ApprovalStatusTypeEnum.Completed]: "Completato",
        }
    },
    disperse: {
        transactionModeEnumList: {
            [TransactionModeTypeEnum.Cash]: "Contanti",
            [TransactionModeTypeEnum.BankTransfer]: "Bonifico Bancario",
            [TransactionModeTypeEnum.Cheque]: "Assegno",
        },
    },
    promotion: {
        CreatePromotion: "Crea Promozione",
        Promotion: "Promozione",
        promotionTo: "Promozione A",
        noDataMessage: "Crea una promozione adesso!",
        selectPromotionTo: "Seleziona Promozione A",
        pleseSelectPromotionTo: "Seleziona Promozione A",
        createPromotion: "Crea Promozione",
        promotionFilterTypeEnum: {
            [PromotionFilterTypeEnum.All]: "Tutte le Promozioni",
            [PromotionFilterTypeEnum.CreatedByMe]: "Create Da Me",
            [PromotionFilterTypeEnum.ForApproval]: "Per Approvazione",
            [PromotionFilterTypeEnum.PromotionForMe]: "Le Mie Promozioni",
        },
        statusEnum: {
            [PromotionStatusEnum.InProcess]: "In Elaborazione",
            [PromotionStatusEnum.Approved]: "Approvato",
            [PromotionStatusEnum.Declined]: "Declinato",
            [PromotionStatusEnum.Cancelled]: "Annullato",
            [PromotionStatusEnum.Hold]: "In Attesa",
        },
        SortEnum: {
            [PromotionFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [PromotionFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento - Crescente",
            [PromotionFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [PromotionFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
            [PromotionFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [PromotionFilterSortEnum.CreateDateAsc]: "Data Creazione - Crescente"

        }

    },
    complains: {
        createComplain: "Crea Reclamo",
        myComplain: "I Miei Reclami",
        AllComplains: "Tutti i Reclami",
        complainOf: "Reclamo Di",
        ComplainDetail: "Dettaglio Reclamo",
        ComplainToMe: "Reclamo A Me",
        ComplainAboutMe: "Reclamo Su Di Me",
        noDataMessage: " – Crea dei reclami adesso!",
        statusEnum: {
            [ComplainApprovalStatusEnum.InProcess]: "In Elaborazione",
            [ComplainApprovalStatusEnum.Approved]: "Approvato",
            [ComplainApprovalStatusEnum.Declined]: "Declinato",
            [ComplainApprovalStatusEnum.Resend]: "Reinviato",
            [ComplainApprovalStatusEnum.Inactive]: "Inattivo",
            [ComplainApprovalStatusEnum.NotRequired]: "Non Richiesto",
            [ComplainApprovalStatusEnum.Cancelled]: "Annullato",
            [ComplainApprovalStatusEnum.Hold]: "In Attesa",
        },
        SortEnum: {
            [ComplainFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [ComplainFilterSortEnum.CreateDateAsc]: "Data Creazione - Crescente",
            [ComplainFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [ComplainFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento - Crescente",
            [ComplainFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
            [ComplainFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente"
        },
    },
    salary: {
        SalaryMembers: "Membri dello Stipendio",
        EffectiveDate: "Data Efficacia",
        BasicSalary: "Stipendio Base",
        Allowances: "Indennità",
        Allowance: "Indennità",
        AllowanceDetail: "Dettaglio Indennità",
        IsTaxable: "È Tassabile",
        Deduction: "Deduzione",
        GrossSalary: "Stipendio Lordo",
        CreateSalary: "Crea Stipendio",
        SalaryDetail: "Dettaglio Stipendio",
        AddSalary: "Aggiungi Stipendio",

        Deductions: "Deduzioni",
        AllowanceType: "Tipo di Indennità",
        EffectiveDateIsRequired: "La Data Efficacia È Richiesta",
        BasicSalaryIsRequired: "Lo Stipendio Base È Richiesto",
        Negativesalaryisnotallowed: "Lo stipendio negativo non è consentito",
        Benefit: "Beneficio",
        noDataMessage: "– Crea lo Stipendio adesso!",
        EnterIncrementPercentage: "Inserisci Percentuale di Incremento",
        salaryFilterTypeEnum: {
            [SalaryFilterTypeEnum.Salaries]: "Stipendi",
            [SalaryFilterTypeEnum.CreateByMe]: "Creati Da Me",
            [SalaryFilterTypeEnum.ForApproval]: "In Attesa di Approvazione",
        },
        statusEnum: {
            [EmployeeSalaryStatusEnum.InProcess]: "In Elaborazione",
            [EmployeeSalaryStatusEnum.Approved]: "Approvato",
            [EmployeeSalaryStatusEnum.Declined]: "Declinato",
            [EmployeeSalaryStatusEnum.Cancelled]: "Annullato",
            [EmployeeSalaryStatusEnum.Hold]: "In Attesa",
            [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "In Attesa di Altra Approvazione",
        },
        SortEnum: {
            [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "Data Creazione - Crescente",
            [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento - Crescente",
            [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [EmployeeSalaryFilterSortEnum.ReferenceNo]: "Numero di Riferimento",

        }
    },
    resignations: {
        resignationHeader: "Dimissioni",
        resignationDate: "Data Dimissioni",
        createresignation: "Crea Dimissioni",
        noDataMessage: "– Crea le Dimissioni adesso!",
        reasonforresignation: "Motivo delle Dimissioni",
        istermination: "È Licenziamento",
        resignationDetail: "Dettaglio Dimissioni",
        statusEnum: {
            [ResignationApprovalStatusEnum.InProcess]: "In Elaborazione",
            [ResignationApprovalStatusEnum.Approved]: "Approvato",
            [ResignationApprovalStatusEnum.Declined]: "Declinato",
            [ResignationApprovalStatusEnum.Cancelled]: "Annullato",
            [ResignationApprovalStatusEnum.Completed]: "Completato",
            [ResignationApprovalStatusEnum.Hold]: "In Attesa",
        },
        resignationFilterTypeEnum: {
            [ResignationFilterTypeEnum.Resignation]: "Dimissioni",
            [ResignationFilterTypeEnum.CreateByMe]: "Creato Da Me",
            [ResignationFilterTypeEnum.ForApproval]: "In Attesa di Approvazione",
        },
        resignationTypeEnum: {
            [ResignationTypeEnum.Resignation]: "Dimissioni",
            [ResignationTypeEnum.Termination]: "Licenziamento",
            [ResignationTypeEnum.Both]: "Entrambi",
        },
        SortEnum: {
            [ResignationFilterSortEnum.CreateDateDesc]: "Data Creazione - Decrescente",
            [ResignationFilterSortEnum.CreateDateAsc]: "Data Creazione - Crescente",
            [ResignationFilterSortEnum.UpdateDateDesc]: "Data Aggiornamento - Decrescente",
            [ResignationFilterSortEnum.UpdateDateAsc]: "Data Aggiornamento - Crescente",
            [ResignationFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [ResignationFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
        },
        ResignationPurposeEnum: {
            [ResignationPurposeEnum.InsufficientWorkLifeBalance]: "Equilibrio Insufficiente tra Vita Lavorativa e Privata",
            [ResignationPurposeEnum.UnrealisticGoalsAndPerformanceObjectives]: "Obiettivi e Obiettivi di Performance Irrealistici",
            [ResignationPurposeEnum.LackOfAClearPathForCareerAdvancement]: "Mancanza di un Percorso Chiaro per l'Avanzamento di Carriera",
            [ResignationPurposeEnum.FeelUnsupportedByManage]: "Sentirsi Non Supportati dalla Direzione",
            [ResignationPurposeEnum.DontFeelChallenged]: "Non Sentirsi Sfidati",
            [ResignationPurposeEnum.TimeOffAndFlexibility]: "Tempo Libero e Flessibilità",
            [ResignationPurposeEnum.Relocation]: "Trasferimento",
            [ResignationPurposeEnum.Others]: "Altro",
        },
    },
    Employees: {
        employeeAttachments: "Allegati dell'Impiegato",
        CreateEmployeeNow: "Crea Impiegato Ora",
        ProbationPeriod: "Periodo di Prova (Giorni)",
        requiredMessageProbation: "Si prega di inserire il periodo di prova",
        requiredMessageProbationPeriod: "Il periodo di prova deve essere compreso tra 0 e 30",
        EmployeeNo: "Numero Impiegato",
        EnterEmployeeNo: "Inserisci Numero Impiegato",
        EmploymentType: "Tipo di Impiego",
        SelectEmploymentType: "Seleziona Tipo di Impiego",
        requiredMessageEmploymentType: "Si prega di selezionare il tipo di impiego",
        OfficeShift: "Turno in Ufficio",
        requiredMessageOfficeShift: "Si prega di selezionare il turno in ufficio",
        SelectOfficeShift: "Seleziona Turno in Ufficio",
        UpdateBasicInfo: "Aggiorna Informazioni di Base",
        Degree: "Titolo di Studio",
        EnterDegree: "Inserisci Titolo di Studio",
        requiredDegree: "Si prega di inserire il titolo di studio",
        Institute: "Istituto",
        EnterInstituteName: "Inserisci Nome Istituto",
        requiredMessageInstitute: "Si prega di inserire il nome dell'istituto",
        ObtainedMarks: "Voti Ottenuti",
        requiredMessageObtained: "Si prega di inserire i voti ottenuti",
        EnterObtainedMarks: "Inserisci Voti Ottenuti",
        TotalMarks: "Voti Totali",
        EnterTotalMarks: "Inserisci Voti Totali",
        requiredMessageMarks: "Si prega di inserire i voti totali",
        UpdateEducation: "Aggiorna Istruzione",
        AddEducation: "Aggiungi Istruzione",
        WorkExperienceInfo: "Informazioni sull'Esperienza Lavorativa",
        UpdateExperience: "Aggiorna Esperienza",
        AddExperience: "Aggiungi Esperienza",
        EmergencyInfo: "Informazioni di Emergenza",
        UpdateEmergencyForm: "Aggiorna Modulo di Emergenza",
        AddEmergency: "Aggiungi Emergenza",
        UpdateEmergency: "Aggiorna Emergenza",
        UpdateBank: "Aggiorna Banca",
        AddBank: "Aggiungi Banca",
        EnableEmployee: "Sei sicuro di abilitare questo impiegato?",
        DisableEmployee: "Sei sicuro di disabilitare questo impiegato?",
        Enable: "Abilita",
        Disabled: "Disabilitato",
        UpdateFamily: "Aggiorna Famiglia",
        AddFamily: "Aggiungi Famiglia",
        AddEmployeesLinkage: "Aggiungi Collegamento Impiegati",
        AllEmployees: "Tutti gli Impiegati",
        FamilyInfo: "Informazioni Familiari",
        Basic_Info: "Informazioni di Base",
        Email_Configuration: "Configurazione Email",
        Bank_Detail: "Dettaglio Bancario",
        Education: "Istruzione",
        Emergency_Info: "Informazioni di Emergenza",
        Work_Experience: "Esperienza Lavorativa",
        Experience: "Esperienza",
        Rebate: "Rimborso",
        Salary: "Stipendio",
        Leaves: "Permessi",
        Devices: "Dispositivi",
        basicInfo: "Informazioni di Base",
        Appraisal: "Valutazione",
        Family: "Famiglia",
        Attachments: "Allegati",
        My_Team: "Il Mio Team",
        Employees_Linkage: "Collegamento Impiegati",
        Company_Goal: "Obiettivo Aziendale",
        Individual_Goal: "Obiettivo Individuale",
        Emails: "Email",
        Finance: "Finanza",
        Goal: "Obiettivi",
        RebateInfo: "Informazioni sul Rimborso",
        UpdateRebate: "Aggiorna Rimborso",
        AddRebate: "Aggiungi Rimborso",
        AddSalary: "Aggiungi Stipendio",
        AddEmailConfiguration: "Aggiungi Configurazione Email",
        AddBank: "Aggiungi Dettaglio Bancario",
        Statistics: "Statistiche",
    },
    leaves: {
        leave: "Congedi",
        leavedetail: "Dettagli del Congedo",
        Leave: "Tipo di Congedo",
        leaveType: "Tipo di Congedo",
        enterLeave: "Inserisci tipo di congedo",
        save: "Salva Tipo di Congedo",
        Add: "Aggiungi Tipo di Congedo",
        allotLeaves: "Congedi Assegnati",
        balanceLeaves: "Tipi di Congedo (congedi residui)",
        leaveFor: "Congedo Per",
        leaveDates: "Date del Congedo",
        createleave: "Crea Congedo",
        messages: "Seleziona il tipo di congedo",
        allocatedLeaves: "Congedi Assegnati",
        availedLeaves: "Congedi Goduti",
        remainingLeaves: "Congedi Residui",
        noDataMessage: " – Crea un Congedo ora!",
        Leavesinfo: "Informazioni sui Congedi",
        UpdateLeave: "Aggiorna Congedo",
        leavesFilterTypeEnum: {
            [LeaveFilterTypeEnum.All]: "Tutti i Congedi",
            [LeaveFilterTypeEnum.CreatedByMe]: "Creati Da Me",
            [LeaveFilterTypeEnum.ForApproval]: "Per Approvazione",
            [LeaveFilterTypeEnum.LeaveForMe]: "I Miei Congedi",
        },
        statusEnum: {
            [LeaveStatusEum.InProcess]: "In Processo",
            [LeaveStatusEum.Approved]: "Approvato",
            [LeaveStatusEum.Declined]: "Rifiutato",
            [LeaveStatusEum.Resend]: "Rinviato",
            [LeaveStatusEum.InActive]: "Inattivo",
            [LeaveStatusEum.NotRequired]: "Non Richiesto",
            [LeaveStatusEum.Cancelled]: "Annullato",
            [LeaveStatusEum.Hold]: "In Attesa",
        },
        SortEnum: {
            [LeaveFilterSortEnum.CreateDateDesc]: "Data di Creazione - Decrescente",
            [LeaveFilterSortEnum.CreateDateAsc]: "Data di Creazione",
            [LeaveFilterSortEnum.UpdateDateDesc]: "Data di Aggiornamento - Decrescente",
            [LeaveFilterSortEnum.UpdateDateAsc]: "Data di Aggiornamento",
            [LeaveFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [LeaveFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
        }
    },
    bonus: {
        Bonus: "Bonus",
        createBonus: "Crea Bonus",
        BonusTo: "Bonus Per",
        CurrentSalary: "Salario Attuale",
        noDataMessage: "– Crea Bonus Adesso!",
        PercentageShouldNotExceed: "La percentuale non deve superare il 100",
        bonusFilterTypeEnum: {
            [BonusFilterTypeEnum.All]: "Tutti i Bonus",
            [BonusFilterTypeEnum.CreatedByMe]: "Creati Da Me",
            [BonusFilterTypeEnum.ForApproval]: "Per Approvazione",
            [BonusFilterTypeEnum.MyBonus]: "I Miei Bonus",
        },
        statusEnum: {
            [BonusStatusEnum.InProcess]: "In Processo",
            [BonusStatusEnum.Approved]: "Approvato",
            [BonusStatusEnum.Declined]: "Rifiutato",
            [BonusStatusEnum.Cancelled]: "Annullato",
            [BonusStatusEnum.Inactive]: "Inattivo",
            [BonusStatusEnum.NotRequired]: "Non Richiesto",
            [BonusStatusEnum.Resend]: "Rinviato",
            [BonusStatusEnum.WaitingForOtherApproval]: "In Attesa di Altra Approvazione",
            [BonusStatusEnum.Hold]: "In Attesa",
            [BonusStatusEnum.Disbursed]: "Erogato",
            [BonusStatusEnum.Completed]: "Completato",
        },

        SortEnum: {
            [BonusFilterSortEnum.CreateDateDesc]: "Data di Creazione - Decrescente",
            [BonusFilterSortEnum.CreateDateAsc]: "Data di Creazione",
            [BonusFilterSortEnum.UpdateDateDesc]: "Data di Aggiornamento - Decrescente",
            [BonusFilterSortEnum.UpdateDateAsc]: "Data di Aggiornamento",
            [BonusFilterSortEnum.ReferenceNoDesc]: "Numero di Riferimento - Decrescente",
            [BonusFilterSortEnum.ReferenceNo]: "Numero di Riferimento",
            [BonusFilterSortEnum.Amount]: "Importo",
            [BonusFilterSortEnum.AmountDesc]: "Importo - Decrescente",
            [BonusFilterSortEnum.Salary]: "Salario",
            [BonusFilterSortEnum.SalaryDesc]: "Salario - Decrescente"
        }
    },
    chartOfAccount: {
        ChartOfAccount: "Piano dei Conti",
        CreateAccount: "Crea Account",
        CreateChartOfAccount: "Crea Piano dei Conti",
        accountType: "Tipo di Account",
        selectType: "Seleziona Tipo",
        parentAccount: "Conto Genitore",
        selectParent: "Seleziona Genitore",
        EnterAccountType: "Si prega di inserire il tipo di account",
        clossingBalance: "Saldo di Chiusura",
    },
    ledgerReports: {
        reports: "Rapporti",
        selectAccount: "Seleziona Account",
        selectDate: "Seleziona Data",
        generateReport: "Genera Rapporto",
        VoucherNo: "Numero Voucher",
    },
    requisitions: {
        requisition: "Richieste",
        MyRequisitions: "Le mie Richieste",
        createRequisition: "Crea Richiesta",
        PleaseEnterRequisitionName: "Inserisci il nome della Richiesta",
        EnterRequisitionName: "Inserisci il nome della Richiesta",
        Budget: "Budget",
        EnterBudget: "Inserisci il Budget",
        Dateofrequestexpire: "Data di scadenza della richiesta",
        noDataMessage: "– Crea ora delle Richieste!",
    },
    DefaultApprovers: {
        TypeEnum: {
            [DefaultApprovalTypeEnum.Expense]: "Spesa",
            [DefaultApprovalTypeEnum.ExpenseFinancers]: "Finanziatori di Spese",
            [DefaultApprovalTypeEnum.Travel]: "Viaggio",
            [DefaultApprovalTypeEnum.TravelAgent]: "Agente di Viaggio",
            [DefaultApprovalTypeEnum.Loan]: "Prestito",
            [DefaultApprovalTypeEnum.Leave]: "Permesso",
            [DefaultApprovalTypeEnum.Asset]: "Asset",
            [DefaultApprovalTypeEnum.Salary]: "Stipendio",
            [DefaultApprovalTypeEnum.Payroll]: "Libro Paga",
            [DefaultApprovalTypeEnum.Reward]: "Ricompensa",
            [DefaultApprovalTypeEnum.ResignationHr]: "Dimissioni HR",
            [DefaultApprovalTypeEnum.ResignationAdmin]: "Dimissioni Amministrazione",
            [DefaultApprovalTypeEnum.ResignationIt]: "Dimissioni IT",
            [DefaultApprovalTypeEnum.ResignationFinance]: "Dimissioni Finanza",
            [DefaultApprovalTypeEnum.ResignationExit]: "Uscita",
            [DefaultApprovalTypeEnum.Requisition]: "Richiesta",
            [DefaultApprovalTypeEnum.CustomApproval]: "Approvazione Personalizzata",
            [DefaultApprovalTypeEnum.Forms]: "Moduli",
            [DefaultApprovalTypeEnum.Appraisals]: "Valutazioni",
            [DefaultApprovalTypeEnum.Promotion]: "Promozione",
            [DefaultApprovalTypeEnum.Warning]: "Avviso",
            [DefaultApprovalTypeEnum.Bonus]: "Bonus",
            [DefaultApprovalTypeEnum.Complains]: "Lamentele",
            [DefaultApprovalTypeEnum.Career]: "Carriera",
            [DefaultApprovalTypeEnum.Quotations]: "Preventivi",
            [DefaultApprovalTypeEnum.RequisitionFinal]: "Richiesta Finale",
            [DefaultApprovalTypeEnum.Attendance]: "Presenza",
            [DefaultApprovalTypeEnum.Auction]: "Asta",
        },
    },
    NonMandatoryApprovers: {
        TypeEnum: {
            [NonMandatoryApprovalTypeEnum.Expense]: "Spesa",
            [NonMandatoryApprovalTypeEnum.Travel]: "Viaggio",
            [NonMandatoryApprovalTypeEnum.Loan]: "Prestito",
            [NonMandatoryApprovalTypeEnum.Leave]: "Permesso",
            [NonMandatoryApprovalTypeEnum.Asset]: "Asset",
            [NonMandatoryApprovalTypeEnum.Salary]: "Stipendio",
            [NonMandatoryApprovalTypeEnum.Payroll]: "Libro Paga",
            [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "Finanziatori di Spese",
            [NonMandatoryApprovalTypeEnum.TravelAgent]: "Agente di Viaggio",
            [NonMandatoryApprovalTypeEnum.Reward]: "Ricompensa",
            [NonMandatoryApprovalTypeEnum.ResignationHr]: "Dimissioni HR",
            [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "Dimissioni Amministrazione",
            [NonMandatoryApprovalTypeEnum.ResignationIt]: "Dimissioni IT",
            [NonMandatoryApprovalTypeEnum.ResignationFinance]: "Dimissioni Finanza",
            [NonMandatoryApprovalTypeEnum.ResignationExit]: "Uscita",
            [NonMandatoryApprovalTypeEnum.Requisition]: "Richiesta",
            [NonMandatoryApprovalTypeEnum.Warning]: "Avviso",
            [NonMandatoryApprovalTypeEnum.Complain]: "Lamentela",
            [NonMandatoryApprovalTypeEnum.Bonus]: "Bonus",
            [NonMandatoryApprovalTypeEnum.Promotion]: "Promozione",
            [NonMandatoryApprovalTypeEnum.RequestForItem]: "Richiesta Articolo",
            [NonMandatoryApprovalTypeEnum.CustomApproval]: "Approvazione Personalizzata",
            [NonMandatoryApprovalTypeEnum.Form]: "Modulo",
            [NonMandatoryApprovalTypeEnum.Document]: "Documento",
            [NonMandatoryApprovalTypeEnum.Career]: "Carriera",
            [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "Approvazione Finale Richiesta",
            [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "Richiesta Articolo Asset Controller",
            [NonMandatoryApprovalTypeEnum.Quotation]: "Preventivo",
            [NonMandatoryApprovalTypeEnum.QuotationClient]: "Cliente del Preventivo",
            [NonMandatoryApprovalTypeEnum.Appraisal]: "Valutazione",
            [NonMandatoryApprovalTypeEnum.Attendance]: "Presenza",
            [NonMandatoryApprovalTypeEnum.Auction]: "Asta",
        }
    },
    activityLog: {
        DeviceType: "Tipo di dispositivo",
        DeviceToken: "Token dispositivo",
        OSVersion: "Versione OS",
        Device: "Dispositivo",
        IP: "IP",
        Comment: "Commento",
        DeviceName: "Nome dispositivo",
        LoginDate: "Data di accesso",
        LoginTime: "Ora di accesso"
    },
    ErrorMessage: {
        someThingWentWrong: "Qualcosa è andato storto",
    },
    setting: {
        BasicInformation: "Informazioni di base",
        ChangePassword: "Cambia password",
        Notifications: "Notifiche",
        Mobile: "Numero di cellulare",
        About: "Informazioni",
        ExistingPassword: "Password attuale",
        EnterExistingPassword: "Inserisci la password attuale",
        NewPassword: "Nuova password",
        EnterNewPassword: "Inserisci la nuova password",
        ConfirmPassword: "Conferma password",
        EnterConfirmPassword: "Conferma la nuova password",
        Signature: "Firma del documento",
        NoSignature: "Si prega di creare una firma",
        ErrorSignature: "Errore nella creazione della firma",
        NoSignatureSave: "Nessuna firma da salvare",
        SignatureSaved: "Firma salvata",
        SaveSignature: "Salva firma",
        ClearSignature: "Cancella firma",
        SignatureCreated: "Firma creata",
        SignatureActive: "Attiva",
        FailedSignature: "Impossibile recuperare le firme",

        BlockNotificationFeatureTypeEnum: {
            [NotificationBlockFeatureTypeEnum.Reward]: "Ricompensa",
            [NotificationBlockFeatureTypeEnum.Feed]: "Feed",
            [NotificationBlockFeatureTypeEnum.Document]: "Documento",
            [NotificationBlockFeatureTypeEnum.Project]: "Progetto",
            [NotificationBlockFeatureTypeEnum.Task]: "Compito",
            [NotificationBlockFeatureTypeEnum.WorkBoard]: "Bacheca di lavoro",
            [NotificationBlockFeatureTypeEnum.Schedule]: "Calendario",
            [NotificationBlockFeatureTypeEnum.Group]: "Gruppo",
            [NotificationBlockFeatureTypeEnum.Messenger]: "Messaggeria",
            [NotificationBlockFeatureTypeEnum.Expense]: "Spesa",
            [NotificationBlockFeatureTypeEnum.Auction]: "Asta",
            [NotificationBlockFeatureTypeEnum.Leave]: "Permesso",
            [NotificationBlockFeatureTypeEnum.Travel]: "Viaggio",
            [NotificationBlockFeatureTypeEnum.Warning]: "Avviso",
            [NotificationBlockFeatureTypeEnum.Complain]: "Reclamo",
            [NotificationBlockFeatureTypeEnum.Bonus]: "Bonus",
            [NotificationBlockFeatureTypeEnum.Promotion]: "Promozione",
            [NotificationBlockFeatureTypeEnum.Loan]: "Prestito",
            [NotificationBlockFeatureTypeEnum.CustomApproval]: "Approvazione personalizzata",
            [NotificationBlockFeatureTypeEnum.Form]: "Modulo",
            [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "Stipendio dipendente",
            [NotificationBlockFeatureTypeEnum.Payroll]: "Libro paga",
            [NotificationBlockFeatureTypeEnum.Career]: "Carriera",
            [NotificationBlockFeatureTypeEnum.Requisition]: "Richiesta",
            [NotificationBlockFeatureTypeEnum.Resignation]: "Dimissioni",
            [NotificationBlockFeatureTypeEnum.Quotation]: "Preventivo",
            [NotificationBlockFeatureTypeEnum.Appraisal]: "Valutazione",
            [NotificationBlockFeatureTypeEnum.LeadManager]: "Responsabile del lead",
            [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "Bacheca di discussione",
            [NotificationBlockFeatureTypeEnum.Pages]: "Pagine",
        }
    },
    emailConfiguration: {
        emailConfiHeader: "Configurazione email utente",
        emailConfi: "Nome utente",
        password: "Password",
        enterEmailConfi: "Inserisci il nome utente",
        incomingPort: "Porta in ingresso",
        enterIncomingPort: "Inserisci la porta in ingresso",
        incomingServerAddress: "Indirizzo del server in ingresso",
        enterIncomingServerAddress: "Inserisci l'indirizzo del server in ingresso",
        outgoingPort: "Porta in uscita",
        enterOutgoingPort: "Inserisci la porta in uscita",
        outgoingServerAddress: "Indirizzo del server in uscita",
        enterOutgoingServerAddress: "Inserisci l'indirizzo del server in uscita",
        provider: "Provider",
        enterProvider: "Inserisci il provider",
        saveEmailConfi: "Salva configurazione email",
        addEmailConfi: "Aggiungi configurazione email",
        name: "Nome",
        clear: "Cancella",
    },
    assetsAllocation: {
        assetsAllocation: "Assegnazione degli Asset",
        assests: "Asset",
        createAssetAllocation: "Crea Assegnazione di Asset",
        handover: "Consegna",
        forMaintenance: "Per Manutenzione",
        noResultFound: "Nessun risultato trovato...",
        addAssetsAllocation: "Aggiungi Assegnazione degli Asset",
        noDataMessage: "– Crea ora l'Assegnazione degli Asset!",
        allocationForMe: "Assegnazione per Me",
        allocationApprovals: "Approvazioni di Assegnazione",
        deAllocation: "Cambia Stato di Assegnazione",
        liability: "Passività",
        capital: "Capitale",
        revenue: "Ricavi",
        expense: "Spesa",
        costOfGoodsale: "Costo della merce venduta",
        assetsCategory: "Categoria degli Asset",
        parentAccount: "Conto Genitore",
        selectParent: "Seleziona Genitore",
        assetsList: "Lista degli Asset",
        createAssests: 'Crea Asset',
        sno: 'S.No',
        inventoryName: "Nome dell'Inventario",
        inventoryValue: "Valore dell'Inventario",
        SelectAssetsController: "Seleziona Controller degli Asset",
    },
    requestListItems: {
        noDataMessage: "– Crea ora la Richiesta!",
        requestForItems: "Richiesta di Articoli",
        RequestForItemsApprovals: "Approvazioni per Richiesta di Articoli",
        AssetController: "Controller degli Asset",
        RequestItem: "Richiedi Articolo",
    },
    profile: {
        firstMenu: '  Primo elemento di menu',
        secondMenu: ' Secondo elemento di menu (disabilitato)',
        thirdMenu: ' Terzo elemento di menu (disabilitato)',
        dangerItem: 'Elemento pericoloso',
        NoPosition: "Nessuna Posizione",
        NoInstitute: "Nessun Istituto",
        NotFound: "Non trovato",
        University: "Università",
        School: "Scuola",
        Graduated: "Laureato",
        CurrentlyWorkHere: "Attualmente lavoro qui",
        Statistics: "Statistiche",
        StorageStatistics: "Statistiche di archiviazione",
        TotalStorageUsed: "Spazio di archiviazione totale utilizzato",
    },
    /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***/
    status: {
        InProcess: "In corso",
        Approved: "Approvato",
        Declined: "Declinato",
        Resend: "Reinviato",
        Inactive: "Inattivo",
        NotRequired: "Non richiesto",
        Cancelled: "Annullato",
        ApprovalRequired: "Approvazione richiesta",
        Hold: "In attesa",
        WaitingForOtherApprover: "In attesa di altri approvatori",
        Draft: "Bozza",
        Remark: "Nota",
        Removed: "Rimosso"
    },
    approvalDictionaryLabel: {
        Approval: "Approvazione",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Esecutore",
        [ApprovalsModuleEnum.ExpenseFinance]: "Finanza",
        [ApprovalsModuleEnum.ResignationHrApproval]: "HR",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finanza",
        [ApprovalsModuleEnum.ResignationItApproval]: "IT",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Admin",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Altre approvazioni",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Intervista di uscita",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Responsabile",
        [ApprovalsModuleEnum.TravelApproval]: "Approvatori",
        [ApprovalsModuleEnum.TravelAgent]: "Agente",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Approvatori finali",
        [ApprovalsModuleEnum.LoanApproval]: "Approvatori",
        [ApprovalsModuleEnum.AuctionApproval]: "Approvatore"

    },
    approvalDictionaryPlaceHolder: {
        Approval: "Approvazione",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Seleziona l'esecutore della spesa",
        [ApprovalsModuleEnum.ExpenseFinance]: "Seleziona gli approvatori della finanza",
        [ApprovalsModuleEnum.ExpenseApproval]: "Seleziona gli approvatori della spesa",
        [ApprovalsModuleEnum.ResignationHrApproval]: "Seleziona HR",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Seleziona Finanza",
        [ApprovalsModuleEnum.ResignationItApproval]: "Seleziona IT",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Seleziona Admin",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Seleziona altre approvazioni",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Seleziona l'intervista di uscita",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Seleziona Responsabile",
        [ApprovalsModuleEnum.TravelApproval]: "Seleziona gli approvatori per il viaggio",
        [ApprovalsModuleEnum.TravelAgent]: "Seleziona l'agente per gli arrangiamenti di viaggio",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Seleziona gli approvatori finali",
        [ApprovalsModuleEnum.LoanApproval]: "Seleziona gli approvatori",
        [ApprovalsModuleEnum.AuctionApproval]: "Seleziona gli approvatori"

    },


    /*** Define language write side ***/
    Direction: "ltr",
}
export default italian;