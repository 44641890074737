import { createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import {
  addEmployeeSalaryAction,
  getCurrentSalaryOfEmployeeAction,
  getEmployeeSalaryAction,
  getGradeAllowanceByIdAction,
} from "./action";
import { getAllAllowanceGreadeData } from "../../../../gradeAllowance/store/action";
import { getEmployeeByIdAction } from "../../../../employee/store/actions";
const initialState = {
  employeeSalary: [],
  currentEmployeeSalary: [],
  loader: false,
  success: false,
  AllGradeAllowance: [],
  handleDesignation: {},
  drawer: true,
  formTableData: [],
  designationData: [],
  gradeAllowancesByUser: [],
};

const employeeSalarySlice = createSlice({
  name: "employeeSalary",
  initialState,
  reducers: {
    handleDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    openDarwer: (state, action) => {
      state.drawer = action.payload;
    },
    allowanceTableData: (state, action) => {
      state.formTableData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeSalaryAction.fulfilled, (state, { payload }) => {
        state.currentEmployeeSalary = payload;
        state.loader = false;
        state.success = true;
      })
      .addCase(getAllAllowanceGreadeData.fulfilled, (state, { payload }) => {
        state.AllGradeAllowance = payload.data;
        state.loader = false;
        state.success = true;
      })

      .addCase(getGradeAllowanceByIdAction.fulfilled, (state, { payload }) => {
        state.gradeAllowancesByUser = payload.data;
      })

      .addCase(getEmployeeByIdAction.fulfilled, (state, { payload }) => {
        state.handleDesignation = payload.data;
      })
      .addCase(addEmployeeSalaryAction.fulfilled, (state, { payload }) => {
        state.currentEmployeeSalary = [
          payload,
          ...state.currentEmployeeSalary,
        ];

        state.loader = false;
        state.success = true;
      })
      .addMatcher(
        isPending(
          ...[addEmployeeSalaryAction, getCurrentSalaryOfEmployeeAction]
        ),
        (state) => {
          state.loader = true;
          state.success = true;
        }
      )
      .addMatcher(
        isRejected(
          ...[addEmployeeSalaryAction, getCurrentSalaryOfEmployeeAction]
        ),
        (state) => {
          state.loader = false;
          state.success = false;
        }
      );
  },
});
export const {
  handleDrawer,
  openDarwer,
  allowanceTableData,
} = employeeSalarySlice.actions;
export default employeeSalarySlice.reducer;
