import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { AuctionOfferStatusEnum, AuctionStatusEnum, auctionFilterTypeEnum } from "../../../main/features/auction/utils/enums";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { PostReferenceType, PostType, ReactionType } from "../../../main/features/feed/utils/constants";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums";

const turkish = {
  /*** PLEASE READ FIRST ALL COMMENTS BEFORE ADD LABELS ***/

  /*** add all shared labels like send, ok, cancel, etc ***/
  sharedLabels: {

    SelectLanguage: "Select Language",
    English: "English ",
    Turkish: "Turkish",
    Hindi: "Hindi",
    Urdu: "اردو",
    Arabic: "عربي",
    czech: "Czech",
    russian: "Russian",
    french: "French",
    spanish: "Spanish",
    italian: "Italian",
    theme: "Tema",
    awardTo: "Ödül",
    inprogress: "Devam Ediyor",
    filter: "Filtrele",
    approvals: "Onaylar",
    approvers: "Onaylayanlar",
    members: "Üyeler",
    Approvers: "Onaylayanlar",
    resignations: "İstifalar",
    Description: "Açıklama",
    Descriptionisrequired: "Açıklama gereklidir!",
    WriteDescriptionhere: "Buraya açıklama yaz...",
    enterDescription: "Açıklama Girin",
    pleseEnterDescription: "Lütfen Açıklama Girin",
    pleaseEnterGrade: "Lütfen Derece Girin",
    manager: "Yönetici",
    requiredMessageManager: "Lütfen Yönetici Seçin",
    SelectManager: "Yönetici Seç",
    create: "Oluştur",
    humanresource: "İK",
    finance: "Finans",
    it: "IT",
    admin: "Yönetici",
    selectMember: "Üye Seç",
    othersapprovals: "Diğer Onaylar",
    exitinterview: "Ayrılış Görüşmesi",
    selectAperson: "Bir kişi seçin",
    SelectHumanResource: "İK Seç",
    selectIt: "IT Seç",
    selectFinance: "Finans Seç",
    selectAdmin: "Yönetici Seç",
    selectOthersApprovals: "Diğer Onayları Seç",
    inProcess: "İşlemde",
    reason: "Sebep",
    refrence: "Referans",
    Rewards: "Ödüller",
    name: "İsim",
    add: "Ekle",
    action: "Aksiyon",
    userType: "Kullanıcı Türü",
    SelectUserType: "Kullanıcı Türü Seçin",
    requiredMessageUserType: "Lütfen Kullanıcı Türü Seçin",
    RewardTo: "Ödül Alan",
    Approved: "Onaylandı",
    Declined: "Reddedildi",
    Resend: "Yeniden Gönder",
    NotRequired: "Gerekli Değil",
    Cancelled: "İptal Edildi",
    InActive: "Pasif",
    ListView: "Liste Görünümü",
    TableView: "Tablo Görünümü",
    MyReward: "Benim Ödüllerim",
    ForApprovals: "Onaylar İçin",
    ForApproval: "Onay İçin",
    RewardToMe: "Bana Ödül",
    Search: "Ara",
    Disable: "Devre Dışı Bırak",
    Attachments: "Ekler",
    Update: "Güncelle",
    update: "güncelle",
    AddEmployee: "Çalışan Ekle",
    complain: "Şikayet",
    Submit: "Gönder",
    Delete: "Sil",
    Edit: "Düzenle",
    NotDesignated: "Belirtilmemiş",
    Profile: "Profil",
    Settings: "Ayarlar",
    Logout: "Çıkış Yap",
    Table: "Tablo",
    List: "Liste",
    Grid: "Kare",
    GridView: "Kare Görünümü",
    startyear: "Başlangıç Yılı",
    endyear: "Bitiş Yılı",
    startMonth: "Başlangıç Ayı",
    endMonth: "Bitiş Ayı",
    EnterStartDate: "Başlangıç Tarihi Girin",
    SelectStartDate: "Başlangıç Tarihi Seçin",
    startDate: "Başlangıç Tarihi",
    endDate: "Bitiş Tarihi",
    businessLogo: "İş Logosu",
    title: "Başlık",
    enterTitle: "Başlık Girin",
    percentage: "Yüzde",
    PleaseEnterPercentage: "Lütfen Yüzde Girin",
    previousCharge: "Önceki Ücret",
    min: "Min",
    max: "Maks",
    country: "Ülke",
    requiredCountry: "Ülke gereklidir",
    SelectCountry: "Ülke Seçin",
    latitude: "Enlem",
    longitude: "Boylam",
    address: "Adres",
    EnterAddress: "Adres Girin",
    requiredMessageAddress: "Lütfen adres girin",
    branch: "Şube",
    question: "Sorular",
    allotLeaves: "Tatil Ata",
    SubjectRequired: "Konu Gerekli",
    WriteSubject: "Konu Yaz",
    category: "Kategori",
    amount: "Miktar",
    documents: "Belgeler",
    enterName: "İsim Girin",
    nameRequired: "İsim Gerekli",
    accessTo: "Erişim",
    selectAccessTo: "Erişimi Seçin",
    collaborators: "İşbirlikçiler",
    selectCollaborators: "İşbirlikçileri Seçin",
    readers: "Okuyucular",
    selectReaders: "Okuyucuları Seçin",
    createBoard: "Pano Oluştur",
    createPad: "Pad Oluştur",
    createGrid: "Grid Oluştur",
    createSlide: "Slayt Oluştur",
    createMindmap: "Zihin Haritası Oluştur",
    drive: "Sürücü",
    myDrive: "Benim Sürücüm",
    allDocuments: "Tüm Belgeler",
    subject: "Konu",
    Subjectisrequired: "Konu Gerekli",
    pleaseEnterSubject: "Lütfen Konu Girin",
    EnterSubject: "Konu Girin",
    enterAmount: "Miktar Girin",
    pleaseEnterAmount: "Lütfen Miktar Girin",
    upload: "Yükle",
    value: "Değer",
    addCategory: "Kategori Ekle",
    saveCategory: "Kategoriyi Kaydet",
    clear: "Temizle",
    loans: "Krediler",
    disburse: "Ödeme",
    referenceNo: "Referans No",
    status: "Durum",
    createDate: "Oluşturma Tarihi",
    creator: "Oluşturan",
    netSalary: "Net Maaş",
    type: "Tip",
    PleaseSelectType: "Lütfen Tip Seçin",
    CreatedByMe: "Benim Tarafımdan Oluşturulan",
    Members: "Üyeler",
    pleaseEnterCategory: "Lütfen Kategori Girin",
    Memberisrequired: "Üye Gerekli",
    EnterCategoryName: "Kategori Adı Girin",
    Clear: "Temizle",
    ReferenceNumber: "Referans Numarası",
    Grade: "Derece",
    Status: "Durum",
    CreateDate: "Oluşturma Tarihi",
    Creator: "Oluşturan",
    SNo: "S.No.",
    Employee: "Çalışan",
    SelectApprovers: "Onaylayıcıları Seç",
    Approverisrequired: "Onaylayıcı Gerekli",
    Yes: "Evet",
    No: "Hayır",
    totalAmount: "Toplam Miktar",
    Total: "Toplam",
    Date: "Tarih",
    Pleaseselectdate: "Lütfen tarih seçin",
    SelectDate: "Tarih Seç",
    requiredMessageDate: "Lütfen Tarih Seçin",
    Priority: "Öncelik",
    Pleaseselectpriority: "Lütfen öncelik seçin",
    Pleaseselecttype: "Lütfen tip seçin",
    SelectProject: "Proje Seç",
    SelectGroup: "Grup Seç",
    Project: "Proje",
    Group: "Grup",
    Progress: "İlerleme",
    SuretoRate: "Değerlendirmek İstediğinizden Emin Misiniz?",
    Invalidfiletype: "Geçersiz dosya türü",
    Pleaseinputyoursubject: "Lütfen konunuzu girin",
    Pleaseinputyourdescription: "Lütfen açıklamanızı girin",
    Return: "Geri Dön",
    EnterReason: "Neden Girin",
    TravelReason: "Seyahat Nedeni",
    NoDesignation: "Belirtilmemiş",
    Hi: "Merhaba",
    Quotation: "Alıntı",
    Amountisrequired: "Miktar Gerekli",
    WriteDescriptionHere: "Açıklamayı Buraya Yazın...",
    Types: "Türler",
    Header: "Başlık",
    MyTeam: "Takımım",
    Email: "E-posta",
    EnterEmail: "E-posta Girin",
    EmailIsRequired: "E-posta Gerekli",
    PhoneNo: "Telefon Numarası",
    Designation: "Ünvan",
    ReferenceType: "Referans Türü",
    referenceId: "referansId",
    Save: "Kaydet",
    save: "kaydet",
    Color: "Renk",
    Yesterday: "Dün",
    Overdue: "Vadesi Geçmiş",
    Today: "Bugün",
    Complete: "Tamamlandı",
    DueDate: "Son Tarih",
    SelectDueDate: "Son Tarihi Seç",
    Cover: "Kapak",
    Cancel: "İptal Et",
    cancel: "iptal",
    and: "ve",
    other: "diğer",
    Others: "Diğerleri",
    Option: "Seçenek",
    Public: "Genel",
    Private: "Özel",
    click: "tıkla",
    With: "İle",
    with: "ile",
    Comments: "Yorumlar",
    Commentsdot: "Yorumlar...",
    Comment: "Yorum",
    Seeless: "Daha Az Gör",
    Seemore: "Daha Fazla Gör",
    Share: "Paylaş",
    ShareNow: "Şimdi Paylaş",
    SelectEmployee: "Çalışan Seç",
    SelectCategory: "Kategori Seç",
    PleaseSelectCategory: "Lütfen Kategori Seçin",
    privacy: "Gizlilik",
    tags: "Etiketler",
    updateDate: "Güncelleme Tarihi",
    otherApprovals: "Diğer Onaylar",
    resignationDate: "İstifa Tarihi",
    purpose: "Amaç",
    warnings: "Uyarılar",
    Courses: "Kurslar",
    selectType: "Tür Seç",
    daysCount: "Gün Sayısı",
    reportingTo: "Rapor Verilen",
    selectReason: "Neden Seç",
    pleaseSelectReason: "Lütfen Neden Seçin",
    days: "Gün",
    day: 'gün',
    Dates: "Tarihler",
    selectMembers: "Üyeleri Seç",
    selectGrade: "Derece Seç",
    newGrade: "Yeni Derece",
    currentGrade: "Mevcut Derece",
    PleaseSelectMember: "Lütfen Üye Seçin",
    enterCategory: "Kategori Girin",
    deadline: "Son Tarih",
    Department: "Departman",
    designationmessage: "Lütfen ünvan seçin",
    selectDesignation: "Ünvan Seç",
    jobdescription: "İş Tanımı",
    salaryRange: "Maaş Aralığı",
    interviewers: "Görüşmeciler",
    hiringBuddy: "İşe Alım Arkadaşı",
    enterCity: "Lütfen Şehir Girin",
    City: "Şehir",
    selectCity: "Şehir Seç",
    requiredCity: "Şehir gereklidir",
    skills: "Yetenekler",
    experience: "Deneyim (Yıl)",
    Experience: "Deneyim",
    jobType: "İş Türü",
    education: "Eğitim",
    interview: "Görüşme",
    Rating: "Değerlendirme",
    poweredbyWorkw: "Workwise Ltd tarafından desteklenmektedir - Telif Hakkı 2016-",
    copyLink: "Bağlantıyı Kopyala",
    EffectiveDate: "Etkili Tarih",
    experienceRequired: "Deneyim Gerekli",
    user: "Kullanıcılar",
    postinterview: "Görüşme Sonrası",
    Copied: "Kopyalandı",
    unreadNotifyItem: "Okunmamış Bildirim",
    createBy: "Tarafından Oluşturulan",
    Create: "Oluştur",
    Open: "Açık",
    Closed: "Kapalı",
    Nodescription: "Açıklama Yok",
    writeDescription: "Açıklamanızı buraya yazın...",
    Employees: "Çalışanlar",
    BasicInfo: "Temel Bilgiler",
    FatherName: "Baba Adı",
    EnterFatherName: "Baba Adını Girin",
    PersonalEmail: "Kişisel E-posta",
    EnterPersonalEmail: "Kişisel E-posta Girin",
    personalEmailIsRequired: "Kişisel E-posta zorunludur",
    PhoneNumber: "Telefon Numarası",
    EnterPhoneNumber: "Telefon Numarası Girin",
    NationalIdentity: "Ulusal Kimlik",
    EnterNationalIdentityNumber: "Ulusal Kimlik Numarasını Girin",
    Grades: "Notlar",
    SelectGrade: "Not Seçin",
    requiredMessageGrade: "Lütfen Bir Not Seçin",
    EnterProbationPeriod: "Deneme Süresini Girin",
    NoticePeriod: "İhbar Süresi (Gün)",
    EnterNoticePeriod: "İhbar Süresini Girin",
    requiredMessageNoticePeriod: "Lütfen İhbar Süresini Girin",
    requiredMessageNoticePeriodRestriction: "İhbar süresi 1 ile 60 arasında olmalıdır",
    DateOfBirth: "Doğum Tarihi",
    Gender: "Cinsiyet",
    requiredMessageGender: "Lütfen Cinsiyet Seçin",
    SelectGender: "Cinsiyet Seçin",
    MartialStatus: "Medeni Durum",
    SelectMartialStatus: "Medeni Durum Seçin",
    requiredDepartment: "Departman Zorunludur",
    SelectDepartment: "Departman Seçin",
    Subsidiary: "İştirak",
    SelectSubsidiary: "İştirak Seçin",
    SubsidiaryOffice: "İştirak Ofisi",
    SelectSubsidiaryOffice: "İştirak Ofisi Seçin",
    PayrollGroup: "Maaş Grubu",
    SelectPayrollGroup: "Maaş Grubu Seçin",
    DefaultApproval: "Varsayılan Onay",
    SelectDefaultApproval: "Varsayılan Onay Seçin",
    SelectAll: "Hepsini Seç",
    NonMandatoryApprovals: "Zorunlu Olmayan Onaylar",
    SelectNonMandatoryApprovals: "Zorunlu Olmayan Onayları Seçin",
    FirstName: "Ad",
    EnterFirstName: "Adınızı Girin",
    requiredMessageName: "Lütfen Ad Girin",
    requiredMessageFName: "Lütfen Ad Girin",
    requiredMessageLName: "Lütfen Soyad Girin",
    LastName: "Soyad",
    EnterLastName: "Soyadınızı Girin",
    DateOfJoining: "İşe Giriş Tarihi",
    requiredDateOfBirth: "Doğum Tarihi Zorunlu",
    requiredMessageJoiningDate: "Lütfen İşe Giriş Tarihini Seçin",
    JobShift: "İş Vardiyası",
    requiredMessageJobShift: "Lütfen İş Vardiyasını Seçin",
    SelectJobShift: "İş Vardiyasını Seçin",
    AccessRole: "Erişim Rolü",
    requiredMessageAccessRole: "Lütfen En Az Bir Erişim Rolü Seçin",
    SelectAccessRole: "Erişim Rolü Seçin",
    StartEndDate: "Başlangıç/Bitiş Tarihi",
    ErrorInValidation: "Doğrulama Hatası",
    EducationInfo: "Eğitim Bilgisi",
    NonMandatory: "Zorunlu Olmayan",
    Present: "Mevcut",
    FailedToFetch: "Alınamadı",
    Position: "Pozisyon",
    EnterPosition: "Pozisyonu Girin",
    requiredMessagePosition: "Lütfen Pozisyon Girin",
    Organization: "Organizasyon",
    EnterOrganization: "Organizasyonu Girin",
    requiredMessageOrganziation: "Lütfen Organizasyon Girin",
    Number: "Numara",
    EnterNumber: "Numarayı Girin",
    requiredMessageNumber: "Lütfen Numara Girin",
    Relation: "İlişki",
    SelectRelation: "İlişki Seçin",
    requiredMessageRelation: "İlişki Zorunlu",
    AccountName: "Hesap Adı",
    BranchCode: "Şube Kodu",
    EnterBranchCode: "Şube Kodunu Girin",
    requiredMessageBranchCode: "Şube Kodu Zorunlu",
    BankName: "Banka Adı",
    requiredMessageBankName: "Banka Adı Zorunlu",
    EnterBankName: "Banka Adını Girin",
    AccountNumber: "Hesap Numarası",
    EnterAccountNumber: "Hesap Numarasını Girin",
    requiredAccountNumber: "Hesap Numarası Zorunlu",
    IBAN: "IBAN",
    EnterIBAN: "IBAN Numarasını Girin",
    requiredIBAN: "IBAN Numarası Zorunlu",
    SortCode: "Sıralama Kodu",
    requiredSortCode: "Sıralama Kodu Zorunlu",
    EnterSortCode: "Sıralama Kodunu Girin",
    BankInfo: "Banka Bilgisi",
    AccountTitle: "Hesap Başlığı",
    EnterAccountTitle: "Hesap Başlığını Girin",
    requiredMessageAccount: "Hesap Başlığı Zorunlu",
    validAccountTitle: "Lütfen geçerli bir hesap başlığı girin",
    AttachmentType: "Ek Tipi",
    requiredAttchmentType: "Ek Tipi Zorunlu",
    Attachment: "Ek",
    requiredAttachment: "Ek Zorunlu",
    AddAttachment: "Ek Ekle",
    requiredMessageAssignMember: "Lütfen Atanacak Üyeyi Seçin",
    Remove: "Kaldır",
    ContactNo: "İletişim No.",
    EnterContactNo: "İletişim No. Girin",
    requiredContactNo: "İletişim No. Zorunlu",
    Contact: "İletişim",
    pleasEnterDescription: "Lütfen açıklama girin.",
    Information: "Bilgi",
    Features: "Özellikler",
    AddMembers: "Üyeleri Ekle",
    Summary: "Özet",
    AcessRole: "Erişim Rolü",
    defaultApprovers: "Varsayılan Onaylayıcılar",
    NonMandatoryApprovers: "Zorunlu Olmayan Onaylayıcılar",
    Pleasefillallrequiredfields: "Lütfen tüm zorunlu alanları doldurun",
    Suretodelete: "Silmek istediğinizden emin misiniz?",
    Code: "Kod",
    createdDate: "Oluşturulma Tarihi",
    Searchmembers: "Üyeleri Ara",
    AddDate: "Tarih Ekle",
    duesoon: "Yakında Ödenecek",
    Payroll: "Maaş Bordrosu",
    ReferenceNo: "Referans No",
    Associate: "Ortak",
    InternalMembers: "İç Üyeler",
    ExternalMembers: "Dış Üyeler",
    TotalExpense: "Toplam Harcama",
    TotalExpenseAmount: "Toplam Harcama Tutarı",
    TotalPost: "Toplam Gönderi",
    TotalMeeting: "Toplam Toplantı",
    Expense: "Harcama",
    TotalBudget: "Toplam Bütçe",
    Balance: "Bakiye",
    successfully: "Başarıyla",
    enterValidEmail: 'Lütfen geçerli bir e-posta adresi girin',
    ViewSummary: "Özeti Görüntüle",
    AddAdmin: "Yönetici Ekle",
    AddExternals: "Dış Üye Ekle",
    DefaultApprovers: "Varsayılan Onaylayıcılar",
    Billings: "Faturalar",
    spent: "Harcanan",
    SelectUser: "Kullanıcı Seç",
    Image: "Resim",
    Contacts: "Kişiler",
    noContacts: "Kişi Yok",
    AddContact: "Kişi Ekle",
    Roles: "Roller",
    Tag: "Etiket",
    AccessRoles: "Erişim Rolleri",
    member: "Üye",
    uploadCover: "Kapak Yükle",
    Pleaseselect: "Lütfen Seçin",
    composeEmail: "E-posta Oluştur",
    send: "Gönder",
    ContactDetail: "İletişim Detayı",
    Detail: "Detay",
    UploadImage: "Resim Yükle",
    WriteName: "Adınızı Yazın",
    business: "İş",
    website: "Web Sitesi",
    Unknown: "Bilinmeyen",
    Interested: "İlgili",
    NotInterested: "İlgisiz",
    meetings: "Toplantılar",
    noMeetings: "Toplantı Yok",
    Communications: "İletişimler",
    Details: "Detaylar",
    LastUpdate: "Son Güncelleme",
    nameRequiredMessage: "Ad Zorunlu!",
    addressRequiredMessage: "Adres Zorunlu!",
    Intrested: "İlgili",
    StartDateTime: "Başlangıç Tarihi ve Saati",
    DateTimeisrequired: "Tarih ve Saat Zorunlu",
    Selectdatetime: "Tarih ve Saati Seçin",
    Board: "Kurul",
    Notes: "Notlar",
    Miletap: "Miletap",
    EMail: "E-posta",
    Attendance: "Katılım",
    Absent: "Yok",
    NoData: "Veri Yok",
    Prority: "Öncelik",
    Heigh: "Yüksek",
    Medium: "Orta",
    Low: "Düşük",
    error: "hata",
    Select: "Seç",
    Interview: "Görüşme",
    Meeting: "Toplantı",
    Appointment: "Randevu",
    Pleaseinputyouremail: "Lütfen e-postanızı girin",
    EnterEmailAddress: "E-posta Adresinizi Girin",
    AddExternalMember: "Dış Üye Ekle",
    Pleaseenterexternalmembers: "Lütfen dış üyeleri girin",
    externals: "dış üyeler",
    Decline: "Reddet",
    NotAvailable: "Uygun Değil",
    AddApprovals: "Onay Ekle",
    Everyone: "Herkes",
    SelectDownloaders: "İndirenleri Seçin",
    SelectCollabrators: "İşbirlikçileri Seçin",
    pleaseSelectAssignMemeber: "Lütfen Atanacak Üyeyi Seçin!",
    Availed: "Kullanılmış",
    Allotted: "Tahsis Edilmiş",
    Degree: "Derece",
    Time: "Zaman",
    Mood: "Mod",
    CheckOut: "Çıkış Yap",
    CheckIn: "Giriş Yap",
    Late: "Geç",
    State: "Durum",
    PleaseSelectYesNo: "Lütfen Evet/Hayır Seçin",
    PleaseselectGrade: "Lütfen Derece Seçin",
    PleaseenterAmount: "Lütfen Tutar Girin",
    Salary: "Maaş",
    Task: "Görev",
    Nameisrequired: "Ad zorunludur",
    PhoneNumberisrequired: "Telefon Numarası zorunludur",
    Dateisrequired: "Tarih zorunludur",
    Month: "Ay",
    Year: "Yıl",
    FinalApprovers: "Son Onay Verenler",
    link: "Bağlantı",
    forFinalApproval: "Son Onay İçin",
    validateBonus: "Bonus işlenemiyor: Çalışan için maaş bilgisi mevcut değil",
    validateLoan: "Kredi işlenemiyor: Çalışan için maaş bilgisi mevcut değil",
    User: "Kullanıcı",
    Action: "Eylem",
    Created: "Oluşturuldu",
    selectyourcountry: "Lütfen ülkenizi seçin",
    PleaseEnterPhoneNumber: "Lütfen telefon numaranızı girin",
    Pleaseselectyourcity: "Lütfen şehrinizi seçin",
    SelectCurrency: "Para Birimini Seç",
    Pleaseselectcurrency: "Lütfen para birimini seçin",
    totalGroups: "Toplam Gruplar",
    totalProjects: "Toplam Projeler",
    totalMemory: "Toplam Bellek",
    memory: "Bellek",
    EnterMemory: "Bellek Girin",
    availedMemory: "Kullanılabilir Bellek (GB)",
    used: "Kullanılan",
    usedMemory: "Kullanılan Bellek (GB)",
    From: "Başlangıç",
    To: "Bitiş",
    WelcomeSendEmail: "Hoş Geldiniz E-postası Gönder",
    SendEmail: "E-posta Gönder",
    writeYourRemarksHere: "Buraya Yorumlarınızı Yazın...",
    NoRemarkFound: "Hiçbir Yorum Bulunamadı",
    ToastEnable: "Toast Etkinleştir",
    EmailEnable: "E-posta Etkinleştir",
    FeatureName: "Özellik Adı",
    LoginMessage: "Lütfen önce kimlik bilgilerini giriniz",
    SelectedDate: "Seçilen Tarih",
    SelectedUser: "Seçilen Kullanıcı",
    Default: "Varsayılan",
    netAmount: "net miktar",
    mylog: "Günlüğüm",
    SelectStatus: "Durum Seç",
    Selectuser: "Kullanıcı Seç",
    PolicyType: "Politika Türü",
    Percent: "Yüzde",
    uploadProfilePicture: "Profil Resmi Yükle",
    inputUsername: "Kullanıcı adı giriniz",
    userName: "kullanıcı adı",
    Dummy: "Boş",
    Lesson: "Ders",
    Duration: "Süre",
    Text: "Metin",
    Enterlink: "Bağlantı giriniz",
    Dashboard: "Kontrol Paneli",
    searchSchedule: "Program Ara...",
    AddOption: "Seçenek Ekle",
    ChangeImage: "Resmi Değiştir",
    DeleteImage: "Resmi Sil",
    Question: "Soru",
    Next: "Sonraki",
    Prev: "Önceki",
    Wrong: "Yanlış",
    Correct: "Doğru",
    YourResult: "Sonucunuz",
    Createdby: "Oluşturan",
    Lastupdated: "Son güncelleme",
    Assignedto: "Atanan",
    Addlesson: "Ders ekle",
    Addtopic: "Konu ekle",
    minutes: "dakika",
    Modules: "Modüller",
    Valid: "Geçerli",
    Expired: "Süresi dolmuş",
    AssignedMembers: "Atanan üyeler",
    Start: "Başlat",
    Previous: "Önceki",
    Read: "Oku",
    DeleteBook: "Kitabı Sil",
    Assign: "Ata",
    Explanation: "Açıklama",
    Hey: "Merhaba",
    requiredMessageEmail: "Lütfen E-posta Adresi Girin",
    BusinessName: "İşletme Adı",
    PleaseEnterBusinessName: "Lütfen İşletme Adı Girin",
    BusinessAddress: "İşletme Adresi",
    PleaseEnterBusinessAddress: "Lütfen İşletme Adresi Girin",
    Offers: "Teklifler",
    PleaseEnterOffer: "Lütfen Teklif Girin",
    CreateOffer: "Teklif Oluştur",
    AppliedDate: "Başvuru Tarihi",
    ShortList: "Kısa Liste",
    Finalised: "Tamamlandı",
    Member: "Üye",
    SelectMember: 'Üye Seçiniz',
    Approver: "Onaylayıcılar",
    CategoryName: "Kategori Adı",
    serialNo: "Seri No",
    Allocated: "Tahsis Edildi",
    Available: "Mevcut",
    lost: "Kayıp",
    damage: "Hasarlı",
    itemType: "Öğe Türü",
    Service: "Hizmet",
    consumable: "Tüketilebilir",
    NonConsumable: "Tüketilmeyen",
    Quantity: "Miktar",
    accountType: "Hesap Türü",
    PleaseEnterQuantity: "Lütfen miktar giriniz",
    QuantityMustBePositive: "Miktar pozitif olmalıdır",
    MembersIsRequired: "Üyeler gereklidir",
    CreateRequest: "Talep Oluştur",
    IBANNumber: "IBAN Numarası",
    About: "Hakkında",
    AboutMe: "Hakkımda",
    TimePeriod: "Zaman Dilimi",
    Company: "Şirket",
    CityTown: "Şehir/Kasaba",
    BankDetails: "Banka Detayları",
    Work: "Çalışma",
    addWorkPlace: "İş Yeri Ekle",
    AddEducation: "Eğitim Ekle",
    basicSalary: "Temel Maaş",
    feed: 'Besleme',
    TakeaNote: "Not Al",
    awards: 'Ödüller',
    Appraisal: "Değerlendirme",
    salary: 'Maaş',
    activityLog: "Aktivite Günlüğü",
    device: 'Cihaz',
    leave: 'İzin',
    Ip: "IP",
    DeviceId: "Cihaz Kimliği",
    AppVersion: "Uygulama Sürümü",
    Selectfeature: "Özelliği seçin",
    EnterYourFeedBackDescription: "Geri bildiriminizin açıklamasını girin",
    HideAll: "Hepsini Gizle",
    ShowAll: "Hepsini Göster",
    PrintBy: "Yazdıran",
    AccCode: "Hesap Kodu",
    SelectAccountType: "Hesap Türünü Seçin",
    Files: "Dosyalar",
    Space: "Boşluk",
    DisburseVoucher: "Dağıtma/Kupon",
    TopUsersbyMostFiles: "En Çok Dosyaya Sahip Kullanıcılar",
    TopUsersbyStorageSize: "Depolama Boyutuna Göre En İyi Kullanıcılar",
    Apply: "Uygula",
    NumberOfTopUsers: "En İyi Kullanıcı Sayısı",
    TopUsers: "En İyi Kullanıcılar",
    SearchUser: "Kullanıcı Ara",
    ByStorageSize: "Depolama Boyutuna Göre",
    ByFileSize: "Dosya Boyutuna Göre",
    companyDashboard: "Şirket Kontrol Paneli",
    signup: "Kayıt Onaylanmadı",
    billing: "Faturalandırma",
    PendingBills: "Bekleyen Faturalar",
    MonthYear: "Ay - Yıl",
    SendBill: "Fatura Gönder",
    BillingUsers: "Fatura Kullanıcıları",
    Item: "Ürün",
    Rate: "Oran",
    PaidAmount: "Ödenen Tutar",
    PaymentDate: "Ödeme Tarihi",
    DarkDays: "Karanlık Günler",
    ProcessingFee: "İşlem Ücreti",
    Currency: "Para Birimi",
    IsEnable: "Etkin mi",
    Actions: "Eylemler",
    TakeNote: "Not Al",
    PleaseSelectUsers: "Lütfen Kullanıcıları Seçin",
    StickyNotes: "Yapışkan Notlar",
    Copy: "Kopyala",
    MarkAllRead: "Hepsini okundu olarak işaretle",
    SeeAll: "Hepsini Gör",
    Refresh: "Yenile",
    close: "Kapat",
    Calling: "Arama",
    LoadMore: "Daha Fazla Yükle",
    Quizez: "Testler",
    content: "İçerik",


  },

  /*** navMenuLabel only used in LeftSideBar ***/
  navMenuLabel: {
    Schedules: "Programları",
    workWiseCompanies: "Workwise Şirketleri",
    promotions: "Promosyonlar",
    customApprovals: "Özel Onaylar",
    feed: "beslemek",
    messenger: "haberci",
    chartOfAccount: "Hesap Planı",
    voucher: "Fiş",
    createVoucher: "Kupon Oluştur",
    voucherList: "Kupon Listesi",
    Quotation: "Alıntı yapmak",
    QuotationClient: "Teklif İstemcisi",
    transaction: "İşlem",
    report: "Rapor",
    mailBox: "Posta Kutusu",
    groups: "gruplar",
    projects: "projeler",
    tasks: "görevler",
    workBoard: "çalışma tahtası",
    leadManager: "rehber Müdür",
    schedules: "programları",
    expense: "masraf",
    travel: "yolculuk",
    docsArchives: "dokümanlarArşivler",
    eLearning: "uzaktan Eğitim",
    inventory: "envanter",
    myTeam: "benim takımım",
    orgChart: "kuruluş şeması",
    payroll: "maaş bordrosu",
    employee: "çalışan",
    administration: "yönetim",
    appraisals: "değerlendirmeler",
    departments: "bölümler",
    leaves: "yapraklar",
    loan: "borç",
    holidays: "Bayram",
    careers: "kariyer",
    companiesSection: "Workwise Companies",
    companies: "şirketler",
    menu: "Menü",
    hr: "saat",
    finance: "finans",
    inventorySection: "envanter bölümü",
    privacy: "mahremiyet",
    TC: "Haber Kaynağı7",
    more: "daha fazla",
    feedBack: "geri bildirim",
    warnings: "uyarılar",
    complains: "şikayet eder",
    bonus: "bonus",
    resignations: "istifalar",
    requisition: "talep",
    rewards: "ödüller",
    businessPolicy: "iş politikası",
    form: "biçim",
    teams: "Benim takımım",
    LD: "L&D",
    jobBoard: "İş Panosu",
    salary: "Aylık maaş",
    ledgerReport: "Defter Raporları",
    assets: "Varlık Tahsisi",
    assetsList: "Varlık Listesi",
    createAssets: "Varlık Oluştur",
    requestListItems: "Ürün Talebi",
    marketPlace: "Pazar Yeri",
    Courses: "Kurslar",
    selectMembers: "Üyeleri Seç",
    auction: "Açık Artırma",
    Nodescription: "Açıklama Yok",
    discussionBoard: "Tartışma Panosu",
    pages: "Sayfalar",
    trialBalance: "Mizan",
    contract: "Sözleşme",
    contract: "معاہدہ"
  },

  /*** AppHeader only used in Component headerTab ***/
  appHeader: {
    /*** ana menü ***/
    newsFeed: {
      posts: "Gönderiler",
      photos: "Fotoğraflar",
      videos: "Videolar",
      polls: "Anketler",
      docs: "Belgeler",
      tagged: "Etiketlenmiş",
    },
    mailBox: {},
    messenger: {},
    groups: {
      groups: "Gruplar",
    },
    projects: {
      projects: "Projeler",
      dashboard: "Kontrol Paneli",
    },
    Task: {
      Tasks: "Görevler",
      myTask: "Benim Görevlerim",
      assignedByMe: "Benim Atadıklarım",
      createdByMe: "Benim Oluşturduklarım",

      teamTask: "Takım Görevi",
      dashboard: "Kontrol Paneli",
    },
    leadManager: {
      leadCategory: "Potansiyel Müşteri Kategorisi",
    },

    schedules: {
      calendar: "Takvim",
      schedule: "Zamanlama",
      scheduleInterviews: "Görüşmeleri Planla",
    },
    expense: {
      expenses: "Masraflar",
      forApprovals: "Onay İçin",
      myExpenses: "Benim Masraflarım",
      forExecution: "Gerçekleştirme İçin",
      forFinance: "Finans İçin",
      reports: "Raporlar",
      dashboard: "Kontrol Paneli",
    },
    travel: {
      travels: "Seyahatler",
      forApprovals: "Onay İçin",
      agentProcess: "Acenta Süreci",
      travelDetail: "Seyahat Detayı",
    },
    docsArchives: {
      documents: "Belgeler",
      myDrive: "Benim Depom",
      myDocuments: "Benim Belgelerim",
      forApprovals: "Onay İçin",
    },
    eLearning: {
      dashboard: "Kontrol Paneli",
    },
    inventory: {
      approvals: "Onaylar",
      inventory: "Envanter",
      category: "Kategori",
    },
    customApproval: {
      CreateCustomApproval: "Özel Onay Oluştur",
      CustomApproval: "Özel Onay",
      ForApproval: "Onay İçin",
      MyCustomApprovals: "Benim Özel Onaylarım",
      AllCustomApproval: "Tüm Özel Onaylar",
    },
    payroll: {
      payroll: "Maaş Bordrosu",
      payrollApproval: "Maaş Bordrosu Onayı",
      salaryApproval: "Maaş Onayı",
    },
    // employee: {
    //   employees: "Çalışanlar",
    // },
    administration: {
      administration: "Yönetim",
    },

    billing: {
      BillType: "Fatura Türü",
      billingTypeEnum: {
        [BillingTypeEnum.NewUser]: "Yeni Kullanıcı",
        [BillingTypeEnum.MonthlySubscription]: "Aylık Abonelik",
        [BillingTypeEnum.NewBusinessNumber]: "Yeni İş Numarası"
      }
    },

    appraisals: {
      appraisals: "Performans Değerlendirmeleri",
    },
    reward_category: {
      reward_category: "Ödül Kategorisi",
    },
    departments: {
      departments: "Departmanlar",
    },
    leaves: {
      myLeaves: "Benim İzinlerim",
      forApprovals: "Onay İçin",
    },
    holidays: {
      holidays: "Tatiller",
    },
    warnings: {
      warnings: "Uyarılar",
    },
    resignations: {
      resignations: "İstifalar",
      approvers: "Onaylayanlar",
    },
    complains: {
      complains: "Şikayetler",
      approvers: "Onaylayanlar",
      createComplain: "Şikayet Oluştur",
    },

    rewards: {
      rewards: "Ödüller",
      AllRewards: "Tüm Bonuslar",
      CreatedByMe: "Tarafımdan Oluşturulanlar",
      ForApprovals: "Onay İçin",
      MyRewards: "Benim Ödüllerim"
    },
    promotions: {
      Promotions: "Terfiler",
    },
    salary: {
      Salary: "Maaş",
      CreateSalary: "Maaş Oluştur",
    },
    pages: {
      Pages: "Sayfalar",
    }
  },
  /*** Main Menu ***/
  globalSearch: {
    All: "Hepsi",
    feed: "Akış",
    Lead_Manager: "Potansiyel Müşteri Yöneticisi",
    Travel: "Seyahat",
    Document: "Belge",
    Project: "Proje",
    Workboard: "Çalışma Tahtası",
    Group: "Grup",
    Expense: "Gider",
    Task: "Görev",
    Employee: "Çalışan",
    e_learning_course: "e-Öğrenme Kursu",
    e_learning_videos: "e-Öğrenme Videoları",
    e_learning_article: "e-Öğrenme Makalesi",
    e_learning_quiz: "e-Öğrenme Testi",
    e_learning_book: "e-Öğrenme Kitabı",
    e_learning_tedTalks: "e-Öğrenme TED Konuşmaları",
    rewards: "Ödüller",
  },

  auction: {
    auctionType: "Müzayede Türü",
    personal: "Kişisel",
    official: "Resmi",
    offer: "Teklif",
    startingBid: "Başlangıç Teklifi",
    buyNowAmount: "Hemen Al Tutarı",
    immediateBuy: "Hemen Satın Al",
    minIncreaseBid: "Minimum Artış Teklifi",
    Auction: "Müzayede",
    CreateAuction: "Müzayede Oluştur",
    actionType: "Müzayede Türü", // silinmesi gereken fazladan bir adet
    external: "Harici",
    auctionDetail: "Müzayede Detayları",
    currentBid: "Mevcut Teklif",

    FormLabel: {
      startingBid: "Başlangıç Teklifi",
      member: "Üye",
      approvers: "Onaylayanlar",
      minIncreaseBid: "Minimum Artış Teklifi",
      buyNowAmount: "Hemen Al Tutarı",
      offer: "Teklif",
      immediateBuy: "Hemen Satın Al",
    },
    FormPlaceholder: {
      name: "Müzayede Adını Girin",
      startingBid: "Başlangıç Teklifini Girin",
      minIncreaseBid: "Minimum Artış Teklifini Girin",
      selectMember: "Üye Seçin",
      selectApprovers: "Onaylayanları Seçin",
      buyNowAmount: "Hemen Al Tutarını Girin",
      offer: "Teklifinizi Girin",
    },
    FormRequiredMessage: {
      name: "Lütfen Müzayede Adını Girin",
      startingBid: "Lütfen Başlangıç Teklifini Girin",
      minIncreaseBid: "Lütfen Minimum Artış Teklifini Girin",
      buyNowAmount: "Lütfen Hemen Al Tutarını Girin",
      offer: "Lütfen Teklifi Girin",
      endDate: "Lütfen Bitiş Tarihini Girin",
    },
    auctionFilterTypeEnumLabel: {
      [auctionFilterTypeEnum.All]: "Tüm Müzayedeler",
      [auctionFilterTypeEnum.CreatedByMe]: "Benim Müzayedem",
      [auctionFilterTypeEnum.ForApproval]: "Onay İçin",
    },
    statusEnum: {
      [AuctionStatusEnum.InProcess]: "İşlemde",
      [AuctionStatusEnum.Approved]: "Onaylandı",
      [AuctionStatusEnum.Declined]: "Reddedildi",
      [AuctionStatusEnum.Resend]: "Yeniden Gönder",
      [AuctionStatusEnum.InActive]: "Etkin Değil",
      [AuctionStatusEnum.NotRequired]: "Gerekli Değil",
      [AuctionStatusEnum.Cancelled]: "İptal Edildi",
      [AuctionStatusEnum.Hold]: "Beklemede",
    },
    auctionOfferStatus: {
      [AuctionOfferStatusEnum.BidInProcess]: "İşlemde",
      [AuctionOfferStatusEnum.BidSold]: "Satıldı",
    },
    noDataMessage: "Müzayede Oluştur",
  },
  newsFeed: {
    WriteForMe: "Benim için Yaz",
    AddDocuments: "Belgeler Ekle",
    AddPolls: "Anket Ekle",
    WhatsOnYourMind: "Aklınızda Ne Var",
    Direction: "ltr",
    Generate: "Oluştur",
    Change: "Değiştir",
    Whatwouldyouliketotalkabout: "Hakkında Konuşmak İstediğiniz Şey Nedir?",
    Post: "Gönderi",
    Poll: "Anket",
    Documents: "Belgeler",
    PhotoVideo: "Fotoğraf/Video",
    Addpolloption: "Anket Seçeneği Ekle",
    Removepoll: "Anketi Kaldır",
    WriteName: "İsim Yaz",
    AddImageVideo: "Resim/Video Ekle",
    CheckIn: "Konum Belirle",
    ShareonFeed: "Akışta Paylaş",
    ShareinChat: "Sohbet İçinde Paylaş",
    NoPosts: "Gönderi Yok...",
    Nomoreposts: "Daha fazla gönderi yok...",
    noDataMessage: "Şimdi Gönderi Oluştur!",
    Saysomethingaboutthis: "Bununla ilgili bir şey söyle...",
    specificTimeline: "zaman çizelgesi",
    SharedestinationNames: {
      [SharePostDestinations.FEED]: "Akış",
      [SharePostDestinations.MEMBERS_PROFILE]: "Üye profili",
      [SharePostDestinations.GROUP]: "Grup",
      [SharePostDestinations.PROJECT]: "Proje",
      [SharePostDestinations.TIMELINE]: "Timeline",
    },
    SharePostSuggestionPlaceholder: {
      [SuggestionTypeEnum.Employee]: "Üye seç",
      [SharePostDestinations.GROUP]: "Grup seç",
      [SharePostDestinations.PROJECT]: "Proje seç",
    },
    CreateFeedNow: "Şimdi Akış Oluştur!",
    QuickOptions: {
      CreateSlide: "Slayt Oluştur",
      CreateBoard: "Pano Oluştur",
      CreateGrid: "Izgara Oluştur",
      CreatePad: "Pad Oluştur",
      CreateTask: "Görev Oluştur",
      CreateExpense: "Gider Oluştur",
      AddUser: "Kullanıcı Ekle",
    },
    reactionDescription: {
      [ReactionType.Like]: "Beğen",
      [ReactionType.Love]: "Aşk",
      [ReactionType.NoReaction]: "Beğen",
      [ReactionType.Support]: "Takdir",
      [ReactionType.Celebrate]: "Kutlama",
      [ReactionType.Curious]: "Meraklı",
    },
    PostTypePlaceHolder: {
      [PostType.DEFAULT]: "Aklınızda Ne Var",
      [PostType.POLL]: "Bir şey sor...",
    },
    Titlecantbeempty: "Başlık Boş Olamaz",
    Attachmentscantbeempty: "Ekler Boş Olamaz",
    Optioncantbeempty: "Seçenek Boş Olamaz",
    SuccessfullySharedonFeed: "Başarıyla Akışta Paylaşıldı",
    SuccessfullySharedonMembersProfile: "Üye Profilinde Başarıyla Paylaşıldı",
    SuccessfullyPostSharedMessage:{
      [PostReferenceType.MAIN_FEED]: "Ana Akışta Başarıyla Paylaşıldı",
      [PostReferenceType.GROUP]: "Grupta Başarıyla Paylaşıldı",
      [PostReferenceType.PROJECTS]: "Projede Başarıyla Paylaşıldı",
      [PostReferenceType.TIMELINE]: "Zaman Çizelgesinde Başarıyla Paylaşıldı",
    },
  },
  mail: {
    composer: "Oluştur",
    menuItem: {
      inbox: "Gelen Kutusu",
      starred: "Yıldızlı",
      snoozed: "Ertelendi",
      sent: "Gönderildi",
      spam: "Spam",
      archive: "Arşiv",
      trash: "Çöp Kutusu",
      draft: "Taslak",
      allMail: "Tüm Postalar",
      createNewLabel: "Gelen Kutusu0",
    },
    close: "Gelen Kutusu1",
    send: "Gelen Kutusu2",
    newMessage: "Gelen Kutusu3",
  },
  messenger: {
    Messenger: "Messenger",
    Typing: "Yazıyor...",
    TypeaMessage: "Bir Mesaj Yaz...",
    Recording: "Kaydediliyor...",
    nameYourGroup: "Grubunuza Bir İsim Verin",
    Create: "Oluştur",
    GroupNameRequired: "Grup Adı Gerekli",
    searchMemberToAdd: "Eklemek İçin Üye Ara",
    contacts: "İletişim",
    selected: "Seçildi",
    whatwouldyoutalkingabout: "Hakkında konuşmak istediğiniz konu nedir?",
    SayHi: "İletişimlerinize merhaba deyin",
  },
  marketPlace: {
    jobBoardName: "İş Panosu",
    requsitionName: "Talep",
  },
  discussionBoard: {
    DiscussionBoard: "Tartışma Panosu",
    Topic: "Konu",
    pleaseEnterTopicName: "Lütfen Konu Adını Girin",
    enterTopicName: "Konu Adını Girin",
    addDiscussionBoard: "Tartışma Panosu Ekle",
    errormessage: "Tartışma panosu alınırken bir hata oluştu.",
    noDataMessage: "– Şimdi Tartışma Oluştur!",
  },
  pages: {
    messageName: "Lütfen Adını Girin",
    tags: "Etiketler",
    messageTags: "Lütfen Etiketleri Girin",
    enterTag: "Etiketleri Girin",
    createPage: "Sayfa Oluştur",
    noDataMessage: "– Şimdi Sayfa Oluştur!"
  },
  group: {
    group: "Gruplar",
    createGroup: "Grup Oluştur",
    Updategroup: "Grubu Güncelle",
    enterGroupName: "Lütfen grup adını girin.",
    Code: "Kod",
    exampleText: "Örneğin: G (bir grup adı için)",
    GroupTag: "Grup Etiketi",
    AddAdmin: "Yönetici Ekle",
    TotalBudget: "Toplam Bütçe",
    Spent: "Harcandı",
    Balance: "Bakiye",
    EnterGroupTag: "Grup Etiketi Girin",
    SaveGroupTag: "Grup Etiketi Kaydet",
    AddGroupTag: "Grup Etiketi Ekle",
    EditGroup: "Grubu Düzenle",
    CreateGroupNow: "Şimdi Grup Oluştur!"
  },
  project: {
    WIP: "Devam Eden İş",
    privateProject: "Özel Proje",
    Projects: "Projeler",
    UpdateProject: "Projeyi Güncelle",
    CreateProject: "Proje Oluştur",
    noDataMessage: "– Şimdi Proje Oluştur!",
    ProjectTag: "Proje Etiketi",
    ProjectRole: "Proje Rolü",
    exampleProjectName: "Örn. P (bir proje adı için)",
    projectDate: "Proje Tarihi",
    EnterProjectTag: "Proje Etiketi Girin",
    SaveProjectTag: "Proje Etiketini Kaydet",
    AddProjectTag: "Proje Etiketi Ekle",
    editProject: "Projeyi Düzenle",
    PersonalNotes: "Kişisel Notlar",
    EnterProjectRole: "Proje Rolünü Girin",
    SaveCustomTag: "Özel Etiketi Kaydet",
    AddProjectRole: "Proje Rolü Ekle",
    TakeaNote: "Not Alın",
    PublicProject: "Halka Açık Proje",
  },
  tasks: {
    Tasks: "Görevler",
    Predecessor: "Önceki Görev",
    AssignTo: "Ata",
    TaskDate: "Görev Tarihi",
    CreateTask: "Görev Oluştur",
    TaskAssignTo: "Görev Ata",
    Rating: "Değerlendirme",
    TaskDetail: "Görev Detayı",
    ProgressofAssignedmembers: "Atanmış Üyelerin İlerlemesi",
    SubTasks: "Alt Görevler",
    Subtask: "Alt Görev",
    CreateSubtask: "Alt Görev Oluştur",
    Addsubtask: "Alt Görev Ekle",
    ParentTask: "Üst Görev",
    DueToday: "Bugün Bitmeli",
    DueTomorrow: "Yarın Bitmeli",
    Overdue: "Gecikmiş",
    Duein: "Bitiş Tarihi",
    days: "gün",
    noDataMessage: "– Şimdi Görev Oluştur!",
    TaskCreatedSuccessfully: "Görev başarıyla oluşturuldu",
    selfTaskEnum: {
      [SelfTaskEnum.SelfTask]: "Kendi Görevim",
      [SelfTaskEnum.AssignTask]: "Ata",
    },
    Priority: "Öncelik",
    taskFilterTypeEnum: {
      [TaskFilterTypeEnum.CreatedByMe]: "Benim Atadıklarım",
      [TaskFilterTypeEnum.MyTasks]: "Benim Görevlerim",
      [TaskFilterTypeEnum.MyTeamTasks]: "Takım Görevleri",
    },
    statusEnum: {
      [TaskStatusEnum.NotStarted]: "Başlamadı",
      [TaskStatusEnum.InProcess]: "Devam Ediyor",
      [TaskStatusEnum.Completed]: "Tamamlandı",
      [TaskStatusEnum.RatingAssign]: "Değerlendirme Ata",
      [TaskStatusEnum.Cancel]: "İptal Edildi",
      [TaskStatusEnum.Hold]: "Beklemede",
    },
    taskReferenceTypeEnum: {
      [TaskReferenceTypeEnum.General]: "Genel",
      [TaskReferenceTypeEnum.Project]: "Proje",
      [TaskReferenceTypeEnum.Group]: "Grup",
      [TaskReferenceTypeEnum.Lead]: "Lider",
    },
    taskPriorityEnum: {
      [TaskPriorityEnum.Default]: "Varsayılan",
      [TaskPriorityEnum.Low]: "Düşük",
      [TaskPriorityEnum.Medium]: "Orta",
      [TaskPriorityEnum.High]: "Yüksek",
    },
    SortEnum: {
      [TaskFilterSortEnum.CreateDateDesc]: "Oluşturma Tarihi - Azalan",
      [TaskFilterSortEnum.CreateDateAsc]: "Oluşturma Tarihi - Artan",
      [TaskFilterSortEnum.StartDateDesc]: "Başlangıç Tarihi - Azalan",
      [TaskFilterSortEnum.StartDateAsc]: "Başlangıç Tarihi - Artan",
      [TaskFilterSortEnum.UpdateDateDesc]: "Güncelleme Tarihi - Azalan",
      [TaskFilterSortEnum.UpdateDateAsc]: "Güncelleme Tarihi - Artan"
    }

  },
  contracts: {
    Contract: "Sözleşme",
    createContract: "Sözleşme Oluştur",
    contractDate: "Sözleşme Tarihi",
    contractPurpose: "Sözleşmenin Amacı",
    selectContractPurpose: "Sözleşme Amacını Seçin",
    purposeofContractisRequired: "Sözleşme Amacı Gereklidir",
    category: "Kategori",
    purpose: "Amaç",
    signatureMember: "İmzalayan Üyeler",
    contractDetail: "Sözleşme Detayı",
    selectCategory: "Kategori Seçin",
    cateogryofContractisRequired: "Kategori Gereklidir",
    externalMember: "Harici İmzalayan Üyeler",
    selectinternalMembers: "Dahili İmzalayan Üyeleri Seçin",
    internalMembers: "Dahili İmzalayan Üyeler",
    selectExternalMember: "Harici İmzalayan Üyeleri Seçin",
    contractType: "Sözleşme Türü",
    contractTypeRequired: "Sözleşme Türü Gereklidir",
    internal: "Dahili",
    external: "Harici",
    noDataMessage: "Şimdi Sözleşme Oluştur!",
    contractsFilterTypeLables: {
      [ContractFilterTypeEnum.Contracts]: "Tüm İş Sözleşmeleri",
      [ContractFilterTypeEnum.BusinessContract]: "Sözleşmelerim"
    },
    statusEnum: {
      [ContractStatusEnum.InProcess]: "İşlemde",
      [ContractStatusEnum.Completed]: "Tamamlandı",
      [ContractStatusEnum.Cancelled]: "İptal Edildi"
    },
    contractReferenceTypeEnum: {
      [ContractReferenceTypeEnum.General]: "Genel",
      [ContractReferenceTypeEnum.Project]: "Proje",
      [ContractReferenceTypeEnum.Group]: "Grup",
      [ContractReferenceTypeEnum.Lead]: "Potansiyel Müşteri"
    }
  },
  workBoard: {
    WorkBoardDashBoard: "Çalışma Panosu Kontrol Paneli",
    pleaseEnterYourBoardName: "Lütfen Pano Adınızı Girin",
    pleaseEnterYourBoardDescription: "Lütfen Pano Açıklamanızı Girin",
    EnterMembers: "Üyeleri Girin",
    UpdateBoard: "Pano Güncelle",
    CreateBoard: "Pano Oluştur",
    privacy: "Gizlilik",
    AddSection: "Bölüm Ekle",
    AddTodo: "Yapılacak Ekle",
    Save: "Kaydet",
    EnterSectionTitle: "Bölüm Başlığını Girin",
    InSection: "Bölümde",
    Label: "Etiket",
    Activity: "Aktivite",
    Labels: "Etiketler",
    UploadCoverPhoto: "Kapak Fotoğrafı Yükle",
    Section: "Bölüm",
    Board: "Pano",
    Todos: "Yapılacaklar",
    CompleteDate: "Tamamlanma Tarihi",
    DetailedDesc: "Daha Fazla Ayrıntılı Açıklama Ekleyin...",
    EnterTodo: "Bu kart için metni girin...",
    CreateWorkBoardNow: "- Şimdi Çalışma Panosu Oluştur!",
    workBoardExample: "Örn. WB (Bir pano adı Work Board için)",
  },
  leadManager: {
    LeadManager: "Potansiyel Müşteri Yöneticisi",
    AssignedLeads: "Atanmış Potansiyel Müşteriler",
    AllLeads: "Tüm Potansiyel Müşteriler",
    Groups: "Gruplar",
    email: "E-posta",
    Lead: "Potansiyel Müşteri",
    Leads: "Potansiyel Müşteriler",
    Pleaseinputyourboardname: "Lütfen tahtanızın adını girin!",
    exampleLeadManagerMessage: "Örn. LM (Potansiyel Müşteri Yöneticisi adlı bir grup için)",
    inputBoardDescription: "Lütfen tahtanızın açıklamasını girin!",
    UpdateLeadManager: "Potansiyel Müşteri Yöneticisini Güncelle",
    CreateLeadManager: "Potansiyel Müşteri Yöneticisi Oluştur",
    CreateLeadGroup: "Potansiyel Müşteri Grubu Oluştur",
    noDataMessage: "– Şimdi Potansiyel Müşteri Yöneticisi Oluştur!",
    LastUpdated: "Son Güncelleme",
    LeadValue: "Potansiyel Müşteri Değeri",
    individual: "Bireysel",
    leadEmailAddress: "Potansiyel Müşteri E-posta Adresi",
    leadPhoneNumber: "Potansiyel Müşteri Telefon Numarası",
    leadAddress: "Potansiyel Müşteri Adresi",
    leadUrl: "Potansiyel Müşteri URL'si",
    ConvertProject: "Projeye Dönüştür",
    goToProject: "Projeye Git",
    SelectAssignMembers: "Atanmış Üyeleri Seç",
    LeadGroup: "Potansiyel Müşteri Grubu",
    leadName: "Potansiyel Müşteri Adı",
    leadMembers: "Potansiyel Müşteri Üyeleri",
    leadWebiste: "Potansiyel Müşteri Websitesi",
    LeadValuenegative: "Potansiyel Müşteri Değeri negatif olamaz!",
    AddLead: "Potansiyel Müşteri Ekle",
    SelectCommunicationTo: "İletişim Kurulacak Kişiyi Seçin",
    CommunicationTypeisrequired: "İletişim Türü gereklidir!",
    PleaseselectCommunicationType: "Lütfen İletişim Türünü Seçin",
    CommunicationType: "İletişim Türü",
    AddCommunication: "İletişim Ekle",
    CommunicationTo: "İletişim Kurulacak Kişi",
    NoCalls: "Çağrı Yok",
    LeadsValue: "Potansiyel Müşteri Değeri",
    metricTypeEnum: {
      [LeadManagerMetricTypeEnum.allGroup]: "Toplam Gruplar",
      [LeadManagerMetricTypeEnum.allAssign]: "Atanmış Potansiyel Müşteri",
      [LeadManagerMetricTypeEnum.leadToProject]: "Projeye Dönüştürülen Potansiyel Müşteri",
      [LeadManagerMetricTypeEnum.leadSection]: "Maksimum Potansiyel Müşteri Bölümü",
    },
  },
  schedule: {
    StartMeeting: "Toplantıyı Başlat",
    MeetinginProgress: "Devam Eden Toplantı",
    EndMeeting: "Toplantıyı Sonlandır",
    MeetingEnded: "Toplantı Sona Erdi",
    NewTokenAdded: "Yeni Token Eklendi",
    Copied: "Kopyalandı",
    SuccessfullyBlocked: "Başarıyla Engellendi",
    SuccessfullyUnBlocked: "Başarıyla Engelleme Kaldırıldı",
    UpdateCalendarLink: "Takvim Bağlantısını Güncelle",
    AddCalendarLink: "Takvim Bağlantısı Ekle",
    GettingCalendarLink: "Takvim bağlantısı alınıyor",
    CopyLink: "Bağlantıyı Kopyala",
    Block: "Engelle",
    UnBlock: "Engeli Kaldır",
    CreateSchedule: "Program Oluştur",
    Draft: "Taslak",
    Finalize: "Finalize Et",
    Complete: "Tamamla",
    Preview: "Önizleme",
    MinutesOftheMeeting: "Toplantı Dakikaları",
    MeetingId: "Toplantı Kimliği",
    Venue: "Mekan",
    Workwisecall: "Workwise Çağrısı",
    ConferenceCall: "Konferans Çağrısı",
    Attendees: "Katılımcılar",
    Absentees: "Katılmayanlar",
    PointsofDiscussion: "Tartışma Noktaları",
    AddPoint: "Nokta Ekle",
    Print: "Yazdır",
    SaveToPDF: "PDF Olarak Kaydet",
    MEETINGMINUTES: "TOPLANTI DAKİKALARI",
    Yes: "Evet",
    No: "Hayır",
    WorkwiseCall: "Workwise Çağrısı",
    MeetingObjectives: "Toplantı Hedefleri",
    Points: "Noktalar",
    RefNo: "Referans No",
    AttendanceatMeeting: "Toplantı Katılımı",
    Loadingschedules: "Programlar Yükleniyor...",
    Nodata: "Veri Yok...",
    Projects: "Projeler",
    Groups: "Gruplar",
    titleMessage: "Emin misiniz? Tür Değişikliği atanan üyeleri kaldıracaktır.",
    pleaseSelectAssignMemeber: "Lütfen Atanmış Üye Seçin!",
    SelectAssignto: "Atanacak Kişiyi Seçin",
    GetLink: "Bağlantıyı Al",
    Anyone: "Herkes",
    anyoneText: "Bağlantıyla herkes randevu oluşturabilir.",
    TodayEvents: "Bugünkü Etkinlikler",
    UpcomingEvents: "Yaklaşan Etkinlikler",
    InterviewRequests: "Mülakat Talepleri",
    sendviachat: "sohbet üzerinden gönder",
    OnVideoConference: "Video Konferansta:",
    OnWorkwiseCall: "Workwise Çağrısında",
    Enterlocation: "Konumu Girin",
    Duration: "Süre",
    inminutes: "dakikada",
    Durationisrequired: "Süre gerekli",
    Rooms: "Odalar",
    SelectRoom: "Oda Seçin",
    CheckRoomAvailability: "Oda Uygunluğunu Kontrol Et",
    Available: "Uygun",
    NotAvailable: "Uygun Değil",
    TravelTime: "Seyahat Süresi",
    PreparationTime: "Hazırlık Süresi",
    SendviaEmail: "E-posta ile Gönder",
    schedulerCalender: "Takvim",
    Next: "Sonraki",
    Prev: "Önceki",
    titlemyCustomButton: "Özel Düğme Başlığı",
    prevnexttoday: "önceki sonraki bugün",
    timeGridDaytimeGridWeekdayGridMonth: "Zaman Izgarası Gün Zaman Izgarası Hafta Gün Izgarası Ay",
    timeGridDay: "Zaman Izgarası Gün",
    top: "Üst",
    block: "Engelle",
    minutes: "Dakika",
    AllDay: "Tüm Gün",
    timeGridWeek: "Zaman Izgarası Hafta",
    slotDuration: "00:15:00",
    numeric: "Sayısal",
    dayGridMonth: "Gün Izgarası Ay",
    dayGridWeek: "Gün Izgarası Hafta",
    timeGrid: "Zaman Izgarası",
    Day: "Gün",
    Week: "Hafta",
    Month: "Ay",
    NoEventsonthisday: "Bu günde etkinlik yok.",
    CreateInterviewSchedule: "Mülakat Programı Oluştur",
    Interviewwith: "İle Mülakat",
    MySchedules: "Benim Programlarım",
    TeamSchedules: "Takım Programları",
    Past: "Geçmiş",
    Today: "Bugün",
    Upcoming: "Yaklaşan",
    ScheduleDetail: "Program Detayı",
    JoinWorkwiseCall: "Workwise Çağrısına Katıl",
    AddWorkwiseCall: "Workwise Çağrısı Ekle",
    Host: "(Ev Sahibi)",
    MakeHost: "Ev Sahibi Yap",
    Attending: "Katılıyor",
    UpdateSchedule: "Programı Güncelle",
    Calendar: "Takvim",
    Schedule: "Program",
    ShareCalendarLink: "Takvim Bağlantısını Paylaş",
    pointer: "İşaretçi",
    default: "Varsayılan",
    blue: "Mavi",
    red: "Kırmızı",
    purple: "Mor",
    Self: "Kendi",
    AssignTo: "Ata",
    assign: "Ata",
    min: "dk",
    Minutes: "15 dakika",
  },
  expenses: {
    CreateExpense: "Gider Oluştur",
    IsReimbursable: "İade Edilebilir",
    WriteHeaderHere: "Başlığı Buraya Yaz...",
    ExpenseDetail: "Gider Detayı",
    ExpenseDate: "Gider Tarihi",
    Executors: "Yürütücüler",
    Financers: "Finansörler",
    ExecutorRequired: "Yürütücü gerekli",
    selectExecutor: "Giderin Yürütücüsünü Seç",
    noDataMessage: "Finansal yolculuğunu güçlendir - Şimdi Gider oluştur!",
    expenseFilterTypeEnum: {
      [ExpenseFilterTypeEnum.MyExpense]: "Kendi Giderim",
      [ExpenseFilterTypeEnum.ForApproval]: "Onay İçin",
      [ExpenseFilterTypeEnum.ForExecutions]: "Yürütme İçin",
      [ExpenseFilterTypeEnum.ForFinance]: "Finans İçin",
    },
    expenseType: {
      [ExpenseReferenceTypeEnum.General]: "Genel",
      [ExpenseReferenceTypeEnum.Group]: "Grup",
      [ExpenseReferenceTypeEnum.Project]: "Proje",
      [ExpenseReferenceTypeEnum.Travel]: "Seyahat",
      [ExpenseReferenceTypeEnum.Assets]: "Varlık",
    },
    statusEnum: {
      [ExpenseApprovalStatusEnum.InProcess]: "İşlemde",
      [ExpenseApprovalStatusEnum.Approved]: "Onaylandı",
      [ExpenseApprovalStatusEnum.Cancelled]: "İptal Edildi",
      [ExpenseApprovalStatusEnum.Completed]: "Tamamlandı",
      [ExpenseApprovalStatusEnum.Hold]: "Beklemede",
      [ExpenseApprovalStatusEnum.Disbursed]: "Ödenen",
    },
    SortEnum: {
      [ExpenseFilterSortEnum.CreateDateDesc]: "Oluşturma Tarihi - Azalan",
      [ExpenseFilterSortEnum.CreateDateAsc]: "Oluşturma Tarihi",
      [ExpenseFilterSortEnum.ExpenseDateDesc]: "Masraf Tarihi - Azalan",
      [ExpenseFilterSortEnum.ExpenseDate]: "Masraf Tarihi",
      [ExpenseFilterSortEnum.ReferenceNo]: "Referans No",
      [ExpenseFilterSortEnum.ReferenceNoDesc]: "Referans No - Azalan",
      [ExpenseFilterSortEnum.Amount]: "Miktar",
      [ExpenseFilterSortEnum.AmountDesc]: "Miktar - Azalan"
    },
    Category: {
      [ExpenseCategoryEnum.Transport]: "Taşıma",
      [ExpenseCategoryEnum.Health]: "Sağlık",
      [ExpenseCategoryEnum.Food]: "Yiyecek",
      [ExpenseCategoryEnum.Shopping]: "Alışveriş",
      [ExpenseCategoryEnum.Entertainment]: "Eğlence",
      [ExpenseCategoryEnum.Travel]: "Seyahat",
      [ExpenseCategoryEnum.Bill]: "Fatura",
      [ExpenseCategoryEnum.Office]: "Ofis",
      [ExpenseCategoryEnum.Fuel]: "Yakıt",
      [ExpenseCategoryEnum.Asset]: "Varlık",
      [ExpenseCategoryEnum.Other]: "Diğer"
    }

  },
  travel: {
    TravelDetail: "Seyahat Detayı",
    CreateTravel: "Seyahat Oluştur",
    PleaseAddTravelDetail: "Lütfen Seyahat Detayı Ekleyin",
    TravelToandFrom: "Gidiş-Dönüş Seyahat",
    ReturnDateIsRequired: "Dönüş Tarihi Gerekli",
    PleaseSelectTravelType: "Lütfen Seyahat Türünü Seçin",
    HotelTADACar: "Otel, TADA ve Araç",
    HotelRequired: "Otel Gerekli",
    RentaCar: "Araç Kirala",
    PickUpRequired: "Alma Gerekli",
    DropOffRequired: "Bırakma Gerekli",
    TADAApplicable: "TADA Uygulanabilir",
    TravelBy: "Seyahat Yolu",
    SpecialRequest: "Özel İstek",
    SearchDestinationCity: "Varış Şehri Ara",
    SearchDepartureCity: "Kalkış Şehri Ara",
    PleaseSelectDepartureCity: "Lütfen Kalkış Şehri Seçin.",
    PleaseSelectDestinationCity: "Lütfen Varış Şehri Seçin.",
    DepartureTimeIsRequired: "Kalkış Saati Gerekli",
    RetunTimeIsRequired: "Dönüş Saati Gerekli",
    AttachmentType: "Ek Türü",
    WriteSpecialRequestDetail: "Özel İstek Detayını Yazın",
    Agents: "Acenteler",
    Departure: "Kalkış",
    Arrival: "Varış",
    DepartureDate: "Kalkış Tarihi",
    DepartureTime: "Kalkış Saati",
    DepartureCity: "Kalkış Şehri",
    ShiftType: "Vardiya Türü",
    DepartureShift: "Kalkış Vardiyası",
    ArrivalCity: "Varış Şehri",
    CreateTravelnow: "Şimdi Seyahat Oluştur",

    TravelAttachmentTypeOptions: {
      [TravelAttachmentTypeEnum.Passport]: "Pasaport",
      [TravelAttachmentTypeEnum.Ticket]: "Bilet",
      [TravelAttachmentTypeEnum.Insurance]: "Sigorta",
      [TravelAttachmentTypeEnum.CarRental]: "Araç Kiralama",
      [TravelAttachmentTypeEnum.Other]: "Diğer",
    },

    Shift: {
      [ShiftEnum.Morning]: "Sabah",
      [ShiftEnum.Afternoon]: "Öğleden Sonra",
      [ShiftEnum.Evening]: "Akşam",
      [ShiftEnum.Night]: "Gece",
    },

    travelBy: {
      plane: "Uçak ile",
      bus: "Otobüs ile",
      ship: "Gemisiyle",
      train: "Tren ile",
      car: "Araba ile",
    },

    TravelOptions: {
      [TravelOptionsEnums.ByPlane]: "Uçak ile",
      [TravelOptionsEnums.ByShip]: "Gemisiyle",
      [TravelOptionsEnums.ByBus]: "Otobüs ile",
      [TravelOptionsEnums.ByTrain]: "Tren ile",
      [TravelOptionsEnums.ByCar]: "Araba ile",
    },

    travelFilterTypeEnum: {
      [TravelFilterTypeEnum.Travels]: "Seyahatler",
      [TravelFilterTypeEnum.MyTravel]: "Benim Seyahatim",
      [TravelFilterTypeEnum.ForApproval]: "Onay İçin",
      [TravelFilterTypeEnum.AgentProcess]: "Acenta Süreci",
    },

    statusEnum: {
      [TravelStatusEnum.InProcess]: "İşlemde",
      [TravelStatusEnum.Approved]: "Onaylandı",
      [TravelStatusEnum.Declined]: "Reddedildi",
      [TravelStatusEnum.Cancelled]: "İptal Edildi",
      [TravelStatusEnum.ApprovedByApprovers]: "Onay Verenler Tarafından Onaylandı",
      [TravelStatusEnum.ApprovedByAgents]: "Acenteler Tarafından Onaylandı",
      [TravelStatusEnum.DeclineByApproves]: "Onay Verenler Tarafından Reddedildi",
      [TravelStatusEnum.DeclineByAgents]: "Acenteler Tarafından Reddedildi",
      [TravelStatusEnum.Hold]: "Beklemede",
    },
  },
  eLearning: {
    Course: "Kurs",
    eBook: "eKitap",
    Quiz: "Sınav",
    TedTalk: "Ted Konuşması",
    Article: "Makale",
    Video: "Video",
    BasicCourseDetail: "Temel Kurs Detayı",
    SelectSubject: "Konu Seç",
    SelectCourse: "Kurs Seç",
    PleaseSelectOption: "Lütfen Seçenek Seçin",
    Selectcoursetype: "Kurs türünü seçin",
    CourseName: "Kurs Adı",
    Entercoursename: "Kurs adını girin",
    Level: "Seviye",
    PleaseSelectLevel: "Lütfen Seviye Seçin",
    Selectlevel: "Seviye seçin",
    CourseAdminMembers: "Kurs Yöneticisi Üyeleri",
    CourseAdminisrequired: "Kurs Yöneticisi gerekli",
    AssignMembers: "Üyeleri Ata",
    UpdateEBook: "eKitap Güncelle",
    CreateEBook: "eKitap Oluştur",
    AuthorName: "Yazar Adı",
    EnterInformation: "Bilgi Girin",
    UploadBook: "Kitap Yükle (PDF)",
    CreateQuiz: "Sınav Oluştur",
    QuizName: "Sınav Adı",
    CreateTedTalk: "Ted Konuşması Oluştur",
    ViaLink: "Bağlantı ile",
    ViaVideoUpload: "Video Yükleme ile",
    PleaseInsertLink: "Lütfen Bağlantı Ekleyin",
    UploadVideo: "Video Yükle",
    CreateArticle: "Makale Oluştur",
    InsertLink: "Bağlantı Ekle",
    UploadPDF: "Fotoğraf veya PDF Yükle",
    CreateVideo: "Video Oluştur",
    LessonDetail: "Ders Detayı",
    Eitherselect: "Ya sınavı seçin ya da kaldırın.",
    Selectquiz: "Sınav seç",
    AddNewQuiz: "Yeni Sınav Ekle",
    Atleastonetopic: "Bu dersin en az bir konusu gereklidir.",
    LessonNameRequired: "Ders adı gereklidir.",
    LessonName: "Ders Adı",
    DeleteLesson: "Dersi Sil",
    LessonDescriptionRequired: "Ders açıklaması gereklidir.",
    TopicNameIsRequired: "Konu adı gereklidir.",
    TopicName: "Konu Adı",
    DeleteTopic: "Konuyu Sil",
    TopicDescription: "Konu açıklaması gereklidir.",
    eitherEnterText: "Ya metin girin ya da kaldırın.",
    EitherenterA: "Ya bir",
    OrRemoveIt: "girin ya da kaldırın.",
    CreateArticlesNow: "Şimdi Makale Oluşturun!",
    CreateCoursesNow: "Şimdi Kurslar Oluşturun!",
    CreateEbooksNow: "Şimdi eKitaplar Oluşturun!",
    CreateElearningNow: "Şimdi E-öğrenme Oluşturun!",
    CreateQuizzesNow: "Şimdi Sınavlar Oluşturun!",
    CreateTeamDashboardNow: "Şimdi Takım Panosu Oluşturun!",
    CreateTedTalksNow: "Şimdi Ted Konuşmaları Oluşturun!",
    CreateVideosNow: "Şimdi Videolar Oluşturun!",
    OptionEmpty: "Seçenek boş olamaz",
    CorrectOptionRequired: "Doğru seçenek gereklidir",
    QuestionEmpty: "Soru boş olamaz",
    UntitledQuestion: "Başlıksız Soru",
    AddQuestion: "Soru Ekle",
    DeleteQuestion: "Soruyu Sil",
    Quiznamerequired: "Sınav adı gereklidir!",
    QuizexpirationDate: "Sınav bitiş tarihi",
    QuizExpirationDateIsRequired: "Sınav bitiş tarihi gereklidir",
    QuizDescriptionRequired: "Sınav açıklaması gereklidir!",
    StartQuiz: "Sınavı Başlat",
    GoToResults: "Sonuçlara git",
    Gettoknown: "Kullanıcı deneyimini tanıyın",
    Missingdata: "Eksik veri.",
    UntitledLesson: "Başlıksız Ders",
    RemoveText: "Metni Kaldır",
    AddText: "Metin Ekle",
    RemoveImage: "Resmi Kaldır",
    AddImage: "Resim Ekle",
    RemoveVideo: "Videoyu Kaldır",
    AddVideo: "Video Ekle",
    RemovePDF: "PDF'yi Kaldır",
    AddPDF: "PDF Ekle",
    RemoveQuiz: "Sınavı Kaldır",
    AddQuiz: "Sınav Ekle",
    RemoveLink: "Bağlantıyı Kaldır",
    AddLink: "Bağlantı Ekle",
    RemoveYouTubeLink: "YouTube Bağlantısını Kaldır",
    AddYouTubeLink: "YouTube Bağlantısı Ekle",
    RemoveExternalCourse: "Harici Kursu Kaldır",
    AddExternalCourse: "Harici Kurs Ekle",
    UntitledTopic: "Başlıksız Konu",
    DashboardChart: "Pano Grafiği",
    LatestTrendInBusiness: "İş Dünyasındaki En Son Trend",
    InformationWillComeHere: "Bilgi burada görünecek",
    CourseContent: "Kurs içeriği",
    DummyContent: "Sahte İçerik",
    EndCourse: "Kursu Bitir",
    Chapters: "Bölümler •",
    Lectures: "Dersler •",
    LessonsInThisProgram: "Bu Programdaki Dersler",
    BookInformation: "Kitap Bilgisi",
    TedTalksDaily: "Günlük Ted Konuşmaları",
    TopicTitle: "Konu Başlığı",
    VisitThis: "Bunu Ziyaret Et",
    LearnPractical: "Kurslar aracılığıyla pratik beceriler öğrenin ve ücretli stajlar alın.",
    Courses: "Kurslar",
    Book: "Kitap",
    Tedtalk: "Ted konuşması",
    Articles: "Makaleler",
    NoPageExist: "Sayfa mevcut değil",
    Books: "Kitaplar",
    TedTalks: "Ted Konuşmaları",
    Videos: "Videolar",
    GoTo: "Git",
    CourseLevelIdLabel: {
      [CourseLevelIdEnum.Advance]: "İleri",
      [CourseLevelIdEnum.Intermediate]: "Orta",
      [CourseLevelIdEnum.Beginner]: "Başlangıç"
    }
  },
  attendence: {
    Attendance: "Katılım",
    TimeSpent: "Geçirilen Zaman",
    GraceTime: "Müsaade Süresi",
    GoodDay: "İyi Günler",
    Howfeeling: "Bugün nasıl hissediyorsun?",
    Note: "Not",
    Noattendanceforapproval: "Onay için katılım yok.",
    Iwaslate: "Trafik nedeniyle geç kaldım.",
    Showtimespent: "Geçirilen zamanı göster",
    ClockIn: "Katılım kaydınızı başlatmak için Giriş Yapın.",
    selectStatus: "Lütfen bir durum seçin!",
    ByTime: "Zamana Göre",
    ByStatus: "Duruma Göre",
    DeductionPolicy: "Kesinti Politikası",
    UpdateDeductionPolicy: "Kesinti Politikasını Güncelle",
    AddDeductionPolicy: "Kesinti Politikası Ekle",
    DaysRange: "Gün Aralığı",
    PerDay: "Günlük",
    SaveDeductionPolicy: "Kesinti Politikasını Kaydet",
    AbsentPolicy: "Devamsızlık Politikası",
    LatePolicy: "Geç Kalma Politikası",
    IsPercentage: "Yüzde Olarak",

    attendanceFilterTypeEnum: {
      [AttendanceFilterTypeEnum.checkInForApproval]: "Onay İçin Giriş",
      [AttendanceFilterTypeEnum.dayWiseAttendance]: "Günlük Katılım",
      [AttendanceFilterTypeEnum.userAttendance]: "Kullanıcı Katılımı",
      [AttendanceFilterTypeEnum.Summary]: "Özet",
    },

    updateErrorMessage: {
      [AttendanceStateEnum.Off]: "Kapalı durumda güncelleme eylemi yapılamaz",
      [AttendanceStateEnum.Holiday]: "Tatil günlerinde güncelleme eylemi yapılamaz",
      [AttendanceStateEnum.Leave]: "İzin durumunda güncelleme eylemi yapılamaz",
    },
    statusEnum: {
      [AttendanceStatusEnum.InProcess]: "Devam Ediyor",
      [AttendanceStatusEnum.Approved]: "Onaylandı",
      [AttendanceStatusEnum.Declined]: "Reddedildi",
    },
    moodLabels: {
      [AttendanceMoodEnum.VeryUnsatisfied]: "Çok Memnuniyetsiz",
      [AttendanceMoodEnum.Unsatisfied]: "Memnuniyetsiz",
      [AttendanceMoodEnum.Neutral]: "Nötr",
      [AttendanceMoodEnum.Satisfied]: "Memnun",
      [AttendanceMoodEnum.VerySatisfied]: "Çok Memnun",
    },
    attendanceState: {
      [AttendanceStateEnum.Unknown]: "",
      [AttendanceStateEnum.Present]: "Mevcut",
      [AttendanceStateEnum.Absent]: "Yok",
      [AttendanceStateEnum.Leave]: "İzinli",
      [AttendanceStateEnum.Late]: "Geç",
      [AttendanceStateEnum.WorkFromHome]: "Evden Çalışma",
      [AttendanceStateEnum.Off]: "Kapalı",
      [AttendanceStateEnum.Holiday]: "Tatil",
    },
    attendanceType: {
      [ATTENDANCE_ENUM.CHECK_IN.IN]: "Giriş",
      [ATTENDANCE_ENUM.CHECK_IN.OUT]: "Çıkış",
    },
    attendanceMetricLabel: {
      [AttendanceMetricTypeEnum.overallTimeSpent]: "Toplam Geçirilen Zaman",
      [AttendanceMetricTypeEnum.Present]: "Mevcut",
      [AttendanceMetricTypeEnum.Absent]: "Yok",
      [AttendanceMetricTypeEnum.Leave]: "İzinli",
      [AttendanceMetricTypeEnum.Late]: "Geç",
    },
  },
  customApproval: {
    createCustomApproval: "Özel Onay Oluştur",
    customApproval: "Özel Onay",
    noDataMessage: "– Şimdi Özel Onaylar Oluşturun!",
    customApprovalFilterTypeEnum: {
      [CustomApprovalFilterTypeEnum.All]: "Tüm Özel Onaylar",
      [CustomApprovalFilterTypeEnum.CreatedByMe]: "Benim Oluşturduğum Özel Onaylar",
      [CustomApprovalFilterTypeEnum.ForApproval]: "Onay İçin",
    },
    statusEnum: {
      [CustomApprovalStatusEum.InProcess]: "İşlemde",
      [CustomApprovalStatusEum.Approved]: "Onaylandı",
      [CustomApprovalStatusEum.Declined]: "Reddedildi",
      [CustomApprovalStatusEum.Resend]: "Yeniden Gönder",
      [CustomApprovalStatusEum.InActive]: "Pasif",
      [CustomApprovalStatusEum.NotRequired]: "Gerekli Değil",
      [CustomApprovalStatusEum.Cancelled]: "İptal Edildi",
      [CustomApprovalStatusEum.Hold]: "Beklemede",
    },
    SortEnum: {
      [CustomApprovalFilterSortEnum.UpdateDateDesc]: "Güncelleme Tarihi - Azalan",
      [CustomApprovalFilterSortEnum.UpdateDateAsc]: "Güncelleme Tarihi",
      [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "Referans No - Azalan",
      [CustomApprovalFilterSortEnum.ReferenceNo]: "Referans No",
      [CustomApprovalFilterSortEnum.CreateDateDesc]: "Oluşturma Tarihi - Azalan",
      [CustomApprovalFilterSortEnum.CreateDateAsc]: "Oluşturma Tarihi"
    }

  },
  reward: {
    CreateReward: "Ödül Oluştur",
    Rewards: "Ödüller",
    RewardTo: "Ödül Alan",
    RewardCategory: "Ödül Kategorisi",
    EnterRewardCategory: "Ödül Kategorisi Girin",
    RewardName: "Ödül Adı",
    EnterRewardName: "Ödül Adını Girin",
    PleaseEnterRewardName: "Lütfen Ödül Adını Girin",
    ReasonForReward: "Ödül Sebebi",
    EnterReasonForReward: "Ödül Sebebini Girin",
    PleaseEnterReasonForReward: "Lütfen Ödül Sebebini Girin",
    noDataMessage: "– Şimdi Ödül Oluştur!",
    rewardFilterTypeEnum: {
      [RewardFilterTypeEnum.All]: "Tüm Ödüller",
      [RewardFilterTypeEnum.CreatedByMe]: "Benim Tarafından Oluşturulanlar",
      [RewardFilterTypeEnum.ForApproval]: "Onay İçin",
      [RewardFilterTypeEnum.RewardForMe]: "Benim Ödüllerim",
    },
    statusEnum: {
      [RewardStatusEnum.InProcess]: "İşlemde",
      [RewardStatusEnum.Approved]: "Onaylandı",
      [RewardStatusEnum.Declined]: "Reddedildi",
      [RewardStatusEnum.Cancelled]: "İptal Edildi",
      [RewardStatusEnum.Hold]: "Beklemede",
    },
  },
  document: {
    mySigned: "Benim İmzaladığım",
    forMySignature: "İmzam İçin",
    draftSignature: "Taslak",
    inSigning: "İmza Aşamasında",
    signaturesCompleted: "İmzalar Tamamlandı",
    DocsArchive: "Belgeler ve Arşivler",
    createDocument: "Belge Oluştur",
    CreateSlides: "Slaytlar Oluştur",
    CreateBoard: "Pano Oluştur",
    CreatePad: "Pad Oluştur",
    CreateGrid: "Grid Oluştur",
    newFolder: "Yeni Klasör",
    createFolder: "Klasör Oluştur",
    uploadDocuments: "Belgeleri Yükle",
    addBoard: "Pano Ekle",
    addPad: "Pad Ekle",
    addGrid: "Grid Ekle",
    addSlide: "Slayt Ekle",
    addMindmap: "Zihin Haritası Ekle",
    CompleteDocument: "Belgeyi Tamamla",
    noDataMessage: "- Şimdi Belge Oluştur!",
    AllDocuments: "Tüm Belgeler",
    MyDrive: "Benim Sürücüm",
    MyDocuments: "Onay İçin Benim Belgelerim",
    ForApprovals: "Onaylar İçin",
    OnlyFiles: "Yalnızca Dosyalar",
    fileName: "Dosya Adı",
    shortDescription: "Kısa Açıklama (maksimum 100 karakter)",
    AllFiles: "Tüm Dosyalar",
    Grids: "Gridler",
    Pads: "Pad'ler",
    Slides: "Slaytlar",
    Boards: "Panolar",
    Mindmaps: "Zihin Haritaları",
    updateFolder: "Klasörü Güncelle",
    updateGrid: "Izgarayı Güncelle",
    updatePad: "Bloknotu Güncelle",
    updateSlide: "Slaytı Güncelle",
    updateBoard: "Panoyu Güncelle",
    updateMindMap: "Zihin Haritasını Güncelle",
    updateDocuments: "Belgeyi Güncelle",

    status: {
      InProcess: "İşlemde",
      Approved: "Onaylandı",
      Declined: "Reddedildi",
      Resend: "Yeniden Gönder",
      NotRequired: "Gerekli Değil",
      Cancelled: "İptal Edildi",
      Draft: "Taslak"
    },
    documentReferenceType: {
      [DocumentReferenceTypeEnum.General]: "Genel",
      [DocumentReferenceTypeEnum.Group]: "Grup",
      [DocumentReferenceTypeEnum.Project]: "Proje",
    },
  },
  warning: {
    WarningDetail: "Uyarı Detayı",
    warningTo: "Uyarı Gönderilen",
    createWarning: "Uyarı Oluştur",
    enterwarningCategory: "Uyarı kategorisi girin",
    noDataMessage: '– Şimdi Uyarı Oluştur!',
    filter: "Filtrele",
    warningFilterTypeEnum: {
      [WarningFilterTypeEnum.Warning]: "Uyarılar",
      [WarningFilterTypeEnum.CreatedByMe]: "Benim Tarafımdan Oluşturulanlar",
      [WarningFilterTypeEnum.ForApproval]: "Onay İçin",
      [WarningFilterTypeEnum.WarningToMe]: "Benim İçin Uyarılar",
    },
    statusEnum: {
      [WarningStatusEnum.InProcess]: "İşlemde",
      [WarningStatusEnum.Approved]: "Onaylandı",
      [WarningStatusEnum.Declined]: "Reddedildi",
      [WarningStatusEnum.Cancelled]: "İptal Edildi",
      [WarningStatusEnum.Hold]: "Beklemede",
    },
    SortEnum: {
      [WarningFilterSortEnum.CreateDateDesc]: "Oluşturma Tarihi - Azalan",
      [WarningFilterSortEnum.CreateDateAsc]: "Oluşturma Tarihi",
      [WarningFilterSortEnum.UpdateDateDesc]: "Güncelleme Tarihi - Azalan",
      [WarningFilterSortEnum.UpdateDateAsc]: "Güncelleme Tarihi",
      [WarningFilterSortEnum.CategoryDesc]: "Kategori - Azalan",
      [WarningFilterSortEnum.Category]: "Kategori",
      [WarningFilterSortEnum.StatusDesc]: "Durum - Azalan",
      [WarningFilterSortEnum.Status]: "Durum",
      [WarningFilterSortEnum.ReferenceNoDesc]: "Referans No - Azalan",
      [WarningFilterSortEnum.ReferenceNo]: "Referans No",
    }
  },
  jobBoard: {
    jobBoard: "İş Panosu",
    noDataMessage: " – Şimdi Kariyerler Oluşturun!",
  },
  businessPolicy: {
    BusinessPolicy: "İş Politikası",
    policies: "Politikalar",
    noPolicy: "Politika Bulunamadı...",
  },
  forms: {
    CreateForm: "Form Oluştur",
    Forms: "Formlar",
    Attempt: "Deneme",
    CopyLink: "Bağlantıyı Kopyala",
    Title: "Başlık",
    EnterTitle: "Başlığı Girin",
    PleaseEnterTitle: "Lütfen Başlığı Girin",
    PleaseFillTheQuestionField: "Lütfen Soru Alanını Doldurun",
    EnterQuestion: "Soruyu Girin",
    SelectAnswerType: "Cevap Türünü Seçin",
    Text: "Metin",
    Number: "Sayı",
    Polls: "Anketler",
    AddQuestion: "Soru Ekle",
    AddOption: "Seçenek Ekle",
    Details: "Detaylar",
    PoweredBy: "Tarafından Güçlendirildi",
    Submit: "Gönder",
    EditForm: "Formu Düzenle",
    EnterEmail: "E-postayı Girin",
    Question: "Soru...",
    YourAnswer: 'Cevabınız',
    noDataMessage: "– Şimdi Form Oluştur!",
    formQuestionTypeEnum: {
      [FormQuestionTypeEnum.Number]: "Sayı",
      [FormQuestionTypeEnum.Text]: "Metin",
      [FormQuestionTypeEnum.Poll]: "Anketler",
    },
    formFilterTypeEnum: {
      [FormFilterTypeEnum.All]: "Tüm Formlar",
      [FormFilterTypeEnum.CreatedByMe]: "Benim Tarafından Oluşturulan",
      [FormFilterTypeEnum.ForApproval]: "Onay İçin",
      [FormFilterTypeEnum.FormForMe]: "Benim İçin Form"
    },
    statusEnum: {
      [FormStatusEnum.InProcess]: "İşlemde",
      [FormStatusEnum.Approved]: "Onaylandı",
      [FormStatusEnum.Declined]: "Reddedildi",
      [FormStatusEnum.Resend]: "Yeniden Gönder",
      [FormStatusEnum.InActive]: "Aktif Değil",
      [FormStatusEnum.NotRequired]: "Gerekli Değil",
      [FormStatusEnum.Cancelled]: "İptal Edildi",
      [FormStatusEnum.Hold]: "Beklemede",
    },
    SortEnum: {
      [FormFilterSortEnum.CreateDateDesc]: "Oluşturma Tarihi - Azalan",
      [FormFilterSortEnum.CreateDateAsc]: "Oluşturma Tarihi",
      [FormFilterSortEnum.UpdateDateDesc]: "Güncelleme Tarihi - Azalan",
      [FormFilterSortEnum.UpdateDateAsc]: "Güncelleme Tarihi",
      [FormFilterSortEnum.ReferenceNoDesc]: "Referans Numarası - Azalan",
      [FormFilterSortEnum.ReferenceNo]: "Referans Numarası"
    }
  },
  careers: {
    Careers: "Kariyer",
    CareerDetail: "Kariyer Detayı",
    createCareer: "Kariyer Oluştur",
    MinSalary: "Minimum Maaş",
    MaxSalary: "Maksimum Maaş",
    Applicants: "Başvuranlar",
    ShortListed: "Kısa Listeye Alınanlar",
    Rejected: "Reddedilenler",
    Completed: "Tamamlananlar",
    Finalist: "Finalist",
    Maybe: "Belki",
    OfferSent: "Teklif Gönderildi",
    WaitingForInterview: "Mülakat Bekleniyor",
    OfferAccepted: "Teklif Kabul Edildi",
    OfferDeclined: "Teklif Reddedildi",
    OfferResend: "Teklif Yeniden Gönder",
    noDataMessage: "– Şimdi Kariyer Oluştur!",
    writeHeaderHere: "Başlık Girin",
    pickCurrentDate: "Geçerli Tarihi Seçin",
    enterAmount: "Miktar Girin",
    selectApprovers: "Onaylayıcıları Seçin",
    selectFinancers: "Finansörleri Seçin",
    selectExecutors: "Yürütücüleri Seçin",
    selectDesignation: "Unvanı Seçin",
    writeDescription: "Açıklama Girin",
    enterMinSalary: "Minimum maaş girin",
    enterMaxSalary: "Maksimum maaş girin",
    selectDepartment: "Departman Seçin",
    selectSupervisor: "Yönetici Seçin",
    selectInterviewers: "Mülakat Yapanları Seçin",
    selectPostInterviewers: "Sonrasında Mülakat Yapanları Seçin",
    description: "Açıklamanızı Buraya Girin...",
    addSkills: "Yetenek Ekleyin",
    selectJobType: "İş Türünü Seçin",
    selectJobshift: "İş Vardiyasını Seçin",
    selectEducation: "Lütfen Eğitimi Seçin",
    selectCareerLevel: "Kariyer Seviyesini Seçin",
    selectEndDate: "Bitiş Tarihini Seçin",
    enterreviewcriteria: "İnceleme Kriterlerini Girin",
    reviewCriteria: "İnceleme Kriterleri",
    enterDepartment: "Lütfen Departmanı Girin",
    supervisor: "Yönetici",
    supervisorrequired: "Yönetici Gerekli",
    postInterviewers: "Sonrasında Mülakat Yapanlar",
    jobShift: "İş Vardiyası",
    enterJobShift: "Lütfen İş Vardiyasını Girin",
    enterJobType: "Lütfen İş Türünü Girin",
    careerLevel: "Kariyer Seviyesi",
    selectBuddy: "Hiring Buddy'yi Seçin",
    selectInerview: "Mülakat Yapanları Seçin",
    requiredInterview: "Mülakat Yapana İhtiyaç Var",
    enterCareerLevel: "Lütfen Kariyer Seviyesini Girin",
    createJob: "İş Oluştur",
    applyJob: "İş Başvurusu Yap",
    firstName: "Ad",
    enterFirstName: "Adınızı Girin",
    requiredMessageFName: "Ad Gerekli",
    lastName: "Soyad",
    enterLastName: "Soyadınızı Girin",
    requiredMessageLName: "Soyad Gerekli",
    email: "E-posta",
    enterEmail: "E-postanızı Girin",
    EmailIsRequired: "E-posta Gerekli",
    phoneNumber: "Telefon Numarası",
    enterPhoneNumber: "Telefon Numaranızı Girin",
    currentSalary: "Mevcut Maaş",
    expectedSalary: "Beklenen Maaş",
    radioMessageApplicant: "Başvuran",
    radioMessageShortlisted: "Kısa Listeye Alınan",
    radioMessageCompleted: "Tamamlanan",
    coverNote: "Kapak Notu",
    noticePeriod: "Bildirim Süresi",
    requiredMessageExperience: "Deneyim Gerekli",
    requiredMessageNoticePeriod: "Bildirim Süresi Gerekli",
    appliedDate: "Başvuru Tarihi",
    interviewSchedule: "Mülakat Programı",
    applyNow: "Şimdi Başvur",
    jobPost: "İlan Tarihi",
    jobexpire: "İlan Sonlanma Tarihi",
    skillrequired: "Gereken Yetenekler",
    joblocation: "İş Yeri",
    jobType: "İş Türü",
    jobDetails: "İş Detayları",
    EducationLevel: "Eğitim Seviyesi",
    MinimumSalary: "Minimum Maaş",
    MaximumSalary: "Maksimum Maaş",
    YearOfExperience: "Deneyim Yılı",
    errormessage: "Durumu Değiştiremezsiniz",
    MakeOffer: "Teklif Yap",
    updatestatus: "Durumu güncellemek istediğinizden emin misiniz?",
    Pleaseenterminsalary: "Lütfen asgari maaşı girin",
    Pleaseentermaxsalary: "Lütfen maksimum maaşı girin",
    NoRequirements: "Gereksinim yok",
    statusEnum: {
      [CareerStatusEnum.InProcess]: "İşlemde",
      [CareerStatusEnum.Approved]: "Onaylandı",
      [CareerStatusEnum.Declined]: "Reddedildi",
      [CareerStatusEnum.Cancelled]: "İptal Edildi",
      [CareerStatusEnum.Hold]: "Beklemede",
    },
    careerFilterTypeEnum: {
      [CareerFilterTypeEnum.All]: "Tüm Kariyerler",
      [CareerFilterTypeEnum.MyCareers]: "Benim Kariyerlerim",
      [CareerFilterTypeEnum.ForApproval]: "Onay İçin",
    },

  },
  /*** HR Menu ***/
  myTeam: {
    MyTeams: "Takımlarım",
    EmployeeNo: "Çalışan No",
    noDataMessage: "Şimdi Takımlar Oluştur!",
    teamDetails: "Takım Detayları",
    DeductionPerMonth: "Aylık Kesinti",
    teamActivities: {
      attendence: "Katılım",
      checkIn: "Giriş Kontrolü",
      leaves: "İzinler",
      rewards: "Ödüller",
      appraisals: "Değerlendirmeler",
      warnings: "Uyarılar",
      complains: "Şikayetler",
      courses: "Kurslar",
      education: "Eğitim",
      experience: "Deneyim",
      loan: "Kredi",
      activityLog: "Aktivite Günlüğü",
      checkInCheckOut: "Giriş / Çıkış",
      storageStatistics: "Depolama İstatistikleri"

    },

  },
  employee: {
    Basic_Info: "Temel Bilgiler",
    Email_Configuration: "E-posta Yapılandırması",
    Bank_Detail: "Banka Detayı",
    Education: "Eğitim",
    Emergency_Info: "Acil Durum Bilgileri",
    Experience: "Deneyim",
  },
  administration: {
    Company_Goal: "Şirket Hedefi",
    basicInfo: "Temel Bilgi",
    Subsidiary_Office: "İştirak Ofisi",
    AddSubsidiary: "İştirak Ekle",
    Fiscal_Year: "Mali Yıl",
    Tax_Slabs: "Vergi Dilimleri",
    Tax_Slabs_Group: "Vergi Dilimi Grubu",
    E_Learning_Category: "E-Öğrenim Kategorisi",
    Company_Policy: "Şirket Politikaları",
    Default_Hiring_Criteria: "Varsayılan İşe Alma Kriterleri",
    subsidiary: "İştirak",
    Business_Logo: "İş Logosu",
    Complain_Category: "Şikayet Kategorisi",
    Custom_Tag: "Özel Etiket",
    Payroll_Group: "Maaş Grubu",
    Business_Policy: "İş Politikası",
    Companies_Policy: "Şirket Politikaları",
    Billing: "Fatura",
    Rebate_Category: "İndirim Kategorisi",
    Assets_Category: "Varlık Kategorisi",
    AccessRole: "Erişim Rolü",
    Grade: "Derece",
    Default_Account_Header: "Varsayılan Hesap Başlığı",
    Business_Paging: "İş Numarası",
    Discussion_Board_Category: "Tartışma Panosu Kategorisi",
    Room: "Oda",
    Business_Association: "İş Birliği",
    Designation: "Unvan",
    Appraisal: "Değerlendirme",
    Office_Timings: "Ofis Çalışma Saatleri",
    Leave_Types: "İzin Türleri",
    User_Types: "Kullanıcı Türleri",
    Expense_Headers: "Gider Başlıkları",
    Salary_Headers: "Maaş Başlıkları",
    Request_For_Right: "Hak İsteği",
    Custom_Approval_Category: "Özel Onay Kategorisi",
    GRADE_ALLOWANCES: "Derece İzinleri",
    Paging_Category: "Sayfa Kategorisi",
    Page_Category: "Sayfa Kategorisi",
    Project_Category: "Proje Kategorisi",
    Email_Configuration: "E-posta Yapılandırma",
    Allowances: "İzinler",
    Default_Approvals: "Varsayılan Onaylar",
    NonMandatory_Approvals: "Zorunlu Olmayan Onaylar",
    WORKGATING: "Çalışma İzin Kriterleri",
    Job_Description: "İş Açıklaması",
    Rewards: "Ödüller",
    Reward_Category: "Ödül Kategorisi",
    Warning_Category: "Uyarı Kategorisi",
    Holidays: "Tatiller",
    paymentCard: "Ödeme Kartı",
    Company_Info: "Şirket Bilgisi",
    Business_Approval: "İş Onayı",
    COMPANY_STATISTICS: "Şirket İstatistikleri",
    ATTENDANCE_SETTINGS: 'Katılım Ayarları',
    Form: {
      Name: "Ad",
      Entername: "Adı girin",
      PleaseEnterName: "Lütfen Adı Girin",
      Description: "Açıklama",
      enterDescription: "Lütfen Açıklamayı Girin",
      Create: "Oluştur",
      Add: "Ekle",
      Save: "Kaydet",
      Clear: "Temizle",
      CategoryName: "Kategori Adı",
      Entercategoryname: "Kategori adını girin",
      SaveCategory: "Kategoriyi Kaydet",
      Date: "Tarih",
      startDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
      Upload: "Yükle",
      Percent: "Yüzde",
      Amount: "Miktar",
      EnterAmount: "Miktarı Girin",
      addCategory: "Kategori Ekle",
      SelectMember: "Üye Seç",
      Email: "E-posta",
      EnterEmail: "E-postayı girin",
      Types: "Türler",
      SelectStatus: "Durumu Seç",
      Submit: "Gönder",
      Nomoreapprovals: "Daha fazla onay yok",
    },
    Table: {
      Name: "Ad",
      Members: "Üyeler",
      Description: "Açıklama",
      Value: "Değer",
      Allot_Leave: "İzin Ayır",
      Type: "Tür",
      Unit: "Birim",
      Taxable: "Vergilendirilebilir",
      Provider: "Sağlayıcı",
      IncomingServerAddress: "Gelen Sunucu Adresi",
      OutgoingServerAddress: "Giden Sunucu Adresi",
      IncomingPort: "Gelen Port",
      OutgoingPort: "Giden Port",
      Action: "Eylem",
      startDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
      Actions: "Eylemler",
      Account: "Hesap",
      approvers: "Onaylayıcılar",
      Status: "Durum",
      Allocated: "Tahsis Edildi",
      AddMember: "Üye Ekle",
      CreateDate: "Oluşturma Tarihi",
    },
    accessRole: {
      Button: {
        AddAccessRole: "Erişim Rolü Ekle",
        UpdateAccessRole: "Erişim Rolünü Güncelle",
      },
      Drawer: {
        CreateAccessRole: "Erişim Rolü Oluştur",
        UpdateAccessRole: "Erişim Rolünü Güncelle",
        UserType: "Kullanıcı Türü",
        Description: "Açıklama",
        placeholders: {
          EnterName: "Erişim Rolü Adını Girin",
          UserType: "Lütfen Kullanıcı Türünü Seçin",
          Description: "Açıklama Girin",
        },
        formLabel: {
          AccessRoleName: "Erişim Rolü Adı",
        },
        placeholders: {
          EnterName: "Erişim rolünü girin",
          SelectUserType: "Kullanıcı türünü seçin",
          EnterDescription: "Açıklama girin",
        },
      },
    },
    grade: {
      AddGrade: "Derece Ekle",
      EnterGrade: "Derece Girin",
      EnterDescription: "Açıklama Girin",
      Grade: "Derece",
      Description: "Açıklama",
      Name: "Ad",
      clear: "Temizle",
      saveGrade: "Dereceyi Kaydet",
    },
    fiscalyear: {
      Add: "Ekle",
      EnterName: "Ad Girin",
      EnterDescription: "Açıklama Girin",
      Fiscalyear: "Mali Yıl",
      description: "Açıklama",
      name: "Ad",
      clear: "Temizle",
      save: "Kaydet",
      startyear: "Başlangıç Yılı",
      endyear: "Bitiş Yılı",
      startMonth: "Başlangıç Ayı",
      endMonth: "Bitiş Ayı",
      startDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
    },
    businessPolicy: {
      companypolicy: "Şirket Politikaları",
      enterName: "Ad Girin",
      name: "Ad",
      type: "Tür Seçin",
      typee: "Tür",
      description: "Açıklama",
      EnterDescription: "Açıklama Girin",
      create: "Oluştur",
      save: "Kaydet",
      createPolicy: "Politika Oluştur",
      updatePolicy: "Politikayı Güncelle",

    },
    taxSlab: {
      TaxSlabs: "Vergi Dilimleri",
      UpdateTaxSlab: "Vergi Dilimi Güncelle",
      CreateTaxSlab: "Vergi Dilimi Oluştur",
      NoTaxSlabGroup: "Vergi Dilimi Grubu Yok...",
      enterCountry: "Ülke Seçin",
      enterName: "Adı Girin",
      enterDescription: "Açıklama Girin",
      description: "Açıklama",
      name: "Ad",
      TaxSlab: "Vergi Dilimi",
      title: "Başlık",
      enterTitle: "Başlık Girin",
      percentage: "Yüzde",
      previousCharge: "Önceki Ücret",
      min: "Min",
      max: "Max",
      enterMin: "Min Girin",
      enterMax: "Max Girin",
      enterPercent: "Yüzde Girin",
      save: "Kaydet",
      clear: "Temizle",
      Add: "Ekle",
      TaxSlabgroup: "Vergi Dilimi Grubu",
      country: "Ülke",
      minimumCharge: "Minimum Ücret",
    },
    payrollGroup: {
      PayrollGroup: "Maaş Grubu",
      save: "Grubu Kaydet",
      clear: "Temizle",
      Add: "Grup Ekle",
      name: "Ad",
      enterName: "Ad Girin",
    },
    subsidiaryOffice: {
      enterName: "Ad Girin",
      enterAddress: "Adres Girin",
      name: "Ad",
      subsidiary_Office: "İştirak Ofisi",
      latitude: "Enlem",
      longitude: "Boylam",
      address: "Adres",
      branch: "Şube",
      Subsidiary: "İştirak",
      select: "İştirak Seçin",
      save: "Kaydet",
      clear: "Temizle",
      Add: "Ekle",
      title: "Başlık",
      enterTitle: "Başlık Girin",
    },
    defaultHiringCriteria: {
      default: "Varsayılan İşe Alma Kriterleri",
      question: "Sorular",
      enterQuestions: "Soruları Girin",
      save: "Soruları Kaydet",
      clear: "Temizle",
      Add: "Soru Ekle",
    },
    workGatingCriteria: {
      work: "Çalışma İzin Kriterleri",
      question: "Sorular",
      enterQuestions: "Soruları Girin",
      save: "Soruları Kaydet",
      clear: "Temizle",
      Add: "Soru Ekle",
    },
    designation: {
      desig: "Unvan",
      enterdesig: "Unvanı Girin",
      enterName: "Ad Girin",
      enterDescription: "Açıklama Girin",
      description: "Açıklama",
      name: "Ad",
      save: "Unvanı Kaydet",
      clear: "Temizle",
      Add: "Unvan Ekle",
    },
    appraisal: {
      Appraisal: "Değerlendirme",
      enterAppraisal: "Değerlendirmeyi Girin",
      enterDescription: "Açıklama Girin",
      description: "Açıklama",
      save: "Değerlendirmeyi Kaydet",
      clear: "Temizle",
      Add: "Değerlendirme Ekle",
      CompanyGoal: "Şirket Hedefi",
      IndividualGoal: "Bireysel Hedef",
      DepartmentsGoal: "Departman Hedefi",
      Goal: "Hedef",
      saveGoal: "Hedefi Kaydet",
      AddCompanyGoal: "Şirket Hedefi Ekle",
      AddDepartmentGoal: "Departman Hedefi Ekle",
      AddIndividualGoal: "Bireysel Hedef Ekle",
      enterCompanyGoal: "Şirket Hedefini Girin",
      enterDepartmentGoal: "Departman Hedefini Girin",
      enterIndividualGoal: "Bireysel Hedefi Girin",

    },
    office: {
      Office_Timing: "Ofis Çalışma Saatleri",
      group: "Grup Adı",
      enterGroup: "Adı Girin",
      enterDescription: "Açıklama Girin",
      description: "Açıklama",
      save: "Ofis Çalışma Saatlerini Kaydet",
      clear: "Temizle",
      Add: "Ofis Çalışma Saatleri Ekle",
      days: "Günler",
      work: "Çalışıyor",
      checkIn: "Giriş",
      checkOut: "Çıkış",
      time: "Grace Süresi",
      min: "Dakika",
      create: "Grup Oluştur",
      new: "Yeni Zaman Grubu",
      UpdateOfficeTiming: "Ofis Zamanını Güncelle",
      CreateOfficeTiming: "Ofis Zamanı Oluştur",
      Monday: "Pazartesi",
      Tuesday: "Salı",
      Wednesday: "Çarşamba",
      Thursday: "Perşembe",
      Friday: "Cuma",
      Saturday: "Cumartesi",
      Sunday: "Pazar",
    },
    leave: {
      Leave: "İzin Türü",
      leaveType: "İzin Türü",
      enterLeave: "İzin Türünü Girin",
      save: "İzin Türünü Kaydet",
      clear: "Temizle",
      Add: "İzin Türü Ekle",
      enterDescription: "Açıklama Girin",
      description: "Açıklama",
      allotLeaves: "Tahsis Edilen İzinler",
      balanceLeaves: "İzin Türleri (kalan izinler)",
      selectType: "Tür Seçin",
      leaveFor: "İzin için",
      leaveDates: "İzin Tarihleri",
      createleave: "İzin Oluştur",
    },
    expense: {
      Expense: "Gider Başlığı",
      enterExpense: "Gider Başlığını Girin",
      enterDescription: "Açıklama Girin",
      description: "Açıklama",
      save: "Gider Başlığını Kaydet",
      clear: "Temizle",
      Add: "Gider Başlığı Ekle",
    },
    elearningCategory: {
      elearningCategory: "E-Öğrenim Kategorisi",
      description: "Açıklama",
      enterelearningCategory: "Kategoriyi Girin",
      enterDescription: "Açıklama Girin",
      save: "E-Öğrenim Kategorisini Kaydet",
      clear: "Temizle",
      Add: "E-Öğrenim Kategorisi Ekle",
    },
    salaryHeader: {
      SalaryHeader: "Maaş Başlığı",
      enterSalaryHeader: "Maaş başlığını girin",
      desc: "Açıklama",
      enterDesc: "Açıklamayı girin",
      saveHeader: "Başlığı Kaydet",
      addHeader: "Başlık Ekle",
      name: "Ad",
      clear: "Temizle",
    },
    room: {
      Room: "Oda",
      Name: "Ad",
      Entername: "Adı girin",
      Description: "Açıklama",
      Enterdescription: "Açıklamayı girin",
      Location: "Konum",
      Enterlocation: "Konumu girin",
      TotalSeats: "Toplam Koltuklar",
      Enterseats: "Koltuk sayısını girin",
      SaveRoom: "Odayı Kaydet",
      AddRoom: "Oda Ekle",
      clear: "Temizle",
    },
    RebateCategory: {
      rebateCategory: "İndirim Kategorisi",
      maxPercentage: "Maksimum Yüzde",
      entermaxPercentage: "Maksimum yüzdeyi girin",
      maxAmount: "Maksimum Miktar",
      enterMaxAmount: "Maksimum miktarı girin",
      type: "Tür",
      basic: "Temel",
      tax: "Vergi",
      fullAmount: "Tam Miktar",
      rebateType: "İndirim Türü",
    },
    projectCategory: {
      ProjectCategory: "Proje Kategorisi",
      AddProjectCategory: "Proje Kategorisi Ekle",
    },
    pageCategory: {
      Pagecategory: "Sayfa Kategorisi",
      AddPageCategory: "Sayfa Kategorisi Ekle",
    },
    NonMandatoryApprovals: {
      NonMandatoryApproval: "Zorunlu Olmayan Onaylar",
    },
    jobdescription: {
      jobDesc: "İş Tanımı",
      designation: "Unvan",
      selectDesign: "Unvan Seç",
      saveJobDesc: "İş Tanımını Kaydet",
      addJobdesc: "İş Tanımı Ekle",
    },
    Holiday: {
      Holidays: "Tatiller",
      AddHoliDay: "Tatil Ekle"
    },
    GradeAllowance: {
      gradeAllowance: "Kademeli İzin",
      Grades: "Kademeler",
      AllowanceType: "İzin Türü",
      SelectType: "Tür Seç",
      AddAllowance: "İzin Ekle",
      UpdateAllowance: "İzni Güncelle",
      Allowance: "İzin",
      IsTaxable: "Vergilendirilebilir Mi",
    },
    EmailConfiguration: {
      emailConfi: "E-posta Yapılandırması",
      enterEmailConfi: "E-posta yapılandırmasını girin",
      incomingPort: "Gelen Port",
      enterIncomingPort: "Gelen portu girin",
      incomingServerAddress: "Gelen Sunucu Adresi",
      enterIncomingServerAddress: "Gelen sunucu adresini girin",
      outgoingPort: "Giden Port",
      enterOutgoingPort: "Giden portu girin",
      outgoingServerAddress: "Giden Sunucu Adresi",
      enterOutgoingServerAddress: "Giden sunucu adresini girin",
      provider: "Sağlayıcı",
      enterProvider: "Sağlayıcıyı girin",
      saveEmailConfi: "E-posta Yapılandırmasını Kaydet",
      addEmailConfi: "E-posta Yapılandırması Ekle",
    },
    DiscussionBoardCategory: {
      discussionBoardCategory: "Tartışma Panosu Kategorisi",
      SaveDiscussionBoardCategory: "Tartışma Panosu Kategorisini Kaydet",
      AddDiscussionBoardCategory: "Tartışma Panosu Kategorisi Ekle",
    },
    DefaultApprovers: {
      defaultApprovers: "Varsayılan Onaylayıcılar",
    },
    DefaultAccountHeader: {
      defaultAccountHeader: "Varsayılan Hesap Başlığı",
      SaveAccountHeader: "Hesap Başlığını Kaydet",
      SelectAccountTypes: "Hesap Türlerini Seç",
    },
    CustomTag: {
      customTag: "Özel Etiket",
      Entercustomtag: "Özel etiketi girin",
      SaveCustomTag: "Özel Etiketi Kaydet",
      AddCustomTag: "Özel Etiket Ekle",
    },
    customApprovalCategory: {
      customApprovalcategory: "Özel Onay Kategorisi",
      enterCustomApprovalcategory: "Özel onay kategorisini girin",
    },
    BusinessNumber: {
      Manual: "Manuel",
      TotalNumber: "Toplam Sayı",
      Country: "Ülke",
      SelectCountry: "Ülke Seç",
      Number: "Sayı",
      Random: "Rastgele",
      SelectNumber: "Sayı Seç",
      Cost: "Maliyet",
      Request: "İstek",
    },
    BusinessAssociation: {
      businessAssociation: "İş Birliği",
      SaveBusinessAssociation: "İş Birliğini Kaydet",
      AddBusinessAssociation: "İş Birliği Ekle",
      AssociationType: "İş Birliği Türü",
    },
    AssetsCategory: {
      assetsCategory: "Varlık Kategorisi",
      categoryName: "Kategori Adı",
      enterCategoryName: "Kategori adını girin",
      desc: "Açıklama",
      enterDesc: "Açıklamayı girin",
      accountType: "Hesap Türü",
      parentAccount: "Ana Hesap",
      selectParent: "Ana Hesap Seç",
      selectType: "Tür Seç",
      add: "Ekle",
      save: "Kaydet",
      clear: "Temizle",
      name: "Ad",
      assets: "Varlık",
      liability: "Yükümlülük",
      capital: "Sermaye",
      revenue: "Gelir",
      expense: "Gider",
      costOfGoodsale: "Satışın Maliyeti",
    },
    Allowance: {
      allowance: "İzin",
      enterAllownace: "İzni girin",
      percent: "Yüzde",
      desc: "Açıklamayı girin",
      grade: "Kademe",
      selectGrade: "Kademe Seç",
      amount: "Miktar",
      benefit: "Fayda",
      deduction: "Kesinti",
      texable: "Vergilendirilebilir",
      nonTexable: "Vergilendirilemez",
      director: "Yönetici",
      ceo: "CEO",
      addAllowance: "İzin Ekle",
      saveAllowance: "İzni Kaydet",
      clear: "Temizle",
      name: "Ad",
      allowanceType: "İzin Türü",
      allowanceUnit: "İzin Birimi",
      istexable: "Vergilendirilebilir Mi",
      yes: "Evet",
      no: "Hayır",
    },
    CompanyInfo: {
      businessLogo: "İş Logosu",
      basicinfo: "Temel Bilgi",
      ShortLogo: "Kısa Logo",
      Logo: "Logo",
      FullLogo: "Tam Logo",
      companyName: "Şirket Adı",
      PleaseEnterCompanyName: "Lütfen Şirket Adını Girin",
      enterCompanyName: "Şirket Adını Girin",
      companyEmail: "Şirket E-postasını Girin",
      PleaseEnterCompanyEmail: "Lütfen Şirket E-postasını Girin",
      SelectOwnerName: "Sahibi Seçin",
      totalEmployees: "Toplam Çalışan Sayısı",
      totalEmployeesInCall: "Toplam Çalışan Sayısı Çağrıda",
      SaveShortLogo: "Kısa Logo Kaydet",
      LongLogo: "Uzun Logo",
      SaveLongLogo: "Uzun Logo Kaydet",
      SaveFullLogo: "Tam Logo Kaydet",
    },
    billing: {
      Invoice: "Fatura",
      InvoiceNo: "Fatura No",
      CustomerName: "Müşteri Adı",
      PayInvoice: "Faturayı Öde",
      Payment: "Ödeme",
      BillNo: "Fatura No",
      CardLast: "Kart Son 4",
      BusinessName: "İşletme Adı",
      CardHolder: "Kart Sahibi",
      Viewinvoicedetails: "Fatura ve Ödeme Detaylarını Görüntüle",
      Viewinvoicedetail: "Fatura Detayını Görüntüle",
      Qty: "Adet",
    },
    PaymentCard: {
      paymentCard: "Ödeme Kartı",
      CardHolderName: "Kart Sahibi Adı",
      AddCard: "Kart Ekle",
      PayInvoice: "Faturayı Öde",
      CardHolderNameRequired: "Kart Sahibi Adı Gerekli.",
      PayCard: "Kartla Öde",
      payanotherway: "Ya da başka bir şekilde öde",
      credentialstocontinue: "Devam etmek için kimlik bilgilerinizi girin.",
      Expiry: "Son Kullanma Tarihi",
      Brand: "Marka",
      Country: "Ülke",
      LastFourDigits: "Son Dört Rakam",
    },

  },
  businessNumber: {
    statusEnum: {
      [BuisnessNumberStatusEnum.Waiting]: "Beklemede",
      [BuisnessNumberStatusEnum.Declined]: "Reddedildi",
      [BuisnessNumberStatusEnum.NumberCancelled]: "Numara İptal Edildi",
      [BuisnessNumberStatusEnum.Purchased]: "Satın Alındı",
      [BuisnessNumberStatusEnum.RequestCancelled]: "İstek İptal Edildi",
      [BuisnessNumberStatusEnum.Rejected]: "Reddedildi",
    },
  },
  businessApproval: {
    statusTypeList: {
      [ApprovalStatusTypeEnum.InProcess]: "İşlemde",
      [ApprovalStatusTypeEnum.Approved]: "Onaylandı",
      [ApprovalStatusTypeEnum.Declined]: "Reddedildi",
      [ApprovalStatusTypeEnum.Cancelled]: "İptal Edildi",
      [ApprovalStatusTypeEnum.Completed]: "Tamamlandı",
    }
  },
  appraisal: {
    Appraisals: "Değerlendirmeler",
    createAppraisals: "Değerlendirme Oluştur",
    noDataMessage: "– Şimdi Değerlendirmeler Oluştur!",
    Approvers: "Onaylayanlar",
    Questions: "Sorular",
    Tasks: "Görevler",
    Bonus: "Bonus",
    Increment: "Artış",
    previousApraisals: "Önceki Değerlendirmeler",
    warning: "Uyarı",
    rewards: "Ödüller",
    courses: "Kurslar",
    Promotion: "Tanıtım",
    PleaseEnterBonusPercentage: "Lütfen Bonus Yüzdesi Girin",
    PleaseEnterBonusAmount: "Lütfen Bonus Miktarı Girin",
    PleaseEnterIncrementAmount: "Lütfen Artış Miktarını Girin",
    RateAtleastOneItem: "Lütfen En Az Bir Öğe Değerlendirin",
    Noemployeeselected: "Hiçbir Çalışan Seçilmedi",
    submitAppraisal: "Değerlendirmeleri Gönder",
    Appraisedof: "Değerlendirildiği",
    AppraisalApprovers: "Değerlendirme Onaylayanları",
    AppraisalDetail: "Değerlendirme Detayı",
    appraisalFilterTypeEnum: {
      [AppraisalFilterEnum.All]: "Tüm Değerlendirmeler",
      [AppraisalFilterEnum.CreatedByMe]: "Benim Tarafından Oluşturulanlar",
      [AppraisalFilterEnum.ForApproval]: "Onay İçin",
      [AppraisalFilterEnum.TeamAppraisal]: "Ekip Değerlendirmesi",
      [AppraisalFilterEnum.MyApprovedAppraisal]: "Benim Onaylanmış Değerlendirmem",
    },
    statusEnum: {
      [AppraisalStatusEnum.InProcess]: "İşlemde",
      [AppraisalStatusEnum.Approved]: "Onaylandı",
      [AppraisalStatusEnum.Declined]: "Reddedildi",
      [AppraisalStatusEnum.Cancelled]: "İptal Edildi",
      [AppraisalStatusEnum.Hold]: "Beklemede",
    },
    SortEnum: {
      [AppraisalFilterSortEnum.CreateDateDesc]: "Oluşturma Tarihi - Azalan",
      [AppraisalFilterSortEnum.CreateDateAsc]: "Oluşturma Tarihi",
      [AppraisalFilterSortEnum.UpdateDateDesc]: "Güncelleme Tarihi - Azalan",
      [AppraisalFilterSortEnum.UpdateDateAsc]: "Güncelleme Tarihi",
      [AppraisalFilterSortEnum.ReferenceNoDesc]: "Referans No - Azalan",
      [AppraisalFilterSortEnum.ReferenceNo]: "Referans No"
    }

  },
  promotion: {
    CreatePromotion: "Promosyon Oluştur",
    Promotion: "Promosyon",
    promotionTo: "Promosyona",
    noDataMessage: "Şimdi Promosyon Oluştur!",
    selectPromotionTo: "Promosyonu Seç",
    pleseSelectPromotionTo: "Lütfen Promosyonu Seçin",
    createPromotion: "Promosyon Oluştur",
    promotionFilterTypeEnum: {
      [PromotionFilterTypeEnum.All]: "Tüm Promosyonlar",
      [PromotionFilterTypeEnum.CreatedByMe]: "Benim Tarafından Oluşturulanlar",
      [PromotionFilterTypeEnum.ForApproval]: "Onaylar İçin",
      [PromotionFilterTypeEnum.PromotionForMe]: "Benim Promosyonlarım",
    },
    statusEnum: {
      [PromotionStatusEnum.InProcess]: "İşlemde",
      [PromotionStatusEnum.Approved]: "Onaylandı",
      [PromotionStatusEnum.Declined]: "Reddedildi",
      [PromotionStatusEnum.Cancelled]: "İptal Edildi",
      [PromotionStatusEnum.Hold]: "Beklemede",
    },
  },
  leaves: {
    leave: "İzinler",
    leavedetail: "İzin Detayları",
    Leave: "İzin Türü",
    leaveType: "İzin Türü",
    enterLeave: "İzin türünü girin",
    save: "İzin Türünü Kaydet",
    clear: "Temizle",
    Add: "İzin Türü Ekle",
    enterDescription: "Açıklama girin",
    description: "Açıklama",
    allotLeaves: "Tahsis Edilen İzinler",
    balanceLeaves: "İzin Türleri (kalan izinler)",
    selectType: "Türü Seç",
    leaveFor: "İzin İçin",
    leaveDates: "İzin Tarihleri",
    createleave: "İzin Oluştur",
    days: "Günler",
    leavetype: "İzin Türü",
    approvers: "Onaylayanlar",
    selectMembers: "Üyeleri Seç",
    daysCount: "Gün Sayısı",
    messages: "Lütfen izin türünü seçin",
    allocatedLeaves: "Tahsis Edilen İzinler",
    availedLeaves: "Kullanılan İzinler",
    remainingLeaves: "Kalan İzinler",
    noDataMessage: " - Şimdi İzin Oluştur!",
    Leavesinfo: "İzin Bilgisi",
    UpdateLeave: "İzni Güncelle",
    leavesFilterTypeEnum: {
      [LeaveFilterTypeEnum.All]: "Tüm İzinler",
      [LeaveFilterTypeEnum.CreatedByMe]: "Benim Tarafından Oluşturulanlar",
      [LeaveFilterTypeEnum.ForApproval]: "Onay İçin",
      [LeaveFilterTypeEnum.LeaveForMe]: "Benim İzinlerim",
    },
    statusEnum: {
      [LeaveStatusEum.InProcess]: "İşlemde",
      [LeaveStatusEum.Approved]: "Onaylandı",
      [LeaveStatusEum.Declined]: "Reddedildi",
      [LeaveStatusEum.Resend]: "Yeniden Gönder",
      [LeaveStatusEum.InActive]: "Pasif",
      [LeaveStatusEum.NotRequired]: "Gerekli Değil",
      [LeaveStatusEum.Cancelled]: "İptal Edildi",
      [LeaveStatusEum.Hold]: "Beklemede",
    },
  },
  loan: {
    loan: "Kredi",
    createLoan: "Kredi Oluştur",
    loanTenure: "Kredi Süresi (Ay Olarak)",
    enterLoanTenure: "Kredi Süresini Girin (Ay Olarak)",
    deduction: "Kesinti/Ay",
    selectLoanPurpose: "Kredi Amacını Seçin",
    noDataMessage: "– Şimdi Krediler Oluşturun!",
    SortBy: "Göre sırala",
    SelectSortBy: "Göre Sırala Seçin",
    InterestRate: "Faiz Oranı",
    loanPurpose: {
      loanPurpose: "Kredi Amaçı",
      vehicle: "Araç",
      personal: "Kişisel",
      wedding: "Düğün",
      medical: "Tıbbi",
      education: "Eğitim",
      house: "Ev",
      other: "Diğer",
    },
    loanPurposeEnumList: {
      [LoanPurposeEnum.Personal]: "Kişisel",
      [LoanPurposeEnum.Wedding]: "Düğün",
      [LoanPurposeEnum.Medical]: "Tıbbi",
      [LoanPurposeEnum.Education]: "Eğitim",
      [LoanPurposeEnum.House]: "Ev",
      [LoanPurposeEnum.Vehicle]: "Araç",
      [LoanPurposeEnum.Other]: "Diğer",
    },
    loanFilterTypeEnum: {
      [LoanFilterTypeEnum.All]: "Tüm Krediler",
      [LoanFilterTypeEnum.ForApproval]: "Onay İçin",
      [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "Benim Kredilerim",
      [LoanFilterTypeEnum.LoanForMe]: "Benim İçin Kredi",
    },
    statusEnum: {
      [LoanStatusEnum.InProcess]: "İşlemde",
      [LoanStatusEnum.Approved]: "Onaylandı",
      [LoanStatusEnum.Declined]: "Reddedildi",
      [LoanStatusEnum.Cancelled]: "İptal Edildi",
      [LoanStatusEnum.Disbursed]: "Ödenen",
      [LoanStatusEnum.PartialCleared]: "Kısmen Temizlendi",
      [LoanStatusEnum.Completed]: "Tamamlandı",
      [LoanStatusEnum.Hold]: "Beklemede",
      [LoanStatusEnum.WaitingForDisburse]: "Ödeme Bekleniyor",
    },
    loanTypeEnum: {
      [LoanTypeEnum.Company]: "Şirket Kredisi",
      [LoanTypeEnum.WorkWise]: "Workwise kredi",
    },
  },
  quotations: {
    Quotation: "Teklif",
    CreateQuotation: "Teklif Oluştur",
    quotationDate: "Tarih",
    ClientName: "Müşteri Adı",
    ClientEmail: "Müşteri E-Posta",
    PhoneNumber: "Telefon Numarası",
    TotalAmount: "Toplam Tutar",
    QuotationType: "Tür",
    Rate: "Fiyat",
    Quantity: "Miktar",
    Attachments: "Ekler",
    serviceItem: "Hizmet/Ürün",
    price: "Fiyat",
    tax: "Vergi (%)",
    taxAmount: "Vergi Tutarı",
    sno: "Sıra No.",
    EnterClientName: "Müşteri Adını Girin",
    clientsName: "Müşteri Adı",
    EnterClientEmail: "Müşteri E-postasını Girin",
    addressLine1: "Adres Satırı 1",
    addressLine2: "Adres Satırı 2",
    EnterAddress1: "Adresi Girin 1:",
    EnterAddress2: "Adresi Girin 2:",
    Product: "Ürün",
    Service: "Hizmet",
    netAmount: "Net Tutar",
    CODE: "KOD",
    DATE: "TARİH",
    TOTAL: "TOPLAM",
    To: "Kime",
    SubTotal: "Ara Toplam",
    noDataMessage: "– Şimdi Teklif Oluşturun!",
    quotationFilterTypeEnum: {
      [QuotationFilterEnum.All]: "Tüm Teklifler",
      [QuotationFilterEnum.CreatedByMe]: "Benim Tarafımdan Oluşturulanlar",
      [QuotationFilterEnum.ForApproval]: "Onay İçin",
    },
    statusEnum: {
      [QuotationStatusEnum.InProcess]: "İşlemde",
      [QuotationStatusEnum.Approved]: "Onaylandı",
      [QuotationStatusEnum.Declined]: "Reddedildi",
      [QuotationStatusEnum.Cancelled]: "İptal Edildi",
      [QuotationStatusEnum.Hold]: "Beklemede",
      [QuotationStatusEnum.ClientApproved]: "Müşteri tarafından onaylandı",
      [QuotationStatusEnum.ClientRejected]: "Müşteri tarafından reddedildi",
    },
    SortEnum: {
      [QuotationFilterSortEnum.CreateDateDesc]: "Oluşturma Tarihi - Azalan",
      [QuotationFilterSortEnum.CreateDateAsc]: "Oluşturma Tarihi - Artan",
      [QuotationFilterSortEnum.UpdateDateDesc]: "Güncelleme Tarihi - Azalan",
      [QuotationFilterSortEnum.UpdateDateAsc]: "Güncelleme Tarihi - Artan",
      [QuotationFilterSortEnum.ReferenceNo]: "Referans Numarası",
      [QuotationFilterSortEnum.ReferenceNoDesc]: "Referans Numarası - Azalan"
    }

  },
  payroll: {
    Payroll: "Maaş Bordrosu",
    createPayroll: "Maaş Bordrosu Oluştur",
    noDataMessage: "– Şimdi Maaş Bordrosu Oluşturun!",
    basicSalary: "Temel Maaş",
    loan: "Kredi",
    allowance: "Ödenek",
    ExpenseReimbursement: "Masraf İade",
    deduction: "Kesinti",
    tax: "Vergi",
    rebate: "İndirim",
    bonus: "Bonus",
    selectMonthYear: "Ay ve Yıl Seçin",
    dispereseDate: "Dağıtım Tarihi",
    Voucher: "Fiş",
    DownloadtoExcel: "Excel'e İndir",
    PayrollDetail: "Maaş Bordro Detayı",
    TransactionMode: "İşlem Modu",
    IBANNumber: "IBAN Numarası",

    statusEnum: {
      [PayrollStatusEnum.InProcess]: "İşlemde",
      [PayrollStatusEnum.Approved]: "Onaylandı",
      [PayrollStatusEnum.Declined]: "Reddedildi",
      [PayrollStatusEnum.Resend]: "Yeniden Gönder",
      [PayrollStatusEnum.Inactive]: "Pasif",
      [PayrollStatusEnum.NotRequired]: "Gerekli Değil",
      [PayrollStatusEnum.Cancelled]: "İptal Edildi",
      [PayrollStatusEnum.Hold]: "Beklemede",
      [PayrollStatusEnum.Disbursed]: "Ödenen",
      [PayrollStatusEnum.Completed]: "Tamamlandı",
    },
    payrollFilterTypeEnum: {
      [PayrollFilterTypeEnum.All]: "Maaş Bordrosu",
      [PayrollFilterTypeEnum.CreatedByMe]: "Tarafımdan Oluşturulan",
      [PayrollFilterTypeEnum.ForApproval]: "Onay İçin",
    },
  },
  voucher: {
    createVoucher: "Fiş Oluştur",
    SelectVoucherType: "Fiş Türünü Seçin",
    BasicSalary: "Temel Maaş",
    LoanPayment: "Kredi Ödemesi",
    LoanDispersable: "Dağıtılabilir Kredi",
    Allowance: "İzin",
    ExpenseReimbursement: "Gider İadesi",
    Deduction: "Kesinti",
    VoucherDetail: "Fiş Detayı",
    SavePrint: "Kaydet ve Yazdır",
    Bonus: "Bonus",
    Rebate: "İndirim",
    Tax: "Vergi",
    ChequeNo: "Çek Numarası",
    Account: "Hesap",
    Narration: "Anlatım",
    Debit: "Borç",
    Credit: "Kredi",
    Dr: "Borç",
    Cr: "Kredi",
    differences: "Farklar",
    drCr: "Borç/Kredi",
    LinkVoucher: "Fişi Bağla",
    PostVoucher: "Fişi Gönder",
    voucherDate: "Fiş Tarihi",
    VoucherType: "Fiş Türü",
    voucherTypeList: {
      [voucherTypeEnum.GeneralVoucher]: "Genel Fiş",
      [voucherTypeEnum.PaymentVoucher]: "Ödeme Fişi",
      [voucherTypeEnum.ReceiptVoucher]: "Alındı Fişi",
    },
  },
  trialBalance: {
    accountTypeList: {
      [accountTypeEnum.Asset]: "Varlık",
      [accountTypeEnum.Capital]: "Sermaye",
      [accountTypeEnum.Expense]: "Gider",
      [accountTypeEnum.Liability]: "Yükümlülük",
      [accountTypeEnum.Revenue]: "Gelir",
    },
  },
  disperse: {
    transactionModeEnumList: {
      [TransactionModeTypeEnum.Cash]: "Nakit",
      [TransactionModeTypeEnum.BankTransfer]: "Banka Transferi",
      [TransactionModeTypeEnum.Cheque]: "Çek",
    },
  },
  warnings: {
    inprogress: "Devam Ediyor",
    filter: "Filtre",
    approvers: "Onaylar",
    createwarnings: "Uyarı Oluştur",
  },
  complains: {
    createComplain: "Şikayet Oluştur",
    myComplain: "Şikayetlerim",
    AllComplains: "Tüm Şikayetler",
    complainOf: "Şikayet Hakkında",
    ComplainDetail: "Şikayet Detayı",
    ComplainToMe: "Bana Şikayet",
    ComplainAboutMe: "Benim Hakkımda Şikayet",
    noDataMessage: " - Şikayetlerinizi şimdi oluşturun!",
    statusEnum: {
      [ComplainApprovalStatusEnum.InProcess]: "İşlemde",
      [ComplainApprovalStatusEnum.Approved]: "Onaylandı",
      [ComplainApprovalStatusEnum.Declined]: "Reddedildi",
      [ComplainApprovalStatusEnum.Resend]: "Yeniden Gönder",
      [ComplainApprovalStatusEnum.Inactive]: "Etkin Değil",
      [ComplainApprovalStatusEnum.NotRequired]: "Gerekli Değil",
      [ComplainApprovalStatusEnum.Cancelled]: "İptal Edildi",
      [ComplainApprovalStatusEnum.Hold]: "Beklemede",
    },
    SortEnum: {
      [ComplainFilterSortEnum.CreateDateDesc]: "Oluşturulma Tarihi - Azalan",
      [ComplainFilterSortEnum.CreateDateAsc]: "Oluşturulma Tarihi",
      [ComplainFilterSortEnum.UpdateDateDesc]: "Güncelleme Tarihi - Azalan",
      [ComplainFilterSortEnum.UpdateDateAsc]: "Güncelleme Tarihi",
      [ComplainFilterSortEnum.ReferenceNo]: "Referans Numarası",
      [ComplainFilterSortEnum.ReferenceNoDesc]: "Referans Numarası - Azalan"
    }

  },
  salary: {
    SalaryMembers: "Maaş Üyeleri",
    EffectiveDate: "Efektif Tarih",
    BasicSalary: "Temel Maaş",
    Allowances: "İzinler",
    Allowance: "İzin",
    AllowanceDetail: "İzin Detayı",
    IsTaxable: "Vergilendirilebilir mi",
    Deduction: "Kesinti",
    GrossSalary: "Brüt Maaş",
    CreateSalary: "Maaş Oluştur",
    SalaryDetail: "Maaş Detayı",
    AddSalary: "Maaş Ekle",
    Deductions: "Kesintiler",
    AllowanceType: "İzin Türü",
    EffectiveDateIsRequired: "Efektif Tarih Gerekli",
    BasicSalaryIsRequired: "Temel Maaş Gerekli",
    Negativesalaryisnotallowed: "Negatif maaşa izin verilmiyor",
    Benefit: "Fayda",
    noDataMessage: " - Şimdi Maaş Oluştur!",
    EnterIncrementPercentage: "Lütfen Artış Yüzdesini Girin",
    salaryFilterTypeEnum: {
      [SalaryFilterTypeEnum.Salaries]: "Maaşlar",
      [SalaryFilterTypeEnum.CreateByMe]: "Benim Tarafımdan Oluşturulan",
      [SalaryFilterTypeEnum.ForApproval]: "Onay İçin",
    },
    statusEnum: {
      [EmployeeSalaryStatusEnum.InProcess]: "İşlemde",
      [EmployeeSalaryStatusEnum.Approved]: "Onaylandı",
      [EmployeeSalaryStatusEnum.Declined]: "Reddedildi",
      [EmployeeSalaryStatusEnum.Cancelled]: "İptal Edildi",
      [EmployeeSalaryStatusEnum.Hold]: "Beklemede",
      [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "Diğer Onay İçin Beklemede",
    },
    SortEnum: {
      [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "Oluşturma Tarihi - Azalan",
      [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "Oluşturma Tarihi - Artan",
      [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "Güncelleme Tarihi - Azalan",
      [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "Güncelleme Tarihi - Artan",
      [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "Referans Numarası - Azalan",
      [EmployeeSalaryFilterSortEnum.ReferenceNo]: "Referans Numarası"
    }

  },
  resignations: {
    resignationHeader: "İstifalar",
    resignationDate: "İstifa Tarihi",
    createresignation: "İstifa Oluştur",
    noDataMessage: "– Şimdi İstifa Oluşturun!",
    reasonforresignation: "İstifa Nedeni",
    istermination: "Sonlandırma mı",
    resignationDetail: "İstifa Detayı",
    statusEnum: {
      [ResignationApprovalStatusEnum.InProcess]: "İşlemde",
      [ResignationApprovalStatusEnum.Approved]: "Onaylandı",
      [ResignationApprovalStatusEnum.Declined]: "Reddedildi",
      [ResignationApprovalStatusEnum.Cancelled]: "İptal edildi",
      [ResignationApprovalStatusEnum.Completed]: "Tamamlandı",
      [ResignationApprovalStatusEnum.Hold]: "Beklemede",
    },
    resignationFilterTypeEnum: {
      [ResignationFilterTypeEnum.Resignation]: "İstifa",
      [ResignationFilterTypeEnum.CreateByMe]: "Benim Tarafımdan Oluşturulan",
      [ResignationFilterTypeEnum.ForApproval]: "Onay İçin",
    },
    resignationTypeEnum: {
      [ResignationTypeEnum.Resignation]: "İstifa",
      [ResignationTypeEnum.Termination]: "Sonlandırma",
    },
  },
  Employees: {
    employeeAttachments: "Çalışan Ekleri",
    CreateEmployeeNow: "Şimdi Çalışan Oluştur",
    ProbationPeriod: "Deneme Süresi (Gün)",
    requiredMessageProbation: "Lütfen deneme sürenizi girin",
    requiredMessageProbationPeriod: "Deneme süresi 0 ile 30 arasında olmalıdır",
    EmployeeNo: "Çalışan Numarası",
    EnterEmployeeNo: "Çalışan Numarasını Girin",
    EmploymentType: "İstihdam Türü",
    SelectEmploymentType: "İstihdam Türünü Seçin",
    requiredMessageEmploymentType: "Lütfen istihdam türünü seçin",
    OfficeShift: "Ofis Vardiyası",
    requiredMessageOfficeShift: "Lütfen Ofis Vardiyasını Seçin",
    SelectOfficeShift: "Ofis Vardiyasını Seçin",
    UpdateBasicInfo: "Temel Bilgileri Güncelle",
    Degree: "Derece",
    EnterDegree: "Dereceyi Girin",
    requiredDegree: "Lütfen Dereceyi Girin",
    Institute: "Enstitü",
    EnterInstituteName: "Enstitü Adını Girin",
    requiredMessageInstitute: "Lütfen Enstitü Adını Girin",
    ObtainedMarks: "Alınan Notlar",
    requiredMessageObtained: "Lütfen Alınan Notları Girin",
    EnterObtainedMarks: "Alınan Notları Girin",
    TotalMarks: "Toplam Notlar",
    EnterTotalMarks: "Toplam Notları Girin",
    requiredMessageMarks: "Lütfen Toplam Notları Girin",
    UpdateEducation: "Eğitimi Güncelle",
    AddEducation: "Eğitim Ekle",
    WorkExperienceInfo: "İş Deneyimi Bilgisi",
    UpdateExperience: "Deneyimi Güncelle",
    AddExperience: "Deneyim Ekle",
    EmergencyInfo: "Acil Durum Bilgisi",
    UpdateEmergencyForm: "Acil Durum Formunu Güncelle",
    AddEmergency: "Acil Durum Ekle",
    UpdateEmergency: "Acil Durumu Güncelle",
    UpdateBank: "Banka Bilgisini Güncelle",
    AddBank: "Banka Ekle",
    EnableEmployee: "Bu çalışanı etkinleştirmek istediğinizden emin misiniz?",
    DisableEmployee: "Bu çalışanı devre dışı bırakmak istediğinizden emin misiniz?",
    Enable: "Etkinleştir",
    Disabled: "Devre Dışı",
    UpdateFamily: "Aileyi Güncelle",
    AddFamily: "Aile Ekle",
    AddEmployeesLinkage: "Çalışan Bağlantısı Ekle",
    AllEmployees: "Tüm Çalışanlar",
    FamilyInfo: "Aile Bilgisi",
    Basic_Info: "Temel Bilgiler",
    Email_Configuration: "E-posta Yapılandırması",
    Bank_Detail: "Banka Detayı",
    Education: "Eğitim",
    Emergency_Info: "Acil Durum Bilgisi",
    Work_Experience: " İş Deneyimi",
    Experience: "Deneyim",
    Rebate: "İndirim",
    Salary: "Maaş",
    Leaves: "İzinler",
    Devices: "Cihazlar",
    basicInfo: "Temel Bilgiler",
    Appraisal: "Değerlendirme",
    Family: "Aile",
    Attachments: "Ekler",
    My_Team: "Benim Takımım",
    Employees_Linkage: "Çalışan Bağlantısı",
    Emails: "E-postalar",
    Finance: "Finans",
    Goal: "Hedefler",
    RebateInfo: "İndirim Bilgisi",
    UpdateRebate: "İndirimi Güncelle",
    AddRebate: "İndirim Ekle",
    AddSalary: "Maaş Ekle",
    Statistics: "İstatistikler",
  },
  loanDictionary: {
    createLoan: "Filtre5",
    loanTenureInMonths: "Filtre6",
    deductionPerMonth: "Filtre7",
    pleaseEnterloanTenureInMonths: "Filtre8",
  },
  /*** HR Menu ***/
  employees: {
    placeholders: {
      fName: "Ad Girin",
      lName: "Soyadı Girin",
      fatherName: "Baba Adı Girin",
      email: "E-posta Girin",
      pEmail: "Kişisel E-posta Girin",
      phNo: "Telefon Numarasını Girin",
      rAddress: "Konut Adresini Girin",
      pAddress: "Kalıcı Adres Girin",
      cnicNo: "CNIC Numarasını Girin",
      searchToSelect: "Seçmek İçin Ara",
      probPeriod: "Soyadı Girin0",
      noticePeriod: "Soyadı Girin1",
      selectDate: "Soyadı Girin2",
      selectGender: "Soyadı Girin3",
      selectMarital: "Soyadı Girin4",
      selectShift: "Soyadı Girin5",
      empNo: "Soyadı Girin6",
      empType: "Soyadı Girin7",
      selectUserType: "Soyadı Girin8",
      selectAccessRole: "Soyadı Girin9",
      degree: "Baba Adı Girin0",
      institute: "Baba Adı Girin1",
      desc: "Baba Adı Girin2",
      tMarks: "Baba Adı Girin3",
      oMarks: "Baba Adı Girin4",
      sDate: "Baba Adı Girin5",
      eDate: "Baba Adı Girin6",
      position: "Baba Adı Girin7",
      name: "Baba Adı Girin8",
      address: "Baba Adı Girin9",
      number: "E-posta Girin0",
      selectRelation: "E-posta Girin1",
      bankName: "E-posta Girin2",
      accTitle: "E-posta Girin3",
      branchCode: "E-posta Girin4",
      accNo: "E-posta Girin5",
      iban: "E-posta Girin6",
      sortCode: "E-posta Girin7",
    },
    EmployeeForm: {
      AddImage: "E-posta Girin8",
      FirstName: "E-posta Girin9",
      LastName: "Kişisel E-posta Girin0",
      FatherName: "Kişisel E-posta Girin1",
      Email: "Kişisel E-posta Girin2",
      PersonalEmail: "Kişisel E-posta Girin3",
      CNICNumber: "Kişisel E-posta Girin4",
      rAddress: "Kişisel E-posta Girin5",
      pAddress: "Kişisel E-posta Girin6",
      PhoneNumber: "Kişisel E-posta Girin7",
      Designation: "Kişisel E-posta Girin8",
      Manager: "Kişisel E-posta Girin9",
      Grades: "Telefon Numarasını Girin0",
      Department: "Telefon Numarasını Girin1",
      Country: "Telefon Numarasını Girin2",
      City: "Telefon Numarasını Girin3",
      Languages: "Telefon Numarasını Girin4",
      ProbationPeriod: "Telefon Numarasını Girin5",
      NoticePeriod: "Telefon Numarasını Girin6",
      DateOfBirth: "Telefon Numarasını Girin7",
      DateOfJoining: "Telefon Numarasını Girin8",
      Gender: "Telefon Numarasını Girin9",
      MaritalStatus: "Konut Adresini Girin0",
      OfficeShift: "Konut Adresini Girin1",
      EmploymentType: "Konut Adresini Girin2",
      UserType: "Konut Adresini Girin3",
      AccessRole: "Konut Adresini Girin4",
      EmpNo: "Konut Adresini Girin5",
    },
    EmergencyForm: {
      EmergencyInfo: "Konut Adresini Girin6",
      Designation: "Kişisel E-posta Girin8",
      Address: "Konut Adresini Girin7",
      Number: "Konut Adresini Girin8",
      Relation: "Konut Adresini Girin9",
      Name: "Kalıcı Adres Girin0",
    },

    EducationForm: {
      EducationInfo: "Eğitim Bilgileri",
      Degree: "Derece",
      Institute: "Enstitü",
      StartEndDate: "Başlangıç/Bitiş Tarihi",
      StartDate: "Başlangıç ​​Tarihi",
      Description: "Açıklama",
      TotalMarks: "Toplam Puanlar",
      ObtainedMarks: "Alınan Notlar",
      Present: "Mevcut",
      Attachments: "Ekler",
      AddMoreEducation: "Derece0",
      Upload: "Derece1",
      City: "Derece2",
    },
    WorkExperienceForm: {
      WorkExperienceInfo: "Derece3",
      Employer: "Derece4",
      Position: "Derece5",
      Designation: "Derece6",
      Department: "Derece7",
      StartEndDate: "Başlangıç/Bitiş Tarihi",
      StartDate: "Başlangıç ​​Tarihi",
      Present: "Mevcut",
      AddMoreExperience: "Derece8",
      EmploymentType: "Derece9",
      City: "Derece2",
    },
    UserForm: {
      UserRights: "Enstitü0",
      UserType: "Enstitü1",
      UserRole: "Enstitü2",
    },
    BankForm: {
      BankInfo: "Enstitü3",
      BankName: "Enstitü4",
      AccountTitle: "Enstitü5",
      BranchCode: "Enstitü6",
      AccountNumber: "Enstitü7",
      IBAN: "Enstitü8",
      SortCode: "Enstitü9",
      City: "Derece2",
      Country: "Başlangıç/Bitiş Tarihi0",
      AddMoreBank: "Başlangıç/Bitiş Tarihi1",
    },

    EmployeeList: {
      number: "Sayı",
      email: "E-posta",
      designation: "Tanımlama",
    },
    submit: "Gönder",
  },
  bonus: {
    Bonus: "Bonus",
    createBonus: "Bonus Oluştur",
    BonusTo: "Bonus Hedefi",
    CurrentSalary: "Mevcut Maaş",
    noDataMessage: "– Şimdi Bonus Oluştur!",
    PercentageShouldNotExceed: "Yüzde 100'ü aşmamalıdır",
    bonusFilterTypeEnum: {
      [BonusFilterTypeEnum.All]: "Tüm Bonuslar",
      [BonusFilterTypeEnum.CreatedByMe]: "Tarafımdan Oluşturulanlar",
      [BonusFilterTypeEnum.ForApproval]: "Onay İçin",
      [BonusFilterTypeEnum.MyBonus]: "Benim Bonuslarım",
    },
    statusEnum: {
      [BonusStatusEnum.InProcess]: "İşlemde",
      [BonusStatusEnum.Approved]: "Onaylandı",
      [BonusStatusEnum.Declined]: "Reddedildi",
      [BonusStatusEnum.Cancelled]: "İptal Edildi",
      [BonusStatusEnum.Inactive]: "Aktif Değil",
      [BonusStatusEnum.NotRequired]: "Gerekli Değil",
      [BonusStatusEnum.Resend]: "Yeniden Gönder",
      [BonusStatusEnum.WaitingForOtherApproval]: "Diğer Onayları Bekliyor",
      [BonusStatusEnum.Hold]: "Beklemede",
      [BonusStatusEnum.Disbursed]: "Ödenen",
    },
  },
  chartOfAccount: {
    ChartOfAccount: "Hesap Tablosu",
    CreateAccount: "Hesap Oluştur",
    CreateChartOfAccount: "Hesap Tablosu Oluştur",
    accountType: "Hesap Türü",
    selectType: "Türü Seç",
    parentAccount: "Ana Hesap",
    selectParent: "Ana Hesabı Seç",
    EnterAccountType: "Lütfen Hesap Türünü Girin",
    clossingBalance: "Kapanış Bakiyesi",
  },
  ledgerReports: {
    reports: "Raporlar",
    selectAccount: "Hesap Seç",
    selectDate: "Tarih Seç",
    generateReport: "Rapor Oluştur",
    VoucherNo: "Fiş Numarası",
  },
  requisitions: {
    requisition: "Talepler",
    MyRequisitions: "Taleplerim",
    createRequisition: "Talep Oluştur",
    PleaseEnterRequisitionName: "Lütfen Talep Adını Girin",
    EnterRequisitionName: "Talep Adını Girin",
    Budget: "Bütçe",
    EnterBudget: "Bütçe Girin",
    Dateofrequestexpire: "Talep Son Tarihi",
    noDataMessage: "– Şimdi Talepler Oluşturun!",
  },
  rewards: {
    rewards: "Filtre5",
    PleaseEnterAwardName: "Filtre6",
    EnterAwardName: "Filtre7",
    EnterAwardReason: "Filtre8",
  },
  DefaultApprovers: {
    TypeEnum: {
      [DefaultApprovalTypeEnum.Expense]: "Masraf",
      [DefaultApprovalTypeEnum.ExpenseFinancers]: "Masraf Finansörleri",
      [DefaultApprovalTypeEnum.Travel]: "Seyahat",
      [DefaultApprovalTypeEnum.TravelAgent]: "Seyahat Acentesi",
      [DefaultApprovalTypeEnum.Loan]: "Kredi",
      [DefaultApprovalTypeEnum.Leave]: "İzin",
      [DefaultApprovalTypeEnum.Asset]: "Varlık",
      [DefaultApprovalTypeEnum.Salary]: "Maaş",
      [DefaultApprovalTypeEnum.Payroll]: "Maaş Bordrosu",
      [DefaultApprovalTypeEnum.Reward]: "Ödül",
      [DefaultApprovalTypeEnum.ResignationHr]: "İstifa İK",
      [DefaultApprovalTypeEnum.ResignationAdmin]: "İstifa Yönetici",
      [DefaultApprovalTypeEnum.ResignationIt]: "İstifa BT",
      [DefaultApprovalTypeEnum.ResignationFinance]: "İstifa Finans",
      [DefaultApprovalTypeEnum.ResignationExit]: "İstifa Çıkış",
      [DefaultApprovalTypeEnum.Requisition]: "Talep",
      [DefaultApprovalTypeEnum.CustomApproval]: "Özel Onay",
      [DefaultApprovalTypeEnum.Forms]: "Formlar",
      [DefaultApprovalTypeEnum.Appraisals]: "Değerlendirmeler",
      [DefaultApprovalTypeEnum.Promotion]: "Terfi",
      [DefaultApprovalTypeEnum.Warning]: "Uyarı",
      [DefaultApprovalTypeEnum.Bonus]: "Bonus",
      [DefaultApprovalTypeEnum.Complains]: "Şikayetler",
      [DefaultApprovalTypeEnum.Career]: "Kariyer",
      [DefaultApprovalTypeEnum.Quotations]: "Teklifler",
      [DefaultApprovalTypeEnum.RequisitionFinal]: "Son Talep",
    },
  },
  NonMandatoryApprovers: {
    TypeEnum: {
      [NonMandatoryApprovalTypeEnum.Expense]: "Masraf",
      [NonMandatoryApprovalTypeEnum.Travel]: "Seyahat",
      [NonMandatoryApprovalTypeEnum.Loan]: "Kredi",
      [NonMandatoryApprovalTypeEnum.Leave]: "İzin",
      [NonMandatoryApprovalTypeEnum.Asset]: "Varlık",
      [NonMandatoryApprovalTypeEnum.Salary]: "Maaş",
      [NonMandatoryApprovalTypeEnum.Payroll]: "Maaş Bordrosu",
      [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "Masraf Finansörleri",
      [NonMandatoryApprovalTypeEnum.TravelAgent]: "Seyahat Acentesi",
      [NonMandatoryApprovalTypeEnum.Reward]: "Ödül",
      [NonMandatoryApprovalTypeEnum.ResignationHr]: "İstifa İK",
      [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "İstifa Yönetici",
      [NonMandatoryApprovalTypeEnum.ResignationIt]: "İstifa BT",
      [NonMandatoryApprovalTypeEnum.ResignationFinance]: "İstifa Finans",
      [NonMandatoryApprovalTypeEnum.ResignationExit]: "İstifa Çıkış",
      [NonMandatoryApprovalTypeEnum.Requisition]: "Talep",
      [NonMandatoryApprovalTypeEnum.Warning]: "Uyarı",
      [NonMandatoryApprovalTypeEnum.Complain]: "Şikayet",
      [NonMandatoryApprovalTypeEnum.Bonus]: "Bonus",
      [NonMandatoryApprovalTypeEnum.Promotion]: "Terfi",
      [NonMandatoryApprovalTypeEnum.RequestForItem]: "Öğe Talebi",
      [NonMandatoryApprovalTypeEnum.CustomApproval]: "Özel Onay",
      [NonMandatoryApprovalTypeEnum.Form]: "Form",
      [NonMandatoryApprovalTypeEnum.Document]: "Belge",
      [NonMandatoryApprovalTypeEnum.Career]: "Kariyer",
      [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "Talep Son Onayı",
      [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "Öğe Talebi Varlık Kontrolcüsü",
      [NonMandatoryApprovalTypeEnum.Quotation]: "Teklif",
      [NonMandatoryApprovalTypeEnum.QuotationClient]: "Teklif Müşterisi",
      [NonMandatoryApprovalTypeEnum.Appraisal]: "Değerlendirme",
    }
  },
  activityLog: {
    DeviceType: "Cihaz Türü",
    DeviceToken: "Cihaz Jetonu",
    OSVersion: "İşletim Sistemi Sürümü",
    Device: "Cihaz",
    IP: "IP",
    Comment: "Yorum",
    DeviceName: "Cihaz Adı",
    LoginDate: "Giriş Tarihi",
  },
  ErrorMessage: {
    someThingWentWrong: "Bir şeyler yanlış gitti"
  },
  setting: {
    BasicInformation: "Temel Bilgiler",
    ChangePassword: "Şifre Değiştir",
    Notifications: "Bildirimler",
    Mobile: "Mobil #",
    About: "Hakkında",
    ExistingPassword: "Mevcut Şifre",
    EnterExistingPassword: "Mevcut Şifreyi Girin",
    NewPassword: "Yeni Şifre",
    EnterNewPassword: "Yeni Şifreyi Girin",
    ConfirmPassword: "Şifreyi Onayla",
    EnterConfirmPassword: "Şifreyi Onaylayın",
    Signature: "Belge İmzası",
    NoSignature: "Lütfen imza oluşturun",
    ErrorSignature: "İmza Oluşturulurken Hata",
    NoSignatureSave: "Kaydedilecek imza yok",
    SignatureSaved: "İmza Kaydedildi",
    SaveSignature: "İmzayı Kaydet",
    ClearSignature: "İmzayı Temizle",
    SignatureCreated: "İmza Oluşturuldu",
    SignatureActive: "Aktif",
    FailedSignature: "İmzalar alınamadı",
    BlockNotificationFeatureTypeEnum: {
      [NotificationBlockFeatureTypeEnum.Reward]: "Ödül",
      [NotificationBlockFeatureTypeEnum.Feed]: "Besleme",
      [NotificationBlockFeatureTypeEnum.Document]: "Belge",
      [NotificationBlockFeatureTypeEnum.Project]: "Proje",
      [NotificationBlockFeatureTypeEnum.Task]: "Görev",
      [NotificationBlockFeatureTypeEnum.WorkBoard]: "Çalışma Panosu",
      [NotificationBlockFeatureTypeEnum.Schedule]: "Zamanlama",
      [NotificationBlockFeatureTypeEnum.Group]: "Grup",
      [NotificationBlockFeatureTypeEnum.Messenger]: "Mesajlaşma",
      [NotificationBlockFeatureTypeEnum.Expense]: "Harcama",
      [NotificationBlockFeatureTypeEnum.Auction]: "Açık artırma",
      [NotificationBlockFeatureTypeEnum.Leave]: "İzin",
      [NotificationBlockFeatureTypeEnum.Travel]: "Seyahat",
      [NotificationBlockFeatureTypeEnum.Warning]: "Uyarı",
      [NotificationBlockFeatureTypeEnum.Complain]: "Şikayet",
      [NotificationBlockFeatureTypeEnum.Bonus]: "Bonus",
      [NotificationBlockFeatureTypeEnum.Promotion]: "Tanıtım",
      [NotificationBlockFeatureTypeEnum.Loan]: "Kredi",
      [NotificationBlockFeatureTypeEnum.CustomApproval]: "Özel Onay",
      [NotificationBlockFeatureTypeEnum.Form]: "Form",
      [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "Çalışan Maaşı",
      [NotificationBlockFeatureTypeEnum.Payroll]: "Maaş Bordrosu",
      [NotificationBlockFeatureTypeEnum.Career]: "Kariyer",
      [NotificationBlockFeatureTypeEnum.Requisition]: "Talep",
      [NotificationBlockFeatureTypeEnum.Resignation]: "İstifa",
      [NotificationBlockFeatureTypeEnum.Quotation]: "Alıntı",
      [NotificationBlockFeatureTypeEnum.Appraisal]: "Değerlendirme",
      [NotificationBlockFeatureTypeEnum.LeadManager]: "Lider Yönetici",
      [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "Tartışma Panosu",
      [NotificationBlockFeatureTypeEnum.Pages]: "Sayfalar",
    }
  },
  emailConfiguration: {
    emailConfiHeader: "Kullanıcı E-posta Yapılandırması",
    emailConfi: "Kullanıcı Adı",
    password: "Parola",
    enterEmailConfi: "Kullanıcı Adını Girin",
    incomingPort: "Gelen Port",
    enterIncomingPort: "Gelen Portu Girin",
    incomingServerAddress: "Gelen Sunucu Adresi",
    enterIncomingServerAddress: "Gelen Sunucu Adresini Girin",
    outgoingPort: "Giden Port",
    enterOutgoingPort: "Giden Portu Girin",
    outgoingServerAddress: "Giden Sunucu Adresi",
    enterOutgoingServerAddress: "Giden Sunucu Adresini Girin",
    provider: "Sağlayıcı",
    enterProvider: "Sağlayıcıyı Girin",
    saveEmailConfi: "E-posta Yapılandırmasını Kaydet",
    addEmailConfi: "E-posta Yapılandırması Ekle",
    name: "Ad",
    clear: "Temizle",
  },
  assetsAllocation: {
    assetsAllocation: "Varlık Tahsisi",
    assests: "Varlıklar",
    createAssetAllocation: "Varlık Tahsisi Oluştur",
    handover: "Devir",
    forMaintenance: "Bakım İçin",
    noResultFound: "Sonuç Bulunamadı...",
    addAssetsAllocation: "Varlık Tahsisi Ekle",
    noDataMessage: "– Şimdi Varlık Tahsisi Oluşturun!",
    allocationForMe: "Benim İçin Tahsis",
    allocationApprovals: "Tahsis Onayları",
    deAllocation: "Tahsis Durumunu Değiştir",
    liability: "Yükümlülük",
    capital: "Sermaye",
    revenue: "Gelir",
    expense: "Gider",
    costOfGoodsale: "Satışın Maliyeti",
    assetsCategory: "Varlık Kategorisi",
    parentAccount: "Ana Hesap",
    selectParent: "Ana Seç",
    assetsList: "Varlık Listesi",
    createAssests: 'Varlık Oluştur',
    sno: 'S.No',
    inventoryName: 'Envanter Adı',
    inventoryValue: 'Envanter Değeri',
    SelectAssetsController: "Varlık Kontrolörünü Seç",
  },
  requestListItems: {
    noDataMessage: "– Şimdi Talep Oluşturun!",
    requestForItems: "Eşya Talebi",
    RequestForItemsApprovals: "Eşya Talebi Onayları",
    AssetController: "Varlık Kontrolörü",
    RequestItem: "Talep Eşyası",
  },
  profile: {
    firstMenu: '  1. menü öğesi',
    secondMenu: '  2. menü öğesi (devre dışı)',
    thirdMenu: '  3. menü öğesi (devre dışı)',
    dangerItem: 'tehlikeli öğe',
    NoPosition: "Pozisyon Yok",
    NoInstitute: "Kurum Yok",
    NotFound: "Bulunamadı",
    University: "Üniversite",
    School: "Okul",
    Graduated: "Mezun",
    CurrentlyWorkHere: "Şu anda burada çalışıyorum",
    Statistics: "İstatistikler",
    StorageStatistics: "Depolama İstatistikleri",
    TotalStorageUsed: "Toplam Kullanılan Depolama",
  },
  /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***/
  status: {
    InProcess: "İşlemde",
    Approved: "Onaylandı",
    Declined: "Reddedildi",
    Resend: "Yeniden Gönder",
    Inactive: "Etkin Değil",
    NotRequired: "Gerekli Değil",
    Cancelled: "İptal Edildi",
    ApprovalRequired: "Onay Gerekli",
    Hold: "Beklemede",
    WaitingForOtherApprover: "Diğer Onay Bekleyenler",
    Draft: "Taslak",
    Remark: "Not",
    Removed: "Kaldırıldı"
  },
  approvalDictionaryLabel: {
    Approval: "Onay",
    [ApprovalsModuleEnum.ExpenseExecutor]: "Yürütücü",
    [ApprovalsModuleEnum.ExpenseFinance]: "Finans",
    [ApprovalsModuleEnum.ResignationHrApproval]: "İK",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finans",
    [ApprovalsModuleEnum.ResignationItApproval]: "IT",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "Yönetici",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "Diğer Onaylar",
    [ApprovalsModuleEnum.ResignationExitApproval]: "Çıkış Görüşmesi",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "Raporlama",
    [ApprovalsModuleEnum.TravelApproval]: "Seyahat Onayları",
    [ApprovalsModuleEnum.TravelAgent]: "Acente",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "Son Onaylar",
    [ApprovalsModuleEnum.LoanApproval]: "Onaylar",
    [ApprovalsModuleEnum.AuctionApproval]: "Onaylar"

  },
  approvalDictionaryPlaceHolder: {
    Approval: "Onay",
    [ApprovalsModuleEnum.ExpenseExecutor]: "Harcamanın Yürütücüsünü Seçin",
    [ApprovalsModuleEnum.ExpenseFinance]: "Finanstan Onaylayanları Seçin",
    [ApprovalsModuleEnum.ExpenseApproval]: "Gider Onaylayıcılarını Seçin",
    [ApprovalsModuleEnum.ResignationHrApproval]: "İK'yi Seçin",
    [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finansı Seçin",
    [ApprovalsModuleEnum.ResignationItApproval]: "IT'yi Seçin",
    [ApprovalsModuleEnum.ResignationAdminApproval]: "Yöneticiyi Seçin",
    [ApprovalsModuleEnum.ResignationOtherApproval]: "Diğer Onayları Seçin",
    [ApprovalsModuleEnum.ResignationExitApproval]: "Çıkış Görüşmesini Seçin",
    [ApprovalsModuleEnum.ResignationReportingToApproval]: "Raporlama Kişisini Seçin",
    [ApprovalsModuleEnum.TravelApproval]: "Seyahat İçin Onaylayıcıları Seçin",
    [ApprovalsModuleEnum.TravelAgent]: "Seyahat Düzenleyicisini Seçin",
    [ApprovalsModuleEnum.RequisitionFinalApproval]: "Son Onaylayıcıları Seçin",
    [ApprovalsModuleEnum.LoanApproval]: "Onaylayıcıları Seçin",
    [ApprovalsModuleEnum.AuctionApproval]: "Onaylar"

  },
  // *** Extra label *** //
  /*** Define language write side ***/
  Direction: "ltr",
};
export default turkish;
