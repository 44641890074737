import moment from 'moment';

const getTaskProgressColor = (startDate, endDate, progress) => {
  const now = moment();
  const start = moment(startDate).startOf("day");
  const end = moment(endDate).endOf("day");
  const totalDuration = end.diff(start, "days", true);
  const elapsedDuration = now.diff(start, "days", true);

  const timeProgress = elapsedDuration / totalDuration; 
  const normalizedProgress = progress / 100;

  const progressDifference = timeProgress - normalizedProgress;

  console.log("task progress calculation", totalDuration, elapsedDuration, timeProgress,normalizedProgress,progressDifference);


  if (normalizedProgress >= 1) {
    // Task is completed
    return { "0%": "#2ecc71", "100%": "#27ae60" }; // light Green to dark Green
  } else if (now.isAfter(end)) {
    // Past due
    return { "0%": "#e83030", "100%": "#e83030" }; //  red
  } else if (progressDifference > 0.25) {
    // Significantly behind schedule
    return { "0%": "#df0000", "100%": "#ffff00" }; // Red to yellow
  } else if (progressDifference > 0) {
    // Slightly behind schedule
    return { "0%": "#ffff00", "100%": "#2ecc71" }; // yellow to green
  } else if (progressDifference < -0.1) {
    // Ahead of schedule
    return { "0%": "#526bb1", "30%": "#2ecc71" }; // theme blue to dark Green
  } else {
    // On track
    return { "0%": "#526bb1", "100%": "#90EE90" }; // theme blue to Light Green
  }
};

export default getTaskProgressColor;