import { ApprovalsModuleEnum } from "../../utils/enum/approvalModuleEnum";
import { Button, Form } from "antd";
import SuggestionBox from "../../../../sharedComponents/SuggestionBox/View/SuggestionBox";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import ApprovalModuleLabel, { ApprovalModulePlaceHolder } from "../approvalModuleLabel";
import { approvalDictionaryList } from "../../localization";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { STRINGS } from "../../../../../utils/base";
import { useDispatch, useSelector } from "react-redux";
import { ExpenseReferenceTypeEnum } from "../../../expense/utils/expenseReferenceTypeEnum";
import { SuggestionTypeEnum } from "../../../../sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import { geAllManagersService } from "../../../employee/services/service";
import { TravelReferenceTypeEnum } from "../../../travel/utils/travelReferenceTypeEnum";
import { QuotationReferenceTypeEnum } from "../../../quotation/utils/quotationReferenceTypeEnum";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { PlusOutlined , DeleteOutlined} from "@ant-design/icons";

function ApprovalComposer({
    module = ApprovalsModuleEnum.ExpenseApproval,
    defaultApprovals = [],// Default Approvals  -- List of Reference object
    isNonMandatoryApproval = false,
    referenceId = STRINGS.DEFAULTS.guid, //Group projects etc Ids
    referenceType = 0,
    users = [], // --List of uniqueidentifiers -- You can pass existing users Id in this
    setManager = true,
    form,
    onChange = () => { },
    isMultiSelect = true,
}) {

    const { userLanguage } = useContext(LanguageChangeContext);
    const { Direction } = dictionaryList[userLanguage];
    const label = ApprovalModuleLabel(module);
    const selectPlaceHolder = ApprovalModulePlaceHolder(module)
    const { user } = useSelector((state) => state.userSlice);
    const singleMember = [
        ApprovalsModuleEnum.TravelAgent,
    ]
    let isSingleMember = singleMember.includes(module) && defaultApprovals.length && defaultApprovals?.[0]?.id !== user.id;
   
    const [formItems, setFormItems] = useState([{
        key: 0, 
        approvals: [] 
    }]);

    const prepareSuggestionTypes = () => {
        switch (module) {
            case ApprovalsModuleEnum.ExpenseApproval:
            case ApprovalsModuleEnum.ExpenseExecutor:
            case ApprovalsModuleEnum.ExpenseFinance:
                switch (referenceType) {
                    case ExpenseReferenceTypeEnum.Group:
                        return [SuggestionTypeEnum.GroupMember]
                    case ExpenseReferenceTypeEnum.Project:
                        return [SuggestionTypeEnum.ProjectMember]
                    default:
                        return [SuggestionTypeEnum.Employee]
                }
            case ApprovalsModuleEnum.TravelApproval:
                switch (referenceType) {
                    case TravelReferenceTypeEnum.Project:
                        return [SuggestionTypeEnum.ProjectMember]
                    default:
                        return [SuggestionTypeEnum.Employee]
                }
            case ApprovalsModuleEnum.TravelAgent:
                switch (referenceType) {
                    case TravelReferenceTypeEnum.Project:
                        return [SuggestionTypeEnum.ProjectMember, SuggestionTypeEnum.TravelAgents]
                    default:
                        return [SuggestionTypeEnum.Employee, SuggestionTypeEnum.TravelAgents]
                }
            case ApprovalsModuleEnum.QuotationApproval:
                switch (referenceType) {
                    case QuotationReferenceTypeEnum.Project:
                        return [SuggestionTypeEnum.ProjectMember]
                    default:
                        return [SuggestionTypeEnum.Employee]
                }

            default:
                return [SuggestionTypeEnum.Employee]
        }
    }

    const [state, setState] = useState({
        suggestionTypes: prepareSuggestionTypes(),
        users: users,
        referenceId: referenceId,
        referenceType: 0,
        isNonMandatoryApproval: isNonMandatoryApproval,
        isMultiSelect: isMultiSelect,
        defaultApprovals: [],
        removeableMembers: [],
        nonRemoveableMembers: [],
        approvals: [], //Approvals which are selected -- List of REFERENCE object
        nonRequiredMembers: [], // Members which are not required in suggestion box -- List of Guid
        defaultApprovalsRemoveAble:[]
    });

    const setManagers = async () => {
        let result = [];
        if (setManager) {
            if (user.managerId !== STRINGS.DEFAULTS.guid && user.managerId !== null)
                result.push({
                    id: user.managerId,
                    name: user.managerName,
                    image: user.managerImage,
                    type: 1 //Need to change with the object key type from Reference type
                });

            if (users.length > 0) {
                //Filter the users except the login user;
                const distinctManagers = users.filter((x) => x !== user.id);
                if (distinctManagers.length > 0) {
                    //Get All managers of selected users
                    await geAllManagersService(distinctManagers).then(({ data }) => {
                        if (data.length > 0) {
                            if (result.length > 0)
                                result = result.concat(data.filter((x) => x.id !== user.id && result[0].id !== x.id));
                            else
                                result = data;
                        }
                    });
                }
            }
        }
        if (module === ApprovalsModuleEnum.ResignationReportingToApproval)
            console.log(result, "MANAGER_LIST");
        return result;
    }
    const prepareDefaultApprovals = async (approvals = []) => {
        let tempDefaultApprovals = approvals;
        await setManagers().then((data) => {

            tempDefaultApprovals = tempDefaultApprovals.concat(data);
            let distinctMemberIds = [];
            tempDefaultApprovals = tempDefaultApprovals.filter((member) => {
                if (!distinctMemberIds.includes(member.id) && !users.includes(member.id) && member.id !== user.id) {
                    distinctMemberIds.push(member.id);
                    return member;
                }
            });
            return tempDefaultApprovals;
        });
        if (module === ApprovalsModuleEnum.ResignationReportingToApproval)
            console.log(tempDefaultApprovals, "tempDefaultApprovals");
        return tempDefaultApprovals;

    };
    useEffect(() => {
        const approvers = state.approvals.length > 0 ? state.approvals.map(({ id, type }) => {
            return { approverId: id, approverType: type }
        }) : []



        console.log(state.approvals, "approvers-composer")
        form.setFieldsValue({
            [module]: state.approvals
        });


        // onChange(state.approvals);
    }, [state.approvals])

    const onChangeSuggestionBox = (members, index) => {
        

        let finalSelection = [];
        
        members.forEach((member) => {
            const memberWithLevel = { ...member, levelNo: index+1 };
            if (!finalSelection.find(x => x.id === member.id) || state.nonRemoveableMembers.find(x => x.id === member.id)) {
                finalSelection.push(memberWithLevel);
            }
        });
        
        // Update the specific form item's approvals with the new selection
        const updatedFormItems = formItems.map((item, idx) =>
            idx === index ? { ...item, approvals: finalSelection } : item
        );
        
        // Combine all approvals from form items into one array
        let allApprovals = [];
        updatedFormItems.forEach((item) => {
            allApprovals = allApprovals.concat(item.approvals);
        });
    
        // Update state with the new approvals and form items
        setFormItems(updatedFormItems);
    
        // Merge nonRemoveableMembers with the current approvals (avoiding duplicates)
        const updatedApprovals = state.nonRemoveableMembers.concat(
            allApprovals.filter(
                approval => !state.nonRemoveableMembers.find(nonRem => nonRem.id === approval.id)
            )
        );
    
        setState({
            ...state,
            removeableMembers: finalSelection,
            approvals: updatedApprovals,  // Update with merged approvals
        });
    };
    

    const addFormItem = () => {

        
        const newFormItem = { key: formItems.length, approvals: [] };

        // Update the formItems state to include the new form item
        const updatedFormItems = [...formItems, newFormItem];
    
        // Update the levelNo for each form item
        updatedFormItems.forEach((item, index) => {
            item.levelNo = index + 1;
        });
        
        // Update state with default approvals for new form items
        const defaultApprovalRemoveAble = state.defaultApprovalsRemoveAble.map(item => ({ ...item, levelNo: updatedFormItems.length }));
        const defaultApprovalNonRemovAble = state.nonRemoveableMembers.map(item => ({ ...item, levelNo: updatedFormItems.length }));
        
        const formItemsWithUpdatedApprovals = updatedFormItems.map(item => ({
            ...item,
            approvals: item.approvals.filter(approval => !defaultApprovalRemoveAble.find(defaultApproval => defaultApproval.id === approval.id))
        }));
    
        // Add default approvals to the new form item
        formItemsWithUpdatedApprovals[formItemsWithUpdatedApprovals.length - 1] = {
            key: formItemsWithUpdatedApprovals.length - 1,
            approvals: defaultApprovalRemoveAble
        };
    
        // Update state
        setFormItems(formItemsWithUpdatedApprovals);

        
        const allApprovals = updatedFormItems.flatMap(item => item.approvals);

    setState({
        ...state,
        users: users,
        nonRemoveableMembers: defaultApprovalNonRemovAble,
        defaultApprovalsRemoveAble: defaultApprovalRemoveAble,
        approvals: allApprovals.concat(defaultApprovalNonRemovAble) // Combine with existing approvals
    });


        // const clearedFormItems = formItems.map(item => ({
        //     ...item,
        //     approvals: [] // Empty approvals for each form item
        // }));

        // const updatedFormItems = [
        //     ...clearedFormItems,
        //     { key: formItems.length, approvals: [] }
        // ];

        // const newLevelNo = updatedFormItems.length;

        // const defaultApprovalRemoveAble = state.defaultApprovalsRemoveAble.map(item => ({ ...item, levelNo: newLevelNo }))
        // const defaultApprovalNonRemovAble = state.nonRemoveableMembers.map(item => ({ ...item, levelNo: newLevelNo }))

        // updatedFormItems[updatedFormItems.length - 1] = {
        //     key:updatedFormItems.length - 1,
        //     approvals:defaultApprovalRemoveAble
        // }


        // setFormItems(updatedFormItems);

        // setState({
        //     ...state,
        //     users: users,
        //     nonRemoveableMembers: defaultApprovalNonRemovAble,
        //     defaultApprovalsRemoveAble:defaultApprovalRemoveAble,
        //     approvals: defaultApprovalNonRemovAble.concat(defaultApprovalRemoveAble)
        // });
    };

    const removeFormItem = (key) => {

        
        // const formItemToDelete = formItems.find(item => item.key === key);
        
        const updatedFormItems = formItems
        .filter(item => item.key !== key)
        .map((item, index) => ({ ...item, key: index, approvals: item.approvals.map(approval => ({ ...approval, levelNo: index + 1 })) }));

    // Update state
        setFormItems(updatedFormItems);

        const allApprovals = updatedFormItems.flatMap(item => item.approvals);

        // const updatedFormItems = formItems
        //     .filter(item => item.key !== key)
        //     .map((item, index) => ({ approvals: [], key: index })); // Renumber the keys
    
        // const updatedApprovals = state.approvals.filter(
        //     approval => !formItemToDelete.approvals.find(itemApproval => itemApproval.id === approval.id)
        // );
    
        const newLevelNo = updatedFormItems.length;

        const defaultApprovalRemoveAble = state.defaultApprovalsRemoveAble.map(item => ({ ...item, levelNo: newLevelNo }))
        const defaultApprovalNonRemovAble = state.nonRemoveableMembers.map(item => ({ ...item, levelNo: newLevelNo }))

        // updatedFormItems[updatedFormItems.length - 1] = {
        //     key:updatedFormItems.length - 1,
        //     approvals:defaultApprovalRemoveAble
        // }


        // setFormItems(updatedFormItems);

        setState({
            ...state,
            users: users,
            nonRemoveableMembers: defaultApprovalNonRemovAble,
            defaultApprovalsRemoveAble:defaultApprovalRemoveAble,
            approvals: defaultApprovalNonRemovAble.concat(allApprovals)
        });
    };
    
    


    useEffect(() => {

        // if(users.length > 0){
        console.log(users, "USERSSSSSSSSS");
        prepareDefaultApprovals(defaultApprovals).then((baseDefaultApprovals) => {
            let removeAble = [];
            let nonRemoveAble = [];
            let nonRequiredMembers = [...users];
            const suggestions = baseDefaultApprovals
            .filter((x) => !nonRequiredMembers.includes(x.id))
            .map((suggestion) => ({
                ...suggestion,
                levelNo: 1, 
            }));

            //This module does not require of the relevant creator
            if (isNonMandatoryApproval)
                removeAble = [...suggestions];
            else
                nonRemoveAble = [...suggestions];

            const formItms = [...formItems]
            
            formItms[0] = {
                key:0,
                approvals:removeAble
            }

            setFormItems(formItms)

            setState({
                ...state,
                users: users,
                defaultApprovals: baseDefaultApprovals,
                isNonMandatoryApproval: isNonMandatoryApproval,
                nonRemoveableMembers: nonRemoveAble,
                removeableMembers: removeAble,
                defaultApprovalsRemoveAble:removeAble,
                nonRequiredMembers: nonRequiredMembers,
                approvals: nonRemoveAble.concat(removeAble)
            });
        });
        // }
    }, [users]);



    console.log(state, module,formItems, "CURRENT_STATE_APPROVAL_COMPOSER");
   
    return (
        <>
            {formItems.map((item, index) => {
                // Compute excluded IDs for this form item

                const excludedIds = [
                    ...state.nonRequiredMembers,
                    ...state.nonRemoveableMembers.map(m => m.id),
                    ...formItems.reduce((acc, curr, idx) => {
                        if (idx !== index) {
                            acc.push(...curr.approvals.map(a => a.id));
                        }
                        return acc;
                    }, [])
                ];

                return (
                    <Form.Item
                        key={item.key}
                        name={index === formItems.length - 1 ? module : `${module}_${item.key}`} // Use unique name for each Form.Item
                        required={false}
                        direction={Direction}
                        style={{ marginBottom: "0px" }}
                        className="custom-label"
                        label={
                            <div className="flex items-center" style={{ justifyContent: "space-between", width: "100%" }}>
                                <span>
                                {index === formItems.length - 1 
                                    ? !state.isNonMandatoryApproval 
                                        ? <span className="text-red-500 mr-[4px]">*</span>
                                        : null
                                    : <span className="text-red-500 mr-[4px]">*</span>
                                }
                                    
                                    {label} {formItems.length > 1 ? ` - Level ${index + 1}` : ''}
                                </span>
                                <div style={{ marginLeft: "auto" }}>
                                    {index === formItems.length - 1 ? (
                                        ![ApprovalsModuleEnum.TravelAgent, 
                                        ApprovalsModuleEnum.ResignationAdminApproval,
                                        ApprovalsModuleEnum.ResignationExitApproval,
                                        ApprovalsModuleEnum.ResignationFinanceApproval,
                                        ApprovalsModuleEnum.ResignationHrApproval,
                                        ApprovalsModuleEnum.ResignationItApproval,
                                        ApprovalsModuleEnum.ResignationOtherApproval,
                                        ApprovalsModuleEnum.ResignationReportingToApproval,
                                    ].includes(module) &&  formItems.length < 5 &&  (
                                            <Button
                                              className="addSubTaskBtn"
                                              icon={<PlusOutlined />}
                                              onClick={addFormItem}
                                            >
                                              <span className="!ml-[2px]">Add Level</span>
                                            </Button>
                                          )
                                    ) : (
                                        <Button
                                            className="addSubTaskBtn"
                                            onClick={() => removeFormItem(item.key)}
                                            icon={<DeleteOutlined />}
                                        >
                                            <span className="!ml-[2px]">Delete</span>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        }
                        rules={[
                            {
                                required: index === formItems.length - 1 ? !state.isNonMandatoryApproval : item.approvals.length === 0,
                                message: `${label} is required`,
                            }
                        ]}
                    >

                        <SuggestionBox
                            placeholder={selectPlaceHolder}
                            initialData={isSingleMember ? [] : item.approvals}
                            nonRemoveable={index === formItems.length - 1 ? state.nonRemoveableMembers : []}
                            notRequiredMemberiDs={excludedIds}
                            suggestionType={state.suggestionTypes}
                            isMultiSelect={state.isMultiSelect}
                            removeSelf={true}
                            referenceId={state.referenceId}
                            callback={(members) => {
                                console.log(members, `onChange_Approval_Composer ${module}`)

                                onChangeSuggestionBox(members, index);
                            }}
                        />
                    </Form.Item>
                )
            })}

        </>
    )

}
export default memo(ApprovalComposer);