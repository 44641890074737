import React, { useState, useEffect } from "react";
import useDebounce from "../../../../../utils/Shared/helper/use-debounce";
import { Select, Avatar } from "antd";

const { Option } = Select;

const CitySelect = ({
  cities,
  selectedCity,
  onSelectCity,
  loading,
  disabled,
  onPageChange,
  onSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleScroll = (event) => {
    const target = event.target;
    const atBottom =
      target.scrollTop + target.offsetHeight + 5 > target.scrollHeight;
    if (atBottom && !loading) onPageChange((prevPageNo) => prevPageNo + 1);
  };

  return (
    <Select
      showSearch
      value={
        selectedCity
          ? `${selectedCity.name} - ${selectedCity.country}`
          : undefined
      }
      onChange={(value) =>
        onSelectCity(cities.find((city) => city.name === value))
      }
      onPopupScroll={handleScroll}
      onSearch={(value) => !loading && setSearchTerm(value)}
      filterOption={false}
      placeholder="Select a city"
      size="large"
      loading={loading}
      disabled={disabled}
    >
      {cities.map((city) => (
        <Option key={city.id} value={city.name}>
          <div className="flex items-center gap-1">
            <Avatar className="!bg-black !text-white">
              {city.name.charAt(0)}
            </Avatar>
            {`${city.name} - ${city.country}`}
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default CitySelect;
