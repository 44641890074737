import moment from 'moment';

const getTaskProgressStatus = (startDate, endDate, progress, createDate) => {
  const now = moment();
  const start = moment(startDate).startOf("day");
  const end = moment(endDate).endOf("day");
  const totalDuration = end.diff(start, "days", true);
  const elapsedDuration = now.diff(start, "days", true);
  const timeProgress = elapsedDuration / totalDuration;
  const normalizedProgress = progress / 100;

  const progressDifference = timeProgress - normalizedProgress;

  if (now.isAfter(end)) {
    return "Over Due";
  } else if (progress === 0 && timeProgress > 0.25) {
    return "Behind Schedule";
  }
  else if (progressDifference > 0.25) {
    return "Behind Schedule";
  }
  else if (progressDifference > 0 ) {
    return "Slightly Behind Schedule";
  }
  else if (progress === 0) {
    return ""; 
  }  else {
    return "On Track";
  }
};

export default getTaskProgressStatus;

export const TaskProgressStatusColor ={
    "Over Due" : "text-red-600	text-green-600",
    'Behind Schedule' : "text-amber-500	",
    "On Track": "text-green-600",
    "Slightly Behind Schedule": "text-lime-500",

  }