import React, { useContext, useEffect, useState } from "react";
import { Popconfirm, Progress, Rate } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { dictionaryList } from "../../../../../../../utils/localization/languages";
import {
  CalendarOutlined,
  TeamOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import UserInfo from "../../../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import Avatar from "../../../../../../sharedComponents/Avatar/avatar";
import { LanguageChangeContext } from "../../../../../../../utils/localization/localContext/LocalContext";
import { NotificationFeatureTypeEnum } from "../../../../../notifiation/enums";
import { readNotificationAction } from "../../../../../notifiation/store/action";
import {
  changeOnProgress,
  handleTaskRating,
  readCountforTask,
} from "../../../../store/taskSlice";
import { TaskPriorityEnum } from "../../../../utils/TaskPriorityEnum";
import { TaskStatusEnum } from "../../../../utils/TaskStatusEnum";
import { STRINGS } from "../../../../../../../utils/base";
import { TaskReferenceTypeEnum } from "../../../../utils/TaskReferenceTypeEnum";
import { priorityColors } from "../../../../utils/priorityColors";
import { postUserTaskRating } from "../../../../services/service";
import Attachments from "../../../../../travel/view/UI/Attachments";
import TaskMembers from "../../../UI/taskMembers";
import { ROUTES } from "../../../../../../../utils/routes";
import StatusTag from "../../../UI/statusTag";
import { cancelTaskAction } from "../../../../store/actions";
import ReferenceTypeTag from "../../../UI/referenceTypeTag";
import TagAvatar from "../../../../../../sharedComponents/Avatar/TagAvatar";
import ExpandableDescription from "../../../../../../sharedComponents/expandableDescription/expandableDescription";
import { userTaskTypeEnum } from "../../../../utils/TaskViewTypeEnum";
import convertLocalToUTC from "../../../../../../sharedComponents/DateHandler/dataLocaltoUTC";
import convertUTCtoLocal, {
  timeFromNow,
} from "../../../../../../sharedComponents/DateHandler/dateUTCtoLocal";
import getTaskProgressColor from "../../../../utils/TaskProgressColor";
import getTaskProgressStatus, {
  TaskProgressStatusColor,
} from "../../../../utils/TaskProgressStatus";

function TaskDetail({ item }) {
  const { userLanguage } = useContext(LanguageChangeContext);
  const { tasks, sharedLabels } = dictionaryList[userLanguage];
  const { taskPriorityEnum, taskReferenceTypeEnum } = tasks;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    initialProgress: item.progress,
    initialRating: item.rating,
    rating: 0,
  });
  const [isExpanded, setIsExpanded] = useState(false);

  const { user } = useSelector((state) => state.userSlice);
  let userId = user.id;

  const {
    id,
    subject = "",
    description = "",
    referenceNo = STRINGS.DEFAULTS.guid,
    ratingAssign = 0,
    priority = TaskPriorityEnum.Low,
    startDate = moment.now(),
    endDate = moment.now(),
    progress = 0,
    members = [],
    creator = {},
    status = TaskStatusEnum.NotStarted,
    attachments,
    predecessor,
    referenceId,
    referenceType = TaskReferenceTypeEnum.General,
    reference = {
      name: "",
    },
    notificationCount = 0,
    createDate = moment.now(),
    subTaskCount = 0,
    userTaskType = userTaskTypeEnum.Task,
  } = item;

  let taskProgressStatus = getTaskProgressStatus(
    startDate,
    endDate,
    progress ? progress : state.initialProgress,
    createDate
  );

  const handleRating = async (id, rating) => {
    await postUserTaskRating(id, rating);
    dispatch(handleTaskRating({ referenceId: id, data: rating }));
  };

  const handleProgressChange = (data) => {
    dispatch(changeOnProgress(data));
  };

  const handleCancel = (e, payload) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(cancelTaskAction(payload));
  };

  const handleClick = (event) => {
    if (reference?.id && reference?.id !== STRINGS.DEFAULT_GUID) {
      event.preventDefault();
      event.stopPropagation();

      let url;
      if (referenceType === TaskReferenceTypeEnum.Project) {
        url = `${ROUTES.PROJECT.DEFAULT}/${reference?.id}`;
      } else if (referenceType === TaskReferenceTypeEnum.Group) {
        url = `${ROUTES.GROUP.DEFAULT}/${reference?.id}`;
      }

      if (url) {
        window.open(url, "_blank");
      }
    }
  };

  useEffect(() => {
    if (notificationCount > 0) {
      const payload = {
        filter: {
          featureType: NotificationFeatureTypeEnum.Task,
          featureId: id,
        },
      };

      dispatch(readNotificationAction(payload)).then((response) => {
        dispatch(readCountforTask(id));
      });
    }
  }, [notificationCount]);

  return (
    <>
      <div className="card-list-item ">
        <div className="card-item-header">
          <div className="left">
            <UserInfo
              avatarSrc={creator?.image}
              name={creator?.name}
              status={creator?.userActiveStatus}
              profileId={creator?.id}
              Subline={
                <SublineDesigWithTime
                  designation={creator?.designation ? creator?.designation : ""}
                  time={timeFromNow(createDate)}
                />
              }
            />
          </div>
          <div className="right mt-1">
            <div className="labels">
              {userTaskType === userTaskTypeEnum.Goal && (
                <span className="taskID">{"Goal"}</span>
              )}
              <span className="taskID">{referenceNo}</span>
              <span
                className="priority "
                style={{ backgroundColor: priorityColors?.[priority] }}
              >
                {taskPriorityEnum?.[priority]}
              </span>
              {userId === creator?.id &&
                progress !== 100 &&
                status !== TaskStatusEnum.Completed &&
                status !== TaskStatusEnum.Cancel && (
                  <span
                    className="cancel-task ThemeBtn"
                    onClick={(e) => handleCancel(e, id)}
                  >
                    {sharedLabels.Cancel}
                  </span>
                )}
              {(status === TaskStatusEnum.Completed ||
                status === TaskStatusEnum.Cancel) && (
                <StatusTag status={status} />
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="card-item-body-main">
            <div className="card-item-body">
              <div className="left">
                <div
                  className="card-Title-Detail font-semibold mt-2"
                  onClick={handleClick}
                >
                  {subject}
                </div>
                <div className="description text-[14px] font-semibold	text-[#757d86]">
                  <ExpandableDescription
                    description={description}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                  />
                </div>
              </div>

              <div className="right !min-w-max">
                <div>
                  <ReferenceTypeTag
                    referenceId={referenceId}
                    referenceType={referenceType}
                    reference={reference}
                  />
                </div>
                <Popconfirm
                  title={sharedLabels.SuretoRate}
                  onConfirm={(e) => handleRating(id, state.rating)}
                  disabled={
                    ratingAssign > 0 ||
                    progress !== 100 ||
                    status === TaskStatusEnum.Cancel ||
                    userId !== creator?.id
                  }
                >
                  <div className="rating">
                    <Rate
                      value={ratingAssign}
                      disabled={
                        ratingAssign > 0 ||
                        progress !== 100 ||
                        status === TaskStatusEnum.Cancel ||
                        userId !== creator?.id
                      }
                      onChange={(value) => {
                        setState({ ...state, rating: value });
                      }}
                    />
                  </div>
                </Popconfirm>
                <div className="!w-max m-4 ml-auto">
                  <Attachments
                    data={attachments}
                    key={{ data: attachments }}
                    toShow={1}
                    onClick={() => {}}
                    size={"50px"}
                  />
                </div>
              </div>
            </div>
            <div>
              {(progress ? progress : state.initialProgress) !== 100 && (
                <div
                  className={`text-center text-[15px] font-bold taskProgressLable ${TaskProgressStatusColor?.[taskProgressStatus]} `}
                >
                  {taskProgressStatus}
                </div>
              )}
              <div>
                <Progress
                  strokeColor={getTaskProgressColor(
                    startDate,
                    endDate,
                    progress ? progress : state.initialProgress
                  )}
                  percent={progress ? progress : state.initialProgress}
                  status={
                    progress
                      ? progress
                      : state.initialProgress !== 100 && "active"
                  }
                />
              </div>
            </div>
            <div className="TaskCardFields mt-3 space-y-1">
              <div>
                <span className="taskFieldLabels gap-2">
                  <CalendarOutlined className="taskfields" />

                  {sharedLabels.Date}
                </span>
                <span className="taskFieldData">
                  {`${convertUTCtoLocal(
                    startDate,
                    "DD MMM YYYY"
                  )} - ${convertUTCtoLocal(endDate, "DD MMM YYYY")}`}
                </span>
              </div>
              <div className="flex items-end">
                <span className="taskFieldLabels gap-2">
                  <TeamOutlined className="taskfields" />
                  {tasks.AssignTo}
                </span>
                <span>
                  {members.length > 0 &&
                    (members.length === 1 ? (
                      <TagAvatar
                        text={members[0].member?.name}
                        img={members[0].member?.image}
                        className="!bg-transparent !pb-[5px] !px-[0px] !pt-[0px] !font-medium !text-black"
                      />
                    ) : (
                      <Avatar
                        isAvatarGroup={true}
                        isTag={false}
                        heading={"Members"}
                        membersData={members}
                      />
                    ))}
                </span>
              </div>

              {predecessor !== "" ? (
                <div>
                  <span className="taskFieldLabels gap-2">
                    <RollbackOutlined className="taskfields" />

                    {tasks.Predecessor}
                  </span>
                  <span className="taskFieldData">{predecessor}</span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="memberProgress font-semibold mt-4">
            {tasks.ProgressofAssignedmembers}
          </div>
          <div className="">
            {
              <TaskMembers
                members={members}
                status={status}
                isRating={ratingAssign !== 0}
                changeOnProgress={handleProgressChange}
                hasSubTasks={subTaskCount > 0}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskDetail;
