import React, { useState, useContext, useEffect } from "react";
import { Modal, Tag, Avatar } from "antd";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";
import AvatarOld from "../../../../sharedComponents/Avatar/avatarOLD";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import DestinationModal from "./SharePostDestinationModal";
import { STRINGS } from "../../../../../utils/base";
import { DestinationTagsColor, PostDestinationByPostRefType, PostTypeAction } from "../../utils/constants";
import { SharePostDestinations } from "../../utils/sharePostDestinationEnums";

const SharePostHeader = ({
  setReferenceId,
  setPostReferenceType,
  moduleReferenceType,
  modulePrivacyId,
  postRefType,
  postRefId,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [destinationText, setDestinationText] = useState("Feed");
  const [selectedDestination, setSelectedDestination] = useState(PostDestinationByPostRefType?.[postRefType]);
  const isExtraSmall = useMediaQuery({ query: "(max-width: 600px)" });

  const { userLanguage } = useContext(LanguageChangeContext);
  const { newsFeed } = dictionaryList[userLanguage];
  const { SharedestinationNames } = newsFeed;

  const [membersfromSuggestionBox, setmembersfromSuggestionBox] = useState([]);
  const [destinationDisabled, setDestinationDisabled] = useState(false);

  const {
    userSlice: {
      user: { name, userImage },
    },
  } = useSelector((state) => state);

  const handleFeedClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSelectDestination = (destination) => {
    setDestinationText(destination);
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (membersfromSuggestionBox.length > 0) {
      setDestinationText(
        `${membersfromSuggestionBox?.[0]?.name}${newsFeed.specificTimeline}`
      );
      setReferenceId(membersfromSuggestionBox?.[0]?.id);
    } else {
      setDestinationText(SharedestinationNames[SharePostDestinations.FEED]);
      setReferenceId(STRINGS.DEFAULTS.guid);
    }
  }, [membersfromSuggestionBox]);

  useEffect(() => {
    let { destination, disabled } = PostTypeAction(
      postRefType,
      SharedestinationNames
    );
    setDestinationText(destination);
    setDestinationDisabled(disabled);
    setReferenceId(postRefId);
    setSelectedDestination(PostDestinationByPostRefType?.[postRefType])
  }, [postRefType]);

  return (
    <>
      <div className="avatar-wrapper">
        <AvatarOld
          width={isExtraSmall ? 33 : 35}
          height={isExtraSmall ? 33 : 35}
          src={userImage}
          name={name}
          round
        />

        <div className="flex flex-col items-baseline">
          <h3 className="text-bold">{name}</h3>
          <Tag
            color={DestinationTagsColor?.[selectedDestination]}
            onClick={handleFeedClick}
            className={`${
              destinationDisabled
                ? "cursor-no-drop pointer-events-none"
                : "cursor-pointer"
            } !font-bold`}
          >
            {destinationText}
          </Tag>
        </div>
      </div>

      <DestinationModal
        visible={isModalVisible}
        onClose={handleModalClose}
        destinationText={destinationText}
        onSelect={handleSelectDestination}
        avatarProps={{
          src: userImage,
          name: name,
          width: 30,
          height: 30,
          round : true,
        }}
        membersfromSuggestionBox={membersfromSuggestionBox}
        setmembersfromSuggestionBox={setmembersfromSuggestionBox}
        setPostReferenceType={setPostReferenceType}
        destination = {selectedDestination}
      />
    </>
  );
};

export default SharePostHeader;
