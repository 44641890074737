import axios from "axios";
import { servicesUrls } from "./baseURLS";
import { getAuthToken } from "./AuthConfig";
const instance = axios.create({
  baseURL: servicesUrls.master,
  headers: {
    // "Content-Type": "application/json",
    "Timezone-Offset": -1 * new Date().getTimezoneOffset()
  },
});
export let store;
export const injectStore = (_store) => {
  store = _store;
};
instance.interceptors.request.use(
  (config) => {
    // Check if a custom token is provided in the config
    const customToken = config.customToken;
    if (customToken && customToken!== null) {
      // Use the custom token for this request only
      config.headers["Authorization"] = `Bearer ${customToken}`;
      // Remove the customToken property to avoid unintended side effects
      delete config.customToken;
    } else {
      // Fallback to the token from the Redux store if no custom token is provided
      const token = store.getState().userSlice.token;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        let d = await getAuthToken();
        const { accessToken } = d.data.data;

        instance.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
        originalConfig._retry = true;
        return instance(originalConfig);
        // return getAuthToken().then(res => {
        // 	// console.log(res, "getAuthToken");
        // 	const { accessToken, refreshToken } = res.data.data;
        // 	store?.dispatch(
        // 		updateAccessToken({ accessToken, refreshToken })
        // 	);
        // 	instance.defaults.headers[
        // 		"Authorization"
        // 	] = `Bearer ${accessToken}`;
        // 	originalConfig._retry = true;
        // 	return instance(originalConfig);
        // });
      }
    }
    if (err.response.data === "") return Promise.reject(err);
    return err.response;
  }
);

export default instance;
