import React, { useEffect, useState } from "react";
import ListView from "./listView";
import { useDispatch } from "react-redux";
import { getAllCareerAction } from "../../../careers/store/action";
import "./style.css";
import "../../../businessPolicy/view/businessPolicyMain/style.css";
import DetailedView from "./DetailedView";
import moment from "moment";
import { SegmentTypeEnum } from "../../../../sharedComponents/Segment/utils/enum";

function CareersListView() {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    identifier: moment().unix(),
    isLoading: true,
    filter: {
      filterType: null,
      search: "",
      pageNo: 1,
      pageSize: 20,
    },
  });

  //Todo call api for get all jobs
  useEffect(() => {
    const payload = {
      pageNo: 1,
      search: state.filter.search,
      filterType: 0,
      segmentType: SegmentTypeEnum.Grid,
      sortBy: 2,
    };
    dispatch(
      getAllCareerAction({
        filter: payload,
        identifier: state.identifier,
        segmentType: SegmentTypeEnum.Grid,
        pageSize: state.filter?.pageSize
      })
    ).then((res) => {
      setState({
        ...state,
        isLoading: false,
      })
    })
  }, [state.filter]);



  return (
    <div className="_careersMainListView ">
      <ListView identifier={state.identifier} loader={state.isLoading} />
      <DetailedView />
    </div>
  );
}

export default CareersListView;
