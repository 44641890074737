import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseType } from "../../../../../utils/api/ResponseResult";
// import { jsonToFormData, STRINGS } from "../../../../../../utils/base";
import { openNotification } from "../../../../../utils/Shared/store/slice";
import {
  getAllCompanyService,
  getCompanyByIdService,
  getAllRewardService,
  getAllLoanService,
  getAllComplainService,
  getAllSignupService,
  ResendSignupEmailService,
  GetSignupByIdService,
  updateBusinessInfoService,
  dashboardViewService,
  updateBusinessDisableStatusService,
  GetAllProductLoanSettingsService,
  SaveProductLoanSettingsService,
  GetPersonalLoanService,
  GetProductLoanSettingsService,
  DisbursedLoanService,
  AddBusinessBankDetailService,
  GetAllBusinessBankDetailService,
  SaveBusinessDetailService,
  getBusinessDetailService,
  GetOverallBusinessStorageDashboardService,
  GetOverallUserStorageDashboardService,
  GetAllAttachmentUploadSizeSettingService,
  SaveAttachmentUploadSizeSettingService,
} from "../services/service";
import { message } from "antd";
import {
  responseMessage,
  responseMessageType,
} from "../../../../../services/slices/notificationSlice";
import { responseCode } from "../../../../../services/enums/responseCode";
import { updateUser } from "../../../../../store/appReducer/userSlice";

export const getCompanyAction = createAsyncThunk(
  "getCompanyAction",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await getAllCompanyService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            // message: "Team added Successfully!",
            type: "success",
            //   duration: 2
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getCompanyByIdAction = createAsyncThunk(
  "getCompanyByIdAction",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await getCompanyByIdService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            type: "success",
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getRewardsAction = createAsyncThunk(
  "teamSlice/getRewardsAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllRewardService(id);
    console.log(id, "rewards action");
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            // message: "Team added Successfully!",
            type: "success",
            //   duration: 2
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getAllLoanAction = createAsyncThunk(
  "teamSlice/ getAllLoanAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllLoanService(id);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            // message: "Team added Successfully!",
            type: "success",
            //   duration: 2
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const ResendSignupEmailAction = createAsyncThunk(
  "Signup/ getAllLoanAction",
  async (id, { dispatch, setState, rejectWithValue }) => {
    const response = await ResendSignupEmailService(id);
    if (response.data.responseCode === 1001) {
      message.success("Request Sent");
      return response;
    } else {
      message.error(response.data.message);
      return rejectWithValue(response.data.message);
    }
    return response;
  }
);

export const GetSignupById = createAsyncThunk(
  "Signup/GetSignupById",
  async (id, { dispatch, setState, rejectWithValue }) => {
    const response = await GetSignupByIdService(id);
    if (response.data.responseCode === 1001) {
      return response;
    } else {
      message.error(response.data.message);
      return rejectWithValue(response.data.message);
    }
    return response;
  }
);

export const getAllComplainAction = createAsyncThunk(
  "teamSlice/ getAllComplainAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await getAllComplainService(id);
    // console.log(response, "complain SERVICE");
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            // message: "Team added Successfully!",
            type: "success",
            //   duration: 2
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const getAllSignupAction = createAsyncThunk(
  "teamSlice/ getAllSignupAction",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await getAllSignupService(payload);
    console.log(response, "getAllSignupAction");

    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            // message: "Team added Successfully!",
            type: "success",
            //   duration: 2
          })
        );
        return response.data;
      default:
        return;
    }
  }
);

export const updateBusinessAction = createAsyncThunk(
  "teamSlice/ updateBusinessAction",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await updateBusinessInfoService(payload);
    console.log(response, "getAllSignupAction");

    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: "Business Updated Successfully!",
            type: "success",
            //   duration: 2
          })
        );
        dispatch(updateUser({
          businessImage : response.data.logo,
          businessShortImage: response.data.shortLogo
        }))
        return response.data;
      default:
        return;
    }
  }
);

export const dashboardViewData = createAsyncThunk(
  "teamSlice/ getAllComplainAction",
  async (id, { rejectWithValue, dispatch }) => {
    const response = await dashboardViewService(id);
    // console.log(response, "complain SERVICE");
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        // dispatch(
        //   openNotification({
        // message: "Team added Successfully!",
        // type: "success",
        //   duration: 2
        // })
        // );
        return response.data;
      default:
        return;
    }
  }
);

export const updateBusinessDisableStatusAction = createAsyncThunk(
  "teamSlice/ updateBusinessAction",
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await updateBusinessDisableStatusService(payload);
    switch (response.type) {
      case ResponseType.ERROR:
        dispatch(
          openNotification({
            message: response.errorMessage,
            type: "error",
            //   duration: 2
          })
        );
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        dispatch(
          openNotification({
            message: "Business Disabled Successfully!",
            type: "success",
            //   duration: 2
          })
        );
        return response;
      default:
        return;
    }
  }
);

export const GetAllProductLoanSettings = createAsyncThunk(
  "loan/GetAllProductLoanSettings",
  async (args, { dispatch }) => {
    const res = await GetAllProductLoanSettingsService();
    return res;
  }
);

export const SaveProductLoanSettings = createAsyncThunk(
  "loan/SaveProductLoanSettings",
  async (args, { dispatch }) => {
    const res = await SaveProductLoanSettingsService(args);
    if (res.data.responseCode === responseCode.Success) {
      message.success(`${res?.data?.data?.name} updated successfully`);
    } else {
      message.error(`${res.data.message}`);
    }

    return res;
  }
);

export const SaveAttachmentUploadSizeSetting = createAsyncThunk(
  "Business/SaveAttachmentUploadSizeSetting",
  async (args, { dispatch }) => {
    const res = await SaveAttachmentUploadSizeSettingService(args);
    if (res.data.responseCode === responseCode.Success) {
      message.success(`${res?.data?.data?.businessName} updated successfully`);
    } else {
      message.error(`${res.data.message}`);
    }
    return res;
  }
);


export const GetPersonalLoanAction = createAsyncThunk(
  "loan/SaveProductLoanSettings",
  async (data, { rejectWithValue, dispatch }) => {
    const response = await GetPersonalLoanService(data);
    return response.data;
  }
);

export const GetProductLoanSettingsAction = createAsyncThunk(
  "loan/GetProductLoanSettings",
  async (id, { dispatch }) => {
    const res = await GetProductLoanSettingsService(id);
    if (res?.data?.responseCode === responseCode.Failure) {
      message.error(`${res.data?.message}`);
    }
    return res;
  }
);

export const GetAllAttachmentUploadSizeSettingAction = createAsyncThunk(
  "Business/GetAllAttachmentUploadSizeSetting",
  async (id, { dispatch }) => {
    const res = await GetAllAttachmentUploadSizeSettingService(id);
    if (res?.data?.responseCode === responseCode.Failure) {
      message.error(`${res.data?.message}`);
    }
    return res;
  }
);

export const DisbursedLoanAction = createAsyncThunk(
  "loan/DisbursedLoan",
  async (loanId, { rejectWithValue, dispatch }) => {
    const res = await DisbursedLoanService(loanId);
    if (res?.data?.responseCode === responseCode.Failure) {
      message.error(`${res.data?.message}`);
    } else if (res?.data?.responseCode === responseCode.Success) {
      dispatch(
        openNotification({
          message: "Disbursed Successfully!",
          type: "success",
          duration: 2,
        })
      );
      return res;
    }
  }
);


export const AddBusinessBankDetailAction = createAsyncThunk(
  "business/AddBusinessBankDetail",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await AddBusinessBankDetailService(data);
    if (res.data.responseCode === responseCode.Success) {
      // message.success(`${args.name} updated successfully`);
    } else {
      message.error(`${res.data.message}`);
    }
    return res;

  }
);

export const GetAllBusinessBankDetailAction = createAsyncThunk(
  "business/GetAllBusinessBankDetail",
  async (_, { rejectWithValue, dispatch }) => {
    const res = await GetAllBusinessBankDetailService();
    if (res.data.responseCode === responseCode.Success) {
      // message.success(`${args.name} updated successfully`);
    } else {
      message.error(`${res.data.message}`);
    }
    return res;

  }
);

export const SaveBusinessDetailAction = createAsyncThunk(
  "business/GetAllBusinessBankDetail",
  async (payload, { rejectWithValue, dispatch }) => {
    const res = await SaveBusinessDetailService(payload);
    if (res.data.responseCode === responseCode.Success) {
      // message.success(`${args.name} updated successfully`);
    } else {
      message.error(`${res.data.message}`);
    }
    return res;

  }
);

export const getBusinessDetailAction = createAsyncThunk(
  "business/getBusinessDetailAction",
  async (businessId, { rejectWithValue, dispatch }) => {
    const response = await getBusinessDetailService(businessId);
    switch (response.type) {
      case ResponseType.ERROR:
        return rejectWithValue(response.errorMessage);
      case ResponseType.SUCCESS:
        return response.data;
      default:
        return;
    }
  }
);
export const GetOverallBusinessStorageDashboardAction = createAsyncThunk(
  "business/GetOverallBusinessStorageDashboard",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await GetOverallBusinessStorageDashboardService(data);
    res.data.responseCode !== responseCode.Success &&
      message.error(`${res.data.message}`);
    return res.data;
  }
);

export const GetOverallUserStorageDashboardAction = createAsyncThunk(
  "business/GetOverallUserStorageDashboard",
  async (data, { rejectWithValue, dispatch }) => {
    const res = await GetOverallUserStorageDashboardService(data);
    res.data.responseCode !== responseCode.Success &&
      message.error(`${res.data.message}`);
    return res.data;
  }
);
