import { useContext, useEffect, useState } from "react";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { PayrollStatusEnum } from "../../utils/payrollStatusEnum";
import { STRINGS } from "../../../../../utils/base";
import { defaultUiid } from "../../../../../utils/Shared/enums/enums";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPayrollById } from "../../store/actions";
import { removeIdentifier } from "../../store/slice";
import moment from "moment";
import CustomModal from "../../../workboard/Modal/CustomModal";
import VoucherPrint from "../../../createVoucher/view/voucherPrintModal";
import { ItemHeader, SingleItem } from "../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "./statusTag";
import { Button, Tag } from "antd";
import Avatar from "../../../../sharedComponents/Avatar/avatar";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import EmployeesDetail from "./EmployeesDetail";
import { formatNumberWithCommas } from "../../../../../utils/Shared/helper/formatNumberWithCommas";
import Disperse from "../../../disperse/view/UI/Disperse";
import { TransactionReferenceTypeEnum } from "../../../createVoucher/enum/TransactionReferenceTypeEnum";
import PostVoucher from "../../../createVoucher/view/postVoucher/UI/PostVoucher";
import "../Page/style/style.css"
import formatLastOffer from "../../../auction/utils/formatOffer";
export default function PayrollDetailCard({ id, index }) {
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, payroll, voucher, ledgerReports } = dictionaryList[userLanguage];
    const [state, setState] = useState({
        identifier: moment().unix(),
        payrollDetail: {
            creator: {},
            approvers: [],
            notificationCount: 0,
            isLoading: true,
            status: PayrollStatusEnum.InProcess,
            details: [],
            month: 1,
            total: 0,
            year: 0,
            disburseDate: "",
            voucherNo: "",
            voucherId: STRINGS.DEFAULTS.guid,
        },
        approvers: {
            isOpen: false,
            data: [],
        },



    });
    const [isOpenModal, setIsOpenModal] = useState(false);

    // ---- REDUX - HOOK ----
    const dispatch = useDispatch();


    // --- Redux - Hooks 
    const { data } = useSelector((state) => state.payrollSlice);
    let payrollDetail = data !== null ? data[state.identifier] : [];
    payrollDetail =
        payrollDetail &&
        payrollDetail?.length > 0 &&
        payrollDetail[0];

    // ---- REACT - HOOK - USEFFECT -----
    useEffect(() => {
        id && dispatch(getPayrollById({ id, identifier: state.identifier }));
    }, [id])

    useEffect(() => {
        payrollDetail &&
            setState({
                ...state,
                payrollDetail: {
                    ...state.payrollDetail,
                    ...payrollDetail,
                    isLoading: false,
                },
                approvers: {
                    ...state.approvers,
                    data: payrollDetail?.approvers,
                },
            });
    }, [payrollDetail]);

    useEffect(() => {
        return () => {
            dispatch(removeIdentifier({ identifier: state.identifier }));
        };
    }, []);


    //close Approvers Modal
    const onCloseApproversModal = (status) => {
        setState({
            ...state,
            approvers: {
                ...state.approvers,
                isOpen: status,
            },
        });
    };

    const {
        creator,
        createDate,
        referenceNo,
        description,
        approvers,
        notificationCount,
        status,
        details,
        month,
        total,
        year,
        disburseDate = moment(),
        voucherNo,
        voucherId,
    } = state.payrollDetail;

    const handleOpenPrintVoucher = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpenModal(true);
    }
    return (<>
        {<CustomModal
            isModalVisible={isOpenModal}
            onCancel={() => setIsOpenModal(false)}
            width={"70%"}
            title={payroll.Voucher}
            footer={null}
            children={<VoucherPrint id={voucherId} />}
            className={""}
        />}
        <div className="payroll-card">
            <SingleItem>
                <ItemHeader>
                    <div className="left">
                        <UserInfo
                            avatarSrc={creator.image}
                            name={creator.name}
                            status={creator.userActiveStatus}
                            Subline={
                                <SublineDesigWithTime
                                    designation={
                                        creator.designation
                                            ? creator.designation
                                            : ""
                                    }
                                    time={moment(createDate).fromNow()}
                                />
                            }
                        />
                    </div>
                    <div className="right">
                        <Tag className="IdTag">{referenceNo}</Tag>
                        <StatusTag status={status}></StatusTag>

                    </div>
                </ItemHeader>
                <div className="description w-full pt-3 pb-5">
                    <p>{description}</p>
                </div>

                <div className="cardSections">
                    <div className="cardSectionItem">
                        <div className="cardSection__title">
                            {sharedLabels.Employee}
                        </div>
                        <div className="cardSection__body">
                            <Avatar
                                isAvatarGroup={true}
                                heading={sharedLabels.approvers}
                                membersData={details.map((item) => ({
                                    approver: item.user,
                                }))}
                            />
                        </div>
                    </div>
                    <div className="cardSectionItem">
                        <div className="cardSection__title">
                            {" "}
                            {payroll.disburseDate}
                        </div>
                        <div className="cardSection__body">
                            {moment(disburseDate).format("MMM Do YYYY")}
                        </div>
                    </div>
                    <div className="cardSectionItem">
                        <div className="cardSection__title"> {sharedLabels.Month}</div>
                        <div className="cardSection__body">
                            {moment(month, "M").format("MMMM")}
                        </div>
                    </div>
                    <div className="cardSectionItem">
                        <div className="cardSection__title">{sharedLabels.Year}</div>
                        <div className="cardSection__body">
                            {moment(year, "Y").format("YYYY")}
                        </div>
                    </div>
                    <div className="cardSectionItem">
                        <div className="cardSection__title">
                            {" "}
                            {sharedLabels.totalAmount}
                        </div>
                        <div className="cardSection__body">{total ? formatNumberWithCommas(formatLastOffer(total.toFixed(2))) : ""}</div>
                    </div>
                    {status === PayrollStatusEnum.Approved && (
                        <div className="cardSectionItem">
                            <div className="cardSection__title"> {sharedLabels.disburse}</div>
                            <div className="cardSection__body">
                                <Disperse
                                    label={sharedLabels.disburse}
                                    id={id}
                                    TransactionReferenceType={TransactionReferenceTypeEnum.Payroll}
                                />
                            </div>
                        </div>
                    )}

                    {
                        voucherId === STRINGS.DEFAULTS.guid && (status === PayrollStatusEnum.Disbursed || status === PayrollStatusEnum.Completed) && (<>
                            <div className="cardSectionItem">
                                <div className="cardSection__title"> {"Post Voucher"}</div>

                                <div className="cardSection__body">
                                    <div onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                        <PostVoucher
                                            label="Post Voucher"
                                            id={id}
                                            TransactionReferenceType={TransactionReferenceTypeEnum.Payroll}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>)
                    }

                    {voucherId !== STRINGS.DEFAULTS.guid && (
                        <div className="cardSectionItem">
                            <div className="cardSection__title"> {"Voucher No"}</div>
                            <div className="cardSection__body">
                                {voucherNo && <Button type="link" className="!text-[12px] !mt-[-6px] !text-[var(--currentThemeColor)] !font-semibold" onClick={(e) => handleOpenPrintVoucher(e)}
                                >{voucherNo}</Button>
                                }
                            </div>
                        </div>
                    )}
                </div>
                <EmployeesDetail details={details} />
                {/* Remarks Approval */}
                <RemarksApproval
                    module={ApprovalsModule.PayrollApproval}
                    status={status}
                    reference={payrollDetail?.id}
                    onStatusChanged={(prev) => { }}
                    setApprovalDetail={(data) => { }}
                    data={state.approvers.data && state.approvers.data}
                    title={sharedLabels.approvers}
                    itemCreator={creator}
                />
            </SingleItem>
        </div>
    </>)
}
