import { CustomApprovalFilterSortEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterSortEnum";
import { CustomApprovalFilterTypeEnum } from "../../../main/features/CustomApprovals/utils/customApprovalFilterTypeEnum";
import { CustomApprovalStatusEum } from "../../../main/features/CustomApprovals/utils/customApprovalStatusEnum";
import { AppraisalFilterSortEnum } from "../../../main/features/appraisalModule/utils/appraisalSortEnum";
import { AppraisalStatusEnum } from "../../../main/features/appraisalModule/utils/appraisalStatusEnum";
import { AppraisalFilterEnum } from "../../../main/features/appraisalModule/utils/appraisalsFilterTypeEnum";
import { ApprovalsModuleEnum } from "../../../main/features/approval/utils/enum/approvalModuleEnum";
import { AttendanceMetricTypeEnum } from "../../../main/features/attendance/utils/AttendanceMetricsTypeEnum";
import { AttendanceStateEnum } from "../../../main/features/attendance/utils/AttendanceStateEnum";
import { AttendanceStatusEnum } from "../../../main/features/attendance/utils/AttendanceStatusEnum";
import { AttendanceMoodEnum } from "../../../main/features/attendance/utils/AttendenceMoodEnum";
import { AttendanceFilterTypeEnum } from "../../../main/features/attendance/utils/attendanceFilterTypeEnum";
import { ATTENDANCE_ENUM } from "../../../main/features/attendance/utils/constant";
import { AuctionOfferStatusEnum, AuctionStatusEnum, auctionFilterTypeEnum } from "../../../main/features/auction/utils/enums";
import { BillingTypeEnum } from "../../../main/features/billing/utils/BillingTypeEnum";
import { BonusFilterSortEnum } from "../../../main/features/bonus/utils/bonusFilterSortEnum";
import { BonusFilterTypeEnum } from "../../../main/features/bonus/utils/bonusFilterTypeEnum";
import { BonusStatusEnum } from "../../../main/features/bonus/utils/bonusStatusEnum";
import { ApprovalStatusTypeEnum } from "../../../main/features/businessApproval/utils/StatusTypeEnum";
import { BuisnessNumberStatusEnum } from "../../../main/features/businessNumber/utils/BusinessNumberStatusEnum";
import { CareerInterviewStatusEnum } from "../../../main/features/careers/utils/CareerInterviewStatusEnum";
import { CareerFilterSortEnum } from "../../../main/features/careers/utils/careerFilterSortEnum";
import { CareerFilterTypeEnum } from "../../../main/features/careers/utils/careerFilterTypeEnum";
import { CareerLevelEnum } from "../../../main/features/careers/utils/careerLevelTypeEnum";
import { CareerStatusEnum } from "../../../main/features/careers/utils/careerStatusEnum";
import { JobShiftTypeEnums } from "../../../main/features/careers/utils/jobShiftTypeEnum";
import { JobTypeEnums } from "../../../main/features/careers/utils/jobTypeEnum";
import { ComplainFilterSortEnum } from "../../../main/features/complain/utils/complainFilterSortEnum";
import { ComplainApprovalStatusEnum } from "../../../main/features/complain/utils/complainStatusEnum";
import { ContractFilterTypeEnum } from "../../../main/features/contracts/utils/enums/ContractFilterTypeEnum";
import { ContractReferenceTypeEnum } from "../../../main/features/contracts/utils/enums/ContractReferenceTypeEnum";
import { ContractStatusEnum } from "../../../main/features/contracts/utils/enums/ContractStatusEnum";
import { accountTypeEnum } from "../../../main/features/createVoucher/utils/AccountTypeEnum";
import { voucherTypeEnum } from "../../../main/features/createVoucher/utils/voucherTypeEnum";
import { DefaultApprovalTypeEnum } from "../../../main/features/defaultApprovers/utils";
import { TransactionModeTypeEnum } from "../../../main/features/disperse/utils/transactionModeEnum";
import { DocumentReferenceTypeEnum } from "../../../main/features/documents/utils/DocumentReferenceTypeEnum";
import { CourseLevelIdEnum } from "../../../main/features/eLearning/utils/courseLevelId";
import { ExpenseCategoryEnum } from "../../../main/features/expense/utils/expenseCategoryEnum";
import { ExpenseFilterTypeEnum } from "../../../main/features/expense/utils/expenseFilterTypeEnum";
import { ExpenseReferenceTypeEnum } from "../../../main/features/expense/utils/expenseReferenceTypeEnum";
import { ExpenseFilterSortEnum } from "../../../main/features/expense/utils/expenseSortEnum";
import { ExpenseApprovalStatusEnum } from "../../../main/features/expense/utils/expenseStatusEnum";
import { PostReferenceType, PostType, ReactionType } from "../../../main/features/feed/utils/constants";
import { FormFilterSortEnum } from "../../../main/features/forms/utils/formFilterSortEnum";
import { FormFilterTypeEnum } from "../../../main/features/forms/utils/formFilterTypeEnum";
import { FormQuestionTypeEnum } from "../../../main/features/forms/utils/formQuestionTypeEnum";
import { FormStatusEnum } from "../../../main/features/forms/utils/formStatusEnum";
import { LeadManagerMetricTypeEnum } from "../../../main/features/leadmanager/enum/enum";
import { LeaveFilterSortEnum } from "../../../main/features/leave/utils/leaveFilterSortEnum";
import { LeaveFilterTypeEnum } from "../../../main/features/leave/utils/leaveFilterTypeEnum";
import { LeaveStatusEum } from "../../../main/features/leave/utils/leaveStatusEnum";
import { LoanFilterTypeEnum } from "../../../main/features/loan/utils/LoanFilterTypeEnum";
import { LoanPurposeEnum } from "../../../main/features/loan/utils/LoanPurposeEnum";
import { LoanStatusEnum } from "../../../main/features/loan/utils/LoanStatusEnum";
import { LoanTypeEnum } from "../../../main/features/loan/utils/LoanTypeEnum";
import { LoanFilterSortEnum } from "../../../main/features/loan/utils/loanFilterSortEnum";
import { NonMandatoryApprovalTypeEnum } from "../../../main/features/nonMandatoryApproval/utils";
import { NotificationBlockFeatureTypeEnum } from "../../../main/features/notifiation/enums";
import { PayrollFilterTypeEnum } from "../../../main/features/payroll/utils/payrollFilterTypeEnum";
import { PayrollStatusEnum } from "../../../main/features/payroll/utils/payrollStatusEnum";
import { PromotionFilterSortEnum } from "../../../main/features/promotion/utils/PromotionFilterSortEnum";
import { PromotionFilterTypeEnum } from "../../../main/features/promotion/utils/PromotionFilterTypeEnum";
import { PromotionStatusEnum } from "../../../main/features/promotion/utils/PromotionStatusEnum";
import { QuotationFilterSortEnum } from "../../../main/features/quotation/utils/quotationFilterSortEnum";
import { QuotationFilterEnum } from "../../../main/features/quotation/utils/quotationFilterTypeEnum";
import { QuotationStatusEnum } from "../../../main/features/quotation/utils/quotationStatusEnum";
import { ResignationFilterSortEnum } from "../../../main/features/resignation/utils/ResignationFilterSortEnum";
import { ResignationFilterTypeEnum } from "../../../main/features/resignation/utils/resignationFIlterTypeEnum";
import { ResignationPurposeEnum } from "../../../main/features/resignation/utils/resignationPurposeEnum";
import { ResignationApprovalStatusEnum } from "../../../main/features/resignation/utils/resignationStatusEnum";
import { ResignationTypeEnum } from "../../../main/features/resignation/utils/resignationTypeEnum";
import { RewardFilterTypeEnum } from "../../../main/features/reward/utils/RewardFilterTypeEum";
import { RewardStatusEnum } from "../../../main/features/reward/utils/RewardStatusEnum";
import { RewardFilterSortEnum } from "../../../main/features/reward/utils/rewardFilterSortEnum";
import { EmployeeSalaryStatusEnum } from "../../../main/features/salary/utils/EmployeeSalaryStatusEnum";
import { SalaryFilterTypeEnum } from "../../../main/features/salary/utils/SalaryFilterTypeEnum";
import { EmployeeSalaryFilterSortEnum } from "../../../main/features/salary/utils/salarySortEnum";
import { SelfTaskEnum } from "../../../main/features/task/utils/SelfTaskEnum";
import { TaskFilterSortEnum } from "../../../main/features/task/utils/TaskFilterSortEnum";
import { TaskFilterTypeEnum } from "../../../main/features/task/utils/TaskFilterTypeEnum";
import { TaskPriorityEnum } from "../../../main/features/task/utils/TaskPriorityEnum";
import { TaskReferenceTypeEnum } from "../../../main/features/task/utils/TaskReferenceTypeEnum";
import { TaskStatusEnum } from "../../../main/features/task/utils/TaskStatusEnum";
import { TravelAttachmentTypeEnum } from "../../../main/features/travel/utils/travelAttachmentTypeEnum";
import { TravelFilterTypeEnum } from "../../../main/features/travel/utils/travelFilterTypeEnum";
import { TravelOptionsEnums } from "../../../main/features/travel/utils/travelOptionsEnum";
import { ShiftEnum } from "../../../main/features/travel/utils/travelShiftEnum";
import { TravelStatusEnum } from "../../../main/features/travel/utils/travelStatusEnum";
import { TravelFilterSortEnum } from "../../../main/features/travel/utils/travleFilterSortEnum";
import { AccountTypeEnum } from "../../../main/features/trialBalance/utils/AccountTypeEnum";
import { WarningFilterTypeEnum } from "../../../main/features/warning/utils/WarningFilterTypeEnum";
import { WarningStatusEnum } from "../../../main/features/warning/utils/WarningStatusEnum";
import { WarningFilterSortEnum } from "../../../main/features/warning/utils/warningFilterSortEnum";
import { SuggestionTypeEnum } from "../../../main/sharedComponents/SuggestionBox/utils/SuggestionTypeEnum";
import {SharePostDestinations} from "../../../main/features/feed/utils/sharePostDestinationEnums";

const spanish = {

    sharedLabels: {
        SelectLanguage: "Select Language",
        English: "English ",
        Turkish: "Turkish",
        Hindi: "Hindi",
        Urdu: "اردو",
        Arabic: "عربي",
        czech: "Czech",
        russian: "Russian",
        french: "French",
        spanish: "Spanish",
        italian: "Italian",
        theme: "Tema",
        awardTo: "Premiar A",
        inprogress: "En Progreso",
        filter: "Filtrar",
        approvals: "Aprobaciones",
        approvers: "Aprobadores",
        members: "Miembros",
        Approvers: "Aprobadores",
        resignations: "Renuncias",
        Description: "Descripción",
        Descriptionisrequired: "¡Descripción es requerida!",
        WriteDescriptionhere: "Escriba la Descripción aquí...",
        enterDescription: "Ingrese Descripción",
        pleseEnterDescription: "Por Favor Ingrese Descripción",
        pleaseEnterGrade: "Por Favor Ingrese Grado",
        manager: "Gerente",
        requiredMessageManager: "Por Favor Seleccione Gerente",
        SelectManager: "Seleccione Gerente",
        create: "Crear",
        humanresource: "RRHH",
        finance: "Finanzas",
        it: "TI",
        admin: "Admin",
        selectMember: "Seleccione Miembro",
        othersapprovals: "Otras Aprobaciones",
        exitinterview: "Entrevista de Salida",
        selectAperson: "Seleccione una Persona",
        SelectHumanResource: "Seleccione RRHH",
        selectIt: "Seleccione TI",
        selectFinance: "Seleccione Finanzas",
        selectAdmin: "Seleccione Admin",
        selectOthersApprovals: "Seleccione Otras Aprobaciones",
        inProcess: "En Proceso",
        reason: "Razón",
        refrence: "Referencia",
        Rewards: "Recompensas",
        name: "Nombre",
        add: "Agregar",
        action: "Acción",
        userType: "Tipo de Usuario",
        SelectUserType: "Seleccione Tipo de Usuario",
        requiredMessageUserType: "Por Favor Seleccione Tipo de Usuario",
        RewardTo: "Recompensar A",
        WarningTo: "Advertencia A",
        Approved: "Aprobado",
        Declined: "Rechazado",
        Resend: "Reenviar",
        NotRequired: "No Requerido",
        Cancelled: "Cancelado",
        InActive: "Inactivo",
        ListView: "Vista de Lista",
        TableView: "Vista de Tabla",
        MyReward: "Mi Recompensa",
        ForApprovals: "Para Aprobaciones",
        ForApproval: "Para Aprobación",
        RewardToMe: "Recompensa Para Mí",
        Search: "Buscar",
        Disable: "Deshabilitar",
        Attachments: "Adjuntos",
        Update: "Actualizar",
        update: "actualizar",
        AddEmployee: "Agregar Empleado",
        complain: "Queja",
        Submit: "Enviar",
        Delete: "Eliminar",
        Edit: "Editar",
        NotDesignated: "No Designado",
        Profile: "Perfil",
        Settings: "Configuraciones",
        Logout: "Cerrar Sesión",
        Table: "Tabla",
        List: "Lista",
        Grid: "Grilla",
        GridView: "Vista de Grilla",
        startyear: "Año de Inicio",
        endyear: "Año de Finalización",
        startMonth: "Mes de Inicio",
        endMonth: "Mes de Finalización",
        EnterStartDate: "Ingrese Fecha de Inicio",
        SelectStartDate: "Seleccione Fecha de Inicio",
        startDate: "Fecha de Inicio",
        endDate: "Fecha de Finalización",
        businessLogo: "Logo de la Empresa",
        title: "Título",
        enterTitle: "Ingrese Título",
        percentage: "Porcentaje",
        PleaseEnterPercentage: "Por Favor Ingrese Porcentaje",
        previousCharge: "Cargo Anterior",
        min: "Mín",
        max: "Máx",
        country: "País",
        requiredCountry: "País es requerido",
        SelectCountry: "Seleccione País",
        latitude: "Latitud",
        longitude: "Longitud",
        address: "Dirección",
        EnterAddress: "Ingrese Dirección",
        requiredMessageAddress: "Por Favor Ingrese Dirección",
        branch: "Sucursal",
        question: "Preguntas",
        allotLeaves: "Días Asignados",
        SubjectRequired: "Asunto Requerido",
        WriteSubject: "Escriba Asunto",
        category: "Categoría",
        amount: "Cantidad",
        documents: "Documentos",
        enterName: "Ingrese Nombre",
        nameRequired: "Nombre es Requerido",
        accessTo: "Acceso A",
        selectAccessTo: "Seleccione Acceso A",
        collaborators: "Colaboradores",
        selectCollaborators: "Seleccione Colaboradores",
        readers: "Lectores",
        selectReaders: "Seleccione Lectores",
        createBoard: "Crear Tablero",
        createPad: "Crear Bloc",
        createGrid: "Crear Cuadrícula",
        createSlide: "Crear Diapositiva",
        createMindmap: "Crear Mapa Mental",
        drive: "Drive",
        myDrive: "Mi Drive",
        allDocuments: "Todos los Documentos",
        subject: "Asunto",
        Subjectisrequired: "Asunto es requerido",
        pleaseEnterSubject: "Por Favor Ingrese Asunto",
        EnterSubject: "Ingrese Asunto",
        enterAmount: "Ingrese Cantidad",
        pleaseEnterAmount: "Por Favor Ingrese Cantidad",
        upload: "Subir",
        value: "Valor",
        addCategory: "Agregar Categoría",
        saveCategory: "Guardar Categoría",
        clear: "Limpiar",
        loans: "Préstamos",
        disburse: "Desembolsar",
        referenceNo: "No. de Referencia",
        status: "Estado",
        createDate: "Fecha de Creación",
        creator: "Creador",
        netSalary: "Salario Neto",
        type: "Tipo",
        PleaseSelectType: "Por Favor Seleccione Tipo",
        CreatedByMe: "Creado Por Mí",
        Members: "Miembros",
        pleaseEnterCategory: "Por Favor Ingrese Categoría",
        Memberisrequired: "Miembro es requerido",
        EnterCategoryName: "Ingrese Nombre de la Categoría",
        Clear: "Limpiar",
        ReferenceNumber: "Número de Referencia",
        Grade: "Grado",
        Status: "Estado",
        CreateDate: "Fecha de Creación",
        Creator: "Creador",
        SNo: "Nro.",
        Employee: "Empleado",
        SelectApprovers: "Seleccione Aprobadores",
        Approverisrequired: "Aprobador es requerido",
        Yes: "Sí",
        No: "No",
        totalAmount: "Cantidad Total",
        Total: "Total",
        Date: "Fecha",
        Pleaseselectdate: "Por Favor Seleccione Fecha",
        SelectDate: "Seleccione Fecha",
        requiredMessageDate: "Por Favor Seleccione Fecha",
        Priority: "Prioridad",
        Pleaseselectpriority: "Por Favor Seleccione Prioridad",
        Pleaseselecttype: "Por Favor Seleccione Tipo",
        SelectProject: "Seleccione Proyecto",
        SelectGroup: "Seleccione Grupo",
        Project: "Proyecto",
        Group: "Grupo",
        Progress: "Progreso",
        SuretoRate: "¿Seguro de Calificar?",
        Invalidfiletype: "Tipo de archivo inválido",
        Pleaseinputyoursubject: "Por Favor ingrese su asunto",
        Pleaseinputyourdescription: "Por Favor ingrese su descripción",
        Return: "Devolver",
        EnterReason: "Ingrese Razón",
        TravelReason: "Razón del Viaje",
        NoDesignation: "Sin Designación",
        Hi: "Hola",
        Quotation: "Cotización",
        Amountisrequired: "Se requiere la cantidad",
        WriteDescriptionHere: "Escribe la descripción aquí...",
        Types: "Tipos",
        Header: "Encabezado",
        MyTeam: "Mi equipo",
        Email: "Correo electrónico",
        EnterEmail: "Ingrese el correo electrónico",
        EmailIsRequired: "Se requiere el correo electrónico",
        PhoneNo: "Número de teléfono",
        Designation: "Designación",
        ReferenceType: "Tipo de referencia",
        referenceId: "Id de referencia",
        Save: "Guardar",
        save: "guardar",
        Color: "Color",
        Yesterday: "Ayer",
        Overdue: "Atrasado",
        Today: "Hoy",
        Complete: "Completo",
        DueDate: "Fecha de vencimiento",
        SelectDueDate: "Seleccione la fecha de vencimiento",
        Cover: "Cubierta",
        Cancel: "Cancelar",
        cancel: "cancelar",
        and: "y",
        other: "otro",
        Others: "Otros",
        Option: "Opción",
        Public: "Público",
        Private: "Privado",
        click: "clic",
        With: "Con",
        with: "con",
        Comments: "Comentarios",
        Commentsdot: "Comentarios...",
        Comment: "Comentario",
        Seeless: "Ver menos",
        Seemore: "Ver más",
        Share: "Compartir",
        ShareNow: "Compartir ahora",
        SelectEmployee: "Seleccione empleado",
        SelectCategory: "Seleccione categoría",
        PleaseSelectCategory: "Por favor seleccione categoría",
        privacy: "Privacidad",
        tags: "Etiquetas",
        updateDate: "Fecha de actualización",
        otherApprovals: "Otras aprobaciones",
        resignationDate: "Fecha de renuncia",
        purpose: "Propósito",
        warnings: "Advertencias",
        Courses: "Cursos",
        selectType: "Seleccione tipo",
        daysCount: "Conteo de días",
        reportingTo: "Informando a",
        selectReason: "Seleccione razón",
        pleaseSelectReason: "Por favor seleccione razón",
        days: "Días",
        day: 'día',
        Day: 'Día',
        Dates: "Fechas",
        selectMembers: "Seleccione miembros",
        selectGrade: "Seleccione grado",
        newGrade: "Nuevo grado",
        currentGrade: "Grado actual",
        PleaseSelectMember: "Por favor seleccione miembro",
        enterCategory: "Ingrese categoría",
        deadline: "Fecha límite",
        Department: "Departamento",
        designationmessage: "Por favor seleccione designación",
        selectDesignation: "Seleccione designación",
        jobdescription: "Descripción del trabajo",
        salaryRange: "Rango salarial",
        interviewers: "Entrevistadores",
        hiringBuddy: "Amigo de contratación",
        enterCity: "Por favor ingrese ciudad",
        City: "Ciudad",
        selectCity: "Seleccione ciudad",
        requiredCity: "Se requiere la ciudad",
        skills: "Habilidades",
        experience: "Experiencia (Años)",
        Experience: "Experiencia",
        jobType: "Tipo de trabajo",
        education: "Educación",
        interview: "Entrevistador",
        Rating: "Calificación",
        poweredbyWorkw: "Desarrollado por Workwise Ltd - Derechos de autor 2016-",
        copyLink: "Copiar enlace",
        EffectiveDate: "Fecha efectiva",
        experienceRequired: "Experiencia requerida",
        user: "Usuarios",
        postinterview: "Entrevistas posteriores",
        Copied: "Copiado",
        unreadNotifyItem: "Elemento de notificación no leído",
        createBy: "Creado por",
        Create: "Crear",
        Open: "Abrir",
        Closed: "Cerrado",
        Nodescription: "Sin descripción",
        writeDescription: "Escribe tu descripción aquí...",
        Employees: "Empleados",
        BasicInfo: "Información básica",
        FatherName: "Nombre del padre",
        EnterFatherName: "Ingrese el nombre del padre",
        PersonalEmail: "Correo electrónico personal",
        EnterPersonalEmail: "Ingrese el correo electrónico personal",
        personalEmailIsRequired: "El correo electrónico personal es requerido",
        PhoneNumber: "Número de teléfono",
        EnterPhoneNumber: "Ingrese el número de teléfono",
        NationalIdentity: "Identidad Nacional",
        EnterNationalIdentityNumber: "Ingrese el número de identidad nacional",
        Grades: "Grados",
        SelectGrade: "Seleccionar grado",
        requiredMessageGrade: "Por favor selecciona un grado",
        EnterProbationPeriod: "Ingrese el período de prueba",
        NoticePeriod: "Período de aviso (días)",
        EnterNoticePeriod: "Ingrese el período de aviso",
        requiredMessageNoticePeriod: "Por favor ingrese su período de aviso",
        requiredMessageNoticePeriodRestriction: "El período de aviso debe estar entre 1 y 60",
        DateOfBirth: "Fecha de nacimiento",
        Gender: "Género",
        requiredMessageGender: "Por favor selecciona un género",
        SelectGender: "Seleccionar género",
        MartialStatus: "Estado civil",
        SelectMartialStatus: "Seleccionar estado civil",
        requiredDepartment: "El departamento es requerido",
        SelectDepartment: "Seleccionar departamento",
        Subsidiary: "Filial",
        SelectSubsidiary: "Seleccionar filial",
        SubsidiaryOffice: "Oficina de la filial",
        SelectSubsidiaryOffice: "Seleccionar oficina de la filial",
        PayrollGroup: "Grupo de nómina",
        SelectPayrollGroup: "Seleccionar grupo de nómina",
        DefaultApproval: "Aprobación predeterminada",
        SelectDefaultApproval: "Seleccionar aprobación predeterminada",
        SelectAll: "Seleccionar todo",
        NonMandatoryApprovals: "Aprobaciones no obligatorias",
        SelectNonMandatoryApprovals: "Seleccionar aprobaciones no obligatorias",
        FirstName: "Nombre",
        EnterFirstName: "Ingrese el nombre",
        requiredMessageName: "Por favor ingrese el nombre",
        requiredMessageFName: "Por favor ingrese el nombre",
        requiredMessageLName: "Por favor ingrese el apellido",
        LastName: "Apellido",
        EnterLastName: "Ingrese el apellido",
        DateOfJoining: "Fecha de ingreso",
        requiredDateOfBirth: "La fecha de nacimiento es requerida",
        requiredMessageJoiningDate: "Por favor seleccione la fecha de ingreso",
        JobShift: "Turno de trabajo",
        requiredMessageJobShift: "Por favor seleccione el turno de trabajo",
        SelectJobShift: "Seleccionar turno de trabajo",
        AccessRole: "Rol de acceso",
        requiredMessageAccessRole: "Por favor seleccione al menos un rol de acceso",
        SelectAccessRole: "Seleccionar rol de acceso",
        StartEndDate: "Fecha de inicio/fin",
        ErrorInValidation: "Error en la validación",
        EducationInfo: "Información educativa",
        NonMandatory: "No obligatorio",
        Present: "Presente",
        FailedToFetch: "Error al obtener datos",
        Position: "Posición",
        EnterPosition: "Ingrese la posición",
        requiredMessagePosition: "Por favor ingrese la posición",
        Organization: "Organización",
        EnterOrganization: "Ingrese la organización",
        requiredMessageOrganziation: "Por favor ingrese la organización",
        Number: "Número",
        EnterNumber: "Ingrese el número",
        requiredMessageNumber: "Por favor ingrese el número",
        Relation: "Relación",
        Id: "ID",
        SelectRelation: "Seleccionar relación",
        requiredMessageRelation: "La relación es requerida",
        AccountName: "Nombre de la cuenta",
        BranchCode: "Código de sucursal",
        EnterBranchCode: "Ingrese el código de sucursal",
        requiredMessageBranchCode: "El código de sucursal es requerido",
        BankName: "Nombre del banco",
        requiredMessageBankName: "El nombre del banco es requerido",
        EnterBankName: "Ingrese el nombre del banco",
        AccountNumber: "Número de cuenta",
        EnterAccountNumber: "Ingrese el número de cuenta",
        requiredAccountNumber: "El número de cuenta es requerido",
        IBAN: "IBAN",
        EnterIBAN: "Ingrese el IBAN",
        requiredIBAN: "El número IBAN es requerido",
        SortCode: "Código de ordenamiento",
        IsDefault: "Predeterminado",
        requiredSortCode: "El código de ordenamiento es requerido",
        EnterSortCode: "Ingrese el código de ordenamiento",
        BankInfo: "Información bancaria",
        AccountTitle: "Título de la cuenta",
        EnterAccountTitle: "Ingrese el título de la cuenta",
        requiredMessageAccount: "El título de la cuenta es requerido",
        validAccountTitle: "Por favor ingrese un título de cuenta válido",
        AttachmentType: "Tipo de adjunto",
        requiredAttchmentType: "Tipo de adjunto requerido",
        Attachment: "Adjunto",
        requiredAttachment: "El adjunto es requerido",
        AddAttachment: "Agregar adjunto",
        requiredMessageAssignMember: "Por favor seleccione el miembro asignado",
        Remove: "Eliminar",
        ContactNo: "Número de contacto",
        EnterContactNo: "Ingrese el número de contacto",
        requiredContactNo: "Se requiere el número de contacto",
        Contact: "Contacto",
        pleasEnterDescription: "Por favor, ingrese una descripción.",
        Information: "Información",
        Features: "Características",
        AddMembers: "Agregar Miembros",
        Summary: "Resumen",
        AcessRole: "Rol de Acceso",
        defaultApprovers: "Aprobadores Predeterminados",
        NonMandatoryApprovers: "Aprobadores No Obligatorios",
        Pleasefillallrequiredfields: "Por favor, complete todos los campos requeridos",
        Suretodelete: "¿Seguro que desea eliminar?",
        Code: "Código",
        createdDate: "Fecha de Creación",
        Searchmembers: "Buscar miembros",
        AddDate: "Agregar Fecha",
        duesoon: "pronto vencido",
        Payroll: "Nómina",
        ReferenceNo: "Número de Referencia",
        Associate: "Asociar",
        InternalMembers: "Miembros Internos",
        ExternalMembers: "Miembros Externos",
        TotalExpense: "Gasto Total",
        TotalExpenseAmount: "Monto Total del Gasto",
        TotalPost: "Total de Publicaciones",
        TotalMeeting: "Total de Reuniones",
        Expense: "Gasto",
        TotalBudget: "Presupuesto Total",
        Balance: "Saldo",
        successfully: "exitosamente",
        enterValidEmail: 'Por favor, ingrese una dirección de correo electrónico válida',
        ViewSummary: "Ver Resumen",
        AddAdmin: "Agregar Administrador",
        AddExternals: "Agregar Externos",
        DefaultApprovers: "Aprobadores Predeterminados",
        Billings: "Facturación",
        spent: "Gastado",
        SelectUser: "Seleccionar Usuario",
        Image: "Imagen",
        Contacts: "Contactos",
        noContacts: "Sin Contactos",
        AddContact: "Agregar Contacto",
        Roles: "Roles",
        Tag: "Etiqueta",
        AccessRoles: "Roles de Acceso",
        member: "miembro",
        uploadCover: "Subir Portada",
        Pleaseselect: "Por favor seleccione",
        composeEmail: "Redactar Correo Electrónico",
        send: "Enviar",
        ContactDetail: "Detalle de Contacto",
        Detail: "Detalle",
        UploadImage: "Subir Imagen",
        WriteName: "Escribir Nombre",
        business: "Negocio",
        website: "Sitio Web",
        Unknown: "Desconocido",
        Interested: "Interesado",
        NotInterested: "No Interesado",
        meetings: "Reuniones",
        noMeetings: "Sin Reuniones",
        Communications: "Comunicaciones",
        Details: "Detalles",
        LastUpdate: "Última Actualización",
        nameRequiredMessage: "¡Nombre requerido!",
        addressRequiredMessage: "¡Dirección requerida!",
        Intrested: "Interesado",
        StartDateTime: "Fecha y Hora de Inicio",
        DateTimeisrequired: "Fecha y Hora son requeridas",
        Selectdatetime: "Seleccionar fecha y hora",
        Board: "Tablero",
        Notes: "Notas",
        Miletap: "Miletap",
        EMail: "Correo Electrónico",
        Attendance: "Asistencia",
        Absent: "Ausente",
        NoData: "Sin Datos",
        Prority: "Prioridad",
        Heigh: "Alto",
        Medium: "Medio",
        Low: "Bajo",
        error: "error",
        Select: "Seleccionar",
        Interview: "Entrevista",
        Meeting: "Reunión",
        Appointment: "Cita",
        Pleaseinputyouremail: "Por favor ingrese su correo electrónico",
        EnterEmailAddress: "Ingresar Dirección de Correo Electrónico",
        AddExternalMember: "Agregar Miembro Externo",
        Pleaseenterexternalmembers: "Por favor ingrese miembros externos",
        externals: "externos",
        Decline: "Rechazar",
        Approve: "Aprobar",
        NotAvailable: "No Disponible",
        AddApprovals: "Agregar Aprobaciones",
        Everyone: "Todos",
        SelectDownloaders: "Seleccionar Descargadores",
        SelectCollabrators: "Seleccionar Colaboradores",
        pleaseSelectAssignMemeber: "¡Por favor seleccione asignar miembro!",
        Availed: "Disponible",
        Allotted: "Asignado",
        Degree: "Grado",
        Time: "Tiempo",
        Mood: "Estado de Ánimo",
        CheckOut: "Salida",
        CheckIn: "Entrada",
        Late: "Tarde",
        State: "Estado",
        PleaseSelectYesNo: "Por favor seleccione Sí/No",
        PleaseselectGrade: "Por favor seleccione Grado",
        PleaseenterAmount: "Por favor ingrese Cantidad",
        Salary: "Salario",
        Task: "Tarea",
        Nameisrequired: "El nombre es obligatorio",
        PhoneNumberisrequired: "El número de teléfono es obligatorio",
        Dateisrequired: "La fecha es obligatoria",
        Month: "Mes",
        Year: "Año",
        FinalApprovers: "Aprobadores Finales",
        link: "Enlace",
        forFinalApproval: "Para Aprobación Final",
        validateBonus: "No se puede procesar el bono: No hay información salarial disponible para el empleado",
        validateLoan: "No se puede procesar el préstamo: No hay información salarial disponible para el empleado",
        User: "Usuario",
        Action: "Acción",
        Created: "Creado",
        Toast: "Notificación",
        selectyourcountry: "Por favor seleccione su país",
        PleaseEnterPhoneNumber: "Por favor ingrese su número de teléfono",
        Pleaseselectyourcity: "Por favor seleccione su ciudad",
        SelectCurrency: "Seleccionar Moneda",
        Pleaseselectcurrency: "Por favor seleccione la moneda",
        totalGroups: "Total de Grupos",
        totalProjects: "Total de Proyectos",
        totalMemory: "Memoria Total",
        memory: "Memoria",
        EnterMemory: "Ingresar Memoria",
        availedMemory: "Memoria Disponible (GB)",
        used: "Usado",
        usedMemory: "Memoria Usada (GB)",
        From: "Desde",
        To: "Hasta",
        WelcomeSendEmail: "Bienvenido Envío de Correo Electrónico",
        SendEmail: "Enviar Correo Electrónico",
        writeYourRemarksHere: "Escribe tus comentarios aquí...",
        NoRemarkFound: "No se encontraron comentarios",
        ToastEnable: "Activar Notificaciones",
        EmailEnable: "Activar Correo Electrónico",
        FeatureName: "Nombre de la Funcionalidad",
        LoginMessage: "Por favor ingrese credenciales primero",
        netAmount: "Monto Neto",
        SelectedDate: "Fecha Seleccionada",
        SelectedUser: "Usuario Seleccionado",
        Default: "Predeterminado",
        mylog: "Mi Registro",
        SelectStatus: "Seleccionar Estado",
        Selectuser: "Seleccionar un usuario",
        PolicyType: "Tipo de Política",
        Percent: "Porcentaje",
        uploadProfilePicture: "Subir Foto de Perfil",
        inputUsername: "Por favor, ingresa el nombre de usuario",
        userName: "nombre de usuario",
        Dummy: "Falso",
        Lesson: "Lección",
        Duration: "Duración",
        Text: "Texto",
        Enterlink: "Ingresar enlace",
        Dashboard: "Tablero",
        searchSchedule: "Buscar horario...",
        AddOption: "Agregar opción",
        ChangeImage: "Cambiar imagen",
        DeleteImage: "Eliminar imagen",
        Question: "Pregunta",
        Next: "Siguiente",
        Prev: "Anterior",
        Wrong: "Incorrecto",
        Correct: "Correcto",
        YourResult: "Tu resultado",
        Createdby: "Creado por",
        Lastupdated: "Última actualización",
        Assignedto: "Asignado a",
        Addlesson: "Agregar lección",
        Addtopic: "Agregar tema",
        minutes: "minutos",
        Modules: "Módulos",
        Valid: "Válido",
        Expired: "Caducado",
        AssignedMembers: "Miembros asignados",
        Start: "Comenzar",
        Previous: "Anterior",
        Read: "Leer",
        DeleteBook: "Eliminar libro",
        Assign: "Asignar",
        Explanation: "Explicación",
        Hey: "Hola",
        requiredMessageEmail: "Por favor, ingrese un correo electrónico",
        BusinessName: "Nombre de la empresa",
        PleaseEnterBusinessName: "Por favor, ingrese el nombre de la empresa",
        BusinessAddress: "Dirección de la empresa",
        PleaseEnterBusinessAddress: "Por favor, ingrese la dirección de la empresa",
        Offers: "Ofertas",
        PleaseEnterOffer: "Por favor, ingrese la oferta",
        CreateOffer: "Crear oferta",
        AppliedDate: "Fecha de aplicación",
        ShortList: "Lista corta",
        Finalised: "Finalizado",
        Member: "Miembro",
        SelectMember: "Seleccionar miembro",
        Approver: "Aprobadores",
        CategoryName: "Nombre de la categoría",
        serialNo: "Número de serie",
        Allocated: "Asignado",
        Available: "Disponible",
        lost: "Perdido",
        damage: "Dañado",
        itemType: "Tipo de artículo",
        Service: "Servicio",
        consumable: "Consumible",
        NonConsumable: "No consumible",
        Quantity: "Cantidad",
        accountType: "Tipo de cuenta",
        PleaseEnterQuantity: "Por favor, ingrese una cantidad",
        QuantityMustBePositive: "La cantidad debe ser positiva",
        MembersIsRequired: "Se requieren miembros",
        CreateRequest: "Crear solicitud",
        IBANNumber: "Número de IBAN",
        About: "Acerca de",
        AboutMe: "Acerca de mí",
        TimePeriod: "Período de tiempo",
        Company: "Empresa",
        CityTown: "Ciudad/Pueblo",
        BankDetails: "Detalles bancarios",
        Work: "Trabajo",
        addWorkPlace: "Agregar lugar de trabajo",
        AddEducation: "Agregar educación",
        basicSalary: "Salario básico",
        feed: 'Feed',
        TakeaNote: "Tomar una nota",
        awards: 'Premios',
        Appraisal: "Evaluación",
        salary: 'Salario',
        activityLog: "Registro de actividad",
        device: 'Dispositivo',
        leave: 'Dejar',
        Ip: "IP",
        DeviceId: "ID del dispositivo",
        AppVersion: "Versión de la aplicación",
        Selectfeature: "Seleccionar función",
        EnterYourFeedBackDescription: "Ingrese la descripción de su comentario",
        HideAll: "Ocultar todo",
        ShowAll: "Mostrar todo",
        PrintBy: "Imprimir por",
        AccCode: "Código de cuenta",
        SelectAccountType: "Seleccione el tipo de cuenta",
        Files: "Archivos",
        Space: "Espacio",
        DisburseVoucher: "Distribuir/Vale",
        TopUsersbyMostFiles: "Usuarios principales por número de archivos",
        TopUsersbyStorageSize: "Usuarios principales por tamaño de almacenamiento",
        Apply: "Aplicar",
        NumberOfTopUsers: "Número de usuarios principales",
        TopUsers: "Usuarios principales",
        SearchUser: "Buscar usuario",
        ByStorageSize: "Por tamaño de almacenamiento",
        ByFileSize: "Por tamaño de archivo",
        companyDashboard: "Panel de control de la empresa",
        signup: "Registro no confirmado",
        billing: "Facturación",
        PendingBills: "Facturas pendientes",
        MonthYear: "Mes - Año",
        SendBill: "Enviar factura",
        BillingUsers: "Usuarios de facturación",
        Item: "Artículo",
        Rate: "Tarifa",
        PaidAmount: "Monto pagado",
        PaymentDate: "Fecha de pago",
        DarkDays: "Días oscuros",
        ProcessingFee: "Tarifa de procesamiento",
        Currency: "Moneda",
        IsEnable: "Está habilitado",
        Actions: "Acciones",
        TakeNote: "Toma una nota",
        PleaseSelectUsers: "Por favor seleccione usuarios",
        StickyNotes: "Notas adhesivas",
        Copy: "Copiar",
        MarkAllRead: "Marcar todo como leído",
        SeeAll: "Ver todo",
        Refresh: "Actualizar",
        close: "Cerrar",
        Calling: "Llamando",
        LoadMore: "Cargar más",
        Quizez: "Cuestionarios",
        content: "Contenido",

    },

    /*** navMenuLabel only used in LeftSideBar ***/

    navMenuLabel: {
        Schedules: "Horarios",
        workWiseCompanies: "Empresas Workwise",
        promotions: "Promociones",
        customApprovals: "Aprobaciones Personalizadas",
        feed: "Noticias",
        messenger: "Mensajería",
        chartOfAccount: "Plan de Cuentas",
        createVoucher: "Crear Comprobante",
        voucherList: "Lista de Comprobantes",
        Quotation: "Cotizaciones",
        QuotationClient: "Cotización al Cliente",
        transaction: "Transacciones",
        report: "Reporte",
        mailBox: "Buzón de Correo",
        groups: "Grupos",
        projects: "Proyectos",
        tasks: "Tareas",
        discussionBoard: "Tablero de Discusión",
        pages: "Páginas",
        workBoard: "Tablero de Trabajo",
        leadManager: "Gestor de Leads",
        schedules: "Horarios",
        expense: "Gastos",
        travel: "Viajes",
        docsArchives: "Documentos/Archivos",
        eLearning: "E-Learning",
        inventory: "Inventario",
        myTeam: "Mi Equipo",
        orgChart: "Organigrama",
        employee: "Empleados",
        administration: "Administración",
        appraisals: "Evaluaciones",
        departments: "Departamentos",
        leaves: "Licencias",
        basicInfo: "Información Básica",
        holidays: "Días Festivos",
        careers: "Carreras",
        companiesSection: "Empresas Workwise",
        companies: "Empresas",
        menu: "Menú",
        hr: "RRHH",
        finance: "Finanzas",
        inventorySection: "Inventario",
        privacy: "Privacidad",
        TC: "Términos y Condiciones",
        more: "más",
        download: "Descargas",
        feedBack: "Feedback",
        warnings: "Advertencias",
        complains: "Quejas",
        bonus: "Bonificaciones",
        resignations: "Renuncias",
        requisition: "Requisiciones",
        assets: "Asignación de Activos",
        assetsList: "Lista de Activos",
        createAssets: "Crear Activos",
        requestListItems: "Solicitar Artículos",
        rewards: "Recompensas",
        businessPolicy: "Políticas de Negocio",
        companiesPolicy: "Políticas de Empresas",
        form: "Formularios",
        ledgerReport: "Reportes de Libro Mayor",
        trialBalance: "Balance de Prueba",
        salary: "Salario",
        payroll: "Nómina",
        jobBoard: "Tablero de Empleo",
        teams: "Mi Equipo",
        LD: "Aprendizaje y Desarrollo",
        marketPlace: "Mercado",
        loan: "Préstamos",
        Courses: "Cursos",
        selectMembers: "Seleccionar Miembros",
        auction: "Subasta",
        Nodescription: "Sin descripción",
        contract: "Contrato"
    },
    /*** AppHeader only used in Component headerTab ***/
    appHeader: {
        newsFeed: {
            posts: "Publicaciones",
            photos: "Fotos",
            videos: "Videos",
            polls: "Encuestas",
            docs: "Documentos",
            tagged: "Etiquetados",
        },
        mailBox: {},
        messenger: {},
        groups: {
            groups: "Grupos",
        },
        projects: {
            projects: "Proyectos",
            dashboard: "Tablero",
        },
        Task: {
            Tasks: "Tareas",
            myTask: "Mis Tareas",
            assignedByMe: "Asignadas Por Mí",
            createdByMe: "Creadas Por Mí",
            teamTask: "Tareas de Equipo",
            dashboard: "Tablero",
        },
        leadManager: {
            leadCategory: "Categoría de Prospectos",
        },
        schedules: {
            calendar: "Calendario",
            schedule: "Horario",
            scheduleInterviews: "Programar Entrevistas",
        },
        expense: {
            expenses: "Gastos",
            forApprovals: "Para Aprobación",
            myExpenses: "Mis Gastos",
            forExecution: "Para Ejecución",
            forFinance: "Para Finanzas",
            reports: "Reportes",
            dashboard: "Tablero",
        },
        travel: {
            travels: "Viajes",
            forApprovals: "Para Aprobación",
            agentProcess: "Proceso de Agente",
            travelDetail: "Detalle de Viaje",
        },
        docsArchives: {
            documents: "Documentos",
            myDrive: "Mi Drive",
            myDocuments: "Mis Documentos",
            forApprovals: "Para Aprobación",
        },
        eLearning: {
            dashboard: "Tablero",
        },
        inventory: {
            approvals: "Aprobaciones",
            inventory: "Inventario",
            category: "Categoría",
        },
        customApproval: {
            CreateCustomApproval: "Crear Aprobación Personalizada",
            CustomApproval: "Aprobación Personalizada",
            ForApproval: "Para Aprobación",
            MyCustomApprovals: "Mis Aprobaciones Personalizadas",
            AllCustomApproval: "Todas las Aprobaciones Personalizadas",
        },
        payroll: {
            payroll: "Nómina",
            payrollApproval: "Aprobación de Nómina",
            salaryApproval: "Aprobación de Salario",
        },
        administration: {
            administration: "Administración",
        },
        appraisals: {
            appraisals: "Evaluaciones",
        },
        reward_category: {
            reward_category: "Categoría de Recompensa",
        },
        departments: {
            departments: "Departamentos",
        },
        leaves: {
            myLeaves: "Mis Licencias",
            forApprovals: "Para Aprobación",
        },
        holidays: {
            holidays: "Vacaciones",
        },
        warnings: {
            warnings: "Advertencias",
        },
        resignations: {
            resignations: "Renuncias",
            approvers: "Aprobaciones",
        },
        complains: {
            complains: "Quejas",
            approvers: "Aprobaciones",
            createComplain: "Crear Queja",
        },
        rewards: {
            rewards: "Recompensas",
            AllRewards: "Todas las Bonificaciones",
            CreatedByMe: "Creadas Por Mí",
            ForApprovals: "Para Aprobación",
            MyRewards: "Mis Recompensas",
        },
        promotions: {
            Promotions: "Promociones",
        },
        salary: {
            Salary: "Salario",
            CreateSalary: "Crear Salario",
        },
        pages: {
            Pages: "Páginas",
        },
    },

    /*** Main Menu ***/
    globalSearch: {
        All: "Todo",
        feed: "Feed",
        Lead_Manager: "Gestor de leads",
        Travel: "Viaje",
        Document: "Documento",
        Project: "Proyecto",
        Workboard: "Tablero de trabajo",
        Group: "Grupo",
        Expense: "Gasto",
        Task: "Tarea",
        Employee: "Empleado",
        e_learning_course: "Curso de e-learning",
        e_learning_videos: "Videos de e-learning",
        e_learning_article: "Artículo de e-learning",
        e_learning_quiz: "Cuestionario de e-learning",
        e_learning_book: "Libro de e-learning",
        e_learning_tedTalks: "TED Talks de e-learning",
        rewards: "Recompensas",
    },

    auction: {
        auctionType: "Tipo de Subasta",
        personal: "Personal",
        official: "Oficial",
        offer: "Oferta",
        startingBid: "Oferta Inicial",
        buyNowAmount: "Precio de Compra Ahora",
        immediateBuy: "Compra Inmediata",
        minIncreaseBid: "Incremento Mínimo de la Oferta",
        Auction: "Subasta",
        CreateAuction: "Crear Subasta",
        actionType: "Tipo de Subasta",
        external: "Externa",
        auctionDetail: "Detalles de la Subasta",
        currentBid: "Oferta Actual",

        FormLabel: {
            startingBid: "Oferta Inicial",
            member: "Miembro",
            approvers: "Aprobadores",
            minIncreaseBid: "Incremento Mínimo de la Oferta",
            buyNowAmount: "Precio de Compra Ahora",
            offer: "Oferta",
            immediateBuy: "Compra Inmediata",
        },
        FormPlaceholder: {
            name: "Ingrese el Nombre de la Subasta",
            startingBid: "Ingrese la Oferta Inicial",
            minIncreaseBid: "Ingrese el Incremento Mínimo de la Oferta",
            selectMember: "Seleccione un Miembro",
            selectApprovers: "Seleccione Aprobadores",
            buyNowAmount: "Ingrese el Precio de Compra Ahora",
            offer: "Ingrese la Oferta",
        },
        FormRequiredMessage: {
            name: "Por favor, ingrese el Nombre de la Subasta",
            startingBid: "Por favor, ingrese la Oferta Inicial",
            minIncreaseBid: "Por favor, ingrese el Incremento Mínimo de la Oferta",
            buyNowAmount: "Por favor, ingrese el Precio de Compra Ahora",
            offer: "Por favor, ingrese la Oferta",
            endDate: "Por favor, ingrese la Fecha de Finalización",
        },
        auctionFilterTypeEnumLabel: {
            [auctionFilterTypeEnum.All]: "Todas las Subastas",
            [auctionFilterTypeEnum.CreatedByMe]: "Mis Subastas",
            [auctionFilterTypeEnum.ForApproval]: "Para Aprobación",
        },
        statusEnum: {
            [AuctionStatusEnum.InProcess]: "En Proceso",
            [AuctionStatusEnum.Approved]: "Aprobada",
            [AuctionStatusEnum.Declined]: "Rechazada",
            [AuctionStatusEnum.Resend]: "Reenviada",
            [AuctionStatusEnum.InActive]: "Inactiva",
            [AuctionStatusEnum.NotRequired]: "No Requerida",
            [AuctionStatusEnum.Cancelled]: "Cancelada",
            [AuctionStatusEnum.Hold]: "En Espera",
            [AuctionStatusEnum.Sold]: "Vendida",
        },
        auctionOfferStatus: {
            [AuctionOfferStatusEnum.BidInProcess]: "Oferta en Proceso",
            [AuctionOfferStatusEnum.BidSold]: "Oferta Vendida",
            [AuctionOfferStatusEnum.OutBid]: "Fuera de la Oferta",
        },
        noDataMessage: "Crear Subasta",
    },
    newsFeed: {
        WriteForMe: "Escribe Para Mí",
        AddDocuments: "Agregar Documentos",
        AddPolls: "Agregar Encuestas",
        WhatsOnYourMind: "¿Qué tienes en mente?",
        Direction: "ltr",
        Generate: "Generar",
        Change: "Cambiar",
        Whatwouldyouliketotalkabout: "¿Sobre qué te gustaría hablar?",
        Post: "Publicar",
        Poll: "Encuesta",
        Documents: "Documentos",
        PhotoVideo: "Foto/Video",
        Addpolloption: "Agregar opción de encuesta",
        Removepoll: "Eliminar encuesta",
        WriteName: "Escribir Nombre",
        AddImageVideo: "Agregar Imagen/Video",
        CheckIn: "Registrarse",
        ShareonFeed: "Compartir en Feed",
        ShareinChat: "Compartir en Chat",
        NoPosts: "No hay Publicaciones...",
        Nomoreposts: "No hay más publicaciones...",
        noDataMessage: "¡Crea una Publicación ahora!",
        Saysomethingaboutthis: "Di algo sobre esto...",
        specificTimeline: "cronología de",
        SharedestinationNames: {
        [SharePostDestinations.FEED]: "Muro",
        [SharePostDestinations.MEMBERS_PROFILE]: "Perfil del miembro",
        [SharePostDestinations.GROUP]: "Grupo",
        [SharePostDestinations.PROJECT]: "Proyecto",
        [SharePostDestinations.TIMELINE]: "Timeline",
        },
        SharePostSuggestionPlaceholder: {
        [SuggestionTypeEnum.Employee]: "Seleccionar miembro",
        [SharePostDestinations.GROUP]: "Seleccionar grupo",
        [SharePostDestinations.PROJECT]: "Seleccionar proyecto",
        },
        CreateFeedNow: "¡Crea un feed ahora!",
        QuickOptions: {
            CreateSlide: "Crear Presentación",
            CreateBoard: "Crear Tablero",
            CreateGrid: "Crear Cuadrícula",
            CreatePad: "Crear Bloque",
            CreateTask: "Crear Tarea",
            CreateExpense: "Crear Gasto",
            AddUser: "Agregar Usuario",
        },
        reactionDescription: {
            [ReactionType.Like]: "Me Gusta",
            [ReactionType.Love]: "Amor",
            [ReactionType.NoReaction]: "Me Gusta",
            [ReactionType.Support]: "Aprecio",
            [ReactionType.Celebrate]: "Celebrar",
            [ReactionType.Curious]: "Curioso",
        },
        PostTypePlaceHolder: {
            [PostType.DEFAULT]: "¿Qué tienes en mente?",
            [PostType.POLL]: "Haz una pregunta...",
        },
        Titlecantbeempty: "El título no puede estar vacío",
        Attachmentscantbeempty: "Los archivos adjuntos no pueden estar vacíos",
        Optioncantbeempty: "La opción no puede estar vacía",
        SuccessfullySharedonFeed: "Compartido en el Feed exitosamente",
        SuccessfullySharedonMembersProfile: "Compartido exitosamente en el perfil del miembro",
        SuccessfullyPostSharedMessage:{
        [PostReferenceType.MAIN_FEED]: "Compartido exitosamente en el Feed principal",
        [PostReferenceType.GROUP]: "Compartido exitosamente en el Grupo",
        [PostReferenceType.PROJECTS]: "Compartido exitosamente en el Proyecto",
        [PostReferenceType.TIMELINE]: "Compartido exitosamente en la Línea de tiempo",
        },
    },
    mail: {
        composer: "Redactar",
        menuItem: {
            inbox: "Bandeja de Entrada",
            starred: "Destacados",
            snoozed: "Pospuestos",
            sent: "Enviados",
            spam: "Spam",
            archive: "Archivo",
            trash: "Papelera",
            draft: "Borrador",
            allMail: "Todos los Correos",
            createNewLabel: "Crear Nueva Etiqueta",
        },
        close: "Cerrar",
        send: "Enviar",
        newMessage: "Nuevo Mensaje",
        newEmail: "Crear Nuevo Correo",
        ComposeEmail: "Redactar Correo Electrónico",
    },
    messenger: {
        Messenger: "Mensajería",
        Typing: "Escribiendo...",
        TypeaMessage: "Escribe un Mensaje...",
        Recording: "Grabando...",
        nameYourGroup: "Nombrar tu Grupo",
        Create: "Crear",
        GroupNameRequired: "Nombre de Grupo Requerido",
        searchMemberToAdd: "Buscar Miembro para Agregar",
        contacts: "Contactos",
        selected: "Seleccionado",
        whatwouldyoutalkingabout: "¿De qué te gustaría hablar?",
        SayHi: "Saluda a tus Contactos",
    },
    marketPlace: {
        jobBoardName: "Tablero de Trabajo",
        requsitionName: "Requisición",
    },
    discussionBoard: {
        DiscussionBoard: "Tablero de Discusión",
        Topic: "Tema",
        pleaseEnterTopicName: "Por favor, ingrese el nombre del tema",
        enterTopicName: "Ingrese el nombre del tema",
        addDiscussionBoard: "Agregar Tablero de Discusión",
        errormessage: "Se produjo un error al obtener el tablero de discusión.",
        noDataMessage: "– ¡Crea una discusión ahora!",
    },
    pages: {
        messageName: "Por favor, ingrese el nombre",
        tags: "Etiquetas",
        messageTags: "Por favor, ingrese las etiquetas",
        enterTag: "Ingrese las etiquetas",
        createPage: "Crear Página",
        noDataMessage: "– ¡Crea una página ahora!",
    },
    group: {
        group: "Grupos",
        createGroup: "Crear Grupo",
        Updategroup: "Actualizar grupo",
        enterGroupName: "Por favor, ingrese el nombre del grupo.",
        Code: "Código",
        exampleText: "Por ejemplo, G (para un nombre de grupo)",
        GroupTag: "Etiqueta de Grupo",
        AddAdmin: "Agregar Administrador",
        TotalBudget: "Presupuesto Total",
        Spent: "Gastado",
        Balance: "Balance",
        EnterGroupTag: "Ingrese la etiqueta del grupo",
        SaveGroupTag: "Guardar etiqueta de grupo",
        AddGroupTag: "Agregar etiqueta de grupo",
        EditGroup: "Editar Grupo",
        CreateGroupNow: "¡Crea un grupo ahora!"
    },
    project: {
        WIP: "En Proceso",
        privateProject: "Proyecto Privado",
        Projects: "Proyectos",
        UpdateProject: "Actualizar Proyecto",
        CreateProject: "Crear Proyecto",
        noDataMessage: "– ¡Crea un proyecto ahora!",
        ProjectTag: "Etiqueta de Proyecto",
        ProjectRole: "Rol de Proyecto",
        exampleProjectName: "Por ejemplo, P (para un nombre de proyecto)",
        projectDate: "Fecha del Proyecto",
        EnterProjectTag: "Ingrese la Etiqueta del Proyecto",
        SaveProjectTag: "Guardar Etiqueta del Proyecto",
        AddProjectTag: "Agregar Etiqueta del Proyecto",
        editProject: "Editar Proyecto",
        PersonalNotes: "Notas Personales",
        EnterProjectRole: "Ingrese el Rol de Proyecto",
        SaveCustomTag: "Guardar Etiqueta Personalizada",
        AddProjectRole: "Agregar Rol de Proyecto",
        TakeaNote: "Tomar una Nota",
        PublicProject: "Proyecto público",
    },
    tasks: {
        Tasks: "Tareas",
        Predecessor: "Predecesor",
        AssignTo: "Asignar A",
        TaskDate: "Fecha de la Tarea",
        CreateTask: "Crear Tarea",
        TaskAssignTo: "Asignar Tarea A",
        Rating: "Calificación",
        TaskDetail: "Detalles de la Tarea",
        ProgressofAssignedmembers: "Progreso de los Miembros Asignados",
        SubTasks: "Subtareas",
        SubGoals: "Submetas",
        Subtask: "Subtarea",
        CreateSubtask: "Crear Subtarea",
        Addsubtask: "Agregar subtarea",
        AddGoal: "Agregar Meta",
        ParentTask: "Tarea Principal",
        DueToday: "Vence Hoy",
        DueTomorrow: "Vence Mañana",
        Overdue: "Vencida",
        Duein: "Vence en",
        days: "días",
        TaskCreatedSuccessfully: "Tarea creada exitosamente",
        noDataMessage: "– ¡Crea una tarea ahora!",
        selfTaskEnum: {
            [SelfTaskEnum.SelfTask]: "Tarea Personal",
            [SelfTaskEnum.AssignTask]: "Asignar Tarea A",
        },
        Priority: "Prioridad",
        taskFilterTypeEnum: {
            [TaskFilterTypeEnum.CreatedByMe]: "Asignadas Por Mí",
            [TaskFilterTypeEnum.MyTasks]: "Mis Tareas",
            [TaskFilterTypeEnum.MyTeamTasks]: "Tareas de Mi Equipo",
        },
        statusEnum: {
            [TaskStatusEnum.NotStarted]: "No Iniciada",
            [TaskStatusEnum.InProcess]: "En Proceso",
            [TaskStatusEnum.Completed]: "Completada",
            [TaskStatusEnum.RatingAssign]: "Calificación Asignada",
            [TaskStatusEnum.Cancel]: "Cancelada",
            [TaskStatusEnum.Hold]: "En Espera",
        },
        taskReferenceTypeEnum: {
            [TaskReferenceTypeEnum.General]: "General",
            [TaskReferenceTypeEnum.Project]: "Proyecto",
            [TaskReferenceTypeEnum.Group]: "Grupo",
            [TaskReferenceTypeEnum.Lead]: "Liderazgo",
        },
        taskPriorityEnum: {
            [TaskPriorityEnum.Default]: "Por Defecto",
            [TaskPriorityEnum.Low]: "Baja",
            [TaskPriorityEnum.Medium]: "Media",
            [TaskPriorityEnum.High]: "Alta",
        },
        SortEnum: {
            [TaskFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [TaskFilterSortEnum.CreateDateAsc]: "Fecha de Creación - Ascendente",
            [TaskFilterSortEnum.StartDateDesc]: "Fecha de Inicio - Descendente",
            [TaskFilterSortEnum.StartDateAsc]: "Fecha de Inicio - Ascendente",
            [TaskFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [TaskFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización - Ascendente"
        }
    },
    contracts: {
        Contract: "Contrato",
        createContract: "Crear Contrato",
        contractDate: "Fecha del Contrato",
        contractPurpose: "Propósito del Contrato",
        selectContractPurpose: "Selecciona el Propósito del Contrato",
        purposeofContractisRequired: "El Propósito del Contrato es Requerido",
        category: "Categoría",
        purpose: "Propósito",
        signatureMember: "Miembros Firmantes",
        contractDetail: "Detalle del Contrato",
        selectCategory: "Selecciona Categoría",
        cateogryofContractisRequired: "Categoría es Requerida",
        externalMember: "Miembros Firmantes Externos",
        selectinternalMembers: "Selecciona Miembros Firmantes Internos",
        internalMembers: "Miembros Firmantes Internos",
        selectExternalMember: "Selecciona Miembros Firmantes Externos",
        contractType: "Tipo de Contrato",
        contractTypeRequired: "Tipo de Contrato es Requerido",
        internal: "Interno",
        external: "Externo",
        noDataMessage: "¡Crea un contrato ahora!",
        contractsFilterTypeLables: {
            [ContractFilterTypeEnum.Contracts]: "Todos los Contratos Comerciales",
            [ContractFilterTypeEnum.BusinessContract]: "Mis Contratos"
        },
        statusEnum: {
            [ContractStatusEnum.InProcess]: "En Proceso",
            [ContractStatusEnum.Completed]: "Completado",
            [ContractStatusEnum.Cancelled]: "Cancelado"
        },
        contractReferenceTypeEnum: {
            [ContractReferenceTypeEnum.General]: "General",
            [ContractReferenceTypeEnum.Project]: "Proyecto",
            [ContractReferenceTypeEnum.Group]: "Grupo",
            [ContractReferenceTypeEnum.Lead]: "Lead"
        }
    },
    workBoard: {
        WorkBoardDashBoard: "Tablero de Trabajo",
        pleaseEnterYourBoardName: "Por favor, ingrese el nombre de su tablero",
        pleaseEnterYourBoardDescription: "Por favor, ingrese la descripción de su tablero",
        EnterMembers: "Ingresar Miembros",
        UpdateBoard: "Actualizar Tablero",
        CreateBoard: "Crear Tablero",
        privacy: "Privacidad",
        AddSection: "Agregar Sección",
        AddTodo: "Agregar Tarea",
        Save: "Guardar",
        EnterSectionTitle: "Ingrese el Título de la Sección",
        InSection: "En la Sección",
        Label: "Etiqueta",
        Activity: "Actividad",
        Labels: "Etiquetas",
        UploadCoverPhoto: "Subir Foto de Portada",
        Section: "Sección",
        Board: " Tablero",
        Todos: "Tareas",
        CompleteDate: "Fecha de Completado",
        DetailedDesc: "Agregar una Descripción Detallada...",
        EnterTodo: "Ingrese el texto para esta tarjeta...",
        CreateWorkBoardNow: "- ¡Crea un Tablero de Trabajo ahora!",
        workBoardExample: "Por ejemplo, TB (para un tablero llamado Tablero de Trabajo)",
    },
    leadManager: {
        LeadManager: "Gestor de Clientes Potenciales",
        AssignedLeads: "Clientes Potenciales Asignados",
        AllLeads: "Todos los Clientes Potenciales",
        Groups: "Grupos",
        email: "Correo Electrónico",
        Lead: "Cliente Potencial",
        Leads: "Clientes Potenciales",
        Pleaseinputyourboardname: "¡Por favor ingrese el nombre de su tablero!",
        exampleLeadManagerMessage: "Por ejemplo, GM (para un grupo llamado Gestor de Clientes Potenciales)",
        inputBoardDescription: "¡Por favor ingrese la descripción de su tablero!",
        UpdateLeadManager: "Actualizar Gestor de Clientes Potenciales",
        CreateLeadManager: "Crear Gestor de Clientes Potenciales",
        CreateLeadGroup: "Crear Grupo de Clientes Potenciales",
        noDataMessage: "– ¡Cree un Gestor de Clientes Potenciales ahora!",
        LastUpdated: "Última Actualización",
        LeadValue: "Valor del Cliente Potencial",
        individual: "Individual",
        leadEmailAddress: "Dirección de Correo Electrónico del Cliente Potencial",
        leadPhoneNumber: "Número de Teléfono del Cliente Potencial",
        leadAddress: "Dirección del Cliente Potencial",
        leadUrl: "URL del Cliente Potencial",
        ConvertProject: "Convertir a Proyecto",
        goToProject: "Ir al Proyecto",
        SelectAssignMembers: "Seleccionar Miembros Asignados",
        LeadGroup: "Grupo de Clientes Potenciales",
        leadName: "Nombre del Cliente Potencial",
        leadMembers: "Miembros del Cliente Potencial",
        leadWebiste: "Sitio Web del Cliente Potencial",
        LeadValuenegative: "¡El valor del Cliente Potencial no puede ser negativo!",
        AddLead: "Agregar Cliente Potencial",
        SelectCommunicationTo: "Seleccionar Comunicación Para",
        CommunicationTypeisrequired: "¡Se requiere tipo de comunicación!",
        PleaseselectCommunicationType: "Por favor seleccione tipo de comunicación",
        CommunicationType: "Tipo de Comunicación",
        AddCommunication: "Agregar Comunicación",
        CommunicationTo: "Comunicación Para",
        NoCalls: "Sin Llamadas",
        LeadsValue: "Valor de los Clientes Potenciales",
        metricTypeEnum: {
            [LeadManagerMetricTypeEnum.allGroup]: "Total de Grupos",
            [LeadManagerMetricTypeEnum.allAssign]: "Clientes Potenciales Asignados",
            [LeadManagerMetricTypeEnum.leadToProject]: "Convertir a Proyecto",
            [LeadManagerMetricTypeEnum.leadSection]: "Sección con Mayor Cantidad de Clientes Potenciales",
        },
    },
    schedule: {
        StartMeeting: "Iniciar Reunión",
        MeetinginProgress: "Reunión en Progreso",
        EndMeeting: "Finalizar Reunión",
        MeetingEnded: "Reunión Finalizada",
        NewTokenAdded: "Nuevo Token Agregado",
        Copied: "Copiado",
        SuccessfullyBlocked: "Bloqueado Exitosamente",
        SuccessfullyUnBlocked: "Desbloqueado Exitosamente",
        UpdateCalendarLink: "Actualizar Enlace del Calendario",
        AddCalendarLink: "Agregar Enlace del Calendario",
        GetCalendarLink: "Obtener Enlace del Calendario",
        CopyLink: "Copiar Enlace",
        Block: "Bloquear",
        UnBlock: "Desbloquear",
        CreateSchedule: "Crear Horario",
        Draft: "Borrador",
        Finalize: "Finalizar",
        Complete: "Completar",
        Preview: "Vista Previa",
        MinutesOftheMeeting: "Minutos de la Reunión",
        MeetingId: "ID de la Reunión",
        Venue: "Lugar",
        Workwisecall: "Llamada de Trabajo",
        ConferenceCall: "Llamada de Conferencia",
        Attendees: "Asistentes",
        Absentees: "Ausentes",
        PointsofDiscussion: "Puntos de Discusión",
        AddPoint: "Agregar Punto",
        Print: "Imprimir",
        SaveToPDF: "Guardar como PDF",
        MEETINGMINUTES: "MINUTOS DE LA REUNIÓN",
        Yes: "Sí",
        No: "No",
        WorkwiseCall: "Llamada de Trabajo",
        MeetingObjectives: "Objetivos de la Reunión",
        Points: "Puntos",
        RefNo: "Ref No",
        AttendanceatMeeting: "Asistencia a la Reunión",
        Loadingschedules: "Cargando horarios...",
        Nodata: "Sin datos...",
        Projects: "Proyectos",
        Groups: "Grupos",
        titleMessage: "¿Estás seguro? Cambiar el tipo removerá los miembros asignados.",
        pleaseSelectAssignMemeber: "¡Por favor selecciona un miembro asignado!",
        SelectAssignto: "Seleccionar Asignado a",
        GetLink: "Obtener Enlace",
        Anyone: "Cualquiera",
        anyoneText: "con el enlace puede crear una cita.",
        TodayEvents: "Eventos de Hoy",
        UpcomingEvents: "Próximos Eventos",
        InterviewRequests: "Solicitudes de Entrevista",
        sendviachat: "Enviar por chat",
        OnVideoConference: "En Conferencia de Video:",
        OnWorkwiseCall: "En Llamada de Trabajo",
        Enterlocation: "Ingresar Ubicación",
        Duration: "Duración",
        inminutes: "en minutos",
        Durationisrequired: "Se requiere duración",
        Rooms: "Salas",
        SelectRoom: "Seleccionar Sala",
        CheckRoomAvailability: "Verificar Disponibilidad de la Sala",
        Available: "Disponible",
        NotAvailable: "No Disponible",
        TravelTime: "Tiempo de Viaje",
        PreparationTime: "Tiempo de Preparación",
        SendviaEmail: "Enviar por Correo Electrónico",
        schedulerCalender: "Calendario de Horarios",
        Next: "Siguiente",
        Prev: "Anterior",
        titlemyCustomButton: "título myCustomButton",
        prevnexttoday: "anterior siguiente hoy",
        timeGridDaytimeGridWeekdayGridMonth: "timeGridDay timeGridWeek dayGridMonth",
        timeGridDay: "timeGridDay",
        top: "arriba",
        block: "bloque",
        minutes: "minutos",
        AllDay: "Todo el Día",
        timeGridWeek: "timeGridWeek",
        slotDuration: "00:15:00",
        numeric: "numérico",
        dayGridMonth: "dayGridMonth",
        dayGridWeek: "dayGridWeek",
        timeGrid: "timeGrid",
        Day: "Día",
        Week: "Semana",
        Month: "Mes",
        NoEventsonthisday: "No hay eventos en este día.",
        CreateInterviewSchedule: "Crear Horario de Entrevista",
        Interviewwith: "Entrevista con",
        MySchedules: "Mis Horarios",
        TeamSchedules: "Horarios del Equipo",
        Past: "Pasado",
        Today: "Hoy",
        Upcoming: "Próximos",
        ScheduleDetail: "Detalles del Horario",
        JoinWorkwiseCall: "Unirse a Llamada de Trabajo",
        AddWorkwiseCall: "Agregar Llamada de Trabajo",
        Host: "(Anfitrión)",
        MakeHost: "Convertirse en Anfitrión",
        Attending: "Asistiendo",
        UpdateSchedule: "Actualizar Horario",
        Calendar: "Calendario",
        Schedule: "Horario",
        ShareCalendarLink: "Compartir Enlace del Calendario",
        pointer: "puntero",
        default: "por defecto",
        blue: "azul",
        red: "rojo",
        purple: "morado",
        Self: "Personal",
        AssignTo: "Asignar A",
        assign: "asignar",
        min: "min",
        Minutes: "15 minutos",
        MeetingTranscript: "Transcripción de la Reunión",
    },
    expenses: {
        CreateExpense: "Crear Gasto",
        IsReimbursable: "Es Reembolsable",
        WriteHeaderHere: "Escribe el Encabezado Aquí...",
        ExpenseDetail: "Detalles del Gasto",
        ExpenseDate: "Fecha del Gasto",
        Executors: "Ejecutores",
        Financers: "Financiadores",
        ExecutorRequired: "Se requiere un Ejecutor",
        selectExecutor: "Seleccionar el Ejecutor del Gasto",
        noDataMessage: "Empodera tu viaje financiero – ¡Crea un Gasto ahora!",
        expenseFilterTypeEnum: {
            [ExpenseFilterTypeEnum.MyExpense]: "Mis Gastos",
            [ExpenseFilterTypeEnum.ForApproval]: "Para Aprobación",
            [ExpenseFilterTypeEnum.ForExecutions]: "Para Ejecución",
            [ExpenseFilterTypeEnum.ForFinance]: "Para Finanzas",
        },
        expenseType: {
            [ExpenseReferenceTypeEnum.General]: "General",
            [ExpenseReferenceTypeEnum.Group]: "Grupo",
            [ExpenseReferenceTypeEnum.Project]: "Proyecto",
            [ExpenseReferenceTypeEnum.Travel]: "Viaje",
            [ExpenseReferenceTypeEnum.Assets]: "Activos",
        },
        statusEnum: {
            [ExpenseApprovalStatusEnum.InProcess]: "En Proceso",
            [ExpenseApprovalStatusEnum.Approved]: "Aprobado",
            [ExpenseApprovalStatusEnum.Declined]: "Rechazado",
            [ExpenseApprovalStatusEnum.Cancelled]: "Cancelado",
            [ExpenseApprovalStatusEnum.Completed]: "Completado",
            [ExpenseApprovalStatusEnum.Hold]: "En Espera",
            [ExpenseApprovalStatusEnum.Disbursed]: "Desembolsado",
            [ExpenseApprovalStatusEnum.WaitingForDisburse]: "Esperando Desembolso",
        },

        SortEnum: {
            [ExpenseFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [ExpenseFilterSortEnum.CreateDateAsc]: "Fecha de Creación",
            [ExpenseFilterSortEnum.ExpenseDateDesc]: "Fecha del Gasto - Descendente",
            [ExpenseFilterSortEnum.ExpenseDate]: "Fecha del Gasto",
            [ExpenseFilterSortEnum.ReferenceNo]: "Número de Referencia",
            [ExpenseFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [ExpenseFilterSortEnum.Amount]: "Cantidad",
            [ExpenseFilterSortEnum.AmountDesc]: "Cantidad - Descendente"
        },
        Category: {
            [ExpenseCategoryEnum.Transport]: "Transporte",
            [ExpenseCategoryEnum.Health]: "Salud",
            [ExpenseCategoryEnum.Food]: "Comida",
            [ExpenseCategoryEnum.Shopping]: "Compras",
            [ExpenseCategoryEnum.Entertainment]: "Entretenimiento",
            [ExpenseCategoryEnum.Travel]: "Viaje",
            [ExpenseCategoryEnum.Bill]: "Factura",
            [ExpenseCategoryEnum.Office]: "Oficina",
            [ExpenseCategoryEnum.Fuel]: "Combustible",
            [ExpenseCategoryEnum.Asset]: "Activo",
            [ExpenseCategoryEnum.Other]: "Otro"
        }
    },
    travel: {
        TravelDetail: "Detalles del Viaje",
        CreateTravel: "Crear Viaje",
        PleaseAddTravelDetail: "Por favor, Agrega Detalles del Viaje",
        TravelToandFrom: "Viaje De y A",
        ReturnDateIsRequired: "Se requiere la Fecha de Regreso",
        PleaseSelectTravelType: "Por favor, Selecciona el Tipo de Viaje",
        HotelTADACar: "Hotel, TADA y Coche",
        HotelRequired: "Hotel Requerido",
        RentaCar: "Alquilar un Coche",
        PickUpRequired: "Recogida Requerida",
        DropOffRequired: "Entrega Requerida",
        TADAApplicable: "TADA Aplicable",
        TravelBy: "Viaje Por",
        SpecialRequest: "Solicitud Especial",
        SearchDestinationCity: "Buscar Ciudad de Destino",
        SearchDepartureCity: "Buscar Ciudad de Salida",
        PleaseSelectDepartureCity: "Por favor, Selecciona la Ciudad de Salida.",
        PleaseSelectDestinationCity: "Por favor, Selecciona la Ciudad de Destino.",
        DepartureTimeIsRequired: "Se requiere la Hora de Salida",
        RetunTimeIsRequired: "Se requiere la Hora de Regreso",
        AttachmentType: "Tipo de Adjunto",
        WriteSpecialRequestDetail: "Escribe Detalles de la Solicitud Especial",
        Agents: "Agentes",
        Departure: "Salida",
        Arrival: "Llegada",
        DepartureDate: "Fecha de Salida",
        DepartureTime: "Hora de Salida",
        DepartureCity: "Ciudad de Salida",
        ShiftType: "Tipo de Turno",
        DepartureShift: "Turno de Salida",
        ArrivalCity: "Ciudad de Llegada",
        CreateTravelnow: "Crear Viaje ahora",
        UpdateTravel: "Actualizar Viaje",
        TravelAttachmentTypeOptions: {
            [TravelAttachmentTypeEnum.Passport]: "Pasaporte",
            [TravelAttachmentTypeEnum.Ticket]: "Billete",
            [TravelAttachmentTypeEnum.Insurance]: "Seguro",
            [TravelAttachmentTypeEnum.CarRental]: "Alquiler de Coche",
            [TravelAttachmentTypeEnum.Other]: "Otro",
        },

        Shift: {
            [ShiftEnum.Morning]: "Mañana",
            [ShiftEnum.Afternoon]: "Tarde",
            [ShiftEnum.Evening]: "Noche",
            [ShiftEnum.Night]: "Noche",
        },

        travelBy: {
            plane: "En Avión",
            bus: "En Autobús",
            ship: "En Barco",
            train: "En Tren",
            car: "En Coche",
        },
        TravelOptions: {
            [TravelOptionsEnums.ByPlane]: "En Avión",
            [TravelOptionsEnums.ByShip]: "En Barco",
            [TravelOptionsEnums.ByBus]: "En Autobús",
            [TravelOptionsEnums.ByTrain]: "En Tren",
            [TravelOptionsEnums.ByCar]: "En Coche",
        },

        travelFilterTypeEnum: {
            [TravelFilterTypeEnum.Travels]: "Viajes",
            [TravelFilterTypeEnum.MyTravel]: "Mi Viaje",
            [TravelFilterTypeEnum.ForApproval]: "Para Aprobación",
            [TravelFilterTypeEnum.AgentProcess]: "Proceso de Agente",
        },

        statusEnum: {
            [TravelStatusEnum.InProcess]: "En Proceso",
            [TravelStatusEnum.Approved]: "Aprobado",
            [TravelStatusEnum.Declined]: "Rechazado",
            [TravelStatusEnum.Cancelled]: "Cancelado",
            [TravelStatusEnum.ApprovedByApprovers]: "Aprobado por Aprobadores",
            [TravelStatusEnum.ApprovedByAgents]: "Aprobado por Agentes",
            [TravelStatusEnum.DeclineByApproves]: "Rechazado por Aprobadores",
            [TravelStatusEnum.DeclineByAgents]: "Rechazado por Agentes",
            [TravelStatusEnum.Hold]: "En Espera",
        },
        SortEnum: {
            [TravelFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [TravelFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización",
            [TravelFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [TravelFilterSortEnum.ReferenceNoAsc]: "Número de Referencia",
            [TravelFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [TravelFilterSortEnum.CreateDateAsc]: "Fecha de Creación",
            [TravelFilterSortEnum.TravelEndDateDesc]: "Fecha de Fin del Viaje",
            [TravelFilterSortEnum.TravelStartDate]: "Fecha de Inicio del Viaje"
        }
    },
    attendence: {
        Attendance: "Asistencia",
        TimeSpent: "Tiempo Transcurrido",
        GraceTime: "Tiempo de Gracia",
        GoodDay: "Buen Día",
        Howfeeling: "¿Cómo te sientes hoy?",
        Note: "Nota",
        Noattendanceforapproval: "No hay asistencia para aprobar.",
        Iwaslate: "Llegué tarde debido al tráfico.",
        Showtimespent: "Mostrar tiempo transcurrido",
        ClockIn: "Registrarse para comenzar tu registro de asistencia.",
        selectStatus: "¡Por favor selecciona un estado!",
        ByTime: "Por Tiempo",
        ByStatus: "Por Estado",
        DeductionPolicy: "Política de Deducción",
        UpdateDeductionPolicy: "Actualizar Política de Deducción",
        AddDeductionPolicy: "Agregar Política de Deducción",
        DaysRange: "Rango de Días",
        PerDay: "Por Día",
        SaveDeductionPolicy: "Guardar Política de Deducción",
        AbsentPolicy: "Política de Ausencia",
        LatePolicy: "Política de Tardanzas",
        IsPercentage: "Es Porcentaje",

        attendanceFilterTypeEnum: {
            [AttendanceFilterTypeEnum.checkInForApproval]: "Registro de Entrada Para Aprobación",
            [AttendanceFilterTypeEnum.dayWiseAttendance]: "Asistencia por Día",
            [AttendanceFilterTypeEnum.userAttendance]: "Asistencia del Usuario",
            [AttendanceFilterTypeEnum.Summary]: "Resumen",
        },

        updateErrorMessage: {
            [AttendanceStateEnum.Off]: "La acción de actualización no está disponible para el estado de descanso",
            [AttendanceStateEnum.Holiday]: "La acción de actualización no está disponible para días festivos",
            [AttendanceStateEnum.Leave]: "La acción de actualización no está disponible para estado de permiso",
        },
        statusEnum: {
            [AttendanceStatusEnum.InProcess]: "En Proceso",
            [AttendanceStatusEnum.Approved]: "Aprobado",
            [AttendanceStatusEnum.Declined]: "Rechazado",
        },
        moodLabels: {
            [AttendanceMoodEnum.VeryUnsatisfied]: "Muy Insatisfecho",
            [AttendanceMoodEnum.Unsatisfied]: "Insatisfecho",
            [AttendanceMoodEnum.Neutral]: "Neutral",
            [AttendanceMoodEnum.Satisfied]: "Satisfecho",
            [AttendanceMoodEnum.VerySatisfied]: "Muy Satisfecho",
        },
        attendanceState: {
            [AttendanceStateEnum.Unknown]: "",
            [AttendanceStateEnum.Present]: "Presente",
            [AttendanceStateEnum.Absent]: "Ausente",
            [AttendanceStateEnum.Leave]: "Permiso",
            [AttendanceStateEnum.Late]: "Tardanza",
            [AttendanceStateEnum.WorkFromHome]: "Trabajar Desde Casa",
            [AttendanceStateEnum.Off]: "Descanso",
            [AttendanceStateEnum.Holiday]: "Día Festivo",
        },
        attendanceType: {
            [ATTENDANCE_ENUM.CHECK_IN.IN]: "Registro de Entrada",
            [ATTENDANCE_ENUM.CHECK_IN.OUT]: "Registro de Salida",
        },
        attendanceMetricLabel: {
            [AttendanceMetricTypeEnum.overallTimeSpent]: "Tiempo Total Transcurrido",
            [AttendanceMetricTypeEnum.Present]: "Presente",
            [AttendanceMetricTypeEnum.Absent]: "Ausente",
            [AttendanceMetricTypeEnum.Leave]: "Permiso",
            [AttendanceMetricTypeEnum.Late]: "Tardanza",
        },
    },
    eLearning: {
        Course: "Curso",
        eBook: "Libro electrónico",
        Quiz: "Cuestionario",
        TedTalk: "Charla TED",
        Article: "Artículo",
        Video: "Video",
        BasicCourseDetail: "Detalle básico del curso",
        SelectSubject: "Seleccionar tema",
        SelectCourse: "Seleccionar curso",
        PleaseSelectOption: "Por favor, selecciona una opción",
        Selectcoursetype: "Seleccionar tipo de curso",
        CourseName: "Nombre del curso",
        Entercoursename: "Ingresa el nombre del curso",
        Level: "Nivel",
        PleaseSelectLevel: "Por favor, selecciona un nivel",
        Selectlevel: "Seleccionar nivel",
        CourseAdminMembers: "Miembros administrativos del curso",
        CourseAdminisrequired: "Se requiere administrador del curso",
        AssignMembers: "Asignar miembros",
        UpdateEBook: "Actualizar libro electrónico",
        CreateEBook: "Crear libro electrónico",
        AuthorName: "Nombre del autor",
        EnterInformation: "Ingresar información",
        UploadBook: "Subir libro (PDF)",
        CreateQuiz: "Crear cuestionario",
        QuizName: "Nombre del cuestionario",
        CreateTedTalk: "Crear charla TED",
        ViaLink: "A través de enlace",
        ViaVideoUpload: "A través de carga de video",
        PleaseInsertLink: "Por favor, inserta un enlace",
        UploadVideo: "Subir video",
        CreateArticle: "Crear artículo",
        InsertLink: "Insertar enlace",
        UploadPDF: "Subir foto o PDF",
        CreateVideo: "Crear video",
        LessonDetail: "Detalle de la lección",
        Eitherselect: "Selecciona un cuestionario o elimínalo.",
        Selectquiz: "Selecciona un cuestionario",
        AddNewQuiz: "Agregar nuevo cuestionario",
        Atleastonetopic: "Se requiere al menos un tema de esta lección.",
        LessonNameRequired: "Se requiere el nombre de la lección.",
        LessonName: "Nombre de la lección",
        DeleteLesson: "Eliminar lección",
        LessonDescriptionRequired: "Se requiere la descripción de la lección.",
        TopicNameIsRequired: "Se requiere el nombre del tema.",
        TopicName: "Nombre del tema",
        DeleteTopic: "Eliminar tema",
        TopicDescription: "Se requiere la descripción del tema.",
        eitherEnterText: "Ingresa texto o elimínalo.",
        EitherenterA: "Ingresa un",
        OrRemoveIt: "o elimínalo.",
        CreateArticlesNow: "¡Crea artículos ahora!",
        CreateCoursesNow: "¡Crea cursos ahora!",
        CreateEbooksNow: "¡Crea libros electrónicos ahora!",
        CreateElearningNow: "¡Crea e-learning ahora!",
        CreateQuizzesNow: "¡Crea cuestionarios ahora!",
        CreateTeamDashboardNow: "¡Crea el panel de equipo ahora!",
        CreateTedTalksNow: "¡Crea charlas TED ahora!",
        CreateVideosNow: "¡Crea videos ahora!",
        OptionEmpty: "La opción no puede estar vacía",
        CorrectOptionRequired: "Se requiere la opción correcta",
        QuestionEmpty: "La pregunta no puede estar vacía",
        UntitledQuestion: "Pregunta sin título",
        AddQuestion: "Agregar pregunta",
        DeleteQuestion: "Eliminar pregunta",
        Quiznamerequired: "¡Se requiere el nombre del cuestionario!",
        QuizexpirationDate: "Fecha de vencimiento del cuestionario",
        QuizExpirationDateIsRequired: "Se requiere la fecha de vencimiento del cuestionario",
        QuizDescriptionRequired: "¡Se requiere la descripción del cuestionario!",
        StartQuiz: "Comenzar cuestionario",
        GoToResults: "Ir a resultados",
        Gettoknown: "Conoce la experiencia del usuario",
        Missingdata: "Datos faltantes.",
        UntitledLesson: "Lección sin título",
        RemoveText: "Eliminar texto",
        AddText: "Agregar texto",
        RemoveImage: "Eliminar imagen",
        AddImage: "Agregar imagen",
        RemoveVideo: "Eliminar video",
        AddVideo: "Agregar video",
        RemovePDF: "Eliminar PDF",
        AddPDF: "Agregar PDF",
        RemoveQuiz: "Eliminar cuestionario",
        AddQuiz: "Agregar cuestionario",
        RemoveLink: "Eliminar enlace",
        AddLink: "Agregar enlace",
        RemoveYouTubeLink: "Eliminar enlace de YouTube",
        AddYouTubeLink: "Agregar enlace de YouTube",
        RemoveExternalCourse: "Eliminar curso externo",
        AddExternalCourse: "Agregar curso externo",
        UntitledTopic: "Tema sin título",
        DashboardChart: "Gráfico del tablero",
        LatestTrendInBusiness: "Última tendencia en negocios",
        InformationWillComeHere: "La información aparecerá aquí",
        CourseContent: "Contenido del curso",
        DummyContent: "Contenido ficticio",
        EndCourse: "Finalizar curso",
        Chapters: "Capítulos •",
        Lectures: "Conferencias •",
        LessonsInThisProgram: "Lecciones en este programa",
        BookInformation: "Información del libro",
        TedTalksDaily: "Charlas TED diarias",
        TopicTitle: "Título del tema",
        VisitThis: "Visitar esto",
        LearnPractical: "Aprende habilidades prácticas a través de cursos y obtén aprendizajes remunerados.",
        Courses: "Cursos",
        Book: "Libro",
        Tedtalk: "Charla TED",
        Articles: "Artículos",
        NoPageExist: "No existe la página",
        Books: "Libros",
        TedTalks: "Charlas TED",
        Videos: "Videos",
        GoTo: "Ir a",
        CourseLevelIdLabel: {
            [CourseLevelIdEnum.Advance]: "Avanzado",
            [CourseLevelIdEnum.Intermediate]: "Intermedio",
            [CourseLevelIdEnum.Beginner]: "Principiante"
        }
    },
    customApproval: {
        createCustomApproval: "Crear Aprobación Personalizada",
        customApproval: "Aprobación Personalizada",
        noDataMessage: "– ¡Cree Aprobaciones Personalizadas ahora!",
        customApprovalFilterTypeEnum: {
            [CustomApprovalFilterTypeEnum.All]: "Todas las Aprobaciones Personalizadas",
            [CustomApprovalFilterTypeEnum.CreatedByMe]: "Mis Aprobaciones Personalizadas",
            [CustomApprovalFilterTypeEnum.ForApproval]: "Para Aprobación",
        },
        statusEnum: {
            [CustomApprovalStatusEum.InProcess]: "En Proceso",
            [CustomApprovalStatusEum.Approved]: "Aprobado",
            [CustomApprovalStatusEum.Declined]: "Rechazado",
            [CustomApprovalStatusEum.Resend]: "Reenviar",
            [CustomApprovalStatusEum.InActive]: "Inactivo",
            [CustomApprovalStatusEum.NotRequired]: "No Requerido",
            [CustomApprovalStatusEum.Cancelled]: "Cancelado",
            [CustomApprovalStatusEum.Hold]: "En Espera",
        },
        SortEnum: {
            [CustomApprovalFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [CustomApprovalFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización",
            [CustomApprovalFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [CustomApprovalFilterSortEnum.ReferenceNo]: "Número de Referencia",
            [CustomApprovalFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [CustomApprovalFilterSortEnum.CreateDateAsc]: "Fecha de Creación"
        }
    },
    reward: {
        CreateReward: "Crear Recompensa",
        Rewards: "Recompensas",
        RewardTo: "Recompensa Para",
        RewardCategory: "Categoría de Recompensa",
        AddRewardCategory: "Agregar Categoría de Recompensa",
        EnterRewardCategory: "Ingrese Categoría de Recompensa",
        RewardName: "Nombre de Recompensa",
        EnterRewardName: "Ingrese Nombre de Recompensa",
        PleaseEnterRewardName: "Por favor, ingrese el nombre de la recompensa",
        ReasonForReward: "Motivo de la Recompensa",
        EnterReasonForReward: "Ingrese Motivo de la Recompensa",
        PleaseEnterReasonForReward: "Por favor, ingrese el motivo de la recompensa",
        noDataMessage: "– ¡Crea una recompensa ahora!",
        rewardFilterTypeEnum: {
            [RewardFilterTypeEnum.All]: "Todas las Recompensas",
            [RewardFilterTypeEnum.CreatedByMe]: "Creadas Por Mí",
            [RewardFilterTypeEnum.ForApproval]: "Para Aprobación",
            [RewardFilterTypeEnum.RewardForMe]: "Mis Recompensas",
        },
        statusEnum: {
            [RewardStatusEnum.InProcess]: "En Proceso",
            [RewardStatusEnum.Approved]: "Aprobado",
            [RewardStatusEnum.Declined]: "Rechazado",
            [RewardStatusEnum.Cancelled]: "Cancelado",
            [RewardStatusEnum.Hold]: "En Espera",
        },
        SortEnum: {
            [RewardFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [RewardFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización",
            [RewardFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [RewardFilterSortEnum.ReferenceNo]: "Número de Referencia",
            [RewardFilterSortEnum.StatusDesc]: "Estado - Descendente",
            [RewardFilterSortEnum.Status]: "Estado",
            [RewardFilterSortEnum.CategoryDesc]: "Categoría - Descendente",
            [RewardFilterSortEnum.Category]: "Categoría",
            [RewardFilterSortEnum.NameDesc]: "Nombre - Descendente",
            [RewardFilterSortEnum.Name]: "Nombre",
            [RewardFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [RewardFilterSortEnum.CreateDateAsc]: "Fecha de Creación"
        }
    },
    document: {
        mySigned: "Firmado por mí",
        forMySignature: "Para mi firma",
        draftSignature: "Borrador",
        inSigning: "En firma",
        signaturesCompleted: "Firmas completadas",
        DocsArchive: "Documentos y Archivos",
        createDocument: "Crear Documento",
        CreateSlides: "Crear Presentación",
        CreateBoard: "Crear Pizarra",
        CreatePad: "Crear Bloc de Notas",
        CreateGrid: "Crear Cuadrícula",
        newFolder: "Nueva Carpeta",
        createFolder: "Crear Carpeta",
        uploadDocuments: "Subir Documentos",
        addBoard: "Agregar Pizarra",
        addPad: "Agregar Bloc de Notas",
        addGrid: "Agregar Cuadrícula",
        addSlide: "Agregar Presentación",
        addMindmap: "Agregar Mapa Mental",
        CompleteDocument: "Documento Completo",
        noDataMessage: "- ¡Crea un documento ahora!",
        AllDocuments: "Todos los Documentos",
        MyDrive: "Mi Drive",
        MyDocuments: "Mis Documentos Para Aprobación",
        ForApprovals: "Para Aprobación",
        OnlyFiles: "Solo Archivos",
        fileName: "Nombre del Archivo",
        shortDescription: "Descripción Corta (máx. 100 caracteres)",
        AllFiles: "Todos los Archivos",
        Grids: "Cuadrículas",
        Pads: "Bloc de Notas",
        Slides: "Presentaciones",
        Boards: "Pizarras",
        Mindmaps: "Mapas Mentales",
        updateFolder: "Actualizar Carpeta",
        updateGrid: "Actualizar Cuadrícula",
        updatePad: "Actualizar Bloc de Notas",
        updateSlide: "Actualizar Presentación",
        updateBoard: "Actualizar Pizarra",
        updateMindMap: "Actualizar Mapa Mental",
        updateDocuments: "Actualizar Documento",
        status: {
            InProcess: "En Proceso",
            Approved: "Aprobado",
            Declined: "Rechazado",
            Resend: "Reenviar",
            NotRequired: "No Requerido",
            Cancelled: "Cancelado",
            Draft: "Borrador"
        },
        documentReferenceType: {
            [DocumentReferenceTypeEnum.General]: "General",
            [DocumentReferenceTypeEnum.Group]: "Grupo",
            [DocumentReferenceTypeEnum.Project]: "Proyecto",
        },
    },
    warning: {
        WarningDetail: "Detalle de Advertencia",
        warningTo: "Advertencia Para",
        createWarning: "Crear Advertencia",
        enterwarningCategory: "Ingrese categoría de advertencia",
        noDataMessage: '– ¡Crea una advertencia ahora!',
        filter: "Filtrar",
        warningFilterTypeEnum: {
            [WarningFilterTypeEnum.Warning]: "Advertencias",
            [WarningFilterTypeEnum.CreatedByMe]: "Creadas Por Mí",
            [WarningFilterTypeEnum.ForApproval]: "Para Aprobación",
            [WarningFilterTypeEnum.WarningToMe]: "Advertencias Para Mí",
        },
        statusEnum: {
            [WarningStatusEnum.InProcess]: "En Proceso",
            [WarningStatusEnum.Approved]: "Aprobado",
            [WarningStatusEnum.Declined]: "Rechazado",
            [WarningStatusEnum.Cancelled]: "Cancelado",
            [WarningStatusEnum.Hold]: "En Espera",
        },
        SortEnum: {
            [WarningFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [WarningFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización - Ascendente",
            [WarningFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [WarningFilterSortEnum.ReferenceNo]: "Número de Referencia",
            [WarningFilterSortEnum.StatusDesc]: "Estado - Descendente",
            [WarningFilterSortEnum.Status]: "Estado",
            [WarningFilterSortEnum.CategoryDesc]: "Categoría - Descendente",
            [WarningFilterSortEnum.Category]: "Categoría",
            [WarningFilterSortEnum.NameDesc]: "Nombre - Descendente",
            [WarningFilterSortEnum.Name]: "Nombre",
            [WarningFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [WarningFilterSortEnum.CreateDateAsc]: "Fecha de Creación - Ascendente"
        }

    },
    jobBoard: {
        jobBoard: "Bolsa de Trabajo",
        noDataMessage: " – ¡Crea Carreras ahora!",
    },
    businessPolicy: {
        BusinessPolicy: "Política Empresarial",
        policies: "Políticas",
        noPolicy: "No se encontraron políticas...",
    },
    forms: {
        CreateForm: "Crear Formulario",
        Forms: "Formularios",
        Attempt: "Intento",
        CopyLink: "Copiar Enlace",
        Title: "Título",
        EnterTitle: "Ingrese Título",
        PleaseEnterTitle: "Por favor, ingrese el título",
        PleaseFillTheQuestionField: "Por favor, complete el campo de pregunta",
        EnterQuestion: "Ingrese Pregunta",
        SelectAnswerType: "Seleccionar Tipo de Respuesta",
        Text: "Texto",
        Number: "Número",
        Polls: "Encuestas",
        Poll: "Encuesta",
        AddQuestion: "Agregar Pregunta",
        AddOption: "Agregar Opción",
        Details: "Detalles",
        PoweredBy: "Desarrollado Por",
        Submit: "Enviar",
        EditForm: "Editar Formulario",
        EnterEmail: "Ingrese Correo Electrónico",
        Question: "Pregunta...",
        YourAnswer: "Tu Respuesta",
        noDataMessage: "– ¡Crea un formulario ahora!",
        formQuestionTypeEnum: {
            [FormQuestionTypeEnum.Number]: "Número",
            [FormQuestionTypeEnum.Text]: "Texto",
            [FormQuestionTypeEnum.Poll]: "Encuesta",
        },
        formFilterTypeEnum: {
            [FormFilterTypeEnum.All]: "Todos los Formularios",
            [FormFilterTypeEnum.CreatedByMe]: "Creados Por Mí",
            [FormFilterTypeEnum.ForApproval]: "Para Aprobación",
            [FormFilterTypeEnum.FormForMe]: "Formularios Para Mí"
        },
        statusEnum: {
            [FormStatusEnum.InProcess]: "En Proceso",
            [FormStatusEnum.Approved]: "Aprobado",
            [FormStatusEnum.Declined]: "Rechazado",
            [FormStatusEnum.Resend]: "Reenviar",
            [FormStatusEnum.Inactive]: "Inactivo",
            [FormStatusEnum.NotRequired]: "No Requerido",
            [FormStatusEnum.Cancelled]: "Cancelado",
            [FormStatusEnum.Hold]: "En Espera",
        },

        SortEnum: {
            [FormFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [FormFilterSortEnum.CreateDateAsc]: "Fecha de Creación - Ascendente",
            [FormFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [FormFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización - Ascendente",
            [FormFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [FormFilterSortEnum.ReferenceNo]: "Número de Referencia"
        }

    },
    careers: {
        Careers: "Carreras",
        CareerDetail: "Detalle de Carrera",
        createCareer: "Crear Carrera",
        MinSalary: "Salario Mínimo",
        MaxSalary: "Salario Máximo",
        All: "Todos",
        Applicants: "Solicitantes",
        ShortListed: "Preseleccionados",
        Rejected: "Rechazados",
        Completed: "Completados",
        Finalist: "Finalistas",
        Maybe: "Quizás",
        OfferSent: "Oferta Enviada",
        WaitingForInterview: "Esperando Entrevista",
        OfferAccepted: "Oferta Aceptada",
        OfferDeclined: "Oferta Rechazada",
        OfferResend: "Reenviar Oferta",
        noDataMessage: "– ¡Crea una Carrera ahora!",
        writeHeaderHere: "Escriba el encabezado",
        pickCurrentDate: "Seleccionar fecha actual",
        enterAmount: "Ingrese cantidad",
        selectApprovers: "Seleccionar aprobadores",
        selectFinancers: "Seleccionar financiadores",
        selectExecutors: "Seleccionar ejecutores",
        selectDesignation: "Seleccionar designación",
        writeDescription: "Escribir descripción",
        enterMinSalary: "Ingrese salario mínimo",
        enterMaxSalary: "Ingrese salario máximo",
        selectDepartment: "Seleccionar departamento",
        selectSupervisor: "Seleccionar supervisor",
        selectInterviewers: "Seleccionar entrevistadores",
        selectPostInterviewers: "Seleccionar post entrevistadores",
        description: "Escriba su descripción aquí...",
        addSkills: "Agregar habilidad",
        selectJobType: "Seleccionar tipo de trabajo",
        selectJobshift: "Seleccionar turno de trabajo",
        selectEducation: "Por favor seleccione educación",
        selectCareerLevel: "Seleccionar nivel de carrera",
        selectEndDate: "Seleccionar fecha de fin",
        enterreviewcriteria: "Ingrese criterios de revisión",
        reviewCriteria: "Criterios de Revisión",
        enterDepartment: "Por favor ingrese departamento",
        supervisor: "Supervisor",
        supervisorrequired: "Se requiere supervisor",
        postInterviewers: "Post Entrevistadores",
        jobShift: "Turno de Trabajo",
        enterJobShift: "Por favor ingrese Turno de Trabajo",
        enterJobType: "Por favor ingrese Tipo de Trabajo",
        careerLevel: "Nivel de Carrera",
        selectBuddy: "Seleccionar Compañero de Contratación",
        selectInerview: "Seleccionar Entrevistadores",
        requiredInterview: "Se requiere entrevistador",
        enterCareerLevel: "Por favor ingrese Nivel de Carrera",
        createJob: "Crear Trabajo",
        applyJob: "Aplicar Trabajo",
        firstName: "Nombre",
        enterFirstName: "Ingrese Nombre",
        requiredMessageFName: "Nombre es requerido",
        lastName: "Apellido",
        enterLastName: "Ingrese Apellido",
        requiredMessageLName: "Apellido es requerido",
        email: "Correo Electrónico",
        enterEmail: "Ingrese Correo Electrónico",
        EmailIsRequired: "Correo Electrónico es requerido",
        phoneNumber: "Número de Teléfono",
        enterPhoneNumber: "Ingrese Número de Teléfono",
        currentSalary: "Salario Actual",
        expectedSalary: "Salario Esperado",
        radioMessageApplicant: "Solicitante",
        radioMessageShortlisted: "Preseleccionado",
        radioMessageCompleted: "Completado",
        coverNote: "Nota de Presentación",
        noticePeriod: "Período de Notificación",
        requiredMessageFName: "Por favor ingrese Nombre",
        requiredMessageLName: "Por favor ingrese Apellido",
        requiredMessageEmail: "Por favor ingrese Correo Electrónico",
        requiredMessagePhoneNumbeer: "Por favor ingrese Número de Teléfono",
        requiredMessageCurrentSalary: "Por favor ingrese Salario Actual",
        requiredMessageExpextedsalary: "Por favor ingrese Salario Esperado",
        requiredMessageExperience: "Por favor ingrese Experiencia",
        requiredMessageNoticePeriod: "Por favor ingrese Período de Notificación",
        appliedDate: "Fecha de Aplicación",
        interviewSchedule: "Programar Entrevista",
        applyNow: "Aplicar Ahora",
        jobPost: "Trabajo Publicado",
        jobexpire: "Trabajo Expira",
        skillrequired: "Habilidades Requeridas",
        joblocation: "Ubicación del Trabajo",
        jobType: "Tipo de Trabajo",
        jobDetails: "Detalles del Trabajo",
        EducationLevel: "Nivel de Educación",
        MinimumSalary: "Salario Mínimo",
        MaximumSalary: "Salario Máximo",
        YearOfExperience: "Años de Experiencia",
        errormessage: "No se puede cambiar el estado",
        MakeOffer: "Realizar Oferta",
        updatestatus: "¿Estás seguro de que deseas actualizar el estado?",
        Pleaseenterminsalary: "Por favor, ingrese el salario mínimo",
        Pleaseentermaxsalary: "Por favor, ingrese el salario máximo",
        NoRequirements: "Sin requisitos",
        careerInterviewStatuEnum: {
            [CareerInterviewStatusEnum.NotScheduled]: "No Programado",
            [CareerInterviewStatusEnum.Ended]: "Finalizado",
            [CareerInterviewStatusEnum.Scheduled]: "Programado",
            [CareerInterviewStatusEnum.Started]: "Iniciado",
            [CareerInterviewStatusEnum.WaitingForReSchedule]: "Esperando Reprogramación"
        },
        statusEnum: {
            [CareerStatusEnum.InProcess]: "En Proceso",
            [CareerStatusEnum.Approved]: "Aprobado",
            [CareerStatusEnum.Declined]: "Rechazado",
            [CareerStatusEnum.Cancelled]: "Cancelado",
            [CareerStatusEnum.Available]: "Disponible",
            [CareerStatusEnum.Expired]: "Expirado",
            [CareerStatusEnum.Hold]: "En Espera",
        },
        careerFilterTypeEnum: {
            [CareerFilterTypeEnum.All]: "Todas las Carreras",
            [CareerFilterTypeEnum.MyCareers]: "Mis Carreras",
            [CareerFilterTypeEnum.ForApproval]: "Para Aprobación",
        },
        SortEnum: {
            [CareerFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [CareerFilterSortEnum.CreateDateAsc]: "Fecha de Creación - Ascendente",
            [CareerFilterSortEnum.ReferenceNoDesc]: "Fecha de Actualización - Descendente",
            [CareerFilterSortEnum.ReferenceNoAsc]: "Fecha de Actualización - Ascendente",
            [CareerFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [CareerFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización - Ascendente",
            [CareerFilterSortEnum.MinSalary]: "Salario Mínimo",
            [CareerFilterSortEnum.MinSalaryDesc]: "Salario Mínimo - Descendente",
            [CareerFilterSortEnum.MaxSalary]: "Salario Máximo",
            [CareerFilterSortEnum.MaxSalaryDesc]: "Salario Máximo - Descendente",
        },
        CareerLevelEnum: {
            [CareerLevelEnum.Entry]: "Nivel de Entrada",
            [CareerLevelEnum.MidLevel]: "Nivel Medio",
            [CareerLevelEnum.SeniorLevel]: "Nivel Senior",
            [CareerLevelEnum.ExecutiveLevel]: "Nivel Ejecutivo",
            [CareerLevelEnum.CSuite]: "C-Suite",
            [CareerLevelEnum.PresidentCeo]: "Presidente CEO",
        },
        JobTypeEnums: {
            [JobTypeEnums.FullTime]: "Tiempo Completo",
            [JobTypeEnums.PartTime]: "Medio Tiempo",
            [JobTypeEnums.Contractual]: "Contratista",
            [JobTypeEnums.Consultant]: "Consultor",
            [JobTypeEnums.Agent]: "Agente",
            [JobTypeEnums.ProjectBased]: "Basado en Proyecto",
        },
        JobShiftTypeEnums: {
            [JobShiftTypeEnums.Day]: "Día",
            [JobShiftTypeEnums.Morning]: "Mañana",
            [JobShiftTypeEnums.AfterNoon]: "Tarde",
            [JobShiftTypeEnums.Night]: "Noche",
            [JobShiftTypeEnums.Other]: "Otro",
        }
    },
    /*** HR Menu ***/
    myTeam: {
        MyTeams: "Mis Equipos",
        EmployeeNo: "Número de Empleado",
        noDataMessage: "¡Crea Equipos ahora!",
        teamDetails: "Detalles del Equipo",
        DeductionPerMonth: "Deducción por Mes",
        teamActivities: {
            attendance: "Asistencia",
            checkIn: "Entrada",
            leaves: "Ausencias",
            rewards: "Recompensas",
            appraisals: "Evaluaciones",
            warnings: "Advertencias",
            complains: "Quejas",
            courses: "Cursos",
            education: "Educación",
            experience: "Experiencia",
            loan: "Préstamo",
            activityLog: "Registro de Actividad",
            checkInCheckOut: "Entrada / Salida",
            storageStatistics: " Estadísticas de almacenamiento"
        },
    },
    administration: {
        basicInfo: "Información Básica",
        Subsidiary_Office: "Oficina de Sucursal",
        Fiscal_Year: "Año Fiscal",
        Tax_Slabs: "Tramos de Impuestos",
        Tax_Slabs_Group: "Grupo de Tramos de Impuestos",
        E_Learning_Category: "Categoría de E-Learning",
        Company_Policy: "Políticas de la Empresa",
        Default_Hiring_Criteria: "Criterios de Contratación por Defecto",
        subsidiary: "Sucursal",
        AddSubsidiary: "Agregar Sucursal",
        Business_Logo: "Logotipo de la Empresa",
        Complain_Category: "Categoría de Quejas",
        Custom_Tag: "Etiqueta Personalizada",
        Payroll_Group: "Grupo de Nómina",
        Business_Policy: "Política de Negocios",
        Companies_Policy: "Políticas de la Empresa",
        Billing: "Facturación",
        Rebate_Category: "Categoría de Descuento",
        Assets_Category: "Categoría de Activos",
        AccessRole: "Rol de Acceso",
        Grade: "Grado",
        Default_Account_Header: "Encabezado de Cuenta por Defecto",
        Business_Paging: "Número de Negocios",
        Discussion_Board_Category: "Categoría de Tablero de Discusión",
        Room: "Sala",
        Business_Association: "Asociación Empresarial",
        Designation: "Designación",
        Company_Goal: "Objetivo de la Empresa",
        Individual_Goal: "Objetivo Individual",
        Office_Timings: "Horarios de Oficina",
        Leave_Types: "Tipos de Ausencia",
        User_Types: "Tipos de Usuario",
        Expense_Headers: "Encabezados de Gastos",
        Salary_Headers: "Encabezados de Salario",
        Request_For_Right: "Solicitud de Derechos",
        Custom_Approval_Category: "Categoría de Aprobación Personalizada",
        GRADE_ALLOWANCES: "Asignaciones de Grado",
        Paging_Category: "Categoría de Paginación",
        Page_Category: "Categoría de Página",
        Add_Paging_Category: "Agregar Categoría de Página",
        Project_Category: "Categoría de Proyecto",
        Email_Configuration: "Configuración de Correo Electrónico",
        Allowances: "Asignaciones",
        Default_Approvals: "Aprobaciones por Defecto",
        NonMandatory_Approvals: "Aprobaciones No Obligatorias",
        WORKGATING: "Criterios de WIP Gating",
        Job_Description: "Descripción del Trabajo",
        Rewards: "Recompensas",
        Reward_Category: "Categoría de Recompensa",
        Add_Reward_Category: "Agregar Categoría de Recompensa",
        Warning_Category: "Categoría de Advertencia",
        Add_Warning_Category: "Agregar Categoría de Advertencia",
        Holidays: "Días Festivos",
        paymentCard: "Tarjeta de Pago",
        addPaymentCard: "Agregar Tarjeta de Pago",
        Company_Info: "Información de la Empresa",
        Business_Approval: "Aprobación de Negocios",
        ATTENDANCE_SETTINGS: "Configuración de Asistencia",
        COMPANY_STATISTICS: "Estadísticas de la empresa",
        Form: {
            Name: "Nombre",
            Entername: "Ingrese nombre",
            PleaseEnterName: "Por favor, ingrese el nombre",
            Description: "Descripción",
            enterDescription: "Por favor, ingrese la descripción",
            Create: "Crear",
            Add: "Agregar",
            AddBusinessNumber: "Agregar Número de Negocios",
            AddAllowance: "Agregar Asignación",
            AddAssetsCategory: "Agregar Categoría de Activos",
            UpdateAssetsCategory: "Actualizar Categoría de Activos",
            AddBusinessAssociation: "Agregar Asociación Empresarial",
            AddCustomApprovalCategory: "Agregar Categoría de Aprobación Personalizada",
            AddCustomTag: "Agregar Etiqueta Personalizada",
            AddDesignation: "Agregar Designación",
            AddComplain: "Agregar Queja",
            Save: "Guardar",
            Clear: "Limpiar",
            CategoryName: "Nombre de la Categoría",
            Entercategoryname: "Ingrese nombre de la categoría",
            SaveCategory: "Guardar Categoría",
            Date: "Fecha",
            startDate: "Fecha de Inicio",
            endDate: "Fecha de Fin",
            Upload: "Cargar",
            Percent: "Porcentaje",
            Amount: "Cantidad",
            EnterAmount: "Ingrese Cantidad",
            addCategory: "Agregar Categoría",
            SelectMember: "Seleccionar Miembro",
            Email: "Correo Electrónico",
            EnterEmail: "Ingrese Correo Electrónico",
            Types: "Tipos",
            SelectStatus: "Seleccionar Estado",
            Submit: "Enviar",
            Nomoreapprovals: "No hay más aprobaciones",
        },
        Table: {
            Name: "Nombre",
            Members: "Miembros",
            Description: "Descripción",
            Value: "Valor",
            Allot_Leave: "Asignar Ausencia",
            Type: "Tipo",
            Unit: "Unidad",
            Taxable: "Gravable",
            Provider: "Proveedor",
            IncomingServerAddress: "Dirección del Servidor de Entrada",
            OutgoingServerAddress: "Dirección del Servidor de Salida",
            IncomingPort: "Puerto de Entrada",
            OutgoingPort: "Puerto de Salida",
            Action: "Acción",
            startDate: "Fecha de Inicio",
            endDate: "Fecha de Fin",
            Actions: "Acciones",
            Account: "Cuenta",
            approvers: "Aprobadores",
            Status: "Estado",
            Allocated: "Asignado",
            AddMember: "Agregar Miembro",
            CreateDate: "Fecha de Creación",
        },
        accessRole: {
            Button: {
                AddAccessRole: "Agregar Rol de Acceso",
                UpdateAccessRole: "Actualizar Rol de Acceso",
            },
            Drawer: {
                CreateAccessRole: "Crear Rol de Acceso",
                UpdateAccessRole: "Actualizar Rol de Acceso",
                UserType: "Tipo de Usuario",
                Description: "Descripción",
                placeholders: {
                    EnterName: "Ingrese el Nombre del Rol de Acceso",
                    UserType: "Por favor, seleccione el Tipo de Usuario",
                    Description: "Ingrese la Descripción",
                },
                formLabel: {
                    AccessRoleName: "Nombre del Rol de Acceso",
                },
                placeholders: {
                    EnterName: "Ingrese el rol de acceso",
                    SelectUserType: "Seleccione el tipo de usuario",
                    EnterDescription: "Ingrese la descripción",
                },
            },
        },
        grade: {
            AddGrade: "Agregar Grado",
            AddGradeAllowances: "Agregar Asignaciones de Grado",
            EnterGrade: "Ingrese el grado",
            EnterDescription: "Ingrese la descripción",
            Grade: "Grado",
            Description: "Descripción",
            Name: "Nombre",
            clear: "Limpiar",
            saveGrade: "Guardar Grado",
        },
        fiscalyear: {
            Add: "Agregar",
            AddFiscalYear: "Agregar Año Fiscal",
            EnterName: "Ingrese el nombre",
            EnterDescription: "Ingrese la descripción",
            Fiscalyear: "Año Fiscal",
            description: "Descripción",
            name: "Nombre",
            clear: "Limpiar",
            save: "Guardar",
            startyear: "Año de Inicio",
            endyear: "Año de Fin",
            startMonth: "Mes de Inicio",
            endMonth: "Mes de Fin",
            startDate: "Fecha de Inicio",
            endDate: "Fecha de Fin",
        },
        businessPolicy: {
            companypolicy: "Políticas de la Empresa",
            enterName: "Ingrese el nombre",
            name: "Nombre",
            type: "Seleccione tipo",
            typee: "Tipo",
            description: "Descripción",
            EnterDescription: "Ingrese la descripción",
            create: "Crear",
            save: "Guardar",
            createPolicy: "Crear Política",
            updatePolicy: "Actualizar Política",
        },
        taxSlab: {
            TaxSlabs: "Tramos de Impuestos",
            UpdateTaxSlab: "Actualizar Tramo de Impuesto",
            CreateTaxSlab: "Crear Tramo de Impuesto",
            NoTaxSlabGroup: "Sin Grupo de Tramo de Impuesto...",
            enterCountry: "Seleccionar País",
            enterName: "Ingresar Nombre",
            enterDescription: "Ingresar Descripción",
            description: "Descripción",
            name: "Nombre",
            TaxSlab: "Tramo de Impuesto",
            title: "Título",
            enterTitle: "Ingresar Título",
            percentage: "Porcentaje",
            previousCharge: "Cargo Anterior",
            min: "Mín",
            max: "Máx",
            enterMin: "Ingresar Mín",
            enterMax: "Ingresar Máx",
            enterPercent: "Ingresar Porcentaje",
            save: "Guardar",
            clear: "Limpiar",
            Add: "Agregar",
            TaxSlabgroup: "Grupo de Tramo de Impuesto",
            country: "País",
            minimumCharge: "Cargo Mínimo",
        },
        payrollGroup: {
            PayrollGroup: "Grupo de Nómina",
            AddPayrollGroup: "Agregar Grupo de Nómina",
            save: "Guardar Grupo",
            clear: "Limpiar",
            Add: "Agregar Grupo",
            name: "Nombre",
            enterName: "Ingresar nombre",
        },
        subsidiaryOffice: {
            enterName: "Ingresar nombre",
            enterAddress: "Ingresar dirección",
            name: "Nombre",
            subsidiary_Office: "Oficina Filial",
            latitude: "Latitud",
            longitude: "Longitud",
            address: "Dirección",
            branch: "Sucursal",
            Subsidiary: "Filial",
            select: "Seleccionar Filial",
            save: "Guardar",
            clear: "Limpiar",
            AddSubsidiaryOffice: "Agregar Oficina Filial",
            Add: "Agregar",
            title: "Título",
            enterTitle: "Ingresar título",
        },
        defaultHiringCriteria: {
            default: "Criterios de Contratación Predeterminados",
            question: "Preguntas",
            enterQuestions: "Ingresar Pregunta",
            save: "Guardar Pregunta",
            clear: "Limpiar",
            Add: "Agregar Pregunta",
        },
        workGatingCriteria: {
            work: "Criterios de Control de WIP",
            question: "Preguntas",
            enterQuestions: "Ingresar pregunta",
            save: "Guardar Pregunta",
            clear: "Limpiar",
            Add: "Agregar Pregunta",
            addWork: "Agregar Criterios de Control de WIP"
        },
        designation: {
            desig: "Designación",
            enterdesig: "Ingresar designación",
            enterName: "Ingresar Nombre",
            enterDescription: "Ingresar descripción",
            description: "Descripción",
            name: "Nombre",
            save: "Guardar Designación",
            clear: "Limpiar",
            Add: "Agregar Designación",
        },
        appraisal: {
            Appraisal: "Evaluación",
            enterAppraisal: "Ingresar evaluación",
            enterDescription: "Ingresar descripción",
            description: "Descripción",
            save: "Guardar Evaluación",
            clear: "Limpiar",
            Add: "Agregar Evaluación",
            CompanyGoal: "Meta de la Empresa",
            IndividualGoal: "Meta Individual",
            DepartmentsGoal: "Meta del Departamento",
            Goal: "Meta",
            saveGoal: "Guardar Meta",
            AddCompanyGoal: "Agregar Meta de la Empresa",
            AddDepartmentGoal: "Agregar Meta del Departamento",
            AddIndividualGoal: "Agregar Meta Individual",
            enterCompanyGoal: "Ingresar Meta de la Empresa",
            enterDepartmentGoal: "Ingresar Meta del Departamento",
            enterIndividualGoal: "Ingresar Meta Individual",
        },
        office: {
            Office_Timing: "Horario de Oficina",
            group: "Nombre del Grupo",
            enterGroup: "Ingresar nombre",
            enterDescription: "Ingresar descripción",
            description: "Descripción",
            save: "Guardar Horario de Oficina",
            clear: "Limpiar",
            Add: "Agregar Horario de Oficina",
            days: "Días",
            work: "Está Trabajando",
            checkIn: "Hora de Entrada",
            checkOut: "Hora de Salida",
            time: "Tiempo de Gracia",
            min: "Min",
            create: "Crear Grupo",
            new: "Nuevo Grupo de Tiempo",
            UpdateOfficeTiming: "Actualizar Horario de Oficina",
            CreateOfficeTiming: "Crear Horario de Oficina",
            Monday: "Lunes",
            Tuesday: "Martes",
            Wednesday: "Miércoles",
            Thursday: "Jueves",
            Friday: "Viernes",
            Saturday: "Sábado",
            Sunday: "Domingo",
        },
        leave: {
            Leave: "Tipo de Ausencia",
            leaveType: "Tipo de Ausencia",
            enterLeave: "Ingresar tipo de ausencia",
            save: "Guardar Tipo de Ausencia",
            clear: "Limpiar",
            Add: "Agregar Tipo de Ausencia",
            enterDescription: "Ingresar descripción",
            description: "Descripción",
            allotLeaves: "Días Asignados",
            balanceLeaves: "Tipos de Ausencia (días restantes)",
            selectType: "Seleccionar tipo",
            leaveFor: "Ausencia Para",
            leaveDates: "Fechas de Ausencia",
            createleave: "Crear Ausencia",
        },
        expense: {
            Expense: "Encabezado de Gastos",
            enterExpense: "Ingresar encabezado de gastos",
            enterDescription: "Ingresar descripción",
            description: "Descripción",
            save: "Guardar",
            clear: "Limpiar",
            Add: "Agregar Encabezado de Gastos",
        },
        elearningCategory: {
            elearningCategory: "Categoría de E-Learning",
            description: "Descripción",
            enterelearningCategory: "Ingresar categoría",
            enterDescription: "Ingresar descripción",
            save: "Guardar",
            clear: "Limpiar",
            Add: "Agregar Categoría de E-Learning",
        },
        salaryHeader: {
            SalaryHeader: "Encabezado de Salario",
            AddSalaryHeader: "Agregar Encabezado de Salario",
            enterSalaryHeader: "Ingresar encabezado de salario",
            desc: "Descripción",
            enterDesc: "Ingresar descripción",
            saveHeader: "Guardar Encabezado",
            addHeader: "Agregar Encabezado",
            name: "Nombre",
            clear: "Limpiar",
        },
        room: {
            Room: "Sala",
            Name: "Nombre",
            Entername: "Ingresar nombre",
            Description: "Descripción",
            Enterdescription: "Ingresar descripción",
            Location: "Ubicación",
            Enterlocation: "Ingresar ubicación",
            TotalSeats: "Total de Asientos",
            Enterseats: "Ingresar asientos",
            SaveRoom: "Guardar Sala",
            AddRoom: "Agregar Sala",
            clear: "Limpiar"
        },
        RebateCategory: {
            rebateCategory: "Categoría de Descuento",
            addRebateCategory: "Agregar Categoría de Descuento",
            maxPercentage: "Porcentaje Máximo",
            entermaxPercentage: "Ingresar porcentaje máximo",
            maxAmount: "Monto Máximo",
            enterMaxAmount: "Ingresar monto máximo",
            type: "Tipo",
            basic: "Básico",
            tax: "Impuesto",
            fullAmount: "Monto Completo",
            rebateType: "Tipo de Descuento",
        },
        projectCategory: {
            ProjectCategory: "Categoría de Proyecto",
            AddProjectCategory: "Agregar Categoría de Proyecto",
        },
        pageCategory: {
            Pagecategory: "Categoría de Página",
            AddPageCategory: "Agregar Categoría de Página",
        },
        NonMandatoryApprovals: {
            NonMandatoryApproval: "Aprobaciones No Obligatorias",
        },
        jobdescription: {
            jobDesc: "Descripción del Trabajo",
            designation: "Designación",
            selectDesign: "Seleccionar Designación",
            saveJobDesc: "Guardar",
            addJobdesc: "Agregar Descripción del Trabajo",
        },
        Holiday: {
            Holidays: "Días Festivos",
            AddHolidays: "Agregar Días Festivos",
            AddHoliDay: "Agregar Día Festivo"
        },
        GradeAllowance: {
            gradeAllowance: "Subsidio por Grado",
            Grades: "Grados",
            AllowanceType: "Tipo de Subsidio",
            SelectType: "Seleccionar Tipo",
            AddAllowance: "Agregar Subsidio",
            UpdateAllowance: "Actualizar Subsidio",
            Allowance: "Subsidio",
            IsTaxable: "Es Gravable",
        },
        EmailConfiguration: {
            emailConfi: "Configuración de Correo Electrónico",
            enterEmailConfi: "Ingrese la configuración de correo electrónico",
            incomingPort: "Puerto de Entrada",
            enterIncomingPort: "Ingrese el puerto de entrada",
            incomingServerAddress: "Dirección del Servidor de Entrada",
            enterIncomingServerAddress: "Ingrese la dirección del servidor de entrada",
            outgoingPort: "Puerto de Salida",
            enterOutgoingPort: "Ingrese el puerto de salida",
            outgoingServerAddress: "Dirección del Servidor de Salida",
            enterOutgoingServerAddress: "Ingrese la dirección del servidor de salida",
            provider: "Proveedor",
            enterProvider: "Ingrese el proveedor",
            saveEmailConfi: "Guardar Configuración de Correo Electrónico",
            addEmailConfi: "Agregar Configuración de Correo Electrónico",
        },
        DiscussionBoardCategory: {
            discussionBoardCategory: "Categoría de Tablero de Discusión",
            SaveDiscussionBoardCategory: "Guardar Categoría de Tablero de Discusión",
            AddDiscussionBoardCategory: "Agregar Categoría de Tablero de Discusión",
            Save: "Guardar",
            Clear: "Limpiar",
            Update: "Actualizar",
        },
        DefaultApprovers: {
            defaultApprovers: "Aprobadores Predeterminados",
        },
        DefaultAccountHeader: {
            defaultAccountHeader: "Encabezado de Cuenta Predeterminado",
            SaveAccountHeader: "Guardar Encabezado de Cuenta",
            SelectAccountTypes: "Seleccionar Tipos de Cuenta",
        },
        CustomTag: {
            customTag: "Etiqueta Personalizada",
            Entercustomtag: "Ingrese etiqueta personalizada",
            SaveCustomTag: "Guardar Etiqueta Personalizada",
            AddCustomTag: "Agregar Etiqueta Personalizada",
        },
        customApprovalCategory: {
            customApprovalcategory: "Categoría de Aprobación Personalizada",
            enterCustomApprovalcategory: "Ingrese la categoría de aprobación personalizada",
        },
        BusinessNumber: {
            Manual: "Manual",
            TotalNumber: "Número Total",
            Country: "País",
            SelectCountry: "Seleccionar País",
            Number: "Número",
            Random: "Aleatorio",
            SelectNumber: "Seleccionar Número",
            Cost: "Costo",
            Request: "Solicitud",
        },
        BusinessAssociation: {
            businessAssociation: "Asociación Empresarial",
            SaveBusinessAssociation: "Guardar Asociación Empresarial",
            AddBusinessAssociation: "Agregar Asociación Empresarial",
            AssociationType: "Tipo de Asociación",
        },
        AssetsCategory: {
            assetsCategory: "Categoría de Activos",
            categoryName: "Nombre de la Categoría",
            enterCategoryName: "Ingrese el nombre de la categoría",
            desc: "Descripción",
            enterDesc: "Ingrese la descripción",
            accountType: "Tipo de Cuenta",
            parentAccount: "Cuenta Principal",
            selectParent: "Seleccionar Principal",
            selectType: "Seleccionar Tipo",
            add: "Agregar",
            save: "Guardar",
            clear: "Limpiar",
            name: "Nombre",
            assets: "Activo",
            liability: "Pasivo",
            capital: "Capital",
            revenue: "Ingresos",
            expense: "Gastos",
            costOfGoodsale: "Costo de Venta",
        },
        Allowance: {
            allowance: "Subsidio",
            enterAllownace: "Ingrese el subsidio",
            percent: "Porcentaje",
            desc: "Ingrese la descripción",
            grade: "Grado",
            selectGrade: "Seleccionar Grado",
            amount: "Monto",
            benefit: "Beneficio",
            deduction: "Deducción",
            texable: "Gravable",
            nonTexable: "No Gravable",
            director: "Director",
            ceo: "CEO",
            addAllowance: "Agregar Subsidio",
            saveAllowance: "Guardar Subsidio",
            clear: "Limpiar",
            name: "Nombre",
            allowanceType: "Tipo de Subsidio",
            allowanceUnit: "Unidad de Subsidio",
            istexable: "Es Gravable",
            yes: "Sí",
            no: "No",
        },
        CompanyInfo: {
            businessLogo: "Logo de la Empresa",
            basicinfo: "Información Básica",
            ShortLogo: "Logo Corto",
            Logo: "Logo",
            FullLogo: "Logo Completo",
            companyName: "Nombre de la Empresa",
            PleaseEnterCompanyName: "Por favor, ingrese el nombre de la empresa",
            enterCompanyName: "Ingrese el nombre de la empresa",
            companyEmail: "Correo Electrónico de la Empresa",
            PleaseEnterCompanyEmail: "Por favor, ingrese el correo electrónico de la empresa",
            SelectOwnerName: "Seleccionar Nombre del Propietario",
            totalEmployees: "Total de Empleados",
            totalEmployeesInCall: "Total de Empleados en Llamada",
            SaveShortLogo: "Guardar Logo Corto",
            LongLogo: "Logo Largo",
            SaveLongLogo: "Guardar Logo Largo",
            SaveFullLogo: "Guardar Logo Completo",
        },
        billing: {
            Invoice: "Factura",
            InvoiceNo: "Número de Factura",
            CustomerName: "Nombre del Cliente",
            PayInvoice: "Pagar Factura",
            Payment: "Pago",
            BillNo: "Número de Factura",
            CardLast: "Últimos 4 Dígitos de la Tarjeta",
            BusinessName: "Nombre de la Empresa",
            CardHolder: "Titular de la Tarjeta",
            Viewinvoicedetails: "Ver detalles de la factura y el pago",
            Viewinvoicedetail: "Ver detalle de la factura",
            Qty: "Cantidad",
            BillType: "Tipo de Factura"
        },
        PaymentCard: {
            paymentCard: "Tarjeta de Pago",
            CardHolderName: "Nombre del Titular de la Tarjeta",
            AddCard: "Agregar Tarjeta",
            PayInvoice: "Pagar Factura",
            CardHolderNameRequired: "Se requiere el nombre del titular de la tarjeta.",
            PayCard: "Pagar con Tarjeta",
            payanotherway: "O pagar de otra manera",
            credentialstocontinue: "Ingrese sus credenciales para continuar.",
            Expiry: "Vencimiento",
            Brand: "Marca",
            Country: "País",
            LastFourDigits: "Últimos Cuatro Dígitos",
        },
    },
    billing: {
        BillType: "Tipo de Factura",
        billingTypeEnum: {
            [BillingTypeEnum.NewUser]: "Nuevo Usuario",
            [BillingTypeEnum.MonthlySubscription]: "Suscripción Mensual",
            [BillingTypeEnum.NewBusinessNumber]: "Nuevo Número de Negocio",
        }
    },
    businessNumber: {
        statusEnum: {
            [BuisnessNumberStatusEnum.Waiting]: "Esperando",
            [BuisnessNumberStatusEnum.Declined]: "Declinado",
            [BuisnessNumberStatusEnum.NumberCancelled]: "Número Cancelado",
            [BuisnessNumberStatusEnum.Purchased]: "Comprado",
            [BuisnessNumberStatusEnum.RequestCancelled]: "Solicitud Cancelada",
            [BuisnessNumberStatusEnum.Rejected]: "Rechazado",
        },
    },
    appraisal: {
        Appraisals: "Evaluaciones",
        createAppraisals: "Crear Evaluaciones",
        noDataMessage: "– ¡Crea evaluaciones ahora!",
        Approvers: "Aprobadores",
        Questions: "Preguntas",
        Tasks: "Tareas",
        Bonus: "Bono",
        Increment: "Incremento",
        previousApraisals: "Evaluaciones Anteriores",
        warning: "Advertencia",
        rewards: "Recompensas",
        courses: "Cursos",
        Promotion: "Promoción",
        PleaseEnterBonusPercentage: "Por favor, ingrese el porcentaje de bonificación",
        validateIncrement: "No se puede procesar el incremento: no hay información salarial disponible para el empleado",
        PleaseEnterBonusAmount: "Por favor, ingrese el monto del bono",
        PleaseEnterIncrementAmount: "Por favor, ingrese el monto del incremento",
        RateAtleastOneItem: "Califique al menos un ítem",
        Noemployeeselected: "Ningún empleado seleccionado",
        submitAppraisal: "Enviar Evaluaciones",
        Appraisedof: "Evaluado de",
        AppraisalApprovers: "Aprobadores de Evaluación",
        AppraisalDetail: "Detalle de Evaluación",
        appraisalFilterTypeEnum: {
            [AppraisalFilterEnum.All]: "Todas las Evaluaciones",
            [AppraisalFilterEnum.CreatedByMe]: "Creadas Por Mí",
            [AppraisalFilterEnum.ForApproval]: "Para Aprobación",
            [AppraisalFilterEnum.TeamAppraisal]: "Evaluación de Equipo",
            [AppraisalFilterEnum.MyApprovedAppraisal]: "Mis Evaluaciones Aprobadas",
        },
        statusEnum: {
            [AppraisalStatusEnum.InProcess]: "En Proceso",
            [AppraisalStatusEnum.Approved]: "Aprobado",
            [AppraisalStatusEnum.Declined]: "Declinado",
            [AppraisalStatusEnum.Cancelled]: "Cancelado",
            [AppraisalStatusEnum.Hold]: "En Espera",
        },
        SortEnum: {
            [AppraisalFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [AppraisalFilterSortEnum.CreateDateAsc]: "Fecha de Creación",
            [AppraisalFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [AppraisalFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización",
            [AppraisalFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [AppraisalFilterSortEnum.ReferenceNo]: "Número de Referencia",

        }
    },
    loan: {
        loan: "Préstamo",
        createLoan: "Crear Préstamo",
        loanTenure: "Duración del Préstamo (en meses)",
        enterLoanTenure: "Ingrese la duración del préstamo (en meses)",
        deduction: "Deducción/Meses",
        selectLoanPurpose: "Seleccione el propósito del préstamo",
        noDataMessage: "– ¡Cree préstamos ahora!",
        SortBy: "Ordenar por",
        SelectSortBy: "Seleccione Ordenar por",
        InterestRate: "Tasa de interés",
        loanPurpose: {
            loanPurpose: "Propósito del Préstamo",
            vehicle: "Vehículo",
            personal: "Personal",
            wedding: "Boda",
            medical: "Médico",
            education: "Educación",
            house: "Casa",
            other: "Otro",
        },
        loanPurposeEnumList: {
            [LoanPurposeEnum.Personal]: "Personal",
            [LoanPurposeEnum.Wedding]: "Boda",
            [LoanPurposeEnum.Medical]: "Médico",
            [LoanPurposeEnum.Education]: "Educación",
            [LoanPurposeEnum.House]: "Casa",
            [LoanPurposeEnum.Vehicle]: "Vehículo",
            [LoanPurposeEnum.Other]: "Otro",
        },
        loanFilterTypeEnum: {
            [LoanFilterTypeEnum.All]: "Todos los Préstamos",
            [LoanFilterTypeEnum.ForApproval]: "Para Aprobación",
            [LoanFilterTypeEnum.CreatedByMeAndLoanOfMe]: "Mis Préstamos",
            [LoanFilterTypeEnum.LoanForMe]: "Préstamo Para Mí",
        },
        statusEnum: {
            [LoanStatusEnum.InProcess]: "En Proceso",
            [LoanStatusEnum.Approved]: "Aprobado",
            [LoanStatusEnum.Declined]: "Declinado",
            [LoanStatusEnum.Cancelled]: "Cancelado",
            [LoanStatusEnum.Disbursed]: "Desembolsado",
            [LoanStatusEnum.PartialCleared]: "Parcialmente Pagado",
            [LoanStatusEnum.Completed]: "Completado",
            [LoanStatusEnum.Hold]: "En Espera",
            [LoanStatusEnum.WaitingForDisburse]: "Esperando Desembolso",

        },
        loanTypeEnum: {
            [LoanTypeEnum.Company]: "Préstamo de la Empresa",
            [LoanTypeEnum.WorkWise]: "Préstamo Workwise",
            [LoanTypeEnum.Both]: "Ambos",
        },
        loanFilterSortEnum: {
            [LoanFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [LoanFilterSortEnum.CreateDateAsc]: "Fecha de Creación",
            [LoanFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [LoanFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización",
            [LoanFilterSortEnum.ReferenceNo]: "Número de Referencia",
        },
    },
    quotations: {
        Quotation: "Cotización",
        CreateQuotation: "Crear Cotización",
        quotationDate: "Fecha",
        ClientName: "Nombre del Cliente",
        ClientEmail: "Correo Electrónico del Cliente",
        PhoneNumber: "Número de Teléfono",
        TotalAmount: "Monto Total",
        QuotationType: "Tipo",
        Rate: "Tarifa",
        Quantity: "Cantidad",
        Attachments: "Adjuntos",
        serviceItem: "Servicio/Ítem",
        price: "Precio",
        tax: "Impuesto (%)",
        taxAmount: "Monto de Impuesto",
        sno: "Núm.",
        EnterClientName: "Ingrese el Nombre del Cliente",
        clientsName: "Nombre del Cliente",
        EnterClientEmail: "Ingrese el Correo Electrónico del Cliente",
        addressLine1: "Dirección línea 1",
        addressLine2: "Dirección línea 2",
        EnterAddress1: "Ingrese Dirección 1:",
        EnterAddress2: "Ingrese Dirección 2:",
        Product: "Producto",
        Service: "Servicio",
        netAmount: "Monto Neto",
        CODE: "CÓDIGO",
        DATE: "FECHA",
        TOTAL: "TOTAL",
        To: "Para",
        SubTotal: "Subtotal",
        noDataMessage: "– ¡Crea Cotización ahora!",

        quotationFilterTypeEnum: {
            [QuotationFilterEnum.All]: "Cotizaciones",
            [QuotationFilterEnum.CreatedByMe]: "Creadas Por Mí",
            [QuotationFilterEnum.ForApproval]: "Para Aprobación",
        },
        statusEnum: {
            [QuotationStatusEnum.InProcess]: "En Proceso",
            [QuotationStatusEnum.Approved]: "Aprobado",
            [QuotationStatusEnum.Declined]: "Declinado",
            [QuotationStatusEnum.Cancelled]: "Cancelado",
            [QuotationStatusEnum.Hold]: "En Espera",
            [QuotationStatusEnum.ClientApproved]: "Aprobado por el cliente",
            [QuotationStatusEnum.ClientRejected]: "Rechazado por el cliente",
        },
        SortEnum: {
            [QuotationFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [QuotationFilterSortEnum.CreateDateAsc]: "Fecha de Creación - Ascendente",
            [QuotationFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [QuotationFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización - Ascendente",
            [QuotationFilterSortEnum.ReferenceNo]: "Número de Referencia",
            [QuotationFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente"
        }
    },
    payroll: {
        Payroll: "Nómina",
        createPayroll: "Crear Nómina",
        noDataMessage: "– ¡Crea la Nómina ahora!",
        basicSalary: "Salario Básico",
        loan: "Préstamo",
        allowance: "Subsidio",
        ExpenseReimbursement: "Reembolso de Gastos",
        deduction: "Deducción",
        tax: "Impuesto",
        rebate: "Reembolso",
        bonus: "Bonificación",
        selectMonthYear: "Seleccionar Mes y Año",
        disburseDate: "Fecha de Desembolso",
        Voucher: "Comprobante",
        DownloadtoExcel: "Descargar a Excel",
        PayrollDetail: "Detalle de la Nómina",
        TransactionMode: "Modo de Transacción",
        IBANNumber: "Número de IBAN",
        statusEnum: {
            [PayrollStatusEnum.InProcess]: "En Proceso",
            [PayrollStatusEnum.Approved]: "Aprobado",
            [PayrollStatusEnum.Declined]: "Rechazado",
            [PayrollStatusEnum.Resend]: "Reenviado",
            [PayrollStatusEnum.Inactive]: "Inactivo",
            [PayrollStatusEnum.NotRequired]: "No Requerido",
            [PayrollStatusEnum.Cancelled]: "Cancelado",
            [PayrollStatusEnum.Hold]: "En Espera",
            [PayrollStatusEnum.Disbursed]: "Desembolsado",
            [PayrollStatusEnum.Completed]: "Completado",
        },
        payrollFilterTypeEnum: {
            [PayrollFilterTypeEnum.All]: "Todas las Nóminas",
            [PayrollFilterTypeEnum.CreatedByMe]: "Creadas Por Mí",
            [PayrollFilterTypeEnum.ForApproval]: "Para Aprobación",
        },
    },
    voucher: {
        createVoucher: "Crear Comprobante",
        SelectVoucherType: "Seleccionar Tipo de Comprobante",
        BasicSalary: "Salario Básico",
        LoanPayment: "Pago de Préstamo",
        LoanDispersable: "Préstamo Disponible",
        Allowance: "Subsidio",
        ExpenseReimbursement: "Reembolso de Gastos",
        Deduction: "Deducción",
        VoucherDetail: "Detalle del Comprobante",
        SavePrint: "Guardar e Imprimir",
        Bonus: "Bonificación",
        Rebate: "Reembolso",
        Tax: "Impuesto",
        ChequeNo: "Número de Cheque",
        Account: "Cuenta",
        Narration: "Narración",
        Debit: "Débito",
        Credit: "Crédito",
        Dr: "D",
        Cr: "C",
        differences: "Diferencias",
        drCr: "D/C",
        LinkVoucher: "Enlazar Comprobante",
        PostVoucher: "Publicar Comprobante",
        voucherDate: "Fecha del Comprobante",
        VoucherType: "Tipo de Comprobante",
        voucherTypeList: {
            [voucherTypeEnum.GeneralVoucher]: "Comprobante General",
            [voucherTypeEnum.PaymentVoucher]: "Comprobante de Pago",
            [voucherTypeEnum.ReceiptVoucher]: "Comprobante de Recibo",
        },
        accountTypeList: {
            [accountTypeEnum.Asset]: "Activo",
            [accountTypeEnum.Capital]: "Capital",
            [accountTypeEnum.Expense]: "Gasto",
            [accountTypeEnum.Liability]: "Pasivo",
            [accountTypeEnum.Revenue]: "Ingresos",
        }

    },
    trialBalance: {
        accountTypeList: {
            [AccountTypeEnum.Asset]: "Activo",
            [AccountTypeEnum.Capital]: "Capital",
            [AccountTypeEnum.Expense]: "Gasto",
            [AccountTypeEnum.Liability]: "Pasivo",
            [AccountTypeEnum.Revenue]: "Ingresos",
        }
    },
    businessApproval: {
        statusTypeList: {
            [ApprovalStatusTypeEnum.InProcess]: "En Proceso",
            [ApprovalStatusTypeEnum.Approved]: "Aprobado",
            [ApprovalStatusTypeEnum.Declined]: "Rechazado",
            [ApprovalStatusTypeEnum.Cancelled]: "Cancelado",
            [ApprovalStatusTypeEnum.Completed]: "Completado",
        }
    },
    disperse: {
        transactionModeEnumList: {
            [TransactionModeTypeEnum.Cash]: "Efectivo",
            [TransactionModeTypeEnum.BankTransfer]: "Transferencia Bancaria",
            [TransactionModeTypeEnum.Cheque]: "Cheque",
        },
    },
    promotion: {
        CreatePromotion: "Crear Promoción",
        Promotion: "Promoción",
        promotionTo: "Promoción a",
        noDataMessage: "¡Crea una Promoción ahora!",
        selectPromotionTo: "Seleccionar Promoción a",
        pleseSelectPromotionTo: "Por favor, selecciona Promoción a",
        createPromotion: "Crear Promoción",
        promotionFilterTypeEnum: {
            [PromotionFilterTypeEnum.All]: "Todas las Promociones",
            [PromotionFilterTypeEnum.CreatedByMe]: "Creadas Por Mí",
            [PromotionFilterTypeEnum.ForApproval]: "Para Aprobación",
            [PromotionFilterTypeEnum.PromotionForMe]: "Mis Promociones",
        },
        statusEnum: {
            [PromotionStatusEnum.InProcess]: "En Proceso",
            [PromotionStatusEnum.Approved]: "Aprobado",
            [PromotionStatusEnum.Declined]: "Rechazado",
            [PromotionStatusEnum.Cancelled]: "Cancelado",
            [PromotionStatusEnum.Hold]: "En Espera",
        },
        SortEnum: {
            [PromotionFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [PromotionFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización",
            [PromotionFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [PromotionFilterSortEnum.ReferenceNo]: "Número de Referencia",
            [PromotionFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [PromotionFilterSortEnum.CreateDateAsc]: "Fecha de Creación"

        }

    },
    complains: {
        createComplain: "Crear Queja",
        myComplain: "Mis Quejas",
        AllComplains: "Todas las Quejas",
        complainOf: "Queja de",
        ComplainDetail: "Detalle de la Queja",
        ComplainToMe: "Queja Para Mí",
        ComplainAboutMe: "Queja Sobre Mí",
        noDataMessage: " – ¡Crea Quejas ahora!",
        statusEnum: {
            [ComplainApprovalStatusEnum.InProcess]: "En Proceso",
            [ComplainApprovalStatusEnum.Approved]: "Aprobado",
            [ComplainApprovalStatusEnum.Declined]: "Rechazado",
            [ComplainApprovalStatusEnum.Resend]: "Reenviado",
            [ComplainApprovalStatusEnum.Inactive]: "Inactivo",
            [ComplainApprovalStatusEnum.NotRequired]: "No Requerido",
            [ComplainApprovalStatusEnum.Cancelled]: "Cancelado",
            [ComplainApprovalStatusEnum.Hold]: "En Espera",
        },
        SortEnum: {
            [ComplainFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [ComplainFilterSortEnum.CreateDateAsc]: "Fecha de Creación",
            [ComplainFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [ComplainFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización",
            [ComplainFilterSortEnum.ReferenceNo]: "Número de Referencia",
            [ComplainFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente"
        },
    },
    salary: {
        SalaryMembers: "Miembros del Salario",
        EffectiveDate: "Fecha Efectiva",
        BasicSalary: "Salario Básico",
        Allowances: "Asignaciones",
        Allowance: "Asignación",
        AllowanceDetail: "Detalle de la Asignación",
        IsTaxable: "Es Gravable",
        Deduction: "Deducción",
        GrossSalary: "Salario Bruto",
        CreateSalary: "Crear Salario",
        SalaryDetail: "Detalle del Salario",
        AddSalary: "Agregar Salario",
        Deductions: "Deducciones",
        AllowanceType: "Tipo de Asignación",
        EffectiveDateIsRequired: "Fecha Efectiva Requerida",
        BasicSalaryIsRequired: "Salario Básico Requerido",
        Negativesalaryisnotallowed: "No se permite salario negativo",
        Benefit: "Beneficio",
        noDataMessage: "– ¡Crea un Salario ahora!",
        EnterIncrementPercentage: "Por favor, ingresa el Porcentaje de Incremento",
        salaryFilterTypeEnum: {
            [SalaryFilterTypeEnum.Salaries]: "Salarios",
            [SalaryFilterTypeEnum.CreateByMe]: "Creado Por Mí",
            [SalaryFilterTypeEnum.ForApproval]: "Para Aprobación",
        },
        statusEnum: {
            [EmployeeSalaryStatusEnum.InProcess]: "En Proceso",
            [EmployeeSalaryStatusEnum.Approved]: "Aprobado",
            [EmployeeSalaryStatusEnum.Declined]: "Rechazado",
            [EmployeeSalaryStatusEnum.Cancelled]: "Cancelado",
            [EmployeeSalaryStatusEnum.Hold]: "En Espera",
            [EmployeeSalaryStatusEnum.WaitingForOtherApproval]: "Esperando Otra Aprobación",
        },
        SortEnum: {
            [EmployeeSalaryFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [EmployeeSalaryFilterSortEnum.CreateDateAsc]: "Fecha de Creación",
            [EmployeeSalaryFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [EmployeeSalaryFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización",
            [EmployeeSalaryFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [EmployeeSalaryFilterSortEnum.ReferenceNo]: "Número de Referencia",

        }
    },
    resignations: {
        resignationHeader: "Renuncias",
        resignationDate: "Fecha de Renuncia",
        createresignation: "Crear Renuncia",
        noDataMessage: "– ¡Crea una Renuncia ahora!",
        reasonforresignation: "Motivo de la Renuncia",
        istermination: "Es Terminación",
        resignationDetail: "Detalle de la Renuncia",
        statusEnum: {
            [ResignationApprovalStatusEnum.InProcess]: "En Proceso",
            [ResignationApprovalStatusEnum.Approved]: "Aprobado",
            [ResignationApprovalStatusEnum.Declined]: "Rechazado",
            [ResignationApprovalStatusEnum.Cancelled]: "Cancelado",
            [ResignationApprovalStatusEnum.Completed]: "Completado",
            [ResignationApprovalStatusEnum.Hold]: "En Espera",
        },
        resignationFilterTypeEnum: {
            [ResignationFilterTypeEnum.Resignation]: "Renuncia",
            [ResignationFilterTypeEnum.CreateByMe]: "Creado Por Mí",
            [ResignationFilterTypeEnum.ForApproval]: "Para Aprobación",
        },
        resignationTypeEnum: {
            [ResignationTypeEnum.Resignation]: "Renuncia",
            [ResignationTypeEnum.Termination]: "Terminación",
            [ResignationTypeEnum.Both]: "Ambos",
        },
        SortEnum: {
            [ResignationFilterSortEnum.CreateDateDesc]: "Fecha de Creación - Descendente",
            [ResignationFilterSortEnum.CreateDateAsc]: "Fecha de Creación",
            [ResignationFilterSortEnum.UpdateDateDesc]: "Fecha de Actualización - Descendente",
            [ResignationFilterSortEnum.UpdateDateAsc]: "Fecha de Actualización",
            [ResignationFilterSortEnum.ReferenceNoDesc]: "Número de Referencia - Descendente",
            [ResignationFilterSortEnum.ReferenceNo]: "Número de Referencia",
        },
        ResignationPurposeEnum: {
            [ResignationPurposeEnum.InsufficientWorkLifeBalance]: "Falta de Equilibrio Entre Trabajo y Vida Personal",
            [ResignationPurposeEnum.UnrealisticGoalsAndPerformanceObjectives]: "Metas y Objetivos de Rendimiento Irrealistas",
            [ResignationPurposeEnum.LackOfAClearPathForCareerAdvancement]: "Falta de un Claro Camino para el Avance Profesional",
            [ResignationPurposeEnum.FeelUnsupportedByManage]: "Sentirse No Apoyado por la Dirección",
            [ResignationPurposeEnum.DontFeelChallenged]: "No Sentirse Retado",
            [ResignationPurposeEnum.TimeOffAndFlexibility]: "Tiempo Libre y Flexibilidad",
            [ResignationPurposeEnum.Relocation]: "Reubicación",
            [ResignationPurposeEnum.Others]: "Otros",
        },
    },
    Employees: {
        employeeAttachments: "Adjuntos del empleado",
        CreateEmployeeNow: "Crear empleado ahora",
        ProbationPeriod: "Período de prueba (días)",
        requiredMessageProbation: "Por favor ingrese su período de prueba",
        requiredMessageProbationPeriod: "El período de prueba debe estar entre 0 y 30",
        EmployeeNo: "Número de empleado",
        EnterEmployeeNo: "Ingrese número de empleado",
        EmploymentType: "Tipo de empleo",
        SelectEmploymentType: "Seleccionar tipo de empleo",
        requiredMessageEmploymentType: "Por favor seleccione el tipo de empleo",
        OfficeShift: "Turno de oficina",
        requiredMessageOfficeShift: "Por favor seleccione el turno de oficina",
        SelectOfficeShift: "Seleccionar turno de oficina",
        UpdateBasicInfo: "Actualizar información básica",
        Degree: "Grado",
        EnterDegree: "Ingrese grado",
        requiredDegree: "Por favor ingrese el grado",
        Institute: "Instituto",
        EnterInstituteName: "Ingrese nombre del instituto",
        requiredMessageInstitute: "Por favor ingrese el nombre del instituto",
        ObtainedMarks: "Marcas obtenidas",
        requiredMessageObtained: "Por favor ingrese las marcas obtenidas",
        EnterObtainedMarks: "Ingrese marcas obtenidas",
        TotalMarks: "Marcas totales",
        EnterTotalMarks: "Ingrese marcas totales",
        requiredMessageMarks: "Por favor ingrese las marcas totales",
        UpdateEducation: "Actualizar educación",
        AddEducation: "Agregar educación",
        WorkExperienceInfo: "Información de experiencia laboral",
        UpdateExperience: "Actualizar experiencia",
        AddExperience: "Agregar experiencia",
        EmergencyInfo: "Información de emergencia",
        UpdateEmergencyForm: "Actualizar formulario de emergencia",
        AddEmergency: "Agregar emergencia",
        UpdateEmergency: "Actualizar emergencia",
        UpdateBank: "Actualizar banco",
        AddBank: "Agregar banco",
        EnableEmployee: "¿Está seguro de habilitar este empleado?",
        DisableEmployee: "¿Está seguro de deshabilitar este empleado?",
        Enable: "Habilitar",
        Disabled: "Deshabilitado",
        UpdateFamily: "Actualizar familia",
        AddFamily: "Agregar familia",
        AddEmployeesLinkage: "Agregar enlace de empleados",
        AllEmployees: "Todos los empleados",
        FamilyInfo: "Información familiar",
        Basic_Info: "Información básica",
        Email_Configuration: "Configuración de correo electrónico",
        Bank_Detail: "Detalle bancario",
        Education: "Educación",
        Emergency_Info: "Información de emergencia",
        Work_Experience: "Experiencia laboral",
        Experience: "Experiencia",
        Rebate: "Descuento",
        Salary: "Salario",
        Leaves: "Permisos",
        Devices: "Dispositivos",
        basicInfo: "Información básica",
        Appraisal: "Evaluación",
        Family: "Familia",
        Attachments: "Adjuntos",
        My_Team: "Mi equipo",
        Employees_Linkage: "Vínculo de empleados",
        Company_Goal: "Objetivo de la empresa",
        Individual_Goal: "Objetivo individual",
        Emails: "Correos electrónicos",
        Finance: "Finanzas",
        Goal: "Metas",
        RebateInfo: "Información de descuento",
        UpdateRebate: "Actualizar descuento",
        AddRebate: "Agregar descuento",
        AddSalary: "Agregar salario",
        AddEmailConfiguration: "Agregar configuración de correo electrónico",
        AddBank: "Agregar detalle bancario",
        Statistics: "Estadísticas",

    },
    leaves: {
        leave: "Permisos",
        leavedetail: "Detalles de permisos",
        Leave: "Tipo de permiso",
        leaveType: "Tipo de permiso",
        enterLeave: "Ingrese tipo de permiso",
        save: "Guardar tipo de permiso",
        Add: "Agregar tipo de permiso",
        allotLeaves: "Permisos asignados",
        balanceLeaves: "Tipos de permisos (permisos restantes)",
        leaveFor: "Permiso para",
        leaveDates: "Fechas de permiso",
        createleave: "Crear permiso",
        messages: "Por favor seleccione el tipo de permiso",
        allocatedLeaves: "Permisos asignados",
        availedLeaves: "Permisos utilizados",
        remainingLeaves: "Permisos restantes",
        noDataMessage: " – ¡Cree un permiso ahora!",
        Leavesinfo: "Información de permisos",
        UpdateLeave: "Actualizar permiso",
        leavesFilterTypeEnum: {
            [LeaveFilterTypeEnum.All]: "Todos los permisos",
            [LeaveFilterTypeEnum.CreatedByMe]: "Creados por mí",
            [LeaveFilterTypeEnum.ForApproval]: "Para aprobación",
            [LeaveFilterTypeEnum.LeaveForMe]: "Mis permisos",
        },
        statusEnum: {
            [LeaveStatusEum.InProcess]: "En proceso",
            [LeaveStatusEum.Approved]: "Aprobado",
            [LeaveStatusEum.Declined]: "Rechazado",
            [LeaveStatusEum.Resend]: "Reenviado",
            [LeaveStatusEum.InActive]: "Inactivo",
            [LeaveStatusEum.NotRequired]: "No requerido",
            [LeaveStatusEum.Cancelled]: "Cancelado",
            [LeaveStatusEum.Hold]: "En espera",
        },
        SortEnum: {
            [LeaveFilterSortEnum.CreateDateDesc]: "Fecha de creación - Descendente",
            [LeaveFilterSortEnum.CreateDateAsc]: "Fecha de creación",
            [LeaveFilterSortEnum.UpdateDateDesc]: "Fecha de actualización - Descendente",
            [LeaveFilterSortEnum.UpdateDateAsc]: "Fecha de actualización",
            [LeaveFilterSortEnum.ReferenceNoDesc]: "Número de referencia - Descendente",
            [LeaveFilterSortEnum.ReferenceNo]: "Número de referencia",
        }
    },
    bonus: {
        Bonus: "Bonificación",
        createBonus: "Crear bonificación",
        BonusTo: "Bonificación a",
        CurrentSalary: "Salario actual",
        noDataMessage: " – ¡Cree una bonificación ahora!",
        PercentageShouldNotExceed: "El porcentaje no debe exceder el 100",
        bonusFilterTypeEnum: {
            [BonusFilterTypeEnum.All]: "Todas las bonificaciones",
            [BonusFilterTypeEnum.CreatedByMe]: "Creadas por mí",
            [BonusFilterTypeEnum.ForApproval]: "Para aprobación",
            [BonusFilterTypeEnum.MyBonus]: "Mis bonificaciones",
        },
        statusEnum: {
            [BonusStatusEnum.InProcess]: "En proceso",
            [BonusStatusEnum.Approved]: "Aprobado",
            [BonusStatusEnum.Declined]: "Rechazado",
            [BonusStatusEnum.Cancelled]: "Cancelado",
            [BonusStatusEnum.Inactive]: "Inactivo",
            [BonusStatusEnum.NotRequired]: "No requerido",
            [BonusStatusEnum.Resend]: "Reenviado",
            [BonusStatusEnum.WaitingForOtherApproval]: "Esperando aprobación de otros",
            [BonusStatusEnum.Hold]: "En espera",
            [BonusStatusEnum.Disbursed]: "Desembolsado",
            [BonusStatusEnum.Completed]: "Completado",
        },

        SortEnum: {
            [BonusFilterSortEnum.CreateDateDesc]: "Fecha de creación - Descendente",
            [BonusFilterSortEnum.CreateDateAsc]: "Fecha de creación",
            [BonusFilterSortEnum.UpdateDateDesc]: "Fecha de actualización - Descendente",
            [BonusFilterSortEnum.UpdateDateAsc]: "Fecha de actualización",
            [BonusFilterSortEnum.ReferenceNoDesc]: "Número de referencia - Descendente",
            [BonusFilterSortEnum.ReferenceNo]: "Número de referencia",
            [BonusFilterSortEnum.Amount]: "Cantidad",
            [BonusFilterSortEnum.AmountDesc]: "Cantidad - Descendente",
            [BonusFilterSortEnum.Salary]: "Salario",
            [BonusFilterSortEnum.SalaryDesc]: "Salario - Descendente"
        }
    },
    chartOfAccount: {
        ChartOfAccount: "Plan de Cuentas",
        CreateAccount: "Crear Cuenta",
        CreateChartOfAccount: "Crear Plan de Cuentas",
        accountType: "Tipo de Cuenta",
        selectType: "Seleccionar Tipo",
        parentAccount: "Cuenta Padre",
        selectParent: "Seleccionar Padre",
        EnterAccountType: "Por favor ingrese el Tipo de Cuenta",
        clossingBalance: "Saldo de Cierre",
    },
    ledgerReports: {
        reports: "Reportes",
        selectAccount: "Seleccionar Cuenta",
        selectDate: "Seleccionar Fecha",
        generateReport: "Generar Reporte",
        VoucherNo: "Número de Comprobante",
    },
    requisitions: {
        requisition: "Requisiciones",
        MyRequisitions: "Mis Requisiciones",
        createRequisition: "Crear Requisición",
        PleaseEnterRequisitionName: "Por favor, ingrese el nombre de la requisición",
        EnterRequisitionName: "Ingrese el nombre de la requisición",
        Budget: "Presupuesto",
        EnterBudget: "Ingrese el presupuesto",
        Dateofrequestexpire: "Fecha de vencimiento de la solicitud",
        noDataMessage: "– Cree requisiciones ahora!",
    },
    DefaultApprovers: {
        TypeEnum: {
            [DefaultApprovalTypeEnum.Expense]: "Gasto",
            [DefaultApprovalTypeEnum.ExpenseFinancers]: "Financiadores de Gastos",
            [DefaultApprovalTypeEnum.Travel]: "Viaje",
            [DefaultApprovalTypeEnum.TravelAgent]: "Agente de Viajes",
            [DefaultApprovalTypeEnum.Loan]: "Préstamo",
            [DefaultApprovalTypeEnum.Leave]: "Permiso",
            [DefaultApprovalTypeEnum.Asset]: "Activo",
            [DefaultApprovalTypeEnum.Salary]: "Salario",
            [DefaultApprovalTypeEnum.Payroll]: "Nómina",
            [DefaultApprovalTypeEnum.Reward]: "Recompensa",
            [DefaultApprovalTypeEnum.ResignationHr]: "Renuncia RH",
            [DefaultApprovalTypeEnum.ResignationAdmin]: "Renuncia Admin",
            [DefaultApprovalTypeEnum.ResignationIt]: "Renuncia TI",
            [DefaultApprovalTypeEnum.ResignationFinance]: "Renuncia Finanzas",
            [DefaultApprovalTypeEnum.ResignationExit]: "Salida por Renuncia",
            [DefaultApprovalTypeEnum.Requisition]: "Requisición",
            [DefaultApprovalTypeEnum.CustomApproval]: "Aprobación Personalizada",
            [DefaultApprovalTypeEnum.Forms]: "Formularios",
            [DefaultApprovalTypeEnum.Appraisals]: "Evaluaciones",
            [DefaultApprovalTypeEnum.Promotion]: "Promoción",
            [DefaultApprovalTypeEnum.Warning]: "Advertencia",
            [DefaultApprovalTypeEnum.Bonus]: "Bonificación",
            [DefaultApprovalTypeEnum.Complains]: "Quejas",
            [DefaultApprovalTypeEnum.Career]: "Carrera",
            [DefaultApprovalTypeEnum.Quotations]: "Cotizaciones",
            [DefaultApprovalTypeEnum.RequisitionFinal]: "Requisición Final",
            [DefaultApprovalTypeEnum.Attendance]: "Asistencia",
            [DefaultApprovalTypeEnum.Auction]: "Subasta",
        },
    },
    NonMandatoryApprovers: {
        TypeEnum: {
            [NonMandatoryApprovalTypeEnum.Expense]: "Gasto",
            [NonMandatoryApprovalTypeEnum.Travel]: "Viaje",
            [NonMandatoryApprovalTypeEnum.Loan]: "Préstamo",
            [NonMandatoryApprovalTypeEnum.Leave]: "Permiso",
            [NonMandatoryApprovalTypeEnum.Asset]: "Activo",
            [NonMandatoryApprovalTypeEnum.Salary]: "Salario",
            [NonMandatoryApprovalTypeEnum.Payroll]: "Nómina",
            [NonMandatoryApprovalTypeEnum.ExpenseFinancers]: "Financiadores de Gastos",
            [NonMandatoryApprovalTypeEnum.TravelAgent]: "Agente de Viajes",
            [NonMandatoryApprovalTypeEnum.Reward]: "Recompensa",
            [NonMandatoryApprovalTypeEnum.ResignationHr]: "Renuncia RH",
            [NonMandatoryApprovalTypeEnum.ResignationAdmin]: "Renuncia Admin",
            [NonMandatoryApprovalTypeEnum.ResignationIt]: "Renuncia TI",
            [NonMandatoryApprovalTypeEnum.ResignationFinance]: "Renuncia Finanzas",
            [NonMandatoryApprovalTypeEnum.ResignationExit]: "Salida por Renuncia",
            [NonMandatoryApprovalTypeEnum.Requisition]: "Requisición",
            [NonMandatoryApprovalTypeEnum.Warning]: "Advertencia",
            [NonMandatoryApprovalTypeEnum.Complain]: "Queja",
            [NonMandatoryApprovalTypeEnum.Bonus]: "Bonificación",
            [NonMandatoryApprovalTypeEnum.Promotion]: "Promoción",
            [NonMandatoryApprovalTypeEnum.RequestForItem]: "Solicitud de Artículo",
            [NonMandatoryApprovalTypeEnum.CustomApproval]: "Aprobación Personalizada",
            [NonMandatoryApprovalTypeEnum.Form]: "Formulario",
            [NonMandatoryApprovalTypeEnum.Document]: "Documento",
            [NonMandatoryApprovalTypeEnum.Career]: "Carrera",
            [NonMandatoryApprovalTypeEnum.RequisitionFinalApproval]: "Aprobación Final de Requisición",
            [NonMandatoryApprovalTypeEnum.RequestForItemAssetController]: "Solicitud de Artículo Controlador de Activos",
            [NonMandatoryApprovalTypeEnum.Quotation]: "Cotización",
            [NonMandatoryApprovalTypeEnum.QuotationClient]: "Cliente de Cotización",
            [NonMandatoryApprovalTypeEnum.Appraisal]: "Evaluación",
            [NonMandatoryApprovalTypeEnum.Attendance]: "Asistencia",
            [NonMandatoryApprovalTypeEnum.Auction]: "Subasta",
        }
    },
    activityLog: {
        DeviceType: "Tipo de Dispositivo",
        DeviceToken: "Token del Dispositivo",
        OSVersion: "Versión del SO",
        Device: "Dispositivo",
        IP: "IP",
        Comment: "Comentario",
        DeviceName: "Nombre del Dispositivo",
        LoginDate: "Fecha de Inicio de Sesión",
    },
    ErrorMessage: {
        someThingWentWrong: "Algo salió mal",
    },
    setting: {
        BasicInformation: "Información Básica",
        ChangePassword: "Cambiar Contraseña",
        Notifications: "Notificaciones",
        Mobile: "Móvil #",
        About: "Acerca de",
        ExistingPassword: "Contraseña Actual",
        EnterExistingPassword: "Ingrese la Contraseña Actual",
        NewPassword: "Nueva Contraseña",
        EnterNewPassword: "Ingrese la Nueva Contraseña",
        ConfirmPassword: "Confirmar Contraseña",
        EnterConfirmPassword: "Ingrese la Confirmación de Contraseña",
        Signature: "Firma del Documento",
        NoSignature: "Por favor, cree una firma",
        ErrorSignature: "Error al Crear la Firma",
        NoSignatureSave: "No hay firma para guardar",
        SignatureSaved: "Firma Guardada",
        SaveSignature: "Guardar Firma",
        ClearSignature: "Borrar Firma",
        SignatureCreated: "Firma Creada",
        SignatureActive: "Activa",
        FailedSignature: "Error al obtener las firmas",

        BlockNotificationFeatureTypeEnum: {
            [NotificationBlockFeatureTypeEnum.Reward]: "Recompensa",
            [NotificationBlockFeatureTypeEnum.Feed]: "Feed",
            [NotificationBlockFeatureTypeEnum.Document]: "Documento",
            [NotificationBlockFeatureTypeEnum.Project]: "Proyecto",
            [NotificationBlockFeatureTypeEnum.Task]: "Tarea",
            [NotificationBlockFeatureTypeEnum.WorkBoard]: "Tablero de Trabajo",
            [NotificationBlockFeatureTypeEnum.Schedule]: "Horario",
            [NotificationBlockFeatureTypeEnum.Group]: "Grupo",
            [NotificationBlockFeatureTypeEnum.Messenger]: "Mensajero",
            [NotificationBlockFeatureTypeEnum.Expense]: "Gasto",
            [NotificationBlockFeatureTypeEnum.Auction]: "Subasta",
            [NotificationBlockFeatureTypeEnum.Leave]: "Permiso",
            [NotificationBlockFeatureTypeEnum.Travel]: "Viaje",
            [NotificationBlockFeatureTypeEnum.Warning]: "Advertencia",
            [NotificationBlockFeatureTypeEnum.Complain]: "Queja",
            [NotificationBlockFeatureTypeEnum.Bonus]: "Bonificación",
            [NotificationBlockFeatureTypeEnum.Promotion]: "Promoción",
            [NotificationBlockFeatureTypeEnum.Loan]: "Préstamo",
            [NotificationBlockFeatureTypeEnum.CustomApproval]: "Aprobación Personalizada",
            [NotificationBlockFeatureTypeEnum.Form]: "Formulario",
            [NotificationBlockFeatureTypeEnum.EmployeeSalary]: "Salario del Empleado",
            [NotificationBlockFeatureTypeEnum.Payroll]: "Nómina",
            [NotificationBlockFeatureTypeEnum.Career]: "Carrera",
            [NotificationBlockFeatureTypeEnum.Requisition]: "Requisición",
            [NotificationBlockFeatureTypeEnum.Resignation]: "Renuncia",
            [NotificationBlockFeatureTypeEnum.Quotation]: "Cotización",
            [NotificationBlockFeatureTypeEnum.Appraisal]: "Evaluación",
            [NotificationBlockFeatureTypeEnum.LeadManager]: "Gerente de Clientes Potenciales",
            [NotificationBlockFeatureTypeEnum.DiscussionBoard]: "Tablero de Discusión",
            [NotificationBlockFeatureTypeEnum.Pages]: "Páginas",
        }
    },
    emailConfiguration: {
        emailConfiHeader: "Configuración de Correo Electrónico del Usuario",
        emailConfi: "Nombre de Usuario",
        password: "Contraseña",
        enterEmailConfi: "Ingrese Nombre de Usuario",
        incomingPort: "Puerto de Entrada",
        enterIncomingPort: "Ingrese Puerto de Entrada",
        incomingServerAddress: "Dirección del Servidor de Entrada",
        enterIncomingServerAddress: "Ingrese Dirección del Servidor de Entrada",
        outgoingPort: "Puerto de Salida",
        enterOutgoingPort: "Ingrese Puerto de Salida",
        outgoingServerAddress: "Dirección del Servidor de Salida",
        enterOutgoingServerAddress: "Ingrese Dirección del Servidor de Salida",
        provider: "Proveedor",
        enterProvider: "Ingrese Proveedor",
        saveEmailConfi: "Guardar Configuración de Correo Electrónico",
        addEmailConfi: "Agregar Configuración de Correo Electrónico",
        name: "Nombre",
        clear: "Borrar",
    },
    assetsAllocation: {
        assetsAllocation: "Asignación de Activos",
        assests: "Activos",
        createAssetAllocation: "Crear Asignación de Activos",
        handover: "Entrega",
        forMaintenance: "Para Mantenimiento",
        noResultFound: "No se encontraron resultados...",
        addAssetsAllocation: "Agregar Asignación de Activos",
        noDataMessage: "– Cree asignaciones de activos ahora!",
        allocationForMe: "Asignación Para Mí",
        allocationApprovals: "Aprobaciones de Asignación",
        deAllocation: "Cambiar Estado de Asignación",
        liability: "Pasivo",
        capital: "Capital",
        revenue: "Ingresos",
        expense: "Gasto",
        costOfGoodsale: "Costo de Venta de Mercancías",
        assetsCategory: "Categoría de Activos",
        parentAccount: "Cuenta Principal",
        selectParent: "Seleccionar Principal",
        assetsList: "Lista de Activos",
        createAssests: 'Crear Activos',
        sno: 'Núm.',
        inventoryName: 'Nombre de Inventario',
        inventoryValue: 'Valor de Inventario',
        SelectAssetsController: "Seleccionar Controlador de Activos",
    },
    requestListItems: {
        noDataMessage: "– Cree una solicitud ahora!",
        requestForItems: "Solicitud de Artículos",
        RequestForItemsApprovals: "Aprobaciones de Solicitud de Artículos",
        AssetController: "Controlador de Activos",
        RequestItem: "Item de Solicitud",
    },
    profile: {
        firstMenu: '  Primer ítem de menú',
        secondMenu: ' Segundo ítem de menú (deshabilitado)',
        thirdMenu: ' Tercer ítem de menú (deshabilitado)',
        dangerItem: 'Ítem de peligro',
        NoPosition: "Sin Posición",
        NoInstitute: "Sin Instituto",
        NotFound: "No encontrado",
        University: "Universidad",
        School: "Escuela",
        Graduated: "Graduado",
        CurrentlyWorkHere: "Actualmente trabajo aquí",
        Statistics: "Estadísticas",
        StorageStatistics: "Estadísticas de almacenamiento",
        TotalStorageUsed: "Almacenamiento total utilizado",
    },
    /***STATUS ENUMS FOR APPROVAL ITEMS EXPENSE***/
    status: {
        InProcess: "En Proceso",
        Approved: "Aprobado",
        Declined: "Rechazado",
        Resend: "Reenviar",
        Inactive: "Inactivo",
        NotRequired: "No Requerido",
        Cancelled: "Cancelado",
        ApprovalRequired: "Aprobación Requerida",
        Hold: "En Espera",
        WaitingForOtherApprover: "Esperando Aprobación de Otros",
        Draft: "Borrador",
        Remark: "Comentario",
        Removed: "Eliminado"
    },
    approvalDictionaryLabel: {
        Approval: "Aprobación",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Ejecutor",
        [ApprovalsModuleEnum.ExpenseFinance]: "Finanzas",
        [ApprovalsModuleEnum.ResignationHrApproval]: "RRHH",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Finanzas",
        [ApprovalsModuleEnum.ResignationItApproval]: "TI",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Admin",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Otras Aprobaciones",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Entrevista de Salida",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Reportando A",
        [ApprovalsModuleEnum.TravelApproval]: "Aprobadores",
        [ApprovalsModuleEnum.TravelAgent]: "Agente",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Aprobadores Finales",
        [ApprovalsModuleEnum.LoanApproval]: "Aprobadores",
        [ApprovalsModuleEnum.AuctionApproval]: "Aprobador"
    },
    approvalDictionaryPlaceHolder: {
        Approval: "Aprobación",
        [ApprovalsModuleEnum.ExpenseExecutor]: "Seleccione el Ejecutor del Gasto",
        [ApprovalsModuleEnum.ExpenseFinance]: "Seleccione los Aprobadores de Finanzas",
        [ApprovalsModuleEnum.ExpenseApproval]: "Seleccione los Aprobadores de Gastos",
        [ApprovalsModuleEnum.ResignationHrApproval]: "Seleccione RRHH",
        [ApprovalsModuleEnum.ResignationFinanceApproval]: "Seleccione Finanzas",
        [ApprovalsModuleEnum.ResignationItApproval]: "Seleccione TI",
        [ApprovalsModuleEnum.ResignationAdminApproval]: "Seleccione Admin",
        [ApprovalsModuleEnum.ResignationOtherApproval]: "Seleccione Otras Aprobaciones",
        [ApprovalsModuleEnum.ResignationExitApproval]: "Seleccione Entrevista de Salida",
        [ApprovalsModuleEnum.ResignationReportingToApproval]: "Seleccione Reportando A",
        [ApprovalsModuleEnum.TravelApproval]: "Seleccione Aprobadores para Viajes",
        [ApprovalsModuleEnum.TravelAgent]: "Seleccione Agente para Arreglos de Viaje",
        [ApprovalsModuleEnum.RequisitionFinalApproval]: "Seleccione Aprobadores Finales",
        [ApprovalsModuleEnum.LoanApproval]: "Seleccione Aprobadores",
        [ApprovalsModuleEnum.AuctionApproval]: "Seleccione Aprobadores"
    },
    /*** Define language write side ***/
    Direction: "ltr",
};
export default spanish