import { useEffect, useState, memo } from "react";
import {
  DefaultApprovalTypeEnum,
  defaultApprovers,
} from "../../../../defaultApprovers/utils";
import { NonMandatoryApprovalTypeEnum } from "../../../../nonMandatoryApproval/utils";
import { getAllDefaultApproversAction } from "../../../../defaultApprovers/store/action";
import { isNonMandatoryApprovalAction } from "../../../../nonMandatoryApproval/store/action";
import { groupByKey } from "../../../../../../utils/base";
import { useDispatch } from "react-redux";
import { ApprovalsModuleEnum } from "../../../../approval/utils/enum/approvalModuleEnum";
import ApprovalComposer from "../../../../approval/view/ApprovalCompser/approvalComposer";

function TravelApprovals({form , referenceId , referenceType}) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    users: [],
    defaultApprovals: {
      [DefaultApprovalTypeEnum.Travel]: [],
      [DefaultApprovalTypeEnum.TravelAgent]: [],
    },
    isNonMandatoryApproval: {
      [NonMandatoryApprovalTypeEnum.Travel]: false,
      [NonMandatoryApprovalTypeEnum.TravelAgent]: false,
    },
  });
 
  const prepareDefaultApprovals = () => {
    dispatch(
      getAllDefaultApproversAction({
        types: [
          DefaultApprovalTypeEnum.Travel,
          DefaultApprovalTypeEnum.TravelAgent,
        ],
        referenceType,
        referenceId

      })
    ).then(({ payload = [] }) => {
      dispatch(
        isNonMandatoryApprovalAction({
          types: [
            NonMandatoryApprovalTypeEnum.Travel,
            NonMandatoryApprovalTypeEnum.TravelAgent,
          ],
          referenceType,
          referenceId

        })
      ).then((response) => {
        const groupByType = groupByKey(response.payload.data, "type") ?? [];
        const isNonMandatoryApproval = {
          [NonMandatoryApprovalTypeEnum.Travel]:
            groupByType[NonMandatoryApprovalTypeEnum.Travel][0].status,
          [NonMandatoryApprovalTypeEnum.TravelAgent]:
            groupByType[NonMandatoryApprovalTypeEnum.TravelAgent][0]?.status,
        };
        const getDefaultApprovalsByType = (type) => {
          return payload
            .filter((x) => x.type === type)
            .map(({ member }) => {
              return member;
            });
        };

        const finalDefaultApproval = {
          [DefaultApprovalTypeEnum.Travel]: getDefaultApprovalsByType(
            DefaultApprovalTypeEnum.Travel
          ),
          [DefaultApprovalTypeEnum.TravelAgent]: getDefaultApprovalsByType(
            DefaultApprovalTypeEnum.TravelAgent
          ),
        };
        setState({
          ...state,
          defaultApprovals: finalDefaultApproval,
          isNonMandatoryApproval: isNonMandatoryApproval,
        });
      });
    });
  };

  useEffect(() => {
    prepareDefaultApprovals();
  }, []);


  return (
    <>
      <ApprovalComposer
        module={ApprovalsModuleEnum.TravelApproval}
        defaultApprovals={
          state.defaultApprovals[DefaultApprovalTypeEnum.Travel]
        }
        setManager={true}
        users={[]}
        isNonMandatoryApproval={
          state.isNonMandatoryApproval[NonMandatoryApprovalTypeEnum.Travel]
        }
        form={form}
        referenceId={referenceId}
        referenceType={referenceType}
      />
      <ApprovalComposer
        module={ApprovalsModuleEnum.TravelAgent}
        isNonMandatoryApproval={
          state.isNonMandatoryApproval[NonMandatoryApprovalTypeEnum.TravelAgent]
        }
        defaultApprovals={
          state.defaultApprovals[DefaultApprovalTypeEnum.TravelAgent]
        }
        users={[]}
        setManager={false}
        form={form}
        referenceId={referenceId}
        referenceType={referenceType}
        isMultiSelect = {false}
      />
    </>
  );
}
export default memo(TravelApprovals);
