import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LanguageChangeContext } from "../../../../../utils/localization/localContext/LocalContext";
import { dictionaryList } from "../../../../../utils/localization/languages";
import { useSelector } from "react-redux";
import moment from "moment";
import { getQuotationById } from "../../store/actions";
import { readCountforQuotation, removeIdentifier } from "../../store/slice";
import { NotificationFeatureTypeEnum } from "../../../notifiation/enums";
import { readNotificationAction } from "../../../notifiation/store/action";
import { Skeleton, Tag } from "antd";
import { ItemHeader, SingleItem } from "../../../../sharedComponents/Card/CardStyle";
import UserInfo from "../../../../sharedComponents/UserShortInfo/UserInfo";
import SublineDesigWithTime from "../../../../sharedComponents/UserShortInfo/SubLine/DesigWithTime";
import StatusTag from "./statusTag";
import { ApprovalsModule } from "../../../../sharedComponents/AppComponents/Approvals/enums";
import RemarksApproval from "../../../../sharedComponents/AppComponents/Approvals/view";
import { fileExtentionPreview } from "../../../travel/utils/fileExtentionHelper";
import convertUTCtoLocal, { timeFromNow } from "../../../../sharedComponents/DateHandler/dateUTCtoLocal";
import { formatNumberWithCommas } from "../../../../../utils/Shared/helper/formatNumberWithCommas";

export default function QuotationDetail({ id, index }) {
    const dispatch = useDispatch();
    const { userLanguage } = useContext(LanguageChangeContext);
    const { sharedLabels, quotations } = dictionaryList[userLanguage];
    const { user } = useSelector((state) => state.userSlice);
    const [state, setState] = useState({
        identifier: moment().unix(),
        quotationDetail: {
            creator: {},
            description: "",
            image: "",
            approvers: [],
            status: 1,
            referenceNo: 0,
            createDate: "",
            attachments: [],
            isLoading: true,
            notificationCount: 0,
            details: [],
            client: {},
            deliveryDate : null,
            expiryDate: null,
            quotationDate: null,
            netAmount: 0,
            taxAmount:0,
            amount:0,
        },
        approvers: {
            isOpen: false,
            data: [],
        },
    });
    const { data } = useSelector((state) => state.quotationSlice);
    let quotationDetail = data !== null ? data[state.identifier] : [];
    quotationDetail =
        quotationDetail &&
        quotationDetail?.length > 0 &&
        quotationDetail[0];

    useEffect(() => {
        id &&
            dispatch(getQuotationById({ id: id, identifier: state.identifier }));
    }, [id]);

    useEffect(() => {
        quotationDetail &&
            setState({
                ...state,
                quotationDetail: {
                    ...state.quotationDetail,
                    ...quotationDetail,
                    isLoading: false,
                },
                approvers: {
                    ...state.approvers,
                    data: quotationDetail?.approvers,
                },
            });
    }, [quotationDetail]);

    useEffect(() => {
        return () => {
            dispatch(removeIdentifier({ identifier: state.identifier }));
        };
    }, []);

    const {
        creator,
        description,
        image = "",
        approvers = [],
        status,
        referenceNo,
        createDate,
        attachments,
        notificationCount,
        details,
        client,
        deliveryDate,
        expiryDate,
        quotationDate,
        netAmount,
        taxAmount,
        amount,
    } = state.quotationDetail
    useEffect(() => {
        if (notificationCount > 0) {
            const payload = {
                filter: {
                    featureType: NotificationFeatureTypeEnum.Quotation,
                    featureId: id
                },
            }

            dispatch(readNotificationAction(payload)).then(response => {
                dispatch(readCountforQuotation(id))
            })
        }
    }, [notificationCount])
    if (state.quotationDetail.isLoading) return <Skeleton />;

    return (<>
        <SingleItem >
            <ItemHeader>
                <div className="left">
                    <UserInfo
                        avatarSrc={creator?.image}
                        name={creator?.name}
                        Subline={
                            <SublineDesigWithTime
                                designation={creator.designation ? creator.designation : ""}
                                time={timeFromNow(createDate)}
                            />
                        }
                    />
                </div>
                <div className="right">
                    <Tag className="IdTag">{referenceNo}</Tag>
                    <StatusTag status={status}></StatusTag>
                </div>
            </ItemHeader>

            <div className="mt-6 mb-6">
    <table className="w-full text-center">
        <thead>
            <tr style={{ backgroundColor: "#526bb1", color: "#fff" }}>
                <th>{"Item"}</th>
                <th>{quotations.Rate}</th>
                <th>{quotations.Quantity}</th>
                <th>{"Tax (%)"}</th>
                <th>{"Tax Amount"}</th>
                <th>{"Total Amount"}</th>
            </tr>
        </thead>
        <tbody>
            {details.map((el, i) => {
                const taxAmount = (el.price * el.tax) / 100;
                const totalAmount = el.price + taxAmount;
                return (
                    <tr
                        key={i}
                        className="text-center text-sm"
                        style={{ borderBottom: "0.5px solid rgb(207 199 199 / 85%)" }}
                    >
                        <td>{el.item}</td>
                        <td>{el.price}</td>
                        <td>{el.quantity}</td>
                        <td>{`${el.tax}%`}</td>
                        <td>{taxAmount.toFixed(2)}</td>
                        <td>{totalAmount.toFixed(2)}</td>
                    </tr>
                );
            })}
        </tbody>
        <tfoot>
            <tr className="bg-gray-100">
                <td colSpan="4"></td> 
                <td className="text-right font-semibold">Total Amount:</td>
                <td className="text-center">{formatNumberWithCommas(amount.toFixed(2))}</td>
            </tr>
            <tr className="bg-gray-100">
                <td colSpan="4"></td>
                <td className="text-right font-semibold">Total Tax Amount:</td>
                <td className="text-center">{formatNumberWithCommas(taxAmount.toFixed(2))}</td>
            </tr>
            <tr className="bg-gray-100">
                <td colSpan="4"></td>
                <td className="text-right font-semibold">Net Amount:</td>
                <td className="text-center">{formatNumberWithCommas(netAmount.toFixed(2))}</td>
            </tr>
        </tfoot>
    </table>
</div>

            <div className="cardSections">
                <div className="cardSectionItem">
                    <div className="cardSection__title">
                        {quotations.ClientName}
                    </div>
                    <div className="cardSection__body">{client?.name}</div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">
                        {quotations.ClientEmail}
                    </div>
                    <div className="cardSection__body">{client?.email}</div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">
                        {quotations.PhoneNumber}
                    </div>
                    <div className="cardSection__body">{client?.phoneNumber}</div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">
                        {"Net Amount"}
                    </div>
                    <div className="cardSection__body">{`${netAmount ? formatNumberWithCommas(netAmount) : 0}`}</div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">
                        {"Quotation Date"}
                    </div>
                    <div className="cardSection__body">{`${convertUTCtoLocal(
                    quotationDate
                    , "DD MMM YYYY")}`}</div>
                </div>
                <div className="cardSectionItem">
                    <div className="cardSection__title">
                        {"Delivery Date"}
                    </div>
                    <div className="cardSection__body">{`${convertUTCtoLocal(
                    deliveryDate
                    , "DD MMM YYYY")}`}</div>
                </div>
                {expiryDate && <div className="cardSectionItem">
                    <div className="cardSection__title">
                        {"Expiry Date"}
                    </div>
                    <div className="cardSection__body">{`${convertUTCtoLocal(
                    expiryDate
                    , "DD MMM YYYY")}`}</div>
                </div>}

            </div>
            <div className="">
                <hr className="border-t-[2px]" />
                <h6 className="text-[16px] font-semibold py-2">{quotations.Attachments}</h6>
                <div
                    className="flex gap-4 items-center overflow-auto cursor-pointer"
                // onClick={() => dispatch(handleAttachmentModal(attachments))}
                >
                    {attachments.map((file) => (
                        <div className="rounded-lg h-[80px] w-[80px]  min-w-[80px] border overflow-hidden">
                            <img
                                className=" object-cover h-full w-full"
                                src={fileExtentionPreview(file.path)}
                                alt={file.id}
                                key={file.id}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Remarks Approval */}
            <RemarksApproval
                module={ApprovalsModule.QuotationApproval}
                status={status}
                reference={quotationDetail?.id}
                onStatusChanged={(prev) => { }}
                setApprovalDetail={(data) => { }}
                data={state.approvers.data && state.approvers.data}
                title={sharedLabels.approvers}
            />
        </SingleItem>
    </>)
}