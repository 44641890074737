export const DefaultApprovalsReferenceTypeEnum = {
  General : 1,
  Project : 2,
  Group : 3
}

export const projectDefaultApprovalTypeEnum = {

  Expense : 1,
  ExpenseFinance: 2,
  Travel: 4,
  Quotations:26
};


export const GroupDefaultApprovalTypeEnum = {
  Expense : 1,
  ExpenseFinancers: 2,
  Travel: 4,
  Quotations:26
};

export const DefaultApprovalTypeEnum = {
  Expense: 1,
  ExpenseFinancers: 2,
  Travel: 4,
  TravelAgent: 5,
  Loan: 6,
  Leave: 7,
  Asset: 8,
  Salary: 9,
  Payroll: 10,
  Reward: 11,
  ResignationHr: 12,
  ResignationAdmin: 13,
  ResignationIt: 14,
  ResignationFinance: 15,
  ResignationExit: 16,
  Requisition: 17,
  CustomApproval: 18,
  Forms: 19,
  Appraisals: 20,
  Promotion: 21,
  Warning: 22,
  Bonus: 23,
  Complains: 24,
  Career: 25,
  Quotations: 26,
  RequisitionFinal : 27,
  Auction : 28,
  Attendance : 29
};


export const SingleDefaultApprovals=[
  DefaultApprovalTypeEnum.ExpenseExecutor,
  DefaultApprovalTypeEnum.TravelAgent,
]


export const prepareListData = (data ,referenceType , moduleLabel)=>{
  console.log("referenceType" ,referenceType);
  if(referenceType == DefaultApprovalsReferenceTypeEnum.Project ){
    return  Object.entries(projectDefaultApprovalTypeEnum).map(([key, value]) =>{
      console.log(data , "datadatadatadata")
      return {
        label:moduleLabel[value],
        type:value,
        Member : data.filter((y) =>y?.type === value)?.map(({member})=> {
          return member
        })
      }
    })
  }else if(referenceType == DefaultApprovalsReferenceTypeEnum.Group ){
    return  Object.entries(GroupDefaultApprovalTypeEnum).map(([key, value]) =>{
      console.log(data , "datadatadatadata")
      return {
        label:moduleLabel[value],
        type:value,
        Member : data.filter((y) =>y?.type === value)?.map(({member})=> {
          return member
        })
      }
    })
  }else{
    return  Object.entries(DefaultApprovalTypeEnum).map(([key, value]) =>{
      console.log(data , "datadatadatadata")
      return {
        label:moduleLabel[value],
        type:value,
        Member : data.filter((y) =>y?.type === value)?.map(({member})=> {
          return member
        })
      }
    })
  }
 
}

