import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCitiesBankAction } from "../../../bankDetails/store/actions";
import EmployeeCitySelect from "./citySelect";

const CitySelectHandler = ({ isCountry = true, selectedCountry, form, selectedCityObj }) => {
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [pageNo, setPageNo] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNextPage, setIsNextPage] = useState(true);
  const pageSize = 20;

  const fetchCities = (newPageNo, newSearchTerm) => {
    const payload = {
      ...(isCountry && { countries: [selectedCountry] }),
      page: newPageNo,
      pageSize,
      textData: newSearchTerm,
    };

    dispatch(getCitiesBankAction(payload)).then((res) => {
      res?.payload?.data?.length < pageSize
        ? setIsNextPage(false)
        : setIsNextPage(true); // No more data to load

      const isPageReset = payload.page === 0;
      const updatedCities = isPageReset
        ? [...res?.payload?.data]
        : [...cities, ...res.payload.data];

      // Check if selectedCityObj is present in the fetched cities
      const isCityPresent = updatedCities.some(
        (city) => city.id === selectedCityObj?.id
      );

      // If selectedCityObj is not present and its valid add it to the cities array
      if (
        selectedCityObj &&
        !isCityPresent &&
        selectedCityObj?.countryId === selectedCountry
      ) {
        updatedCities.unshift({ ...selectedCityObj }); // Used selectedCityObj to avoid direct state mutation
      }

      // Filter out duplicates and update state
      const uniqueCities = updatedCities.filter(
        (city, index, self) => self.findIndex((c) => c.id === city.id) === index
      );
      setCities(uniqueCities);

      // Prevent page increase if less than pageSize
      if (!isPageReset && res.payload.data.length < pageSize) return;
      setIsLoading(false);
    });
  };

  const callFetchCities = (pageNo, searchTerm) => {
    (selectedCountry || !isCountry) &&
      (isNextPage || pageNo === 0) &&
      fetchCities(pageNo, searchTerm);
  };

  useEffect(() => {
    selectedCityObj
      ? setSelectedCity(selectedCityObj) // Set selectedCity from selectedCityObj prop
      : setSelectedCity(undefined); // Reset selectedCity if selectedCityObj is not provided
  }, [selectedCityObj]);

  useEffect(() => {
    pageNo !== 0 && callFetchCities(pageNo, searchTerm);
  }, [pageNo]);

  useEffect(() => {
    setPageNo(0);
    callFetchCities(0, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    selectedCityObj?.countryId !== selectedCountry &&
      setSelectedCity(undefined);
    setSearchTerm("");
    setPageNo(0);
    callFetchCities(0, "");
  }, [selectedCountry]);

  useEffect(() => {
    selectedCity?.id
      ? form.setFieldValue("cityId", selectedCity?.id)
      : form.setFieldValue("cityId", "");

      selectedCity?.name
      ? form.setFieldValue("city", selectedCity?.name)
      : form.setFieldValue("city", "");
  }, [selectedCity]);

  return (
    <EmployeeCitySelect
      key={selectedCountry}
      cities={cities}
      selectedCity={selectedCity}
      onSelectCity={(city) => {
        setSelectedCity(city);
        setSearchTerm("");
        setPageNo(0);
      }}
      loading={isLoading}
      disabled={isCountry ? !selectedCountry : false}
      onPageChange={(newPageNo) => {
        if (!isLoading) setPageNo(newPageNo);
      }}
      onSearch={(newSearchTerm) => {
        setSearchTerm(newSearchTerm);
      }}
    />
  );
};

export default CitySelectHandler;
